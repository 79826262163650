export default {
    apiKey: "AIzaSyBGNUp3NgY2wzw85vFJPddvPG-Jx6X2i8I",
    authDomain: "ffootip.firebaseapp.com",
    databaseURL: "https://ffootip.firebaseio.com",
    projectId: "ffootip",
    storageBucket: "ffootip.appspot.com",
    messagingSenderId: "507963401667",
    appId: "1:507963401667:web:73c36faa1c90f6a3024976",
    measurementId: "G-YS174TX587"
}
