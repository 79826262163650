import {levelsGroup} from 'src/tracker/model/column/groups/levels-group'
import {equipmentGroup} from 'src/tracker/model/column/groups/equipment-group'
import {summonBoardsGroup} from 'src/tracker/model/column/groups/summon-boards-group'
import {datesGroup} from 'src/tracker/model/column/groups/dates-group'
import {calculatedGroup} from 'src/tracker/model/column/groups/calculated-group'
import {standardGroup} from 'src/tracker/model/column/groups/standard-group'
import {allGroup} from 'src/tracker/model/column/groups/all-group'
import {objectAssigningReducer} from 'src/forecast/model/universal-data-converter'
import {utilizationGroup} from 'src/tracker/model/column/groups/utilization-group'
import { ColumnGroup } from 'src/tracker/model/column/groups/column-group'
import { Uuid } from 'src/forecast/model/universal-data-model'
import { AccessibleColumn, ColumnType, TrackerColumn } from '../tracker-column'
import { getPropertyFromId } from '../../property/property-deserializer'
import { DateProperty } from '../../property/date-property'
import { PlannerDateProperty } from '../../property/planner-date-property'
import { CharacterProperty } from '../../property/character-property'

const builtInDisplayableGroups = [standardGroup, allGroup, levelsGroup, equipmentGroup, summonBoardsGroup, utilizationGroup, calculatedGroup, datesGroup]

export function augmentDisplayableColumnGroups(...customGroups: ColumnGroup[]) {
  const seenGroupIds = new Set<Uuid>()
  builtInDisplayableGroups.forEach(group => {
    if (seenGroupIds.has(group.id)) {
      console.log(`Found duplicate group id ${group.id} on ${group.name}`)
    }
    seenGroupIds.add(group.id)
  })

  const seenColumnIds = new Set<Uuid>()
  const allColumns = new Set<TrackerColumn>(allGroup.columns)
  allColumns.forEach(column => {
    if (seenColumnIds.has(column.id)) {
      console.log(`Found duplicate column id ${column.id} on ${column.name}`)
    }
    seenColumnIds.add(column.id)
  })
  return [...builtInDisplayableGroups, ...customGroups]
}

const columnLookupMap = allGroup.columns.map(column => {
  return { [column.id]: column }
}).reduce(objectAssigningReducer)

export const getColumnFromId = (id: string) => {
  const candidateProperty = getPropertyFromId(id)
  if (candidateProperty !== undefined && (candidateProperty instanceof DateProperty || candidateProperty instanceof PlannerDateProperty)) {
    return new AccessibleColumn("100px", ColumnType.DATE, candidateProperty as CharacterProperty<any>)
  }
  return columnLookupMap[id]
}
