import {BtWeaponLimitBreak, Resource } from 'src/tracker/model/tracker-model'
import {exProperty} from 'src/tracker/model/property/equipment/weapon/ex-property'
import { pullableGroup } from "../equipment/weapon/weapon-group";
import { EnumerableCharacterProperty } from "../enumerable-character-property";
import { defaultCharacterDataNumberConverter } from '../../tracker-model-converter';
import { AllCharacterData } from '../../components/all-character-data';
import { Region } from '../../components/region';
import { DateTime } from 'luxon'
import { frProperty } from '../equipment/weapon/fr-property';
import { btProperty } from '../equipment/weapon/bt-property';

function getPowerStonesFromColumn(character: AllCharacterData | undefined, property: EnumerableCharacterProperty<any>, when: DateTime, forceToMaxValue: boolean, region: Region) {
  if (!character) {
    return 0
  }
  const maxVal = property.getMax(character, when, region)
  const currentVal = forceToMaxValue ? 0 : property.get(region, character)
  var refundForSelling = 4
  if (property.name === exProperty.name) {
    refundForSelling = 0
  } else if (property.name === frProperty.name && btProperty.getMax(character, when, region) > BtWeaponLimitBreak.ZERO) {
    refundForSelling = 80
  }
  const currentStones = property.valueToResource(currentVal, Resource.POWER_STONE)
  const maxStones = Math.max(0, property.valueToResource(maxVal, Resource.POWER_STONE) - refundForSelling)
  const output =  Math.max(0, maxStones - currentStones)
  return output
}

function derivePowerStonesRemaining(region: Region, character?: AllCharacterData, when: DateTime = DateTime.utc(), forceToMaxValue: boolean = false) {
  const sumOfAllPowerStonesRemaining = pullableGroup
    .map(prop => getPowerStonesFromColumn(character, prop, when, forceToMaxValue, region))
    .reduce((a, b) => a + b)
  return Math.max(0, sumOfAllPowerStonesRemaining)
}

export const powerStonesRemainingProperty = new EnumerableCharacterProperty<number>(
  "3fd9e76d-8b6d-45a3-bf78-cf12442c2d3b",
  "Power Stones Remaining",
  "P. Stone Req",
  defaultCharacterDataNumberConverter,
  derivePowerStonesRemaining,
  (character, when, region) => {
    const maxPowerStones = derivePowerStonesRemaining(region, character, when, true)
    return Array.from(new Array(maxPowerStones + 1), (x,i) => i)
  }
)
