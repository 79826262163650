import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shouldGetAsset } from "src/image-cache/image-effects";
import { selectImage } from "src/image-cache/image-selectors";

export enum WeaponBadgeLevel {
  NONE, NORMAL, PLUS
}

interface WeaponBadgeProperties {
  level: WeaponBadgeLevel,
  normalImageName: string
  plusImageName?: string,
  alt: string
}

const WeaponBadge: FunctionComponent<WeaponBadgeProperties> = ({
  level,
  normalImageName,
  plusImageName,
  alt
}) => {
  const imageName = (level === WeaponBadgeLevel.PLUS && !!plusImageName) ? plusImageName : normalImageName
  const image = useSelector(selectImage(imageName))

  const dispatch = useDispatch()
  useEffect(() => {
    if (!image) {
      dispatch(shouldGetAsset(imageName, imageName))
    }
  })

  if (image === null || level === WeaponBadgeLevel.NONE) {
    return null
  }

  const badgeStyling = {
    maxWidth: '100%',
    height: 'auto'
  }

  return <img src={image} alt={alt} style={badgeStyling} />
}

interface ImplementedWeaponBadgeProperties {
  level: WeaponBadgeLevel
}

export const ExWeaponBadge: FunctionComponent<ImplementedWeaponBadgeProperties> = ({
  level,
}) => {
  return <WeaponBadge level={level} normalImageName="ex.png" plusImageName="explus.png" alt="ex"/>
}

export const LdWeaponBadge: FunctionComponent<ImplementedWeaponBadgeProperties> = ({
  level,
}) => {
  return <WeaponBadge level={level} normalImageName="ld.png" alt="ld"/>
}

export const BtWeaponBadge: FunctionComponent<ImplementedWeaponBadgeProperties> = ({
  level,
}) => {
  return <WeaponBadge level={level} normalImageName="bt.png" plusImageName="btplus.png" alt="bt"/>
}

export const FrWeaponBadge: FunctionComponent<ImplementedWeaponBadgeProperties> = ({
  level,
}) => {
  return <WeaponBadge level={level} normalImageName="fr.png" alt="fr"/>
}
