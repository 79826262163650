import { armorProperty } from 'src/tracker/model/property/equipment/armor/armor-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const armorPageProperty = createStandardCharacterResourceProperty(
  'ad722349-632e-474a-94fa-50a3aee42af6',
  'Armor Pages',
  'armorPages',
  armorProperty,
  Resource.ARMOR_PAGES,
)
