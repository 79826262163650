import React, {lazy, Suspense} from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import LoginComponent from 'src/login/login-component'

const ForecastComponent = lazy(() => import("src/forecast/forecast-component"))
const TrackerComponent = lazy(() => import('src/tracker/tracker-component'))
const PreferencesComponent = lazy(() => import('src/preferences/preferences-component'))
const NewsComponent = lazy(() => import('src/news/news-component'))
const ContactUsComponent = lazy(() => import('src/irl/contact-us-component'))
const PlannerComponent = lazy(() => import("src/planner/planner-component"))

export const FORECAST_PATH = "/forecast"
export const TRACKER_PATH = "/tracker"
export const PLANNER_PATH = "/planner"
export const LOGIN_PATH = "/login"
export const PREFERENCES_PATH = "/preferences"
export const NEWS_PATH = "/news"
export const CONTACT_US_PATH = "/contactUs"
export const PRIVACY_POLICY_PATH = "/privacyPolicy"

export const SHARED_ID_ARG = "sharedId"
export const CHARACTER_PANEL_NAME = "characterPanelName"
export const EVENT_PANEL_ID = "eventPanelId"
export const REGION_ARG = "region"

export function RootRouter() {
  const theme = useTheme()
  return (
    <Suspense fallback={
      <div style={{ display: "flex", justifyContent: "center", padding: theme.spacing(2) }}>
        <CircularProgress />
      </div>
    }>
      <Switch>
        <Route exact path={["/"]}>
          <Redirect to={FORECAST_PATH} />
        </Route>
        <Route exact path={[FORECAST_PATH]}>
          <ForecastComponent />
        </Route>
        <Route exact path={[PLANNER_PATH]}>
          <PlannerComponent />
        </Route>
        <Route exact path={["/login"]}>
          <LoginComponent />
        </Route>
        <Route exact path={[TRACKER_PATH]}>
          <TrackerComponent />
        </Route>
        <Route path={[PREFERENCES_PATH]}>
          <PreferencesComponent />
        </Route>
        <Route exact path={[NEWS_PATH]}>
          <NewsComponent />
        </Route>
        <Route exact path={[CONTACT_US_PATH]}>
          <ContactUsComponent />
        </Route>
        <Route exact path={[PRIVACY_POLICY_PATH]} component={() => {
          window.location.href = 'https://www.iubenda.com/privacy-policy/87718912/full-legal';
          return null;
        }} />
      </Switch>
    </Suspense>
  )
}
