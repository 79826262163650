import React, { FunctionComponent } from 'react'
import TextField from '@material-ui/core/TextField';

interface ColumnListItemNumberPickerProps {
  value: number
  onChange: (input: number) => void
}

const ColumnListItemNumberPicker: FunctionComponent<ColumnListItemNumberPickerProps> = ({
  value,
  onChange
}) => {
  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || ""
    const numberValue = parseInt(value, 10)
    if (!isNaN(numberValue) && numberValue > 0) {
      onChange(numberValue)
    }
  }
  return <TextField
    variant='filled'
    margin='dense'
    type='number'
    value={value}
    onChange={onChangeInternal}
    style={{ maxWidth: '75px' }}
  />
}

export default ColumnListItemNumberPicker
