import {AppState} from 'src/store/root-reducer'
import queryString from 'query-string'
import { SHARED_ID_ARG } from 'src/router/root-router'

export function getAuth(state: AppState) {
  return state.firebase.auth
}

export function getUserId(state: AppState) {
  return getAuth(state)?.uid as string | undefined
}

export function isLoggedIn(state: AppState) {
  const auth = getAuth(state)
  return auth.isLoaded && !auth.isEmpty
}

export function isNotLoggedIn(state: AppState) {
  const auth = getAuth(state)
  return auth.isLoaded && auth.isEmpty
}

export function getLoggedInEmail(state: AppState) {
  return getAuth(state).email
}

export function getSpecifiedId(state: AppState) {
  return queryString.parse(state.router.location.search)?.[SHARED_ID_ARG] as string | undefined
}

export function isSpecifiedId(state: AppState) {
  return !!getSpecifiedId(state)
}

export function getResolvedUser(state: AppState) {
  const loggedInUser = getUserId(state)
  const specifiedUser = getSpecifiedId(state)
  if (!loggedInUser && !specifiedUser) {
    return undefined
  }
  return specifiedUser || loggedInUser
}

export function viewingOthersData(state: AppState) {
  const specifiedId = getSpecifiedId(state)
  const loginId = getUserId(state)
  return !!specifiedId && specifiedId !== loginId
}
