import { gt, isEqual, lt } from 'lodash'
import {Uuid} from 'src/forecast/model/universal-data-model'
import { TierUtilization, createDefaultTierUtilization } from './components/tier-utilization'
import { createDefaultTranscendenceTierUtilization, TranscendenceTierUtilization } from './components/transcendence-tier-utilization'
import { EditableCharacterProperty } from './property/editable-character-property'
import { characterEditablePropertyList } from './property/group-of-groups'

export interface Tracker {
  "GL": TrackerRegionData
  "JP": TrackerRegionData
  shared: boolean
}

export const createDefaultTracker: () => Tracker = () => {
  return {
    "GL": createDefaultTrackerRegionData(),
    "JP": createDefaultTrackerRegionData(),
    shared: false,
  }
}

export interface TrackerRegionData {
  characters: TrackerCharacterData
  utilization: TrackerUtilizationData
}

export const createDefaultTrackerRegionData: () => TrackerRegionData = () => {
  return {
    characters: {},
    utilization: createDefaultTrackerUtilizationData()
  }
}

export type TrackerCharacterData = Record<Uuid, CharacterData>

export interface TrackerUtilizationData {
  Entropy: EntropyUtilization
  Transcendence: TranscendenceUtilization
}

export const createDefaultTrackerUtilizationData: () => TrackerUtilizationData = () => {
  return {
    Entropy: Object.assign({}, defaultEntropyUtilization),
    Transcendence: createDefaultTranscendenceUtilization()
  }
}

export type CharacterDataValue = number | boolean

export interface CharacterData {
  "Acquired": boolean,
  "Level": number,
  "Crystal Level": number
  "Silver Weapon": StandardLimitBreak,
  "15 CP": StandardLimitBreak,
  "35 CP": StandardLimitBreak,
  "EX": ExWeaponLimitBreak,
  "LD": StandardLimitBreak,
  "BT": BtWeaponLimitBreak,
  "FR": StandardLimitBreak,
  "Manikin": StandardLimitBreak,
  "World of Illusions": StandardLimitBreak,
  "NT": StandardLimitBreak,
  "Armor": ArmorLimitBreak,
  "Silver Armor": StandardLimitBreak,
  "Bloom" : BloomLimitBreak,
  "Character Boards": CharacterBoardStatus,
  "Character LD Boards": LdBoardStatus,
  "Character FR Boards": LdBoardStatus,
  "Force Enhancement": number,
  "Ifrit": SummonBoardStatus,
  "Shiva": SummonBoardStatus,
  "Ramuh": SummonBoardStatus,
  "Leviathan": SummonBoardStatus,
  "The Brothers": SummonBoardStatus,
  "Pandemonium": SummonBoardStatus,
  "Diabolos": SummonBoardStatus,
  "Alexander": SummonBoardStatus,
  "Odin": SummonBoardStatus,
  "Bahamut": SummonBoardStatus,
  [key: string]: CharacterDataValue
}

export function instanceOfCharacterData(object: any): object is CharacterData {
  return "NT" in object
}

export const valuesSet = (characterData: CharacterData) => {
  return characterEditablePropertyList.map(property => {
    const baseline = generateDefaultCharacterData()
    const baselineValue = property.getFromCharacterData(baseline)
    const setValue = property.getFromCharacterData(characterData)
    return (property.valueComparator(baselineValue, setValue, gt) ? 1 : 0)
  }).reduce((a: number, b: number) => a + b, 0)
}

export const mergeCharacterDataHighestValue = (a: CharacterData, b: CharacterData) => {
  var result = Object.assign({}, a)
  characterEditablePropertyList.forEach(property => {
    const castedProperty = property as EditableCharacterProperty<CharacterDataValue>
    const valueA = castedProperty.getFromCharacterData(a)
    const valueB = castedProperty.getFromCharacterData(b)
    if (castedProperty.valueComparator(valueA, valueB, lt)) {
      result = castedProperty.set(result, valueB)
    }
  })
  return result
}

export interface EntropyUtilization {
  "1": TierUtilization,
  "2": TierUtilization,
  "3": TierUtilization,
  "4": TierUtilization,
  "5": TierUtilization,
  "6": TierUtilization,
  "7": TierUtilization,
  "8": TierUtilization,
  "9": TierUtilization,
  "10": TierUtilization,
  "11": TierUtilization,
  "13": TierUtilization,
  "14": TierUtilization,
  "15": TierUtilization,
  "16": TierUtilization,
  "17": TierUtilization,
  "18": TierUtilization,
  "19": TierUtilization,
  "20": TierUtilization,
  [key: string]: TierUtilization,
}

export interface TranscendenceUtilization {
  "1": TranscendenceTierUtilization,
  "2": TranscendenceTierUtilization,
  "3": TranscendenceTierUtilization,
  "4": TranscendenceTierUtilization,
  "5": TranscendenceTierUtilization,
  "6": TranscendenceTierUtilization,
  "7": TranscendenceTierUtilization,
  "8": TranscendenceTierUtilization,
  "9": TranscendenceTierUtilization,
  "10": TranscendenceTierUtilization,
  "11": TranscendenceTierUtilization,
  "12": TranscendenceTierUtilization,
  "13": TranscendenceTierUtilization,
  "14": TranscendenceTierUtilization,
  "15": TranscendenceTierUtilization,
  "16": TranscendenceTierUtilization,
  [key: string]: TranscendenceTierUtilization,
}

export enum StandardLimitBreak {
  UNOBTAINED,
  ZERO,
  ONE,
  TWO,
  THREE,
}

export enum ExWeaponLimitBreak {
  UNOBTAINED,
  ZERO,
  ONE,
  TWO,
  THREE,
  PLUS_ZERO,
  PLUS_ONE,
  PLUS_TWO,
  PLUS_THREE,
}

export enum ArmorLimitBreak {
  UNOBTAINED,
  THIRTY_FIVE_ZERO,
  THIRTY_FIVE_ONE,
  THIRTY_FIVE_TWO,
  THIRTY_FIVE_THREE,
  NINETY_ZERO,
  NINETY_ONE,
  NINETY_TWO,
  NINETY_THREE,
  PLUS_ZERO,
  PLUS_ONE,
  PLUS_TWO,
  PLUS_THREE,
  HIGH,
  HIGH_PLUS_ZERO,
  HIGH_PLUS_ONE,
  HIGH_PLUS_TWO,
  HIGH_PLUS_THREE,
}

export enum BloomLimitBreak {
  UNOBTAINED,
  THREE
}

export enum SummonBoardStatus {
  NOT_STARTED,
  TREASURED,
  MASTERED,
}

export enum CharacterBoardStatus {
  ZERO,
  ONE,
  TWO,
  THREE,
}

export enum LdBoardStatus {
  ZERO,
  ONE,
}

export enum BtWeaponLimitBreak {
  UNOBTAINED,
  ZERO,
  PLUS_ZERO,
  PLUS_ONE,
  PLUS_TWO,
  PLUS_THREE,
}

export const standardLimitBreakShort = [
  "X",
  "0",
  "1",
  "2",
  "3"
]

export const exWeaponLimitBreakShort = [
  "X",
  "0",
  "1",
  "2",
  "3",
  "+ 0",
  "+ 1",
  "+ 2",
  "+ 3",
]

export const armorLimitBreakShort = [
  "X",
  "35 0",
  "35 1",
  "35 2",
  "35 3",
  "90 0",
  "90 1",
  "90 2",
  "90 3",
  "+ 0",
  "+ 1",
  "+ 2",
  "+ 3",
  "High",
  "High +0",
  "High +1",
  "High +2",
  "High +3",
]

export const bloomLimitBreakShort = [
  "X",
  "3"
]

export const summonBoardStatusShort = [
  "0",
  "T",
  "M"
]

export const characterBoardStatusShort = [
  "0",
  "1",
  "2",
  "3"
]

export const ldBoardStatusShort = [
  "0",
  "1",
]

export const btWeaponLimitBreakShort = [
  "X",
  "0",
  "+ 0",
  "+ 1",
  "+ 2",
  "+ 3",
]

export enum TranscendenceGate {
  "Left", "Right", "Center"
}

export function createDefaultTranscendenceUtilization(): TranscendenceUtilization {
  return {
    "1": createDefaultTranscendenceTierUtilization(),
    "2": createDefaultTranscendenceTierUtilization(),
    "3": createDefaultTranscendenceTierUtilization(),
    "4": createDefaultTranscendenceTierUtilization(),
    "5": createDefaultTranscendenceTierUtilization(),
    "6": createDefaultTranscendenceTierUtilization(),
    "7": createDefaultTranscendenceTierUtilization(),
    "8": createDefaultTranscendenceTierUtilization(),
    "9": createDefaultTranscendenceTierUtilization(),
    "10": createDefaultTranscendenceTierUtilization(),
    "11": createDefaultTranscendenceTierUtilization(),
    "12": createDefaultTranscendenceTierUtilization(),
    "13": createDefaultTranscendenceTierUtilization(),
    "14": createDefaultTranscendenceTierUtilization(),
    "15": createDefaultTranscendenceTierUtilization(),
    "16": createDefaultTranscendenceTierUtilization(),
  }
}


export const generateDefaultCharacterData: () => CharacterData = () => {
  return {
    "Acquired": false,
    "Level": 0,
    "Crystal Level": 0,
    "Silver Weapon": StandardLimitBreak.UNOBTAINED,
    "15 CP": StandardLimitBreak.UNOBTAINED,
    "35 CP": StandardLimitBreak.UNOBTAINED,
    "EX": ExWeaponLimitBreak.UNOBTAINED,
    "LD": StandardLimitBreak.UNOBTAINED,
    "BT": BtWeaponLimitBreak.UNOBTAINED,
    "FR": StandardLimitBreak.UNOBTAINED,
    "Manikin": StandardLimitBreak.UNOBTAINED,
    "World of Illusions": StandardLimitBreak.UNOBTAINED,
    "NT": StandardLimitBreak.UNOBTAINED,
    "Armor": ArmorLimitBreak.UNOBTAINED,
    "Silver Armor": StandardLimitBreak.UNOBTAINED,
    "Bloom": BloomLimitBreak.UNOBTAINED,
    "Character Boards": CharacterBoardStatus.ZERO,
    "Character LD Boards": LdBoardStatus.ZERO,
    "Character FR Boards": LdBoardStatus.ZERO,
    "Force Enhancement": 0,
    "Ifrit": SummonBoardStatus.NOT_STARTED,
    "Shiva": SummonBoardStatus.NOT_STARTED,
    "Ramuh": SummonBoardStatus.NOT_STARTED,
    "Leviathan": SummonBoardStatus.NOT_STARTED,
    "The Brothers": SummonBoardStatus.NOT_STARTED,
    "Pandemonium": SummonBoardStatus.NOT_STARTED,
    "Diabolos": SummonBoardStatus.NOT_STARTED,
    "Alexander": SummonBoardStatus.NOT_STARTED,
    "Odin": SummonBoardStatus.NOT_STARTED,
    "Bahamut": SummonBoardStatus.NOT_STARTED,
  }
}

export const defaultEntropyUtilization = {
  "1": createDefaultTierUtilization(),
  "2": createDefaultTierUtilization(),
  "3": createDefaultTierUtilization(),
  "4": createDefaultTierUtilization(),
  "5": createDefaultTierUtilization(),
  "6": createDefaultTierUtilization(),
  "7": createDefaultTierUtilization(),
  "8": createDefaultTierUtilization(),
  "9": createDefaultTierUtilization(),
  "10": createDefaultTierUtilization(),
  "11": createDefaultTierUtilization(),
  "12": createDefaultTierUtilization(),
  "13": createDefaultTierUtilization(),
  "14": createDefaultTierUtilization(),
  "15": createDefaultTierUtilization(),
  "16": createDefaultTierUtilization(),
  "17": createDefaultTierUtilization(),
  "18": createDefaultTierUtilization(),
  "19": createDefaultTierUtilization(),
  "20": createDefaultTierUtilization(),
} as EntropyUtilization

export enum Resource {
  POWER_STONE = "POWER_STONE",
  CP = "CP",
  GEMS = "GEMS",
  TICKETS = "TICKETS",
  ARMOR_TOKENS = "ARMOR_TOKENS",
  WEAPON_TOKENS = "WEAPON_TOKENS",
  BLOOM_TOKENS = "BLOOM_TOKENS",
  BLOOM_FRAGMENTS = "BLOOM_FRAGMENTS",
  WEAPON_PAGES = "WEAPON_PAGES",
  WEAPON_NUGGETS = "WEAPON_NUGGETS",
  ARMOR_PAGES = "ARMOR_PAGES",
  ARMOR_NUGGETS = "ARMOR_NUGGETS",
  EX_WEAPON_TOKENS = "EX_WEAPON_TOKENS",
  BT_TOKENS = "BT_TOKENS",
  HIGH_ARMOR_TOKENS = "HIGH_ARMOR_TOKENS",
  HIGH_ARMOR_PAGES = "HIGH_ARMOR_PAGES",
  HIGH_ARMOR_NUGGETS = "HIGH_ARMOR_NUGGETS",
  BT_PAGES = "BT_PAGES",
  BT_NUGGETS = "BT_NUGGETS",
  ENHANCEMENT_POINTS = "ENHANCEMENT_POINTS",
  PROVIDENCE_CORES = "PROVIDENCE_CORES",
  FORCE_STONE_SHARDS = "FORCE_STONE_SHARDS",
  MULTI_TICKETS = "MULTI_TICKETS",
  OTHER = "OTHER",
}

const defaultMap = new Map(Object.entries(createDefaultTierUtilization()))
export const numberOfSetEntropyTiers = (entropyUtilization: EntropyUtilization) => {
  return Object.values(entropyUtilization).map(tierUtilization => {
    const tierMap = new Map(Object.entries(tierUtilization))
    return (isEqual(tierMap, defaultMap)) ? 0 : 1
  }).reduce((a: number, b: number) => a + b, 0)
}
