import { armorPageProperty } from "../../property/resource/armor-page-property";
import { ResourceColumn } from "../planner-column";

export const armorPageColumn = new ResourceColumn(
  'Armor Pages',
  'stl_limit_break_item_5_cropped.png',
  'armorPages',
  armorPageProperty,
  undefined,
  'stl_limit_break_item_9_cropped.png',
  'armorBooks',
)
