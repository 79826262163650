import React, {useCallback} from 'react'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {navigateTo} from 'src/router/router-effects'
import { useSelector, useDispatch } from 'react-redux'
import {getPageName} from 'src/router/router-selectors'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Theme from 'src/theme'

export class NavigationTab {
  constructor(
    route: string,
    label: string,
    icon: React.ReactElement,
  ) {
    this.route = route
    this.label = label
    this.icon = icon
  }
  route: string
  label: string
  icon: React.ReactElement
}

interface NavigationTabsProps {
  tabs: NavigationTab[]
  shrinkOnSmallBreakpoint?: boolean
}

export default function NavigationTabs(props: NavigationTabsProps) {
  const currentTab = useSelector(getPageName)
  const navigationTabLabelsRendered = useMediaQuery(Theme.breakpoints.up("sm"))

  const dispatch = useDispatch()
  const dispatchNavigation = useCallback((path: string) => {
    dispatch(navigateTo(path))
  }, [dispatch])
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    dispatchNavigation(newValue)
  }

  const possibleTabs = props.tabs.map(tab => tab.route)
  const showLabels = !(props.shrinkOnSmallBreakpoint && !navigationTabLabelsRendered)

  const tabs = props.tabs.map(tab => <Tab icon={tab.icon} label={showLabels && tab.label} value={tab.route} key={tab.label}/>)

  return (
    <Tabs value={!!possibleTabs.find(tab => tab === currentTab) && currentTab} onChange={handleChange}>
      {tabs}
    </Tabs>
  )
}
