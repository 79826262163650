export class PreferencesWidget {
  constructor(
    component: JSX.Element,
    title: string,
  ) {
    this.component = component
    this.title = title
  }
  component: JSX.Element
  title: string
}
