import {AppState} from 'src/store/root-reducer'
import {Uuid, Character, Event, Summon, TimeRange, Milestone, RegionAvailability} from 'src/forecast/model/universal-data-model'
import { TrackerCharacterData, CharacterData } from 'src/tracker/model/tracker-model'
import { SortSpecification, FilterSpecification, FilterComparator, deserializeFilterSpecifications } from "src/tracker/model/selector-model"
import { getAllCharactersMap, getSortSpecification, createGetAllDeUtilizedCharacters } from 'src/tracker/tracker-selectors'
import Fuse from 'fuse.js'
import {lte, lt, eq, gt, gte, merge, mergeWith, isArray} from 'lodash'
import { seriesColumn } from 'src/tracker/model/column/static/series-column'
import { getDefaultSavedView, getSavedViews, getShowFutureCharacters } from 'src/preferences/preferences-selectors'
import { createDeepEqualsSelector } from 'src/utils/create-deep-equals-selector'
import { defaultMemoize } from 'reselect'
import { AllCharacterData } from 'src/tracker/model/components/all-character-data'
import { DeUtilization } from 'src/tracker/model/components/de-utilization'
import { Region } from 'src/tracker/model/components/region'
import { getActiveRegion } from 'src/router/router-selectors'
import { orderEvents, timeRangeComparitor } from 'src/utils/event-time-utilities'
import { DateTime } from 'luxon'
import { EnumerableCharacterProperty } from 'src/tracker/model/property/enumerable-character-property'
import { getEventAllocations } from 'src/planner/planner-selectors'
import { EventAllocations } from 'src/planner/model/planner-model'
import { CharacterProperty } from 'src/tracker/model/property/character-property'
import { SpecialPropertyValues } from 'src/tracker/property-picker/property-picker'
import { getPropertyFromId } from 'src/tracker/model/property/property-deserializer'
import { CharacterDebut } from './model/character-debut'

export function getUniversalData(state: AppState) {
  return state.forecast.universalData
}

export function getEventIdsByCharacterId(state: AppState) {
  return getUniversalData(state).eventIdsByCharacterId
}

export function getEventsById(state: AppState) {
  return getUniversalData(state).events
}

export const getEventById = (eventId: Uuid) => (state: AppState) => {
  return getEventsById(state)[eventId]
}

export function getBannersById(state: AppState) {
  return getUniversalData(state).banners
}

export function getAllCharacterData(state: AppState) {
  return getUniversalData(state).characters
}

export function getAllCharacterDataLoaded(state: AppState) {
  return Object.values(getAllCharacterData(state)).length > 0
}

export function getCharacterIdsByName(state: AppState) {
  return getUniversalData(state).characterIdsByName
}

export function getSummons(state: AppState) {
  return getUniversalData(state).summons
}

export function getSummonIdsByName(state: AppState) {
  return getUniversalData(state).summonIdsByDisplayName
}

export const getUniversalCharacterData = (characterId: Uuid) => (state: AppState) => {
  return getAllCharacterData(state)[characterId]
}

export function getSearchString(state: AppState) {
  return state.forecast.searchString
}

export function getFilterSpecifications(state: AppState) {
  const filterSpecificationsSet = state.forecast.filterSpecificationsSet
  if (filterSpecificationsSet) {
    return state.forecast.filterSpecifications
  } else {
    const defaultSavedViewId = getDefaultSavedView(state)
    if (defaultSavedViewId === undefined) {
      return []
    }
    const savedViews = getSavedViews(state)
    const defaultSavedView = savedViews.find(savedView => savedView.id === defaultSavedViewId)
    if (defaultSavedView === undefined) {
      return []
    }
    return deserializeFilterSpecifications(defaultSavedView.filterSpecifications)
  }
}

export const createInternalSortSpec = (sortSpec: SortSpecification[]) => {
  return [...sortSpec, new SortSpecification(seriesColumn.id, false)]
}

const sortAccordingToSortSpec = (sortSpec: SortSpecification[], region: Region) => (characters: AllCharacterData[]) => {
  return characters.sort((a: AllCharacterData, b: AllCharacterData) => {
        const relevantSortSpec = sortSpec.find(sortSpec => {
          const resolvedOrdering = getPropertyFromId(sortSpec.propertyId)?.sortOrdering
          if (!resolvedOrdering) {
            return false
          }
          return resolvedOrdering(a, b, region) !== 0
        })

        if (!relevantSortSpec) {
          return 0
        }

        const descMultiplier = (relevantSortSpec.isDesc) ? -1 : 1
        const resolvedOrdering = getPropertyFromId(relevantSortSpec.propertyId)?.sortOrdering

        if (!resolvedOrdering) {
          return 0
        }

        return resolvedOrdering(a, b, region) * descMultiplier
      })
}

export const memoizedGetAllOrderedEvents = defaultMemoize(createGetAllOrderedEvents)

export function createGetAllOrderedEvents() {
  return createDeepEqualsSelector(
    (state: AppState) => getActiveRegion(state),
    (state: AppState) => getEventsById(state),
    (region: Region, events: Record<Uuid, Event>) => {
      // TODO: If we could feed the actual time in here in a sane way, then we could update and remove events without a refresh
      return orderEvents(Object.values(events), region)
    }
  )
}

export const memoizedGetAllDeUtilizedCharacters = defaultMemoize(createGetAllDeUtilizedCharacters)

export function createGetFilteredCharacters() {
  return createDeepEqualsSelector(
    (state: AppState) => getAllCharacterData(state),
    (state: AppState) => getSearchString(state),
    (state: AppState) => getFilterSpecifications(state),
    (state: AppState) => getAllCharactersMap(state),
    (state: AppState) => memoizedGetAllDeUtilizedCharacters()(state),
    (state: AppState) => getSortSpecification(state),
    (state: AppState) => getShowFutureCharacters(state),
    (state: AppState, overrideShowFutureCharacters: boolean) => overrideShowFutureCharacters,
    (state: AppState) => getActiveRegion(state),
    (state: AppState) => getEventAllocations(state),
    (state: AppState) => getEventsById(state),
    (characterData: Record<Uuid, Character>, resolvedSearchString: string, resolvedFilterSpecifications: FilterSpecification<any>[],
      allUserCharacterData: TrackerCharacterData, deUtilization: Record<Uuid, DeUtilization>, existingSortSpecification: SortSpecification[],
      showFutureCharactersPreference: boolean, overrideShowFutureCharacters: boolean, region: Region, eventAllocations: EventAllocations,
      events: Record<Uuid, Event>) => {

    const internalSortSpecification = createInternalSortSpec(existingSortSpecification)
    const now = DateTime.utc()

    const showFutureCharacters = showFutureCharactersPreference || overrideShowFutureCharacters

    var characterResults: Character[] = []
    const options = {
      keys: ['name'],
      threshold: 0.2,
    }
    const fuseCharacters = new Fuse(Object.values(characterData), options)
    characterResults = fuseCharacters.search(resolvedSearchString).map(result => result.item)
    const resolvedCharacterResults = characterResults.length > 0 ? characterResults : Object.values(characterData)

    const filterPredicates = resolvedFilterSpecifications.map(filterSpecification => {
      const property = getPropertyFromId(filterSpecification.propertyId)
      const eqValue = (character: AllCharacterData) => property.valueComparator(property.get(region, character), filterSpecification.comparatorValue, eq)
      switch (filterSpecification.comparator) {
        case FilterComparator["≤"]:
          return (character: AllCharacterData) => property.valueComparator(property.get(region, character), filterSpecification.comparatorValue, lte)
        case FilterComparator["<"]:
          return (character: AllCharacterData) => property.valueComparator(property.get(region, character), filterSpecification.comparatorValue, lt)
        case FilterComparator["="]:
        default:
          return eqValue
        case FilterComparator[">"]:
          return (character: AllCharacterData) => property.valueComparator(property.get(region, character), filterSpecification.comparatorValue, gt)
        case FilterComparator["≥"]:
          return (character: AllCharacterData) => property.valueComparator(property.get(region, character), filterSpecification.comparatorValue, gte)
        case FilterComparator["Obt"]:
          if (property instanceof EnumerableCharacterProperty) {
            return (character: AllCharacterData) => !!property.options(character, now, region).find(option => filterSpecification.comparatorValue === option)
          }
          return eqValue
        case FilterComparator["!Obt"]:
          if (property instanceof EnumerableCharacterProperty) {
            return (character: AllCharacterData) => !property.options(character, now, region).find(option => filterSpecification.comparatorValue === option)
          }
          return eqValue
      }
    })

    const characterIdToEventIdAndAllocationMap: Record<Uuid, [TimeRange, CharacterData][]> = Object.entries(eventAllocations)
      .filter(([eventId, _]) => availabilityMatchesRegion(events[eventId].regionAvailability, region))
      .sort(([eventId1, _], [eventId2, _2]) => {
        const eventTime1 = getEventTime(region, events[eventId1])
        const eventTime2 = getEventTime(region, events[eventId2])
        return timeRangeComparitor(eventTime1, eventTime2)
      })
      .map(([eventId, eventAllocation]) => {
        return Object.entries(eventAllocation.characterAllocations)
          .map(([characterId, characterAllocationData]) => {
            const event = events[eventId]
            const time = getEventTime(region, event)
            return {
              [characterId]: [[time, characterAllocationData]]
            }
          }).reduce(merge, {})
      }).reduce((prevValue, currentValue) => {
        return mergeWith(prevValue, currentValue, (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue);
          }
        })
      }, {})

    return sortAccordingToSortSpec(internalSortSpecification, region)(resolvedCharacterResults.map(character => character.id)
      .map(characterId => new AllCharacterData(characterData[characterId], allUserCharacterData?.[characterId],
        deUtilization?.[characterId], characterIdToEventIdAndAllocationMap[characterId]))
      .filter(allCharacterData => {
        return filterPredicates.every(predicate => predicate(allCharacterData))
      })
      .filter(character => {
        return showFutureCharacters || !!getEventAppearances(region, character.universal)?.find(eventAppearance => eventAppearance.timeRange.start < now)
      }))
  })
}

const memoizedGetFilteredCharacters = defaultMemoize(createGetFilteredCharacters)

export function createGetFilteredEvents() {
  return createDeepEqualsSelector(
    (state: AppState, overrideShowFutureCharacters: boolean) => memoizedGetFilteredCharacters()(state, overrideShowFutureCharacters),
    (state: AppState) => getEventIdsByCharacterId(state),
    (state: AppState) => getEventsById(state),
    (state: AppState) => getActiveRegion(state),
    (state: AppState) => getAllCharacterData(state),
    (filteredCharacters: AllCharacterData[], eventIdsByCharacterId: Record<Uuid, Uuid[]>, eventsByEventId: Record<Uuid, Event>, region: Region, allCharacters: Record<Uuid, Character>) => {

      if (filteredCharacters.length === Object.values(allCharacters).length) {
        return orderEvents(Object.values(eventsByEventId), region)
      }

      return orderEvents(Array.from(new Set(filteredCharacters
        .map(character => character.universal.id)
        .flatMap(characterId => {
          return eventIdsByCharacterId[characterId]
        })
        .map(eventId => {
          return eventsByEventId[eventId]
        }))), region)
    }
  )
}

export const memoizedGetFilteredEvents = defaultMemoize(createGetFilteredEvents)

export function createCharacterSearchedFor() {
  return createDeepEqualsSelector(
    (state: AppState) => memoizedGetFilteredCharacters()(state, true),
    (state: AppState) => getAllCharacterData(state),
    (state: AppState, characterId: Uuid) => characterId,
    (filteredCharacters: AllCharacterData[], characterData: Record<string, Character>, characterId: Uuid) => {
      const characterMatched = filteredCharacters
        .map(character => character.universal.id)
        .includes(characterId)
      const allCharactersMatched = Object.values(characterData).length === filteredCharacters.length
      return characterMatched && !allCharactersMatched
    })
}

export function getCurrentTime(state: AppState) {
  return state.forecast.currentTime
}

export function getEventAppearances(region: Region, character?: Character) {
  return region === "GL" ? character?.eventAppearancesGl : character?.eventAppearancesJp
}

export function getSummonEventAppearances(region: Region, summon?: Summon) {
  const candidate = region === "GL" ? summon?.summonEventAppearancesGl : summon?.summonEventAppearancesJp
  return candidate || []
}

export function getFirstCharacterOfEvent(event: Event, region: Region) {
  const characters = getBannersFromEvent(event, region).flatMap(banner => banner.weapons)
    .flatMap(weapon => weapon.character)
  return characters.length <= 0 ? null : characters[0]
}

export function getDebutHappenedBeforeOrOnEvent(region: Region, character: Character, eventId: Uuid, debut: CharacterDebut) {
  const eventAppearances = getEventAppearances(region, character)
  if (!eventAppearances) {
    return false
  }
  const occurrenceEvent = eventAppearances.find(eventAppearance => eventAppearance.debuts.find(innerDebut => debut === innerDebut) !== undefined)
  if (!occurrenceEvent) {
    return false
  }
  const currentEvent = eventAppearances.find(eventAppearance => eventAppearance.eventId === eventId)
  return !!currentEvent && occurrenceEvent.timeRange.start <= currentEvent.timeRange.start
}

export const debutBeforeTime = (region: Region, character: Character, when: DateTime) => (debut: CharacterDebut) => {
  const eventAppearances = getEventAppearances(region, character)
  if (!eventAppearances) {
    return false
  }
  return (eventAppearances?.find(eventAppearance => eventAppearance.timeRange?.start <= when &&
      eventAppearance.debuts.find(innerDebut => innerDebut === debut) !== undefined) !== undefined)
}

export function getEventTime(region: Region, event: Event) {
  return (region === "GL" ? event.glTime : event.jpTime) as TimeRange
}

export const getEventTimeForActiveRegion = (event: Event) => (state: AppState) => {
  const region = getActiveRegion(state)
  return getEventTime(region, event)
}

export const getCharacterIdsFromEvent = (event: Event, region: Region) => {
  const charactersOnBanner = new Set(getBannersFromEvent(event, region).flatMap(banner => banner.weapons)
    .map(weapon => weapon.character.id))
  const characterIdsSet = new Set([...Array.from(charactersOnBanner),
    ...(event.characterDebutsOnEvent.map((characterDebutOnEvent) => characterDebutOnEvent.characterId) || [])])
  return [...Array.from(characterIdsSet)]
}

export const getCharacterFromEventGivenId = (event: Event, characterId: Uuid, region: Region) => {
  return getBannersFromEvent(event, region).flatMap(banner => banner.weapons)
    .map(weapon => weapon.character)
    .find(character => character.id === characterId)
}

export const getTimeOfMilestone = (milestone: Milestone) => (state: AppState) => {
  const eventId = getUniversalData(state).eventIdsByMilestone[milestone]
  const event = getEventById(eventId)(state)
  const eventTime = getEventTimeForActiveRegion(event)(state)
  return eventTime
}

export const calculateValuesOfProperty = (state: AppState) => (property: CharacterProperty<any> | SpecialPropertyValues | undefined) => {
  const endOfTime = DateTime.utc(9999)
  const characterData = getAllCharacterData(state)
  const region = getActiveRegion(state)
  if (property !== undefined && property instanceof EnumerableCharacterProperty) {
    return Object.values(characterData).map(character => {
      return property.options(new AllCharacterData(character, undefined, undefined), endOfTime, region)
    }).reduce((a, b) => (a.length > b.length) ? a : b, [])
  }
  return []
}

export function returnValueOrValidValue<T>(value: T, validValues: T[]) {
  if (validValues.find(element => element === value) === undefined) {
    if (validValues.length > 0) {
      return validValues[0]
    } else {
      return null
    }
  } else {
    return value
  }
}

export const getBannersFromEvent = (event: Event, region: Region) => {
  return event.banners.filter(banner => {
    const availability = banner.availability
    return availabilityMatchesRegion(availability, region)
  })
}

const availabilityMatchesRegion = (availability: RegionAvailability, region: Region) => {
  return availability === RegionAvailability.BOTH
    || (region === Region.JP && availability === RegionAvailability.JP)
    || (region === Region.GL && availability === RegionAvailability.GL)
}
