import {getPreferencesDocumentLocation} from 'src/preferences/preferences-selectors'
import { useFirestoreConnect } from 'react-redux-firebase'
import {getUserId, getResolvedUser} from 'src/login/login-selectors'
import {getTrackerDocumentLocation} from 'src/tracker/tracker-selectors'
import { useDispatch, useSelector } from 'react-redux';
import { getPlannerDocumentLocation } from 'src/planner/planner-selectors';
import { getAllCharacterDataLoaded } from 'src/forecast/forecast-selectors';
import { useCallback, useEffect } from 'react';
import { shouldGetUniversalData } from 'src/forecast/forecast-effects';

const useEssentialData = () => {
  const userId = useSelector(getUserId)
  const resolvedUserId = useSelector(getResolvedUser)
  const documentsToConnectTo = [getPreferencesDocumentLocation(userId), getPlannerDocumentLocation(resolvedUserId), getTrackerDocumentLocation(resolvedUserId)]
    .filter(it => !!it)
    .map(it => it as string)
  useFirestoreConnect(documentsToConnectTo)

  const sortedDataLoaded = useSelector(getAllCharacterDataLoaded)
  const dispatch = useDispatch()

  const getUniversalDataCallback = useCallback(() => {
    dispatch(shouldGetUniversalData())
  }, [dispatch])

  useEffect(() => {
    if (!sortedDataLoaded) {
      getUniversalDataCallback()
    }
  })
}

export default useEssentialData
