import { CharacterDebut } from "src/forecast/model/character-debut"
import { WeaponType } from "src/forecast/model/weapon-type"
import { DatePropertyMode } from "../date-property"
import { generateDatePropertyDebut } from "../date-property-debut-scheme"
import { generateDateProperty } from "../date-property-misc-scheme"
import { generateDatePropertyWeaponAppearance } from "../date-property-weapon-appearance-scheme"
import { MiscDefinitionValues } from "../misc-definition-values"

export const legacyIdDefinitionLookup = (id: string) => {
  switch (id) {
    case "50b366af-fde5-4a88-9599-8e4036b2c560":
      return generateDatePropertyDebut(CharacterDebut.AWAKENING_70, DatePropertyMode.ALL)
    case "3a073438-0dcf-4f79-a19c-33b50e5805c9":
      return generateDatePropertyDebut(CharacterDebut.AWAKENING_80, DatePropertyMode.ALL)
    case "447d7360-0f08-46f7-9252-d64e8cbb4346":
      return generateDatePropertyDebut(CharacterDebut.AWAKENING_90, DatePropertyMode.ALL)
    case "13204e38-b173-44fd-9fb6-5d59f6443960":
      return generateDateProperty(MiscDefinitionValues.Banner, DatePropertyMode.FUTURE)
    case "31f17591-b4c1-489d-8564-420b8d64e4d4":
      return generateDatePropertyDebut(CharacterDebut.CHARACTER_BOARD, DatePropertyMode.ALL)
    case "33acaeb0-d82f-4b37-a0c7-1cc745443719":
      return generateDatePropertyWeaponAppearance(WeaponType.BT, DatePropertyMode.ALL)
    case "ead97f08-e9a2-4742-864e-f4708ad2228a":
      return generateDatePropertyDebut(CharacterDebut.BT_REALIZATION, DatePropertyMode.ALL)
    case "8f2fda3c-5d72-4315-b817-7d65abeadcaf":
      return generateDatePropertyDebut(CharacterDebut.EX_REALIZATION, DatePropertyMode.ALL)
    case "4a6b5aa0-e563-4f3e-91bc-12f291e47853":
      return generateDatePropertyDebut(CharacterDebut.FORCE_ENHANCEMENT, DatePropertyMode.ALL)
    case "99c65d36-a2b9-4cf3-8de2-74b6fdc93e13":
      return generateDatePropertyWeaponAppearance(WeaponType.FR, DatePropertyMode.ALL)
    case "3c09ecc2-384c-44bd-8fb2-e3afb1e6a24b":
      return generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR, DatePropertyMode.ALL)
    case "0e8ce630-19b7-488c-ba2b-5844e770cd63":
      return generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR_REALIZATION, DatePropertyMode.ALL)
    case "5afeafd4-4b1d-4f9f-bf1c-38a30427a388":
      return generateDatePropertyDebut(CharacterDebut.LD_CHARACTER_BOARD, DatePropertyMode.ALL)
    case "b0b5c7a7-ae78-4e89-b25f-db59a07c7287":
      return generateDatePropertyWeaponAppearance(WeaponType.LD, DatePropertyMode.ALL)
    case "d086bcb1-5a38-44bb-8f39-3bc00fb6932d":
      return generateDatePropertyDebut(CharacterDebut.AWAKENING_80, DatePropertyMode.FUTURE)
    case "0c90fbb4-c372-4ac4-8dd8-203369cf9774":
      return generateDatePropertyDebut(CharacterDebut.AWAKENING_90, DatePropertyMode.FUTURE)
    case "5ae8f3aa-f6cf-4078-8cd2-0529928c8908":
      return generateDatePropertyDebut(CharacterDebut.CHARACTER_BOARD, DatePropertyMode.FUTURE)
    case "1fdc2a9e-44d0-4920-a347-09f8d6fdff86":
      return generateDatePropertyWeaponAppearance(WeaponType.BT, DatePropertyMode.FUTURE)
    case "3a5bf046-1bf6-4cd9-a64b-4b5f888d0488":
      return generateDatePropertyDebut(CharacterDebut.BT_REALIZATION, DatePropertyMode.FUTURE)
    case "36632ab3-7b87-4f78-bfc4-4f77ef85e907":
      return generateDatePropertyWeaponAppearance(WeaponType.EX, DatePropertyMode.FUTURE)
    case "2968b7bd-d206-4e1e-9417-1e0e19bfc468":
      return generateDatePropertyDebut(CharacterDebut.EX_REALIZATION, DatePropertyMode.FUTURE)
    case "033edc49-df6e-46e9-a205-ecf745a402ca":
      return generateDatePropertyDebut(CharacterDebut.FORCE_ENHANCEMENT, DatePropertyMode.FUTURE)
    case "eeb7bfbb-38d2-4129-ab00-f5b50a858ca3":
      return generateDatePropertyWeaponAppearance(WeaponType.FR, DatePropertyMode.FUTURE)
    case "bd058ea4-4f95-4b60-9766-085a86bd231a":
      return generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR, DatePropertyMode.FUTURE)
    case "d2ebc258-d30a-4565-af15-6e35f123c766":
      return generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR_REALIZATION, DatePropertyMode.FUTURE)
    case "0f884519-867a-4cbc-a30b-7ada50eb0038":
      return generateDatePropertyDebut(CharacterDebut.LD_CHARACTER_BOARD, DatePropertyMode.FUTURE)
    case "9818bd04-a73d-4588-a6f7-bf30dd52ca91":
      return generateDatePropertyWeaponAppearance(WeaponType.LD, DatePropertyMode.FUTURE)
    case "85ec604a-953b-44ff-9a22-35de9555e6e5":
      return generateDateProperty(MiscDefinitionValues.Major, DatePropertyMode.FUTURE)
    case "38b3e751-09eb-4cc9-a12e-1517c2744462":
      return generateDateProperty(MiscDefinitionValues.Banner, DatePropertyMode.PAST)
    case "3554fbfe-a798-4ae5-bff8-8cdc08dccc62":
      return generateDatePropertyWeaponAppearance(WeaponType.BT, DatePropertyMode.PAST)
    case "ed59e284-c9b5-4efd-9964-8eac16962615":
      return generateDatePropertyDebut(CharacterDebut.EX_REALIZATION, DatePropertyMode.PAST_AND_PRESENT)
    case "2f9f5ebf-b794-4dbd-b8e6-ea38b623f596":
      return generateDatePropertyWeaponAppearance(WeaponType.FR, DatePropertyMode.PAST)
    case "97b2b6cf-70e2-4257-b3ec-8de95012a9d1":
      return generateDatePropertyWeaponAppearance(WeaponType.LD, DatePropertyMode.PAST)
    case "8cccd91f-0618-4884-bf85-b2d1979cab47":
      return generateDateProperty(MiscDefinitionValues.Major, DatePropertyMode.PAST_AND_PRESENT)
    default:
      return undefined
  }
}
