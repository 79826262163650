export const ALERT_OPEN = "ALERT_OPEN"
export const ALERT_CLOSED = "ALERT_CLOSED"

export enum AlertType {
  OK,
  OK_CANCEL
}

export enum CloseType {
  OK,
  CANCEL
}

export function openAlert(message: string, type: AlertType = AlertType.OK, subtype: string = "") {
  return {
    type: ALERT_OPEN,
    message: message,
    alertType: type,
    subtype: subtype,
  }
}

export function closeAlert(closeType: CloseType = CloseType.OK, subtype: string = "") {
  return {
    type: ALERT_CLOSED,
    closeType: closeType,
    subtype: subtype
  }
}

export type OpenAlertAction = ReturnType<typeof openAlert>
export type CloseAlertAction = ReturnType<typeof closeAlert>
