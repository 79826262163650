import {ASSET_FOUND, AssetFoundAction} from 'src/image-cache/image-actions'
import {AppState} from 'src/store/root-reducer'

export type ImageUrl = string
type ImageState = Record<string, ImageUrl>

const initialState = {} as ImageState

export function imageReducer(state = initialState, action: AssetFoundAction) {
  switch (action.type) {
    case ASSET_FOUND:
      return Object.assign({}, state, {[action.lookupId]: action.asset})
    default:
      return state
  }
}

export function imageActionSanitizer(action: AssetFoundAction) {
  switch (action.type) {
    case ASSET_FOUND:
      return Object.assign({}, action, {asset: 'actual asset'})
    default:
      return action
  }
}

export function imageStateSanitizer(state: AppState) {
  return Object.assign({}, state, { assets: 'stuff' })
}

