import { DateTime, Duration } from 'luxon'
import { CharacterDebut } from './character-debut'
import { WeaponType } from './weapon-type'

export interface UniversalData {
  events: Record<Uuid, Event>
  banners: Record<Uuid, Banner>
  weapons: Record<Uuid, Weapon>
  characters: Record<Uuid, Character>
  eventIdsByCharacterId: Record<Uuid, Uuid[]>
  summons: Record<Uuid, Summon>
  armor: Record<Uuid, Armor>
  characterIdsByName: Record<string, Uuid>
  summonIdsByDisplayName: Record<string, Uuid>
  eventIdsByMilestone: Record<number, Uuid>
}

export interface Event {
  id: Uuid
  banners: Banner[]
  glTime: TimeRange | null
  jpTime: TimeRange | null
  name: string
  type: EventType
  regionAvailability: RegionAvailability
  eventResources: EventResources
  milestones: Milestone[]
  eventSize: EventSize
  characterDebutsOnEvent: CharacterDebutOnEvent[]
}

export interface EventResources {
  gems: number
  tickets: number
  weaponPages: number
  armorPages: number
  weaponNuggets: number
  armorNuggets: number
  btTokens: number
  highArmorTokens: number
  bloomTokens: number
  bloomFragments: number
  armorTokens: number
  weaponTokens: number
  exWeaponTokens: number
  highArmorPages: number
  highArmorNuggets: number
  btPages: number
  btNuggets: number
  providenceCores: number
  providenceCoresPremium: number
  powerStones: number
  forceStoneShards: number
  enhanceableEvents: number
  [key: string]: number
}

export const generateEmptyEventResources: () => EventResources = () => {
  return {
    gems: 0,
    tickets: 0,
    weaponPages: 0,
    armorPages: 0,
    weaponNuggets: 0,
    armorNuggets: 0,
    btTokens: 0,
    highArmorTokens: 0,
    bloomTokens: 0,
    bloomFragments: 0,
    armorTokens: 0,
    weaponTokens: 0,
    exWeaponTokens: 0,
    highArmorPages: 0,
    highArmorNuggets: 0,
    btPages: 0,
    btNuggets: 0,
    providenceCores: 0,
    providenceCoresPremium: 0,
    powerStones: 0,
    forceStoneShards: 0,
    enhanceableEvents: 0,
  }
}

export interface Banner {
  id: Uuid
  weapons: Weapon[]
  availability: RegionAvailability
}

const timeRangeSchemaVersion = "v1"
const timeRangeSchema = "TimeRange"

export class TimeRange {
  constructor (
    start: DateTime,
    duration: Duration,
    estimate: boolean,
    missingEvent: boolean
  ) {
    this.start = start
    this.duration = duration
    this.estimate = estimate
    this.missingEvent = missingEvent
    this.serialize = `${timeRangeSchemaVersion}:${timeRangeSchema}:${start.toMillis()}:${duration.toMillis()}:${estimate}`
  }
  start: DateTime
  duration: Duration
  estimate: boolean
  serialize: string
  missingEvent: boolean
}

export const deserializeTimeRange = (serializedTimeRange: string) => {
  const splitString = serializedTimeRange.split(":")
  if (splitString[0] !== undefined && splitString[0] === timeRangeSchemaVersion&& splitString[1] !== undefined && splitString[1] === timeRangeSchema) {
    if (splitString[2] !== undefined && splitString[3] !== undefined && splitString[4] !== undefined) {
      const startCandidateMillis = parseInt(splitString[2])
      const durationCandidateMillis = parseInt(splitString[3])
      if (!isNaN(startCandidateMillis) && !isNaN(durationCandidateMillis)) {
        const startCandidate = DateTime.fromMillis(startCandidateMillis)
        const durationCandidate = Duration.fromMillis(durationCandidateMillis)
        const estimateCandidate = splitString[4] === "true"
        return new TimeRange(startCandidate, durationCandidate, estimateCandidate, false)
      }
    }
  }
}

export interface Weapon {
  id: Uuid
  character: Character
  name: string
  type: WeaponType
}

export interface Character {
  id: Uuid
  name: string
  color: CrystalColor
  internalNumber: number
  elementsUsed: Element[]
  recommendedArtifacts: string[]
  summonOverrides: Record<Uuid, number[]>
  weaponVariant: WeaponVariant
  series: Series,
  orderInSeries: number
  eventAppearancesGl: EventAppearance[]
  eventAppearancesJp: EventAppearance[]
  availableWeapons: WeaponType[]
  availableArmors: ArmorType[]
}

export enum CrystalColor {
  RED,
  BLUE,
  GREEN,
  YELLOW,
  BLACK,
  WHITE,
}

export enum Element {
  FIRE,
  ICE,
  LIGHTNING,
  WATER,
  EARTH,
  WIND,
  HOLY,
  DARK,
}

export enum EventType {
  LOST_CHAPTER,
  CHARACTER,
  HERETIC,
  RAID,
  ULTIMATE_SUMMON,
  STORY,
  ENTROPY,
  MISSION_DUNGEON,
  CAMPAIGN,
  BOSS_RUSH,
  DIVINE_SUMMON,
  TRANSCENDENCE,
  SIX_MAN,
  INTERTWINED_WILLS,
}

export enum EventSize {
  NORMAL,
  END_MONTH,
  MID_MONTH,
}

export enum RegionAvailability {
  GL,
  JP,
  BOTH
}

export enum SummonType {
  IFRIT,
  SHIVA,
  RAMUH,
  LEVIATHAN,
  THE_BROTHERS,
  PANDEMONIUM,
  DIABOLOS,
  ALEXANDER,
  ODIN,
  BAHAMUT
}

export interface Summon {
  id: Uuid
  name: SummonType,
  internalNumber: number
  elementType: Element | undefined
  passives: string[]
  defaults: number[]
  elementOverride: number[]
  displayName: string
  summonEventAppearancesGl: SummonEventAppearance[]
  summonEventAppearancesJp: SummonEventAppearance[]
}

export enum Series {
  I, II, III, IV, V, VI, VII, VIII, IX, X, XI, XII, XIII, XIV, XV, T, T0, CC, WOFF, SOP
}

export interface Armor {
  id: Uuid
  character: Character
  name: string
  type: ArmorType
}

export enum ArmorType {
  BRONZE,
  SILVER,
  THIRTY_FIVE_CP,
  NINETY_CP,
  HIGH_ARMOR,
}

export enum WeaponVariant {
  DAGGER, SWORD, GREATSWORD, STAFF, GUN, FIST, THROWN, SPEAR, BOW, WHIP, MISC
}

export type Uuid = string

export interface EventAppearance {
  eventId: Uuid,
  timeRange: TimeRange,
  weaponsContained: WeaponType[],
  debuts: CharacterDebut[],
}

export interface SummonEventAppearance {
  eventId: Uuid,
  timeRange: TimeRange,
  ultimateBoard: boolean,
  divineBoard: boolean,
  spiritus: boolean
}


export enum Milestone {
    CRAFTABLE_SPHERES,
    LUFENIA,
    LDBT,
    LEVEL_80,
    LD_BOARDS,
    COSMOS,
    CHAOS,
    CHARACTER_BOARDS,
    HIGH_ARMOR_REALIZATION,
    BT_REALIZATION,
    LEVEL_90,
    SHINRYU,
    FR_BOARDS,
    FOUR_EVENTS_A_MONTH,
    BRILLIANCE,
    FORCE_ENHANCEMENT_50,
    GL_BRILLIANCE,
    RE_SHINRYU,
    EOS,
}

export class MilestoneData {
  constructor(
    milestone: Milestone,
    name: string,
    verb: string,
  ) {
    this.milestone = milestone
    this.name = name
    this.verb = verb
  }
  milestone: Milestone
  name: string
  verb: string
}

export const toMilestoneData = (milestone: Milestone) => {
  const returnFn = (name: string, verb: string) => {
    return new MilestoneData(milestone, name, verb)
  }

  switch(milestone) {
    case Milestone.CRAFTABLE_SPHERES:
      return returnFn("Craftable spheres", "are now craftable")
    case Milestone.LUFENIA:
      return returnFn("Lufenia difficulty", "is now available")
    case Milestone.LDBT:
      return returnFn("LD and BT weapons", "are now available")
    case Milestone.LEVEL_80:
      return returnFn("Level 80 awakenings", "are now available")
    case Milestone.LD_BOARDS:
      return returnFn("LD character boards", "are now available")
    case Milestone.COSMOS:
      return returnFn("COSMOS difficulty", "is now available")
    case Milestone.CHAOS:
      return returnFn("CHAOS difficulty", "is now available")
    case Milestone.CHARACTER_BOARDS:
      return returnFn("Character boards", "are now available")
    case Milestone.HIGH_ARMOR_REALIZATION:
      return returnFn("High armor realizations", "are now available")
    case Milestone.BT_REALIZATION:
      return returnFn("BT+ realizations", "are now available")
    case Milestone.LEVEL_90:
      return returnFn("Level 90 awakenings and ultima weapons", "are now available")
    case Milestone.SHINRYU:
      return returnFn("FR weapons and Shinryu difficulty", "are now available")
    case Milestone.FR_BOARDS:
      return returnFn("FR character boards", "are now available")
    case Milestone.FOUR_EVENTS_A_MONTH:
      return returnFn("The schedule now", "has four events per month")
    case Milestone.BRILLIANCE:
      return returnFn("Brilliance and the crystal room", "are now available in JP")
    case Milestone.FORCE_ENHANCEMENT_50:
      return returnFn("Force enhancement to level fifty", "is now available")
    case Milestone.GL_BRILLIANCE:
      return returnFn("Brilliance and the crystal room", "are now available in GL")
    case Milestone.RE_SHINRYU:
      return returnFn("Re-Shinryu difficulty", "is now available")
    case Milestone.EOS:
      return returnFn("End of Service", "has been announced. When this event is over, the game will end.")
  }
}

export interface CharacterDebutOnEvent {
  characterId: Uuid
  regionAvailability: RegionAvailability
  debut: CharacterDebut
}

