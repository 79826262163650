import { getEventAppearances } from "src/forecast/forecast-selectors"
import { AllCharacterData } from "../../components/all-character-data"
import { Region } from "../../components/region"
import { DateTime } from 'luxon'

export const acquirableOptionFunction = (universalCharacterData: AllCharacterData, when: DateTime, region: Region) => {
    const characterExists = !!(getEventAppearances(region, universalCharacterData.universal)?.find(eventAppearance => eventAppearance.timeRange?.start < when))
    if (characterExists) {
      return [false, true]
    } else {
      return [false]
    }
  }
