import { WeaponType } from "src/forecast/model/weapon-type";
import { DateTransienceMode } from "../date-property";
import { DatePropertyEventDefinition } from "../date-property-event-definition";
import { MiscDefinitionValues } from "../misc-definition-values";

const bannerCountedWeaponTypes = new Set([WeaponType.FIFTEEN_CP, WeaponType.THIRTY_FIVE_CP, WeaponType.EX, WeaponType.LD, WeaponType.BRONZE, WeaponType.FR])

export const bannerDefinition = new DatePropertyEventDefinition(
  MiscDefinitionValues.Banner,
  "Banner",
  DateTransienceMode.ONGOING,
  (eventAppearance) => eventAppearance.weaponsContained.find(weaponType => bannerCountedWeaponTypes.has(weaponType)) !== undefined
)
