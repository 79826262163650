import { highArmorPagesProperty } from "../../property/resource/high-armor-pages-property";
import { ResourceColumn } from "../planner-column";

export const highArmorPagesColumn = new ResourceColumn(
  'HA Pages',
  'stl_limit_break_item_11_cropped.png',
  'highArmorPages',
  highArmorPagesProperty,
  undefined,
  'stl_limit_break_item_12_cropped.png',
  'highArmorBooks',
)
