import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {cpColumn} from 'src/tracker/model/column/variable/calculated/cp-column'
import {powerstonesRemainingColumn} from 'src/tracker/model/column/variable/calculated/power-stones-remaining-column'

export const calculatedGroup = new ColumnGroup(
  '77bfc4f9-a365-4322-bc2d-e8dc91fc26c4',
  'Calculated',
  [cpColumn, powerstonesRemainingColumn]
)

