import React, {FunctionComponent, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { selectImage } from 'src/image-cache/image-selectors';
import { ResourceColumn } from 'src/planner/model/column/planner-column';
import { shouldGetAsset } from 'src/image-cache/image-effects'
import { imgScalingStyling } from 'src/forecast/character-image/character-image';
import Skeleton from '@material-ui/lab/Skeleton';
import { getDisplayAsRefined } from 'src/preferences/preferences-selectors';

interface ResourceImageProps {
  dimension?: string
  column: ResourceColumn<any>
  forceRefinedMaterial?: boolean
}

const ResourceImage: FunctionComponent<ResourceImageProps> = ({
  dimension = '36px',
  column,
  forceRefinedMaterial = false,
}) => {
  const displayAsRefined = useSelector(getDisplayAsRefined)
  const useRefinedMaterial = forceRefinedMaterial || displayAsRefined
  const imageLocator = useRefinedMaterial && !!column.realizableImageLocator ? column.realizableImageLocator : column.imageLocator
  const imageName = useRefinedMaterial && !!column.realizableImageName ? column.realizableImageName : column.imageName
  const image = useSelector(selectImage(imageLocator))

  const dispatch = useDispatch()
  const dispatchShouldGetAsset = useCallback((location: string, id: string) => {
    dispatch(shouldGetAsset(location, id))
  }, [dispatch])

  useEffect(() => {
    if (!image) {
      dispatchShouldGetAsset(imageName, imageLocator)
    }
  })

  return !!image ?
    <img src={image} alt={column.imageLocator} style={Object.assign(imgScalingStyling(dimension))} /> :
    <Skeleton variant='rect' style={Object.assign({}, imgScalingStyling(dimension))}/>
}

export default ResourceImage
