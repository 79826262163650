import {AppState} from 'src/store/root-reducer'

export function getDocumentLoaded(state: AppState, documentLocation: string | undefined) {
  if (!documentLocation) {
    return true
  }
  const dataRequested = state.firestore.status?.requesting?.[documentLocation] === true
  const dataLoaded = state.firestore.status?.requested?.[documentLocation] === true
  return dataLoaded || !dataRequested
}

export function getDocumentError(state: AppState, documentLocation: string | undefined) {
  if (!documentLocation) {
    return undefined
  }
  return state.firestore.errors.byQuery?.[documentLocation]?.code as string | undefined
}
