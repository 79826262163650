import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {ifritColumn} from 'src/tracker/model/column/variable/summon/ifrit-column'
import {shivaColumn} from 'src/tracker/model/column/variable/summon/shiva-column'
import {ramuhColumn} from 'src/tracker/model/column/variable/summon/ramuh-column'
import {leviathanColumn} from 'src/tracker/model/column/variable/summon/leviathan-column'
import {brothersColumn} from 'src/tracker/model/column/variable/summon/brothers-column'
import {pandemoniumColumn} from 'src/tracker/model/column/variable/summon/pandemonium-column'
import {diabolosColumn} from 'src/tracker/model/column/variable/summon/diabolos-column'
import { alexanderColumn } from '../variable/summon/alexander-column'
import { odinColumn } from '../variable/summon/odin-column'
import { bahamutColumn } from '../variable/summon/bahamut-column'

export const summonBoardsGroup = new ColumnGroup(
  '749c1f3f-5056-4b43-869d-ee97358214af',
  'Summon Boards',
  [ifritColumn, shivaColumn, ramuhColumn, leviathanColumn, brothersColumn, pandemoniumColumn, diabolosColumn, alexanderColumn, odinColumn, bahamutColumn]
)
