export enum CharacterDebut {
  "ACQUIRABLE", "SILVER", "FIFTEEN_CP", "THIRTY_FIVE_CP", "EX", "LD", "BT", "WORLD_OF_ILLUSION", "MANIKIN", "NT", "FR",
  "EX_REALIZATION", "CHARACTER_BOARD", "AWAKENING_60", "AWAKENING_70", "AWAKENING_80", "LD_CHARACTER_BOARD", "HIGH_ARMOR",
  "HIGH_ARMOR_REALIZATION", "BT_REALIZATION", "AWAKENING_90", "FORCE_ENHANCEMENT", "FR_CHARACTER_BOARD", "FORCE_ENHANCEMENT_50"
}

export const getFriendlyNameFromCharacterDebut = (characterDebut: CharacterDebut) => {
  switch (characterDebut) {
    case CharacterDebut.ACQUIRABLE:
      return "Acquirable"
    case CharacterDebut.SILVER:
      return "Silver Weapon"
    case CharacterDebut.FIFTEEN_CP:
      return "15 CP"
    case CharacterDebut.THIRTY_FIVE_CP:
      return "35 CP"
    case CharacterDebut.EX:
      return "EX"
    case CharacterDebut.LD:
      return "LD"
    case CharacterDebut.BT:
      return "BT"
    case CharacterDebut.WORLD_OF_ILLUSION:
      return "World of Illusion"
    case CharacterDebut.MANIKIN:
      return "Manikin"
    case CharacterDebut.NT:
      return "NT"
    case CharacterDebut.FR:
      return "FR"
    case CharacterDebut.EX_REALIZATION:
      return "EX Realization"
    case CharacterDebut.CHARACTER_BOARD:
      return "Character Board"
    case CharacterDebut.AWAKENING_60:
      return "60 Awakening"
    case CharacterDebut.AWAKENING_70:
      return "70 Awakening"
    case CharacterDebut.AWAKENING_80:
      return "80 Awakening"
    case CharacterDebut.LD_CHARACTER_BOARD:
      return "LD Character Board"
    case CharacterDebut.HIGH_ARMOR:
      return "High Armor"
    case CharacterDebut.HIGH_ARMOR_REALIZATION:
      return "High Armor Realization"
    case CharacterDebut.BT_REALIZATION:
      return "BT Realization"
    case CharacterDebut.AWAKENING_90:
      return "90 Awakening"
    case CharacterDebut.FORCE_ENHANCEMENT:
      return "Force Enhancement"
    case CharacterDebut.FR_CHARACTER_BOARD:
      return "FR Character Board"
    case CharacterDebut.FORCE_ENHANCEMENT_50:
      return "Force Enhancement 50"
  }
}

export const getShortNameFromCharacterDebut = (characterDebut: CharacterDebut) => {
  switch (characterDebut) {
    case CharacterDebut.ACQUIRABLE:
      return "Acq"
    case CharacterDebut.SILVER:
      return "S Wep"
    case CharacterDebut.FIFTEEN_CP:
      return "15 CP"
    case CharacterDebut.THIRTY_FIVE_CP:
      return "35 CP"
    case CharacterDebut.EX:
      return "EX"
    case CharacterDebut.LD:
      return "LD"
    case CharacterDebut.BT:
      return "BT"
    case CharacterDebut.WORLD_OF_ILLUSION:
      return "WoI"
    case CharacterDebut.MANIKIN:
      return "Manikin"
    case CharacterDebut.NT:
      return "NT"
    case CharacterDebut.FR:
      return "FR"
    case CharacterDebut.EX_REALIZATION:
      return "EX+"
    case CharacterDebut.CHARACTER_BOARD:
      return "C. Boards"
    case CharacterDebut.AWAKENING_60:
      return "60"
    case CharacterDebut.AWAKENING_70:
      return "70"
    case CharacterDebut.AWAKENING_80:
      return "80"
    case CharacterDebut.LD_CHARACTER_BOARD:
      return "LD Boards"
    case CharacterDebut.HIGH_ARMOR:
      return "HA"
    case CharacterDebut.HIGH_ARMOR_REALIZATION:
      return "HA+"
    case CharacterDebut.BT_REALIZATION:
      return "BT+"
    case CharacterDebut.AWAKENING_90:
      return "90"
    case CharacterDebut.FORCE_ENHANCEMENT:
      return "FE"
    case CharacterDebut.FR_CHARACTER_BOARD:
      return "FR Boards"
    case CharacterDebut.FORCE_ENHANCEMENT_50:
      return "FE 50"
  }
}
