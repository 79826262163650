import React, { FunctionComponent } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { propertyList, propertyIdToPropertyGroupMap, loggedOutPropertyMap } from '../model/property/group-of-groups'
import TextField from '@material-ui/core/TextField';
import { CharacterProperty } from '../model/property/character-property';
import { Uuid } from 'src/forecast/model/universal-data-model';
import { useSelector } from 'react-redux'
import { getResolvedUser } from 'src/login/login-selectors';

interface PropertyPickerProps {
  property: PropertyPickerProperty
  onPropertyPicked: (prop: PropertyPickerProperty) => void
  style?: React.CSSProperties
  disabledOptions?: Uuid[] // property ids
  visibleOptions?: PropertyPickerProperty[]
}

export enum SpecialPropertyValues {
  "CharacterWeaponDateProp",
  "CharacterDebutDateProp",
  "CharacterMiscDateProp",
  "PlannerDateProp"
}

export type PropertyPickerProperty = CharacterProperty<any> | SpecialPropertyValues

const PropertyPicker: FunctionComponent<PropertyPickerProps> = ({
  property,
  onPropertyPicked,
  style = {},
  disabledOptions = [],
  visibleOptions = [...propertyList, SpecialPropertyValues.CharacterWeaponDateProp,
    SpecialPropertyValues.CharacterDebutDateProp, SpecialPropertyValues.CharacterMiscDateProp,
    SpecialPropertyValues.PlannerDateProp],
}) => {
  if (visibleOptions.find(option => option === property) === undefined) {
    visibleOptions.push(property)
  }

  const viewingUser = !!useSelector(getResolvedUser)
  const handleChange = (event: any, newValue: PropertyPickerProperty | null) => {
    if (newValue !== null) {
      onPropertyPicked(newValue)
    }
  }

  const disabledOptionSet = new Set(disabledOptions)

  return (
    <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      disableClearable
      openOnFocus
      blurOnSelect
      forcePopupIcon={false}
      style={Object.assign({ width: '200px' }, style)}
      options={visibleOptions}
      renderInput={(params) => (
        <TextField {...params} variant='filled' margin='dense' />
      )}
      getOptionLabel={(property) => {
        if (property === SpecialPropertyValues.CharacterWeaponDateProp) {
          return "Character Weapon Date Property"
        } else if (property === SpecialPropertyValues.CharacterDebutDateProp) {
          return "Character Debut Date Property"
        } else if (property === SpecialPropertyValues.CharacterMiscDateProp) {
          return "Character Miscellaneous Date Property"
        } else if (property === SpecialPropertyValues.PlannerDateProp) {
          return "Planner Date Property"
        }
        return property.name
      }}
      getOptionDisabled={(option) => {
        // logged out case
        if (!viewingUser) {
          if (Object.values(SpecialPropertyValues).includes(option as SpecialPropertyValues)) {
            return option === SpecialPropertyValues.PlannerDateProp
          } else {
            const castOption = option as CharacterProperty<any>
            return !loggedOutPropertyMap[castOption.id]
          }
        } else {
          if (Object.values(SpecialPropertyValues).includes(option as SpecialPropertyValues)) {
            return false
          } else {
            const castOption = option as CharacterProperty<any>
            return disabledOptionSet.has(castOption.id)
          }
        }
      }}
      onChange={handleChange}
      groupBy={
        (option) => {
          if (option instanceof CharacterProperty) {
            return propertyIdToPropertyGroupMap[option.id]?.name
          } else {
            return "Custom"
          }
        }}
      value={property}
    />
  )

}

export default PropertyPicker
