import { ColumnGroup, standardStaticColumns } from './column-group'
import { weaponVariantColumn } from '../static/weapon-variant-column'
import { actionColumn } from '../static/action-column'

export const standardStaticGroup = new ColumnGroup(
  "aa1cf1d4-1cbc-44e5-9053-809f5c8d6547",
  "Standard Static",
  standardStaticColumns,
)

export const staticGroup = new ColumnGroup(
  "ef7db5fb-a34d-4193-a865-040a9a7c6c6d",
  "Static",
  [...standardStaticGroup.columns, weaponVariantColumn, actionColumn]
)
