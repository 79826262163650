import React, {useState} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Theme from 'src/theme'

interface TrackerPickerEntryProps {
  options: string[]
  updateFunction: (newValue: string) => void
  width: string
  disabled: boolean
  value?: string
}

const getRootStyle = (width: string, isDisabled: boolean, isFocused: boolean, isMousedOver: boolean) => {
  return () => { // wrapping in a function so nothing messes with it.
    const light = Theme.palette.type === 'light';
    const backgroundColorDisabledOffset = isDisabled || isFocused || !isMousedOver ? 0 : 0.03
    const backgroundColor = light ? `rgba(0, 0, 0, ${0.09 + backgroundColorDisabledOffset})` : `rgba(255, 255, 255, ${0.09 + backgroundColorDisabledOffset})`;

    return {
      width: width,
      backgroundColor,
      borderTopLeftRadius: Theme.shape.borderRadius,
      borderTopRightRadius: Theme.shape.borderRadius,
      transition: Theme.transitions.create('background-color', {
        duration: Theme.transitions.duration.shorter,
        easing: Theme.transitions.easing.easeOut,
      }),
      // Mimics the default input display property used by browsers for an input.
      ...Theme.typography.body1,
      color: isDisabled ? Theme.palette.text.disabled : Theme.palette.text.primary,
      lineHeight: '1.1876em', // Reset (19px), match the native input line-height
      boxSizing: 'border-box', // Prevent padding issue with fullWidth.
      position: 'relative',
      cursor: isDisabled ? 'default' : 'text',
      display: 'inline-flex',
      alignItems: 'center',
    } as React.CSSProperties
  }
}

export default function TrackerPickerEntry(props: TrackerPickerEntryProps) {
  const updateFunction = (event: any, newValue: string | null) => {
    if (!!newValue) {
      props.updateFunction(newValue)
    }
  }

  const isDisabled = props.disabled || props.options.length <= 1
  const [isFocused, setIsFocused] = useState(false)
  const [isMousedOver, setIsMousedOver] = useState(false)
  const rootFunction = getRootStyle(props.width, isDisabled, isFocused, isMousedOver)

  const input = {
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'currentColor',
    padding: `8px 8px 6px`,
    border: 0,
    boxSizing: 'content-box',
    background: 'none',
    height: '1.1876em', // Reset (19px), match the native input line-height
    margin: 0, // Reset for Safari
    WebkitTapHighlightColor: 'transparent',
    display: 'block',
    // Make the flex item shrink with Firefox
    minWidth: 0,
    width: '100%', // Fix IE 11 width issue
    animationName: 'mui-auto-fill-cancel',
    animationDuration: '10ms',
  } as React.CSSProperties

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  const onMouseEnter = () => {
    setIsMousedOver(true)
  }

  const onMouseLeave = () => {
    setIsMousedOver(false)
  }

  return (
    <Autocomplete
      autoSelect
      selectOnFocus
      clearOnEscape
      autoHighlight
      disableClearable
      openOnFocus={true}
      disabled={isDisabled}
      options={props.options}
      renderInput={(params) => (
        <div ref={params.InputProps.ref} style={rootFunction()} >
          <input type="text" style={input} {...params.inputProps} />
        </div>
      )}
      onChange={updateFunction}
      value={props.value}
      style={{width: props.width}}
      forcePopupIcon={false}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
