import {Uuid} from 'src/forecast/model/universal-data-model'
import { PlannerColumn } from './planner-column'

export class ColumnGroup<T extends PlannerColumn> {
  constructor(
    id: Uuid,
    name: string,
    columns: T[],
  ) {
    this.id = id
    this.name = name
    this.columns = columns
  }
  id: Uuid
  name: string
  columns: T[]
}
