import React, {useCallback} from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchBar from 'src/topbar/search-bar/search-bar'
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from 'src/store/root-reducer'
import {FORECAST_PATH, PLANNER_PATH, TRACKER_PATH} from 'src/router/root-router'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { setDisplayNavigationDrawerActionCreator } from 'src/navigation-drawer/navigation-drawer-actions';
import { getActiveRegion } from 'src/router/router-selectors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Region } from 'src/tracker/model/components/region';
import { toggleRegion } from 'src/router/router-effects';

export default function TopBar() {
  const currentPath = useSelector((state: AppState) => state.router.location.pathname)
  const region = useSelector(getActiveRegion)

  const shouldShowSearchBar = currentPath === FORECAST_PATH || currentPath === TRACKER_PATH || currentPath === PLANNER_PATH

  const dispatch = useDispatch()
  const dispatchDisplayNavDrawer = useCallback(() => {
    dispatch(setDisplayNavigationDrawerActionCreator(true))
  }, [dispatch])
  const dispatchToggleRegion = useCallback(() => {
    dispatch(toggleRegion())
  }, [dispatch])
  const handleDisplayNavDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatchDisplayNavDrawer()
  }
  const toggleRegionEventHandler = () => {
    dispatchToggleRegion()
  };

  const regionToggle =
    <FormControlLabel key='label'
      control={
        <Switch
          name={region}
          color="primary"
          checked={region === Region.JP}
          onChange={toggleRegionEventHandler}
        />
      }
      label={region}
    />


  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton onClick={handleDisplayNavDrawer}>
          <MenuIcon />
        </IconButton>
        {shouldShowSearchBar && <SearchBar />}
        {shouldShowSearchBar && regionToggle}
      </Toolbar>
    </AppBar>)
}
