import { CrystalColor } from 'src/forecast/model/universal-data-model'
import { EnumerableCharacterProperty } from '../enumerable-character-property'
import { CharacterValueConverter } from 'src/tracker/model/tracker-model-converter'
import { getValues } from 'src/utils/enum-values'

const crystalColorConverter = new CharacterValueConverter<CrystalColor>(
  (value) => {
    switch (value) {
      case CrystalColor.RED:
        return "Red"
      case CrystalColor.BLUE:
        return "Blue"
      case CrystalColor.GREEN:
        return "Green"
      case CrystalColor.YELLOW:
        return "Yellow"
      case CrystalColor.BLACK:
        return "Black"
      case CrystalColor.WHITE:
        return "White"
    }
  },
  (value) => {
    switch (value) {
      case "Red":
      default:
        return CrystalColor.RED
      case "Blue":
        return CrystalColor.BLUE
      case "Green":
        return CrystalColor.GREEN
      case "Yellow":
        return CrystalColor.YELLOW
      case "Black":
        return CrystalColor.BLACK
      case "White":
        return CrystalColor.WHITE
    }
  }
)

export const crystalColorProperty = new EnumerableCharacterProperty<CrystalColor>(
  "e6c1b304-9420-4a88-afbc-71fa4a6c43bb",
  "Crystal Color",
  "Color",
  crystalColorConverter,
  (region, character) => {
    return character?.universal.color || CrystalColor.RED
  },
  () => getValues(CrystalColor)
)
