import { TranscendenceGate } from "../tracker-model"

export class CharacterTranscendenceUtilization {
  constructor(
    tierNumber: number,
    gate: TranscendenceGate,
  ) {
    this.tierNumber = tierNumber
    this.gate = gate
  }
  tierNumber: number
  gate: TranscendenceGate
}
