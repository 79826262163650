import {ActionCreator} from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from 'src/store/root-reducer'
import { getFirebase } from 'react-redux-firebase'
import { CharacterData, Tracker, TrackerCharacterData, TranscendenceGate} from 'src/tracker/model/tracker-model'
import {
  WriteTrackerDataFailureAction,
  writeTrackerDataFailureActionCreator,
  storeCharacterMassEditActionCreator,
  MASS_EDIT_CONFIRMATION_DIALOG_SUBTYPE,
  clearStoredCharacterActionCreator,
  storeTrackerCharacterDataActionCreator,
  COLUMN_EDIT_CONFIRMATION_DIALOG_SUBTYPE,
  clearStoredTrackerCharacterDataActionCreator,
  ClearStoredTrackerCharacterDataAction,
} from 'src/tracker/tracker-actions'
import {Uuid} from 'src/forecast/model/universal-data-model'
import {merge} from 'lodash'
import {characterDataValidator} from 'src/tracker/model/character-data-validator'
import {getUserId, getSpecifiedId} from 'src/login/login-selectors'
import {
  getTrackerDocument,
  TRACKER_COLLECTION_NAME,
  getAllCharactersMap,
  getCharacterData,
  createDocumentMergableCharacterData,
  createDocumentMergableSharedData,
  createDocumentMergableTrackerCharacterData,
  getStoredCharacterId,
  getStoredCharacterData,
  getStoredTrackerCharacterData,
  createDocumentMergableTranscendenceData,
  createDocumentMergableEntropyDataFromCompoments
} from 'src/tracker/tracker-selectors'
import {getUniversalCharacterData, getAllCharacterData} from 'src/forecast/forecast-selectors'
import { objectAssigningReducer } from 'src/forecast/model/universal-data-converter';
import { openAlert, AlertType } from 'src/alert/alert-actions';
import { getActiveRegion } from 'src/router/router-selectors';
import { writePlannerData } from 'src/planner/planner-effects';
import { createDocumentMergableSharedData as createDocumentMergableSharedDataPlanner } from 'src/planner/planner-selectors';
import { getPreviewedTrackerData, isPreviewedTrackerData } from 'src/preferences/preferences-selectors';
import { SetPreviewedTrackerDataAction, setPreviewedTrackerDataActionCreator } from 'src/preferences/preferences-actions';
import { DateTime } from 'luxon'

type OutputtableActions = WriteTrackerDataFailureAction | SetPreviewedTrackerDataAction | ClearStoredTrackerCharacterDataAction
type TrackerThunk = ActionCreator<ThunkAction<void, AppState, typeof getFirebase, OutputtableActions>>

export const writeCharacterData: TrackerThunk =
  (idDataPairs: [Uuid, CharacterData][], when: DateTime) => {
    return async (dispatch, getState, getFirebase) => {
      writeCharacterDataInternal(idDataPairs, when, dispatch, getState, getFirebase)
    }
  }

export const writeEntropyUtilizationData: TrackerThunk =
  (entropyTier: string, tierUtilizationPropertyName: string, id: Uuid | null) => {
    return async (dispatch, getState, getFirebase) => {
      const region = getActiveRegion(getState())
      writeTrackerData(createDocumentMergableEntropyDataFromCompoments(getState(), region, entropyTier, tierUtilizationPropertyName, id), dispatch, getState, getFirebase)
    }
  }

export const writeTranscendenceUtilizationData: TrackerThunk =
  (transcendenceTier: string, gate: TranscendenceGate, tierUtilizationPropertyName: string, id: Uuid | null) => {
    return async (dispatch, getState, getFirebase) => {
      const region = getActiveRegion(getState())
      writeTrackerData(createDocumentMergableTranscendenceData(getState(), region, transcendenceTier, gate, tierUtilizationPropertyName, id), dispatch, getState, getFirebase)
    }
  }

export const writeSharableData: TrackerThunk =
  (shareable: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writeTrackerData(createDocumentMergableSharedData(shareable), dispatch, getState, getFirebase, true)
      writePlannerData(createDocumentMergableSharedDataPlanner(shareable), dispatch, getState, getFirebase, true)
    }
  }

export const writeAllCharacterData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, OutputtableActions>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const storedTrackerCharacterData = getStoredTrackerCharacterData(getState())
      const region = getActiveRegion(getState())
      if (storedTrackerCharacterData !== null) {
        const allUniversalCharacterData = getAllCharacterData(getState())
        const allExistingPersonalCharacterData = getAllCharactersMap(getState())
        const validatedTrackerCharacterData = Object.keys(storedTrackerCharacterData).map(characterId => {
          return {
            [characterId]: characterDataValidator(storedTrackerCharacterData[characterId],
              allExistingPersonalCharacterData[characterId], allUniversalCharacterData[characterId], DateTime.utc(), region)
          }
        }).reduce(objectAssigningReducer)
        writeTrackerData(createDocumentMergableTrackerCharacterData(validatedTrackerCharacterData, region), dispatch, getState, getFirebase)
      }
      dispatch(clearStoredTrackerCharacterDataActionCreator())
    }
  }

export const writeStoredCharacterData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, OutputtableActions>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const storedCharacterId = getStoredCharacterId(getState())
      const storedCharacterData = getStoredCharacterData(getState())
      if (storedCharacterId !== null && storedCharacterData !== null) {
        writeCharacterDataInternal([[storedCharacterId, storedCharacterData]], DateTime.utc(), dispatch, getState, getFirebase)
      }
      dispatch(clearStoredCharacterActionCreator())
    }
  }

const writeCharacterDataInternal = (idDataPairs: [Uuid, CharacterData][], when: DateTime, dispatch: ThunkDispatch<AppState, typeof getFirebase, OutputtableActions>, getState: () => AppState, firebaseInt: typeof getFirebase) => {
  const region = getActiveRegion(getState())
  const validatedPairs = idDataPairs.map(([characterId, characterData]) => {
    const pair: [Uuid, CharacterData] = [characterId, characterDataValidator(characterData, getCharacterData(characterId)(getState()), getUniversalCharacterData(characterId)(getState()), when, region)]
    return pair
  })
  writeTrackerData(createDocumentMergableCharacterData(region, validatedPairs), dispatch, getState, firebaseInt)
}


const writeTrackerData = (createDocumentMergableData: Partial<Tracker>, dispatch: ThunkDispatch<AppState, typeof getFirebase, OutputtableActions>, getState: () => AppState, firebaseInt: typeof getFirebase, ignoreSpecifiedId: boolean = false) => {
  const userId = getUserId(getState())
  const idIsSpecified = !ignoreSpecifiedId && !!getSpecifiedId(getState())
  if (!userId) {
    dispatch(writeTrackerDataFailureActionCreator("User not logged in"))
  } else if (idIsSpecified) {
    dispatch(writeTrackerDataFailureActionCreator("Tried to edit other user's data"))
  } else {
    const trackerData = getTrackerDocument(getState())
    const mergedTrackerData = merge({}, trackerData, createDocumentMergableData)
    const trackerWritableData = JSON.parse(JSON.stringify(mergedTrackerData)) as Tracker
    if (isPreviewedTrackerData(getState())) { // if we're in preview, set the previewed data to change instead
      dispatch(setPreviewedTrackerDataActionCreator(trackerWritableData))
    } else {
      firebaseInt().firestore().collection(TRACKER_COLLECTION_NAME).doc(userId)
        .set(trackerWritableData)
    }
  }
}

export const commitPreviewedData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, OutputtableActions>> =
  () => {
    return async (dispatch, getState, firebaseInt) => {
      const trackerData = getPreviewedTrackerData(getState())
      if (!!trackerData) {
        const userId = getUserId(getState())
        if (!userId) {
          dispatch(writeTrackerDataFailureActionCreator("User not logged in"))
        } else {
          const writableTrackerData = JSON.parse(JSON.stringify(trackerData))
          firebaseInt().firestore().collection(TRACKER_COLLECTION_NAME).doc(userId)
            .set(writableTrackerData)
          dispatch(setPreviewedTrackerDataActionCreator(null))
        }
      }
    }
  }

export const deleteData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, any>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const userId = getUserId(getState())
      if (!userId) {
        dispatch(writeTrackerDataFailureActionCreator("User not logged in"))
      } else {
        getFirebase().firestore().collection(TRACKER_COLLECTION_NAME).doc(userId).delete()
      }
    }
  }

export const saveCharacterToEditAndDisplayConfirmationDialog: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, any>> =
  (characterId: Uuid, characterData: CharacterData, message: string) => {
    return async (dispatch, getState, getFirebase) => {
      dispatch(storeCharacterMassEditActionCreator(characterId, characterData))
      dispatch(openAlert(message, AlertType.OK_CANCEL, MASS_EDIT_CONFIRMATION_DIALOG_SUBTYPE))
    }
  }

export const saveColumnToEditAndDisplayConfirmationDialog: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, any>> =
  (trackerCharacterData: TrackerCharacterData, message: string) => {
    return async (dispatch, getState, getFirebase) => {
      dispatch(storeTrackerCharacterDataActionCreator(trackerCharacterData))
      dispatch(openAlert(message, AlertType.OK_CANCEL, COLUMN_EDIT_CONFIRMATION_DIALOG_SUBTYPE))
    }
  }

