import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {CustomColumnGroup} from 'src/preferences/model/preferences-model'
import {ColumnGroup as PlannerColumnGroup} from 'src/planner/model/column/column-group'
import { plannerColumnLookupMap } from 'src/planner/model/column/group-of-groups'
import { getColumnFromId } from 'src/tracker/model/column/groups/group-of-groups'

export function convertToColumnGroup(customColumnGroup: CustomColumnGroup) {
  return new ColumnGroup(
    customColumnGroup.id,
    customColumnGroup.name,
    customColumnGroup.columns.map(columnId => getColumnFromId(columnId)),
    false
  )
}

export function convertToPlannerColumnGroup(customColumnGroup: CustomColumnGroup) {
  return new PlannerColumnGroup(
    customColumnGroup.id,
    customColumnGroup.name,
    customColumnGroup.columns.map(columnId => {
      return plannerColumnLookupMap[columnId]
    }),
  )
}
