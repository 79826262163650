import React, {FunctionComponent, useCallback, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import { CustomColumnGroups, CustomColumnGroup} from 'src/preferences/model/preferences-model'
import { v4 as uuidv4 } from 'uuid';
import {PreferencesThunk} from 'src/preferences/preferences-effects'
import {CUSTOM_GROUP_LIMIT} from 'src/preferences/model/preferences-model-validators'
import TextField from '@material-ui/core/TextField';
import {isEqual} from 'lodash'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import theme from 'src/theme';
import InsertionList from 'src/utils/insertion-list/insertion-list';
import { AppState } from 'src/store/root-reducer'
import { Uuid } from 'src/forecast/model/universal-data-model'

export interface ObjectWithId {
  id: Uuid
}

function addNewColumnGroup(groups: CustomColumnGroups) {
  const numberOfGroups = Object.keys(groups).length
  const id = uuidv4()
  const newGroups = groups.slice()
  newGroups.push({
    id: id,
    name: `Custom ${numberOfGroups + 1}`,
    columns: [],
  })
  return newGroups
}

function editColumnGroup(newGroup: CustomColumnGroup, groups: CustomColumnGroups) {
  const output = groups.slice()
  const replaceIndex = groups.findIndex(group => group.id === newGroup.id)
  if (replaceIndex === -1) {
    output.push(newGroup)
  } else {
    output[replaceIndex] = newGroup
  }
  return output
}

function removeColumnGroup(groupToRemove: CustomColumnGroup, groups: CustomColumnGroups) {
  const newGroups = groups.slice()
  const indexToRemove = newGroups.findIndex(group => group.id === groupToRemove.id)
  if (indexToRemove !== -1) {
    newGroups.splice(indexToRemove, 1)
  }
  return newGroups
}

interface AbstractCustomColumnsComponentProps {
  getCustomColumnGroups: (state: AppState) => CustomColumnGroups
  writeCustomColumnGroups: PreferencesThunk
  dataToString: (input: string) => string | undefined
  selector: (onIdPicked: (objectWithId: ObjectWithId | null) => void) => JSX.Element
}

const AbstractCustomColumnsComponent: FunctionComponent<AbstractCustomColumnsComponentProps> = ({
  getCustomColumnGroups,
  writeCustomColumnGroups,
  dataToString,
  selector,
}) =>{
  const currentCustomColumnGroups = useSelector(getCustomColumnGroups)

  const [selectedGroup, setSelectedGroup] = useState(null as CustomColumnGroup | null)
  const [insertionPosition, setInsertionPosition] = useState(0)
  const setSelectedGroupWrapper = (newVal: CustomColumnGroup | null) => {
    setSelectedGroup(newVal)
  }
  if (!!selectedGroup && !currentCustomColumnGroups.find(currentGroup => isEqual(currentGroup, selectedGroup))) {
    const updatedGroup = currentCustomColumnGroups.find(currentGroup => currentGroup.id === selectedGroup?.id) || null
    setSelectedGroup(updatedGroup)
  }

  const selectedGroupColumns = selectedGroup?.columns || []

  const dispatch = useDispatch()
  const writeCustomColumnsCallback = useCallback((groups: CustomColumnGroups) => {
    dispatch(writeCustomColumnGroups(groups))
  }, [dispatch, writeCustomColumnGroups])

  const addGroupOnClick = () => {
    writeCustomColumnsCallback(addNewColumnGroup(currentCustomColumnGroups))
  }
  const removeGroupOnClick = (groupToRemove: CustomColumnGroup) => {
    return () => {
      if (selectedGroup?.id === groupToRemove.id) {
        setSelectedGroupWrapper(null)
      }
      writeCustomColumnsCallback(removeColumnGroup(groupToRemove, currentCustomColumnGroups))
    }
  }
  const onGroupNameChange = (event: any) => {
    const changedGroup = Object.assign({}, selectedGroup, {name: event.target.value})
    writeCustomColumnsCallback(editColumnGroup(changedGroup, currentCustomColumnGroups))
  }

  const addToColumnGroupList = (property: ObjectWithId | null) => {
    if (property !== null && dataToString(property.id) !== undefined && !!selectedGroup) {
      const newColumnsList = selectedGroupColumns.slice()
      newColumnsList.splice(insertionPosition, 0, property.id)

      const newColumnGroup = Object.assign({}, selectedGroup, { columns: newColumnsList })
      writeCustomColumnsCallback(editColumnGroup(newColumnGroup, currentCustomColumnGroups))
    }
  }

  const addGroupButton = (
    <IconButton onClick={addGroupOnClick} disabled={Object.keys(currentCustomColumnGroups).length >= CUSTOM_GROUP_LIMIT}>
      <Add />
    </IconButton>
  )

  const onColumnGroupChange = (event: any, newValue: string | null) => {
    return setSelectedGroupWrapper(currentCustomColumnGroups.find(customColumnGroup => customColumnGroup.id === newValue) || null)
  }

  const columnGroupItems = Object.values(currentCustomColumnGroups).map(columnGroup => {
    return (
      <Tab key={columnGroup.name} label={columnGroup.name} value={columnGroup.id} />
    )
  })

  const newDataListConsumer = (newDataList: string[]) => {
    const newColumnGroup = Object.assign({}, selectedGroup, { columns: newDataList })
    writeCustomColumnsCallback(editColumnGroup(newColumnGroup, currentCustomColumnGroups))
  }
  const dataToStringInt = (data: string) => {
    const output = dataToString(data)
    return output === undefined ? `Unknown ID = ${data}` : output
  }

  // todo perf: add edit button
  const editableColumnGroup = (
    !!selectedGroup &&
    <div>
      <Typography variant='h6'>Edit column group:</Typography>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography style={{padding: theme.spacing(2)}}>Edit group name: </Typography>
        <TextField value={selectedGroup.name} variant="filled" onChange={onGroupNameChange} />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography style={{padding: theme.spacing(2)}}>Delete group: </Typography>
        <IconButton onClick={removeGroupOnClick(selectedGroup)}>
          <Delete />
        </IconButton>
      </div>
      <Typography style={{ padding: theme.spacing(2) }}>Edit columns in group: </Typography>
      <div style={{display: 'flex', alignItems: 'start', flexWrap: 'wrap'}}>
        <div style={{padding: theme.spacing(2), maxHeight: '544px', overflowY: 'auto', maxWidth: '400px', flexGrow: 1}}>
          <InsertionList dataList={selectedGroupColumns} newDataListConsumer={newDataListConsumer} dataToString={dataToStringInt}
            positionObserver={setInsertionPosition} />
        </div>
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
          <Typography style={{ padding: theme.spacing(2) }}>Pick a column to add: </Typography>
          {selector(addToColumnGroupList)}
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <Typography variant='h6'>Column groups:</Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tabs onChange={onColumnGroupChange} value={!!selectedGroup && selectedGroup.id} style={{padding: theme.spacing(2)}}>
          {columnGroupItems}
        </Tabs>
        {addGroupButton}
      </div>
      {editableColumnGroup}
    </div>
  )
}

export default AbstractCustomColumnsComponent
