import { createDefaultTranscendenceUtilization, TranscendenceGate } from "../../tracker-model"
import { EnumerableCharacterProperty } from "../enumerable-character-property"
import { CharacterValueConverter } from "../../tracker-model-converter"
import { CharacterTranscendenceUtilization } from "../../components/character-transcendence-utilization"
import { eq } from 'lodash'
import {getValues} from 'src/utils/enum-values'

const characterTranscendenceValueConverter = new CharacterValueConverter<CharacterTranscendenceUtilization | undefined>(
  (value) => {
    if (!value) {
      return "0"
    }
    const tierNumberString = value.tierNumber.toString()
    const gateName = TranscendenceGate[value.gate]
    return tierNumberString + " " + gateName
  },
  (value) => {
    if (value === "0") {
      return undefined
    }
    const splitValues = value.split(" ")
    return new CharacterTranscendenceUtilization(parseInt(splitValues[0], 10), TranscendenceGate[splitValues[1] as keyof typeof TranscendenceGate])
  },
  (value1, value2, comparitorFunction) => {
    if (!value1) {
      if (!value2) {
        return comparitorFunction(0, 0)
      }
      return comparitorFunction(0, value2.tierNumber)
    } if (!value2) {
      return comparitorFunction(value1.tierNumber, 0)
    } else {
      const tierEquivalent = eq(value1.tierNumber, value2.tierNumber)
      if (tierEquivalent) {
        return comparitorFunction(value1.gate, value2.gate)
      }
      return comparitorFunction(value1.tierNumber, value2.tierNumber)
    }
  }
)

export const transcendenceProperty = new EnumerableCharacterProperty<CharacterTranscendenceUtilization | undefined>(
  "9dd6ec9e-5060-4fe9-9746-0647d6814db3",
  "Dimension's End: Transcendence",
  "Transc.",
  characterTranscendenceValueConverter,
  (region, character) => character?.deUtilization?.transcendenceUtilization,
  () => {
    const availableTiers = Object.keys(createDefaultTranscendenceUtilization()).map(value => parseInt(value, 10))
    const availableGates = getValues(TranscendenceGate) as TranscendenceGate[]
    const options = [undefined] as (CharacterTranscendenceUtilization | undefined)[]
    availableTiers.forEach(tier => {
      availableGates.forEach(gate => {
        options.push(new CharacterTranscendenceUtilization(tier, gate))
      })
    })
    return options
  }
)
