import { armorProperty } from 'src/tracker/model/property/equipment/armor/armor-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { BannerResourceProperty } from '../resource-meta/banner-resource-property'
import { createStandardCharacterDataAccessor } from '../resource-meta/character-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

const resource = Resource.ARMOR_TOKENS

export const armorTokenProperty = new BannerResourceProperty(
  '64a274eb-84e8-4719-afa1-1e38cb36096e',
  'Armor Tokens',
  'armorTokens',
  resource,
  false,
  new DailyResourceStats(5, 5),
)

armorTokenProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(armorProperty, resource))

