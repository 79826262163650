import { getEventAppearances, getEventTime } from 'src/forecast/forecast-selectors'
import {TimeRange, EventAppearance, Event} from 'src/forecast/model/universal-data-model'
import { AllCharacterData } from 'src/tracker/model/components/all-character-data'
import { Region } from 'src/tracker/model/components/region'
import { DatePropertyMode } from 'src/tracker/model/property/date-property'
import { DateTime, Duration } from 'luxon'

export enum EventTimeMode {
  TIME_TO_START, TIME_TO_END, TIME, ESTIMATE, ESTIMATE_PAST, MISSING_EVENT, NONE
}

export class EventTimeState {
  constructor(
    timeRemaining: Duration,
    countdownTime: DateTime,
    eventTimeMode: EventTimeMode,
  ) {
    this.timeRemaining = timeRemaining
    this.countdownTime = countdownTime
    this.eventTimeMode = eventTimeMode
  }
  timeRemaining: Duration
  countdownTime: DateTime
  eventTimeMode: EventTimeMode
}

export function calculateMode(timeRange: TimeRange, now: DateTime = DateTime.utc()) {
    const startTime = timeRange.start
    const endTime = startTime.plus(timeRange.duration)

    let eventTimeMode = EventTimeMode.NONE
    if (timeRange.missingEvent === true) {
      eventTimeMode = EventTimeMode.MISSING_EVENT
    } else if (timeRange.estimate === true) {
      if (startTime > now) {
        eventTimeMode = EventTimeMode.ESTIMATE
      } else {
        eventTimeMode = EventTimeMode.ESTIMATE_PAST
      }
    } else if (startTime > now) {
      eventTimeMode = EventTimeMode.TIME_TO_START
    } else if (endTime > now) {
      eventTimeMode = EventTimeMode.TIME_TO_END
    } else {
      eventTimeMode = EventTimeMode.TIME
    }
    const countdownTime = eventTimeMode === EventTimeMode.TIME_TO_END || eventTimeMode === EventTimeMode.ESTIMATE_PAST ? endTime : startTime
    const output = new EventTimeState(countdownTime.diff(now), countdownTime, eventTimeMode)
    return output as EventTimeState
}

export const filterTimeRangeByMode = (datePropertyMode: DatePropertyMode, when: DateTime) => (timeRange: TimeRange) => {
  switch(datePropertyMode) {
    case DatePropertyMode.FUTURE:
      return timeRange.start > when
    case DatePropertyMode.PAST:
      return timeRange.start.plus(timeRange.duration) < when
    case DatePropertyMode.FUTURE_AND_PRESENT:
      return timeRange.start.plus(timeRange.duration) >= when
    case DatePropertyMode.PAST_AND_PRESENT:
      return timeRange.start <= when
    case DatePropertyMode.ALL:
    default:
      return true
  }
}

export const functionalGetEventAppearanceValue = (predicate: (appearance: EventAppearance) => boolean) => (propertyMode: DatePropertyMode) => (region: Region, character: AllCharacterData | undefined) => {
  return getEventAppearanceValue(character, predicate, propertyMode, region)
}

export const getEventAppearanceValue = (character: AllCharacterData | undefined, predicate: (appearance: EventAppearance) => boolean, propertyMode: DatePropertyMode, region: Region) => {
  const now = DateTime.utc()
  const eventAppearances = getEventAppearances(region, character?.universal)
  const timeRangeFilter = filterTimeRangeByMode(propertyMode, now)
  const validEventApperances = eventAppearances?.filter(eventAppearance => {
    const timeRange = eventAppearance.timeRange
    return timeRangeFilter(timeRange)
  }).filter(eventAppearance => {
    return predicate(eventAppearance as EventAppearance)
  }).sort((a, b) => {
    return timeRangeComparitor((a?.timeRange as TimeRange), (b?.timeRange as TimeRange))
  }).map(value => value?.timeRange)

  if (!validEventApperances) {
    return null
  } else if (DatePropertyMode.PAST === propertyMode || DatePropertyMode.PAST_AND_PRESENT === propertyMode) {
    return validEventApperances[validEventApperances.length - 1] || null
  } else {
    return validEventApperances[0] || null
  }
}

export function orderEvents(events: Event[], region: Region, now: DateTime = DateTime.utc()) {
  return events.filter(event => {
    const time = getEventTime(region, event)
    if (!time) {
      return false;
    }
    const start = time.start
    const end = start.plus(time.duration);
    return end > now;
  })
    .sort((a, b) => {
      const aTime = getEventTime(region, a)
      const bTime = getEventTime(region, b)
      return timeRangeComparitor(aTime, bTime);
    });
}

export const timeRangeComparitor = (a: TimeRange, b: TimeRange) => {
  return a.start.valueOf() - b.start.valueOf()
}
