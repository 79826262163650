import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getGreyAcquiredCharacters} from 'src/preferences/preferences-selectors'
import {GreyAcquiredCharacters, greyAcquiredCharactersValues} from 'src/preferences/model/preferences-model'
import {writeGreyAcquiredCharacters} from 'src/preferences/preferences-effects'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import TextField from '@material-ui/core/TextField';

function GreyAcquiredCharactersComponent() {
  const currentGreyAcquiredCharacters = useSelector(getGreyAcquiredCharacters)

  const dispatch = useDispatch()
  const writeCustomColumnsCallback = useCallback((greyAcquiredCharacters: GreyAcquiredCharacters) => {
    dispatch(writeGreyAcquiredCharacters(greyAcquiredCharacters))
  }, [dispatch])

  const setGreyAcquiredCharacters = (event: any, newValue: GreyAcquiredCharacters | null) => {
    if (!!newValue) {
      writeCustomColumnsCallback(newValue)
    }
  }

  return (
    <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      disableClearable
      openOnFocus
      blurOnSelect
      forcePopupIcon={false}
      style={{ width: '128px' }}
      options={greyAcquiredCharactersValues}
      renderInput={(params) => (
        <TextField {...params} />
      )}
      onChange={setGreyAcquiredCharacters}
      value={currentGreyAcquiredCharacters}
    />
  )
}

export const GreyAcquiredCharactersWidget = new PreferencesWidget(
  <GreyAcquiredCharactersComponent />,
  "Grey acquired characters",
)
