import {Uuid} from 'src/forecast/model/universal-data-model'
import { Region } from 'src/tracker/model/components/region'
import { SavedView } from './saved-view'

export interface Preferences {
  general: GeneralPreferences
  forecast: ForecastPreferences
  tracker: TrackerPreferences
  planner: PlannerPreferences
}

export interface GeneralPreferences {
  displayDetailedEstimates: boolean
  defaultRegion: Region
  savedViews: SavedView[]
  defaultSavedView: Uuid
}

export interface ForecastPreferences {
  greyAcquiredCharacters: GreyAcquiredCharacters
  showLocalTime: boolean
}

export type GreyAcquiredCharacters = 'Acquired' | 'Maxed' | 'Never'
export const greyAcquiredCharactersValues = ['Acquired', 'Maxed', 'Never'] as GreyAcquiredCharacters[]

export interface TrackerPreferences {
  customColumnGroups: CustomColumnGroups
  defaultEditMode: boolean
  highlightMaxed: boolean
  showFutureCharacters: boolean
  importBreadcrumbDismissed: boolean
}

export type CustomColumnGroups = CustomColumnGroup[]

export interface CustomColumnGroup {
  id: Uuid,
  name: string,
  columns: Uuid[]
}

export interface PlannerPreferences {
  highlightAltered: boolean
  displayFirstLdName: boolean
  importBreadcrumbDismissed: boolean
  customColumnGroups: CustomColumnGroups
  defaultColumnGroup: Uuid
  displayAsRefined: boolean
}
