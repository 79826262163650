import { CharacterTranscendenceUtilization } from "./character-transcendence-utilization";

export class DeUtilization {
  constructor(
    entropyUtilization: number | undefined,
    transcendenceUtilization: CharacterTranscendenceUtilization | undefined,
  ) {
    this.entropyUtilization = entropyUtilization
    this.transcendenceUtilization = transcendenceUtilization
  }
  entropyUtilization: number | undefined
  transcendenceUtilization: CharacterTranscendenceUtilization | undefined
}

export function createDefaultDeUtilization() {
  return new DeUtilization(undefined, undefined)
}
