import { btProperty } from 'src/tracker/model/property/equipment/weapon/bt-property'
import { exProperty } from 'src/tracker/model/property/equipment/weapon/ex-property'
import { frProperty } from 'src/tracker/model/property/equipment/weapon/fr-property'
import { BtWeaponLimitBreak, ExWeaponLimitBreak, Resource, StandardLimitBreak } from 'src/tracker/model/tracker-model'
import { CharacterResourceProperty } from '../resource-meta/character-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

export const weaponNuggetProperty = new CharacterResourceProperty(
  '75cf854c-92de-4ce1-97d8-8feaaf0d39dc',
  'Weapon Nuggets',
  'weaponNuggets',
  Resource.WEAPON_NUGGETS,
  new DailyResourceStats(2, 1),
)

weaponNuggetProperty.getResourceFromCharacterDataAccessors.push(
  (characterData, negate) => {
    const value = exProperty.getFromCharacterData(characterData)
    const btDiscount = (btProperty.getFromCharacterData(characterData) > BtWeaponLimitBreak.UNOBTAINED || frProperty.getFromCharacterData(characterData) > StandardLimitBreak.UNOBTAINED) && value >= ExWeaponLimitBreak.PLUS_THREE ? -20 : 0
    const resourceValue = exProperty.valueToResource(value, Resource.WEAPON_NUGGETS)
    return (resourceValue + btDiscount) * (negate ? -1 : 1)
  }
)
