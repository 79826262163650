import {Resource} from 'src/tracker/model/tracker-model'
import {CharacterValueConverter} from 'src/tracker/model/tracker-model-converter'
import { Uuid } from 'src/forecast/model/universal-data-model'
import { CharacterProperty } from './character-property'
import { acquirableOptionFunction } from './acquired/acquirable-option-function'
import { AllCharacterData } from '../components/all-character-data'
import { Region } from '../components/region'
import { DateTime } from 'luxon'

export interface ResourceRetrievalFunction<T> {
  resource: Resource
  valueToResource: (value: T) => number
}

export class EnumerableCharacterProperty<T> extends CharacterProperty<T> {
  constructor(
    id: Uuid,
    name: string,
    shortName: string,
    converter: CharacterValueConverter<T>,
    get: (region: Region, characterData?: AllCharacterData) => T,
    options: (universalCharacterData: AllCharacterData, when: DateTime, region: Region) => T[],
    resourceRetrievalOverrides: ResourceRetrievalFunction<T>[] = []
  ) {
    super(id, name, shortName, get, converter.valueToString, converter.valueComparitor, converter.valueToString, converter.valueFromString)

    this.options = (characterData, when, region) => {
      const passedOptions = options(characterData, when, region)
      const acquirable = acquirableOptionFunction(characterData, when, region).length > 1
      return acquirable ? passedOptions : passedOptions.slice(0, 1)
    }
    this.getMax = (characterData, when, region) => {
      const resolvedOptions = this.options(characterData, when, region)
      return resolvedOptions[resolvedOptions.length - 1]
    }
    this.getMin = (characterData, when, region) => {
      const resolvedOptions = this.options(characterData, when, region)
      return resolvedOptions[0]
    }
    this.valueFromString = converter.valueFromString
    this.valueToResource = (value: T, resource: Resource) => {
      const retrievalOverrideFunction = resourceRetrievalOverrides.find(override => override.resource === resource)
      if (!!retrievalOverrideFunction) {
        return retrievalOverrideFunction.valueToResource(value)
      }
      return converter.valueToResource(value, resource)
    }
  }
  options: (characterData: AllCharacterData, when: DateTime, region: Region) => T[]
  getMax: (characterData: AllCharacterData, when: DateTime, region: Region) => T
  getMin: (characterData: AllCharacterData, when: DateTime, region: Region) => T
  valueFromString: (value: string) => T
  valueToResource: (value: T, resource: Resource) => number
}
