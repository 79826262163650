import {ImageUrl} from "src/image-cache/image-reducer"
export const ASSET_FOUND = "ASSET_FOUND"

export function assetFoundActionCreator(lookupId: string, asset: ImageUrl) {
  return {
    type: ASSET_FOUND,
    lookupId: lookupId,
    asset: asset
  }
}

export type AssetFoundAction = ReturnType<typeof assetFoundActionCreator>
