import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak, Resource} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import {ArmorType} from 'src/forecast/model/universal-data-model'
import { EditableCharacterProperty } from '../../editable-character-property'

export const silverArmorProperty = new EditableCharacterProperty(
  "04bd23f3-aeb7-4ed4-9995-4cc37dd9ba73",
  "Silver Armor",
  "4* Armor",
  standardLimitBreakConverter,
  "Silver Armor",
  (characterData) => {
    const allWeaponLimitBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (!!characterData.universal.availableArmors.find(armorType => ArmorType.SILVER === armorType)) {
      return allWeaponLimitBreakValues
    } else {
      return allWeaponLimitBreakValues.slice(0, 1)
    }
  },
  [
    {resource: Resource.CP, valueToResource: (value) => {
      const valueLookupMap = {
        [StandardLimitBreak.UNOBTAINED]: 0,
        [StandardLimitBreak.ZERO]: 20,
        [StandardLimitBreak.ONE]: 25,
        [StandardLimitBreak.TWO]: 30,
        [StandardLimitBreak.THREE]: 40,
      }
      return valueLookupMap[value] || 0
    }}
  ]
)
