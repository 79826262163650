import { isEqual } from 'lodash'
import {parse} from 'papaparse'
import { Banner, Event, Uuid } from 'src/forecast/model/universal-data-model'
import { Resource } from 'src/tracker/model/tracker-model'
import { BannerAllocations, createDefaultBannerAllocation, createDefaultCurrentResourceAccounting, createDefaultPlannerRegionData, createEmptyResourceList, EventAllocation, EventAllocations, Planner, ResourcesList } from '../planner-model'
import { BannerResourceProperty } from '../property/resource-meta/banner-resource-property'
import { resourcesMap } from '../property/resource/resources-group'

/**
 * Assumptions:
 * 1. The header row has not had any of its cells renamed (traditionally row 5)
 * 2. The header row (e.g., gems) generally corresponds to the resource that will appear below
 * 3. The current resources appear to the left of the planned resource expenditures
 * 4. The calculated resources appear to the right of both the current and planned resources.
 * 5. The "Event Name"s have not been renamed
 * 6. If there are two banners in an event, both appear right next to each other
 * 7. The first character in the banner has not been renamed (and the order of the banner has not been changed)
 */
const resourceTypeLookup: Record<string, Resource> = {
  "Gems": Resource.GEMS,
  "Tickets": Resource.TICKETS,
  "Books": Resource.WEAPON_PAGES,
  "# of new EX+": Resource.WEAPON_PAGES,
  "Ingots": Resource.WEAPON_NUGGETS,
  "Burst Token": Resource.BT_TOKENS,
  "High Armor Token": Resource.HIGH_ARMOR_TOKENS,
}

const resourceTypeLookupPostBtRealization: Record<string, Resource> = {
  [Resource.WEAPON_PAGES]: Resource.BT_PAGES,
  [Resource.WEAPON_NUGGETS]: Resource.BT_NUGGETS
}

const lookupPostBtRealization = (resource: Resource) => {
  const postValue = resourceTypeLookupPostBtRealization[resource]
  return !!postValue ? postValue : resource
}

interface RowLookup {
  eventRow: number | null
  bannerRow: number | null
  currentResourceLookup: Partial<Record<Resource, number>>
  plannedExpenditureLookup: Partial<Record<Resource, number>>
}

const createDefaultRowLookup: () => RowLookup = () => {
  return {
    eventRow: null,
    bannerRow: null,
    currentResourceLookup: {},
    plannedExpenditureLookup: {},
  }
}

class RowAndBannerPair {
  constructor(
    row: string[],
    banner: Banner,
  ) {
    this.row = row
    this.banner = banner
  }
  row: string[]
  banner: Banner
}

export const importTonberryTroupeCsv = async (
  csvFile: File,
  existingPlanner: Planner | undefined,
  eventsByEventId: Record<Uuid, Event>,
  bannersByBannerId: Record<Uuid, Banner>
) => {
  const newPlanner = Object.assign({}, existingPlanner)
  const newGlRegionData = Object.assign(createDefaultPlannerRegionData(), newPlanner.GL)
  const newCurrentResourceAccounting = createDefaultCurrentResourceAccounting()
  const [parsedCurrentResources, parsedEventAllocations] = await parseCsv(csvFile, eventsByEventId, bannersByBannerId)
  newCurrentResourceAccounting.currentHeld = parsedCurrentResources
  newGlRegionData.currentResourceAccounting = newCurrentResourceAccounting
  newGlRegionData.eventAllocations = parsedEventAllocations
  newPlanner.GL = newGlRegionData
  return newPlanner
}

const parseCsv = (
  csvFile: File,
  eventsByEventId: Record<Uuid, Event>,
  bannersByBannerId: Record<Uuid, Banner>
): Promise<[ResourcesList, EventAllocations]> => {
  return new Promise((resolve) => {
    parse(csvFile, {
      complete: (results) => {
        const castedData = results.data as string[][]
        const rowLookup = createDefaultRowLookup()
        var currentResources = createEmptyResourceList()
        const eventAllocation: EventAllocations = {}
        castedData.forEach((row, index) => {
          const currentGemResourceIndex = rowLookup.currentResourceLookup[Resource.GEMS]
          // header
          if (row[0] === 'Date' && rowLookup.eventRow === null) {
            row.forEach((cell, index) => {
              parseHeaderCell(cell, index, rowLookup)
            })
            // current resources row
          } else if (rowLookup.eventRow !== null && isEqual(currentResources, createEmptyResourceList()) && !!currentGemResourceIndex && !isNaN(parseInt(row[currentGemResourceIndex], 10))) {
            currentResources = parseResourceRow(row, rowLookup, currentResources)
          } else {
            const currentEventAllocation = convertToEventAllocations(castedData, index, rowLookup, eventsByEventId, bannersByBannerId)
            Object.assign(eventAllocation, currentEventAllocation)
          }
        })
        resolve([currentResources, eventAllocation])
      },
    })
  })
}

const parseHeaderCell = (headerCell: string, index: number, rowLookup: RowLookup) => {
  const resourceType: Resource | null = resourceTypeLookup[headerCell] || null
  if (resourceType !== null) {
    if (rowLookup.currentResourceLookup[resourceType] !== undefined) {
      if (rowLookup.plannedExpenditureLookup[resourceType] === undefined) {
        rowLookup.plannedExpenditureLookup[resourceType] = index
      }
    } else {
      rowLookup.currentResourceLookup[resourceType] = index
    }
  } else if (headerCell === 'Event Name') {
    rowLookup.eventRow = index
  } else if (headerCell === 'Banner') {
    rowLookup.bannerRow = index
  }
}

const parseResourceRow = (row: string[], rowLookup: RowLookup, resources: ResourcesList) => {
  var newResources = resources
  Object.entries(rowLookup.currentResourceLookup).forEach(([key, value]) => {
    const resourceValue = convertTtValueToFfootipValue(parseFloat(row[value as number]), key as Resource) || 0
    const property = resourcesMap[key]
    newResources = property.resourcesListReducer(newResources, resourceValue)
  })
  return newResources
}

const twentiethResourceTypes = new Set([Resource.WEAPON_NUGGETS, Resource.WEAPON_PAGES, Resource.BT_PAGES, Resource.BT_NUGGETS])
const convertTtValueToFfootipValue = (ttValue: number, resourceType: Resource) => {
  if (isNaN(ttValue)) {
    return null
  } else if (twentiethResourceTypes.has(resourceType)) {
    return Math.round(ttValue * 20)
  } else if (resourceType === Resource.GEMS) {
    return Math.round(ttValue * 1000)
  }
  return Math.round(ttValue)
}

class EventValues {
  constructor(
    eventId: Uuid,
    bannerLookup?: Record<string, Uuid>,
    btEra?: boolean,
  ) {
    this.eventId = eventId
    this.bannerLookup = bannerLookup || {}
    this.btEra = btEra || false
  }
  eventId: Uuid
  bannerLookup: Record<string, Uuid>
  btEra: boolean
}

const eventNameLookup: Record<string, EventValues | Record<string, EventValues>> = {
  "": {
    "Galuf LD": new EventValues("c8ac513b-6a04-4a59-8df1-2d1a88e7c9b3"),
    "Sephiroth BT LD": new EventValues("b130f068-510c-4a7f-b1c5-82572604c139"),
    "Vaan LD BT": new EventValues("346f03a9-f727-4152-b4d2-6f73452f3378"),
  },
  "↕️ synergy": {
    "Ignis LD": new EventValues("cbf440d9-aefd-4873-965f-aacaa0b64994"),
    "Setzer LD": new EventValues("d076e90a-3f32-4b7b-afbb-6bade157b331"),
  },
  "Transcendence #4": {
    "Barret LD": new EventValues("531eb8e1-9679-4bcb-9024-0d021cda6d9a"),
    "Ace": new EventValues("6ca4f755-f476-4192-b6f3-b376e3b2a267", {
      "Ace": "03f292be-b25c-4cf6-ac89-a3e4124ee186",
      "Bartz": "5e3499d3-e102-4873-87c4-ea05444d59d1",
    }, true)
  },
  "Heretics": {
    "Bartz": new EventValues("13b6c988-3983-4eb5-9645-e73642e7eec3", {
      "Bartz": "c03b0c2d-bb94-4e89-9b34-d605bd38d68b",
      "Penelo LD": "85f78c0a-65b9-453c-a11c-ebfd5afe4718",
    }),
    "Quistis LD": new EventValues("b7ba8c27-fd8f-487b-a4e4-ccab0a8b954c", {}, true)
  },
  "Boss Rush #3": new EventValues("4c5726f5-1341-42cd-a568-acee9485d6b3"),
  "Story 3.2.1": new EventValues("ee129fca-4060-4c59-9973-a88afd5b3cf9", {
    "Terra": "44ac8154-c3a5-4f97-847a-e7f6294b9451",
    "Basch LD": "b427d6c9-957d-4bbc-ac45-ee5db1c2e5bf"
  }),
  "Amidatelion LC": new EventValues("2d92d3af-b83a-4522-933a-4482ecec0283"),
  "Story 3.2.2": new EventValues("92f51bed-5f44-43a3-ae5b-3c23ba63ff4f"),
  "Raid #13": new EventValues("7398969c-3f9d-4fe6-bf95-eec8b4e9bbd9", {
    "Garland": "775e39c0-a1de-43fa-9d8d-08612c8a30e9",
    "Seven LD": "728dbaa4-54f7-4a97-8fe8-4040184ab0d9",
  }),
  "Desch LC": new EventValues("5db42d51-3538-4400-8480-897bf662ec3a"),
  "Transcendence #2": new EventValues("641f3cfc-7806-4b0d-b9f6-81e03ffa5205"),
  "Divine Alexander": new EventValues("1114c870-33df-4ee7-abf3-5b8cbc713e08", {
    "Golbez": "d1404d0f-7799-49b5-be9d-bb867b9b34cd",
    "Hope LD": "f52df316-2709-485a-b24f-ee0e9fd9c846",
  }),
  "Nine LC": new EventValues("b71c3c6e-0aaa-4c7a-ac8a-6477dfff0a64"),
  "Kadaj Event": new EventValues("23c65137-4e04-47da-9551-b5532aebeebc"),
  "Edward LC": new EventValues("341a362f-dcdf-40b1-ba00-f24493c8a78f"),
  "Boss Rush #4": new EventValues("be33c824-ca14-42ca-9fef-67bec17d3bd8"),
  "Story 3.3.1": new EventValues("75e39aef-8f11-4b16-968c-13f099bd97fd", {
    "Eald'narche": "0e47d390-d016-4b93-96e6-9bdddba5bb4f",
    "Noel LD": "b88daff0-169d-4016-b068-105f3efacbc7"
  }),
  "Strago LC": new EventValues("0f18168d-98ec-41d4-b943-e651166d9c15"),
  "Story 3.3.2": new EventValues("d37162e6-6ace-4d8b-9cdc-042acfbecf6d"),
  "6 party Co-op": new EventValues("fcf3c6e3-72e7-4e33-96d9-d69d05b1cbe4", {
    "Layle": "eacd5393-91ea-4763-998b-356e8cd6048e",
    "Celes LD": "51752178-6c4f-43c8-950d-51fef6a482bf",
  }),
  "Kurasame LC": new EventValues("c9a791d8-4ea0-4450-a2e9-0e534647a12e"),
  "Transcendence #3": new EventValues("393b0d69-21d6-400a-b5e4-0b4c106bdb4e"),
  "Divine Brothers": new EventValues("967e225c-0fd7-41ea-adc8-8fc254686650", {
    "C. of Darkness": "99d724a2-0ab5-4c8d-a77c-90f6f79e7454",
    "Gabranth LD": "cae28c64-1ab1-436d-82f4-30589669ea91",
  }),
  "Gladio LC": new EventValues("054a6a36-3d63-4fb5-aedb-0896e0300883"),
  "Queen Event": new EventValues("2788a050-fc81-4aa5-93c6-5a4328dab688"),
  "Raid #14": new EventValues("ebffe34c-0947-48a2-91ee-028ef2a6eca5", {
    "Tidus": "08c70b32-47b0-4ac3-86ec-b2e1abd5d3eb",
    "Seven LD": "822e2123-06fb-4dfb-a250-30d221168185",
  }),
  "Reno LC": new EventValues("b795ff45-4fbb-4ccc-aa57-f7ef6a8136b8"),
  "Boss Rush #5": new EventValues("2037045c-607d-470b-9110-3b4fb33fd437"),
  "Story 3.4.1": new EventValues("88f1bf26-d3b0-45a7-8330-279bda4ef4b4", {
    "Yuna": "cdfa1b51-9945-4c55-a2e8-fe97462a107f",
    "Desch LD": "df6ef86b-3a10-4001-ad2a-a4b868a89110",
  }),
  "Lv250": new EventValues("1fdd34ba-2a0f-493e-8c89-a1a9abbf294a", {
    "Agrias LD": "3bc48b60-0e03-421c-ae62-cfd0e80f8ced",
    "Shantotto LD BT": "d44d9d47-cc11-4e6e-9445-5bb62f622761",
  }),
  "Rude LC": new EventValues("914bf581-90cf-4002-8286-4fd450d09625"),
  "Raid #15": new EventValues("0f6042bb-0d93-45e0-bcff-4a27f4465c89", {
    "Exdeath": "0688ed77-63b8-4774-8629-1faf05ea2481",
    "Lion LD": "6456783c-b7eb-4355-8987-c62b46624d6b",
  }),
  "Xande LC": new EventValues("4dd54921-fe7e-49d7-99fc-9d764fb27c25"),
  "Story 3.4.2": new EventValues("3e77bf81-0e6b-4860-af94-51fe85cd6052"),
  "HA+ Start Dash": new EventValues("fce8bf40-9cf2-4dd5-bffa-0e7b9fc656b1"),
  "Divine Pandemonium": new EventValues("dd174f8f-d4aa-4c16-8e5d-a38be363f4b2", {
    "Zidane": "c7f61ea5-c512-4e8c-ae55-065bab028ef0",
    "Y'shtola": "216645a6-72b8-43bf-89f5-2ff737dc662b",
  }, true),
  "Guy LC": new EventValues("ea365a8f-196e-422a-8489-f0549ed58521", {}, true),
  "Anniversary #1": new EventValues("a0ac6483-4d59-4daf-b762-db303280b86d", {}, true),
  "Raines Event": new EventValues("9295aff9-5e65-4b85-ae3c-3d261c9315fd", {}, true),
  "Aranea LC": new EventValues("b82008df-108c-4dba-8781-9ec0e1dd76d6", {}, true),
  "Anniversary #2": new EventValues("1cf9368a-df89-452c-9d6d-4864f13ac593", {}, true),

}

const twoRowEventNames = new Set(["Transcendence", "Divine"])
const convertToEventAllocations = (wholeChunk: string[][], index: number, rowLookup: RowLookup,
  eventsByEventId: Record<Uuid, Event>, bannersByBannerId: Record<Uuid, Banner>): EventAllocations => {
  const output = {}
  const eventColumn = rowLookup.eventRow
  const bannerColumn = rowLookup.bannerRow
  const bannerAllocations: BannerAllocations = {}
  var miscellaneousAllocations = createEmptyResourceList()
  const currentRow = wholeChunk[index]

  const candidateIndex = index + 1
  const nextRow = candidateIndex >= wholeChunk.length ? null : wholeChunk[candidateIndex]
  if (eventColumn === null) {
    console.log("Error: no event name column found")
    return output
  } else if (bannerColumn === null) {
    console.log("Error: no banner column found")
    return output
  } else {
    var eventNameCandidate = currentRow[eventColumn].trim()
    if (twoRowEventNames.has(eventNameCandidate)) {
      if (!nextRow) {
        console.log(`Error: found a name which should have 2 halves but there was no second half. First half=${eventNameCandidate}`)
        return output
      }
      eventNameCandidate = eventNameCandidate + " " + nextRow[eventColumn].trim()
    }

    const ttBannerName = currentRow[bannerColumn].trim()
    const lookupValue = eventNameLookup[eventNameCandidate]
    var eventValues: EventValues
    if (!lookupValue) {
      return output
    } else if (lookupValue instanceof EventValues) {
      eventValues = lookupValue
    } else {
      eventValues = lookupValue[ttBannerName]
    }

    if (!eventValues) {
      return output
    }

    const event = eventsByEventId[eventValues.eventId]
    if (!event) {
      console.log(`Error: no event found for eventName=${eventNameCandidate} and id=${eventValues.eventId}`)
      return output
    }

    const banners = event.banners.map(banner => banner.id)
      .map(bannerId => bannersByBannerId[bannerId])
    const rowsAndBanners: RowAndBannerPair[] = Object.entries(eventValues.bannerLookup).length === 0 ? [new RowAndBannerPair(currentRow, banners[0])] :
      Object.entries(eventValues.bannerLookup).map(([ttEventValueBannerName, bannerId]) => {
        const banner = bannersByBannerId[bannerId]
        if (currentRow[bannerColumn] === ttEventValueBannerName) {
          return new RowAndBannerPair(currentRow, banner)
        } else if (!!nextRow && nextRow[bannerColumn] === ttEventValueBannerName) {
          return new RowAndBannerPair(nextRow, banner)
        } else {
          console.log(`Error: Found no row on .csv near event named ${eventNameCandidate} which corresponded to the expected banner beginning with ${ttEventValueBannerName}`)
          return null
        }
      }).filter(pair => pair !== null) as RowAndBannerPair[]

    rowsAndBanners.forEach(({ row, banner }) => {
      Object.entries(rowLookup.plannedExpenditureLookup).forEach(([key, value]) => {
        const resource = eventValues.btEra ? lookupPostBtRealization(key as Resource) : key
        const property = resourcesMap[resource]
        const ttValue = convertTtValueToFfootipValue(parseFloat(row[value as number]), key as Resource) || 0

        if (property instanceof BannerResourceProperty) {
          var bannerAllocation = bannerAllocations[banner.id]
          if (!bannerAllocation) {
            bannerAllocation = createDefaultBannerAllocation()
          }
          const existingValue = property.getResourceFromBannerAllocation(bannerAllocation)
          bannerAllocations[banner.id] = property.setResourceFromBannerAllocation(bannerAllocation, ttValue + existingValue)
        } else {
          const existingValue = property.resourcesListAccessor(miscellaneousAllocations)
          miscellaneousAllocations = property.resourcesListReducer(miscellaneousAllocations, ttValue + existingValue)
        }
      })
    })
    return {
      [event.id]: new EventAllocation(bannerAllocations, undefined, miscellaneousAllocations, false)
    }
  }
}
