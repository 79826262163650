import {UniversalData as InputUniversalData, 
  TimeRange as InputTimeRange, 
  EventAppearance as InputEventAppearance, 
  EventResources as InputEventResources, 
  SummonEventAppearance as InputSummonEventAppearance, 
  CharacterDebutOnEvent as InputCharacterDebutOnEvent} from 'src/data/incoming-data-model'
import {
  UniversalData as OutputUniversalData,
  Character,
  CrystalColor,
  Weapon,
  Banner,
  TimeRange as OutputTimeRange,
  Event,
  Element,
  EventType,
  Summon,
  SummonType,
  Series,
  ArmorType,
  Armor,
  WeaponVariant,
  EventAppearance,
  EventSize,
  Milestone,
  SummonEventAppearance,
  Uuid,
  generateEmptyEventResources,
  RegionAvailability,
  CharacterDebutOnEvent} from './universal-data-model'
import { WeaponType } from "./weapon-type"
import { CharacterDebut } from "./character-debut"
import { DateTime, Duration } from 'luxon'

export const entriesToObject = <K extends string | number | symbol, V>(entry: [K, V]) => {
  return {
    [entry[0]]: entry[1]
  }
}

export const objectAssigningReducer = <T extends {}, U>(accumulator: T, currentValue: U) => Object.assign(accumulator, currentValue)

const convertTimeRange = (input: InputTimeRange | null) => {
  if (!!input) {
    return new OutputTimeRange(
      DateTime.fromISO(input.start),
      Duration.fromISO(input.duration),
      input.estimate,
      input.missingEvent,
    )
  } else {
    return undefined
  }
}

const convertSummonEventAppearance: (input: InputSummonEventAppearance) => SummonEventAppearance = (input: InputSummonEventAppearance) => {
  return {
    eventId: input.eventId,
    timeRange: convertTimeRange(input.timeRange) as OutputTimeRange,
    ultimateBoard: input.ultimateBoard,
    divineBoard: input.divineBoard,
    spiritus: input.spiritus,
  }
}

const convertEventAppearance: (input: InputEventAppearance) => EventAppearance = (input: InputEventAppearance) => {
  return {
    eventId: input.eventId,
    timeRange: convertTimeRange(input.timeRange) as OutputTimeRange,
    weaponsContained: input.weaponsContained.map(weaponType => WeaponType[weaponType as keyof typeof WeaponType]),
    debuts: input.debuts.map(debut => CharacterDebut[debut as keyof typeof CharacterDebut])
  }
}

const convertEventResources = (input: InputEventResources) => {
  return Object.entries(input).map(([resourceName, resourceValue]) => {
    const returnValue: [string, number] = [resourceName, resourceValue || 0]
    return returnValue
  }).reduce((accumulator, [resourceName, resourceValue]) => {
    return {
      ...accumulator,
      [resourceName]: resourceValue
    }
  }, generateEmptyEventResources())
}

const convertCharacterDebutOnEvent: (input: InputCharacterDebutOnEvent) => CharacterDebutOnEvent = (input: InputCharacterDebutOnEvent) => {
  return {
    characterId: input.characterId,
    regionAvailability: RegionAvailability[input.regionAvailability as keyof typeof RegionAvailability],
    debut: CharacterDebut[input.debut as keyof typeof CharacterDebut]
  }
}

export function convertInputToModel(input: InputUniversalData): OutputUniversalData {
  const characters = input.characters.map(x => {
    return {
      [x.id]: {
        id: x.id,
        name: x.name,
        color: CrystalColor[x.color as keyof typeof CrystalColor],
        internalNumber: x.internalNumber,
        elementsUsed: x.elementsUsed.map(element => Element[element as keyof typeof Element]),
        recommendedArtifacts: x.recommendedArtifacts,
        summonOverrides: x.summonOverrides,
        weaponVariant: WeaponVariant[x.weaponVariant as keyof typeof WeaponVariant],
        series: Series[x.series as keyof typeof Series],
        orderInSeries: x.orderInSeries,
        eventAppearancesGl: x.eventAppearancesGl.map(convertEventAppearance),
        eventAppearancesJp: x.eventAppearancesJp.map(convertEventAppearance),
        availableWeapons: x.availableWeapons.map(weaponType => WeaponType[weaponType as keyof typeof WeaponType]),
        availableArmors: x.availableArmors.map(armorType => ArmorType[armorType as keyof typeof ArmorType]),
      } as Character
    }
  }).reduce(objectAssigningReducer)
  const weapons = input.weapons.map(x => {
    return {
      [x.id]: {
        id: x.id,
        name: x.name,
        type: WeaponType[x.type as keyof typeof WeaponType],
        character: characters[x.character],
      } as Weapon
    }
  }).reduce(objectAssigningReducer)
  const banners = input.banners.map(x => {
    return {
      [x.id]: {
        id: x.id,
        weapons: x.weapons.map(weaponId => weapons[weaponId]),
        availability: RegionAvailability[x.availability as keyof typeof RegionAvailability],
      } as Banner
    }
  }).reduce(objectAssigningReducer)
  const events = input.events.map(x => {
    return {
      [x.id]: {
        id: x.id,
        glTime: convertTimeRange(x.glTime),
        jpTime: convertTimeRange(x.jpTime),
        banners: x.banners.map(bannerId => banners[bannerId]),
        name: x.name,
        type: EventType[x.type as keyof typeof EventType],
        regionAvailability: RegionAvailability[x.regionAvailability as keyof typeof RegionAvailability],
        eventResources: convertEventResources(x.eventResources),
        milestones: x.milestones.map(milestone => Milestone[milestone as keyof typeof Milestone]),
        eventSize: EventSize[x.eventSize as keyof typeof EventSize],
        characterDebutsOnEvent: x.characterDebutsOnEvent.map(characterDebutOnEvent => convertCharacterDebutOnEvent(characterDebutOnEvent))
      } as Event
    }
  }).reduce(objectAssigningReducer)
  const summons: Record<Uuid, Summon> = input.summons.map(x => {
    return {
      [x.id]: {
        id: x.id,
        name: SummonType[x.name as keyof typeof SummonType],
        internalNumber: x.internalNumber,
        elementType: Element[x.elementType as keyof typeof Element],
        passives: x.passives,
        defaults: x.defaults,
        elementOverride: x.elementOverride,
        displayName: x.displayName,
        summonEventAppearancesGl: x.summonEventAppearancesGl.map(convertSummonEventAppearance),
        summonEventAppearancesJp: x.summonEventAppearancesJp.map(convertSummonEventAppearance),
      }
    }
  }).reduce(objectAssigningReducer)
  const armor = input.armor.map(x => {
    return {
      [x.id]: {
        id: x.id,
        name: x.name,
        type: ArmorType[x.type as keyof typeof ArmorType],
        character: characters[x.character],
      } as Armor
    }
  }).reduce(objectAssigningReducer)

  const eventIdsByCharacterId = Object.values(characters).map(character => {
    const appearanceSet = new Set([...character.eventAppearancesGl, ...character.eventAppearancesJp])
    const eventIds = Array.from(appearanceSet).map(appearance => {
      return appearance.eventId
    })
    return {
      [character.id]: Array.from(eventIds)
    }
  }).reduce(objectAssigningReducer)

  const characterIdsByName = Object.values(characters).map(character => {
    return {
      [character.name]: character.id
    }
  }).reduce(objectAssigningReducer)

  const summonIdsByDisplayName = Object.values(summons).map(summon => {
    return {
      [summon.displayName]: summon.id
    }
  }).reduce(objectAssigningReducer)

  const eventIdsByMilestone = Object.values(events)
    .filter(event => event.milestones.length > 0)
    .map(event => {
      return {
        [event.milestones[0]]: event.id
      }
  }).reduce(objectAssigningReducer, {})

  return {
    events: events,
    banners: banners,
    weapons: weapons,
    characters: characters,
    eventIdsByCharacterId: eventIdsByCharacterId,
    summons: summons,
    armor: armor,
    characterIdsByName: characterIdsByName,
    summonIdsByDisplayName: summonIdsByDisplayName,
    eventIdsByMilestone: eventIdsByMilestone
  }
}
