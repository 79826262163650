import {ActionCreator} from 'redux'
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'src/store/root-reducer'
import { universalDataFoundActionCreator, UniversalDataFoundAction} from './forecast-actions';
import data from 'src/data/universal-data.json'
import { UniversalData } from 'src/data/incoming-data-model';
import { convertInputToModel } from './model/universal-data-converter';
import { getFirebase } from 'react-redux-firebase'

export const shouldGetUniversalData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, UniversalDataFoundAction>> = () => {
  return async (dispatch, getState, getFirebase) => {
    const typedData = data as UniversalData
    const dataModel = convertInputToModel(typedData)

    dispatch(universalDataFoundActionCreator(dataModel))
  }
}
