import { objectAssigningReducer } from "src/forecast/model/universal-data-converter"
import { Uuid } from "src/forecast/model/universal-data-model"

export class TierUtilization {
  constructor(
    character1: Uuid | null,
    character2: Uuid | null,
    character3: Uuid | null,
    summon: Uuid | null,
    call1: Uuid | null,
    call2: Uuid | null,
    call3: Uuid | null,
  ) {
    this.character1 = character1
    this.character2 = character2
    this.character3 = character3
    this.summon = summon
    this.call1 = call1
    this.call2 = call2
    this.call3 = call3
  }
  character1: Uuid | null
  character2: Uuid | null
  character3: Uuid | null
  summon: Uuid | null
  call1: Uuid | null
  call2: Uuid | null
  call3: Uuid | null
  [key: string]: Uuid | null
}

export const createDefaultTierUtilization = () => {
  return new TierUtilization(null, null, null, null, null, null, null)
}

function getPropertyNameByIdMap(tierUtilization: TierUtilization) {
  return Object.entries(tierUtilization)
    .filter(([propertyName, id]) => !!id)
    .map(([propertyName, id]) => {
      const safeId = id as Uuid
      return {
        [safeId]: propertyName
      }
    }).reduce(objectAssigningReducer, {})
}

export function getCharacterIds(tierUtilization: TierUtilization) {
  return [tierUtilization.character1 || "", tierUtilization.character2 || "", tierUtilization.character3 || ""].filter((id) => !!id)
}

function getCallIds(tierUtilization: TierUtilization) {
  return [tierUtilization.call1 || "", tierUtilization.call2 || "", tierUtilization.call3 || ""].filter((id) => !!id)
}

export function getAllUsedIds(tierUtilization: TierUtilization) {
  return [...getCharacterIds(tierUtilization), ...getCallIds(tierUtilization)]
}

function getCallPropForCharacterProp(propName: string) {
  if (propName === "character1") {
    return "call1"
  }
  if (propName === "character2") {
    return "call2"
  }
  if (propName === "character3") {
    return "call3"
  }
  return ""
}

function propIsCharacterProp(prop: string) {
  return prop === "character1" || prop === "character2" || prop === "character3"
}

export function executeSwap(tierUtilization: TierUtilization, tierUtilizationPropertyName: string, id: Uuid | null) {
  const propMap = getPropertyNameByIdMap(tierUtilization)
  // swap?
  if (!!id && !!propMap[id]) {
    const propToSwap = propMap[id]
    const idToSwap = tierUtilization[tierUtilizationPropertyName] || null
    // need to swap calls?
    if (propIsCharacterProp(tierUtilizationPropertyName) && propIsCharacterProp(propToSwap)) {
      const callPropSelected = getCallPropForCharacterProp(tierUtilizationPropertyName)
      const callPropToSwap = getCallPropForCharacterProp(propToSwap)
      const callSelected = tierUtilization[callPropSelected] || null
      const callToSwap = tierUtilization[callPropToSwap] || null
      return Object.assign({}, tierUtilization, { [propToSwap]: idToSwap, [tierUtilizationPropertyName]: id, [callPropSelected]: callToSwap, [callPropToSwap]: callSelected}) as TierUtilization
    } else {
      return Object.assign({}, tierUtilization, { [propToSwap]: idToSwap, [tierUtilizationPropertyName]: id }) as TierUtilization
    }
  } else {
    return Object.assign({}, tierUtilization, { [tierUtilizationPropertyName]: id}) as TierUtilization
  }
}
