import {UniversalData, Event, Uuid} from 'src/forecast/model/universal-data-model'
import { FilterSpecification } from 'src/tracker/model/selector-model'
import { DateTime } from 'luxon'
import { SavedView } from 'src/preferences/model/saved-view'

export const UNIVERSAL_DATA_FOUND = "UNIVERSAL_DATA_FOUND"
export const SEARCH_UNIVERSAL_DATA_RESULTS = "SEARCH_UNIVERSAL_DATA_RESULTS"
export const SEARCH_STRING = "SEARCH_STRING"
export const SET_FILTER_SPECIFICATIONS = "SET_FILTER_SPECIFICATIONS"
export const UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME"
export const SET_SAVED_VIEW = "SET_SAVED_VIEW"

export function universalDataFoundActionCreator(data: UniversalData) {
  return {
    type: UNIVERSAL_DATA_FOUND,
    data: data,
  }
}

export function searchUniversalDataResultsActionCreator(events?: Event[], characterIds?: Uuid[], searchString?: string, filterSpecifications?: FilterSpecification<any>[]) {
  return {
    type: SEARCH_UNIVERSAL_DATA_RESULTS,
    searchResults: {
      events: events || [],
      characterIds: characterIds || [],
      searchString: searchString || "",
      filterSpecifications: filterSpecifications || [],
    }
  }
}

export function searchStringActionCreator(string: string) {
  return {
    type: SEARCH_STRING,
    searchString: string
  }
}

export function setFilterSpecificationsActionCreator(filterSpecifications: FilterSpecification<any>[]) {
  return {
    type: SET_FILTER_SPECIFICATIONS,
    filterSpecifications
  }
}

export function updateCurrentTimeActionCreator(currentTime: DateTime) {
  return {
    type: UPDATE_CURRENT_TIME,
    currentTime
  }
}

export function setSavedViewActionCreator(savedView: SavedView) {
  return {
    type: SET_SAVED_VIEW,
    savedView
  }
}

export type UniversalDataFoundAction = ReturnType<typeof universalDataFoundActionCreator>
export type SearchUniversalDataResultsAction = ReturnType<typeof searchUniversalDataResultsActionCreator>
export type SearchStringAction = ReturnType<typeof searchStringActionCreator>
export type SetFilterSpecificationsAction = ReturnType<typeof setFilterSpecificationsActionCreator>
export type UpdateCurrentTimeAction = ReturnType<typeof updateCurrentTimeActionCreator>
export type SetSavedViewAction = ReturnType<typeof setSavedViewActionCreator>
