import React, { FunctionComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import {getTonberryUrl} from 'src/character-details/external-links/tonberry-url-generator'
import {getDissidiaDbUrl} from 'src/character-details/external-links/dissidia-db-url-generator'
import { getCompendiumUrl } from './compendium-url-generator';
import ExternalLink from './external-link/external-link';
import { useTheme } from '@material-ui/core';

interface ExternalLinksProps {
  characterName: string
}

const ExternalLinks: FunctionComponent<ExternalLinksProps> = ({
  characterName
}) => {
  const theme = useTheme()

  return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: theme.spacing(2) }}>
    <Typography>External links:</Typography>
    <ExternalLink characterName={characterName} imageName="tonberrytroupe.png" description="Tonberry Troupe infographic" urlRetriever={getTonberryUrl}/>
    <ExternalLink characterName={characterName} imageName="dissidiadb.png" description="Dissidia DB page" urlRetriever={getDissidiaDbUrl}/>
    <ExternalLink characterName={characterName} imageName="compendium.png" description="Compendium page" urlRetriever={getCompendiumUrl}/>
  </div>
  )
}

export default ExternalLinks
