import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getDefaultEditMode, getDefaultRegion} from 'src/preferences/preferences-selectors'
import {writeDefaultEditMode, writeDefaultRegion} from 'src/preferences/preferences-effects'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import generateToggle from '../generate-toggle'
import { Region } from 'src/tracker/model/components/region'

function GeneralTogglesComponent() {
  const defaultEditMode = useSelector(getDefaultEditMode)
  const defaultRegion = useSelector(getDefaultRegion)

  const dispatch = useDispatch()

  const defaultEditModeCallback = useCallback((editMode: boolean) => {
    dispatch(writeDefaultEditMode(editMode))
  }, [dispatch])
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    defaultEditModeCallback(event.target.checked)
  }

  const defaultRegionCallback = useCallback((newDefaultRegion: Region) => {
    dispatch(writeDefaultRegion(newDefaultRegion))
  }, [dispatch])
  const handleDefaultRegionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRegion = event.target.checked ? Region.JP : Region.GL
    defaultRegionCallback(newRegion)
  }

  return (
    <>
      {generateToggle("Default edit mode: on means on start up, the grid and planner are editable, false means it cannot be edited without toggling the edit mode switch in the app.",
        defaultEditMode, handleToggleChange)}
      {generateToggle("Select default region on app start up. Toggled off represents GL, and on represents JP. The region is also always toggleable from the toggle in the navigation drawer.",
        defaultRegion === Region.JP, handleDefaultRegionChanged)}
    </>
  )
}

export const GeneralTogglesWidget = new PreferencesWidget(
  <GeneralTogglesComponent />,
  "General toggles",
)
