import { objectAssigningReducer } from "src/forecast/model/universal-data-converter"
import { allColumnGroup } from "./all-column-group"
import { ColumnGroup } from "./column-group"
import { resourcesNeededColumnGroup } from "./needed/needed-column-group"
import { PlannerColumn } from "./planner-column"
import { allResourcesColumnGroup } from "./resource/resources-column-group"

const builtInDisplayableGroups = [allColumnGroup, allResourcesColumnGroup, resourcesNeededColumnGroup]

export function augmentDisplayableColumnGroups(...customGroups: ColumnGroup<PlannerColumn>[]) {
  return [...builtInDisplayableGroups, ...customGroups]
}

export const plannerColumnLookupMap = allColumnGroup.columns.map(column => {
  return { [column.id]: column }
}).reduce(objectAssigningReducer)
