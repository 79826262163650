import { armorProperty } from 'src/tracker/model/property/equipment/armor/armor-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const highArmorNuggetsProperty = createStandardCharacterResourceProperty(
  '9e6bc2be-6882-4e5b-932b-500ccb48f06a',
  'High Armor Nuggets',
  'highArmorNuggets',
  armorProperty,
  Resource.HIGH_ARMOR_NUGGETS,
)
