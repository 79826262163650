import {objectAssigningReducer} from 'src/forecast/model/universal-data-converter'
import {StandardLimitBreak, standardLimitBreakShort, ExWeaponLimitBreak, exWeaponLimitBreakShort,
  ArmorLimitBreak, armorLimitBreakShort, BloomLimitBreak, bloomLimitBreakShort,
  SummonBoardStatus, summonBoardStatusShort, CharacterBoardStatus, characterBoardStatusShort,
  BtWeaponLimitBreak, btWeaponLimitBreakShort, Resource, ldBoardStatusShort, LdBoardStatus} from './tracker-model'

function valueToResourceFunctionGenerator<T extends string | number | symbol>(resourceToResourceValueMaps?: Partial<Record<Resource, Record<T, number>>>) {
  return (value: T, resource: Resource) => {
    const resolvedMapOfMaps = resourceToResourceValueMaps || ({} as Record<Resource, Record<T, number>>)
    const valueToResourceLookup = resolvedMapOfMaps[resource] || ({} as Record<T, number>)
    return valueToResourceLookup[value] || 0
  }
}

export type ValueComparator<T, C> = (value1: T, value2: T, comparisonFunction: (a: C, b: C) => boolean) => boolean

export class CharacterValueConverter<T> {
  constructor(
    valueToString: (value: T) => string,
    valueFromString: (value: string) => T,
    valueComparitor?: ValueComparator<T, any>,
    valueToResource?: (value: T, resource: Resource) => number,
  ) {
    this.valueToString = valueToString
    this.valueFromString = valueFromString
    this.valueComparitor = (!!valueComparitor) ? valueComparitor :
      (value1, value2, comparisonFunction) => comparisonFunction(value1, value2)
    this.valueToResource = (!!valueToResource) ? valueToResource : () => 0
  }
  valueToString: (value: T) => string
  valueFromString: (value: string) => T
  valueComparitor: ValueComparator<T, any>
  valueToResource: (value: T, resource: Resource) => number
}

class CharacterDataNumberConverter extends CharacterValueConverter<number> {
  constructor () {
    super(
      (value) => value.toString(),
      (value) => parseInt(value, 10),
    )
  }
}

export const defaultCharacterDataNumberConverter = new CharacterDataNumberConverter()

class CharacterDataEnumValueConverter<T extends number> extends CharacterValueConverter<T> {
  constructor(
    shortToValueMap: Record<string, T>,
    resourceToResourceValueMaps?: Partial<Record<Resource, Record<T, number>>>
  ) {
    const valueToShortMap = Object.entries(shortToValueMap).map(([shortVal, enumVal]) => {
      return {
        [enumVal]: shortVal
      }
    }).reduce(objectAssigningReducer)
    super(
     (value) => valueToShortMap[value],
     (value) => shortToValueMap[value],
     undefined,
     valueToResourceFunctionGenerator(resourceToResourceValueMaps),
    )

  }
}

export const standardLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [standardLimitBreakShort[0]]: StandardLimitBreak.UNOBTAINED,
    [standardLimitBreakShort[1]]: StandardLimitBreak.ZERO,
    [standardLimitBreakShort[2]]: StandardLimitBreak.ONE,
    [standardLimitBreakShort[3]]: StandardLimitBreak.TWO,
    [standardLimitBreakShort[4]]: StandardLimitBreak.THREE,
  },
  {
    [Resource.POWER_STONE]: {
      [StandardLimitBreak.UNOBTAINED]: 0,
      [StandardLimitBreak.ZERO]: 0,
      [StandardLimitBreak.ONE]: 4,
      [StandardLimitBreak.TWO]: 8,
      [StandardLimitBreak.THREE]: 12,
    } as Record<StandardLimitBreak, number>,
    [Resource.CP]: {
      [StandardLimitBreak.UNOBTAINED]: 0,
      [StandardLimitBreak.ZERO]: 15,
      [StandardLimitBreak.ONE]: 20,
      [StandardLimitBreak.TWO]: 25,
      [StandardLimitBreak.THREE]: 30,
    }
  }
)

export const exWeaponLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [exWeaponLimitBreakShort[0]]: ExWeaponLimitBreak.UNOBTAINED,
    [exWeaponLimitBreakShort[1]]: ExWeaponLimitBreak.ZERO,
    [exWeaponLimitBreakShort[2]]: ExWeaponLimitBreak.ONE,
    [exWeaponLimitBreakShort[3]]: ExWeaponLimitBreak.TWO,
    [exWeaponLimitBreakShort[4]]: ExWeaponLimitBreak.THREE,
    [exWeaponLimitBreakShort[5]]: ExWeaponLimitBreak.PLUS_ZERO,
    [exWeaponLimitBreakShort[6]]: ExWeaponLimitBreak.PLUS_ONE,
    [exWeaponLimitBreakShort[7]]: ExWeaponLimitBreak.PLUS_TWO,
    [exWeaponLimitBreakShort[8]]: ExWeaponLimitBreak.PLUS_THREE,
  } as Record<string, ExWeaponLimitBreak>,
  {
    [Resource.POWER_STONE]: {
      [ExWeaponLimitBreak.UNOBTAINED]: 0,
      [ExWeaponLimitBreak.ZERO]: 0,
      [ExWeaponLimitBreak.ONE]: 4,
      [ExWeaponLimitBreak.TWO]: 8,
      [ExWeaponLimitBreak.THREE]: 12,
      [ExWeaponLimitBreak.PLUS_ZERO]: 12,
      [ExWeaponLimitBreak.PLUS_ONE]: 12,
      [ExWeaponLimitBreak.PLUS_TWO]: 12,
      [ExWeaponLimitBreak.PLUS_THREE]: 12,
    } as Record<ExWeaponLimitBreak, number>,
    [Resource.CP]: {
      [ExWeaponLimitBreak.UNOBTAINED]: 0,
      [ExWeaponLimitBreak.ZERO]: 70,
      [ExWeaponLimitBreak.ONE]: 72,
      [ExWeaponLimitBreak.TWO]: 75,
      [ExWeaponLimitBreak.THREE]: 80,
      [ExWeaponLimitBreak.PLUS_ZERO]: 100,
      [ExWeaponLimitBreak.PLUS_ONE]: 102,
      [ExWeaponLimitBreak.PLUS_TWO]: 105,
      [ExWeaponLimitBreak.PLUS_THREE]: 110,
    } as Record<ExWeaponLimitBreak, number>,
    [Resource.WEAPON_PAGES]: {
      [ExWeaponLimitBreak.UNOBTAINED]: 0,
      [ExWeaponLimitBreak.ZERO]: 0,
      [ExWeaponLimitBreak.ONE]: 0,
      [ExWeaponLimitBreak.TWO]: 0,
      [ExWeaponLimitBreak.THREE]: 0,
      [ExWeaponLimitBreak.PLUS_ZERO]: 20,
      [ExWeaponLimitBreak.PLUS_ONE]: 20,
      [ExWeaponLimitBreak.PLUS_TWO]: 20,
      [ExWeaponLimitBreak.PLUS_THREE]: 20,
    } as Record<ExWeaponLimitBreak, number>,
    [Resource.WEAPON_NUGGETS]: {
      [ExWeaponLimitBreak.UNOBTAINED]: 0,
      [ExWeaponLimitBreak.ZERO]: 0,
      [ExWeaponLimitBreak.ONE]: 0,
      [ExWeaponLimitBreak.TWO]: 0,
      [ExWeaponLimitBreak.THREE]: 0,
      [ExWeaponLimitBreak.PLUS_ZERO]: -20,
      [ExWeaponLimitBreak.PLUS_ONE]: 0,
      [ExWeaponLimitBreak.PLUS_TWO]: 20,
      [ExWeaponLimitBreak.PLUS_THREE]: 40,
    } as Record<ExWeaponLimitBreak, number>,
  }
)


export const armorLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [armorLimitBreakShort[0]]: ArmorLimitBreak.UNOBTAINED,
    [armorLimitBreakShort[1]]: ArmorLimitBreak.THIRTY_FIVE_ZERO,
    [armorLimitBreakShort[2]]: ArmorLimitBreak.THIRTY_FIVE_ONE,
    [armorLimitBreakShort[3]]: ArmorLimitBreak.THIRTY_FIVE_TWO,
    [armorLimitBreakShort[4]]: ArmorLimitBreak.THIRTY_FIVE_THREE,
    [armorLimitBreakShort[5]]: ArmorLimitBreak.NINETY_ZERO,
    [armorLimitBreakShort[6]]: ArmorLimitBreak.NINETY_ONE,
    [armorLimitBreakShort[7]]: ArmorLimitBreak.NINETY_TWO,
    [armorLimitBreakShort[8]]: ArmorLimitBreak.NINETY_THREE,
    [armorLimitBreakShort[9]]: ArmorLimitBreak.PLUS_ZERO,
    [armorLimitBreakShort[10]]: ArmorLimitBreak.PLUS_ONE,
    [armorLimitBreakShort[11]]: ArmorLimitBreak.PLUS_TWO,
    [armorLimitBreakShort[12]]: ArmorLimitBreak.PLUS_THREE,
    [armorLimitBreakShort[13]]: ArmorLimitBreak.HIGH,
    [armorLimitBreakShort[14]]: ArmorLimitBreak.HIGH_PLUS_ZERO,
    [armorLimitBreakShort[15]]: ArmorLimitBreak.HIGH_PLUS_ONE,
    [armorLimitBreakShort[16]]: ArmorLimitBreak.HIGH_PLUS_TWO,
    [armorLimitBreakShort[17]]: ArmorLimitBreak.HIGH_PLUS_THREE,
  } as Record<string, ArmorLimitBreak>,
  {
    [Resource.CP]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 35,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 46,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 58,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 70,
      [ArmorLimitBreak.NINETY_ZERO]: 90,
      [ArmorLimitBreak.NINETY_ONE]: 100,
      [ArmorLimitBreak.NINETY_TWO]: 110,
      [ArmorLimitBreak.NINETY_THREE]: 120,
      [ArmorLimitBreak.PLUS_ZERO]: 130,
      [ArmorLimitBreak.PLUS_ONE]: 150,
      [ArmorLimitBreak.PLUS_TWO]: 170,
      [ArmorLimitBreak.PLUS_THREE]: 190,
      [ArmorLimitBreak.HIGH]: 210,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 220,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 223,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 226,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 230,
    },
    [Resource.ARMOR_TOKENS]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 5,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 10,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 15,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 20,
      [ArmorLimitBreak.NINETY_ZERO]: 40,
      [ArmorLimitBreak.NINETY_ONE]: 60,
      [ArmorLimitBreak.NINETY_TWO]: 80,
      [ArmorLimitBreak.NINETY_THREE]: 100,
      [ArmorLimitBreak.PLUS_ZERO]: 100,
      [ArmorLimitBreak.PLUS_ONE]: 100,
      [ArmorLimitBreak.PLUS_TWO]: 100,
      [ArmorLimitBreak.PLUS_THREE]: 100,
      [ArmorLimitBreak.HIGH]: 100,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 100,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 100,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 100,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 100,
    },
    [Resource.ARMOR_PAGES]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 0,
      [ArmorLimitBreak.NINETY_ZERO]: 0,
      [ArmorLimitBreak.NINETY_ONE]: 0,
      [ArmorLimitBreak.NINETY_TWO]: 0,
      [ArmorLimitBreak.NINETY_THREE]: 20,
      [ArmorLimitBreak.PLUS_ZERO]: 20,
      [ArmorLimitBreak.PLUS_ONE]: 20,
      [ArmorLimitBreak.PLUS_TWO]: 20,
      [ArmorLimitBreak.PLUS_THREE]: 20,
      [ArmorLimitBreak.HIGH]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 20,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 20,
    },
    [Resource.ARMOR_NUGGETS]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 0,
      [ArmorLimitBreak.NINETY_ZERO]: 0,
      [ArmorLimitBreak.NINETY_ONE]: 0,
      [ArmorLimitBreak.NINETY_TWO]: 0,
      [ArmorLimitBreak.NINETY_THREE]: 0,
      [ArmorLimitBreak.PLUS_ZERO]: -20,
      [ArmorLimitBreak.PLUS_ONE]: 0,
      [ArmorLimitBreak.PLUS_TWO]: 20,
      [ArmorLimitBreak.PLUS_THREE]: 40,
      [ArmorLimitBreak.HIGH]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 20,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 20,
    },
    [Resource.HIGH_ARMOR_TOKENS]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 0,
      [ArmorLimitBreak.NINETY_ZERO]: 0,
      [ArmorLimitBreak.NINETY_ONE]: 0,
      [ArmorLimitBreak.NINETY_TWO]: 0,
      [ArmorLimitBreak.NINETY_THREE]: 0,
      [ArmorLimitBreak.PLUS_ZERO]: 0,
      [ArmorLimitBreak.PLUS_ONE]: 0,
      [ArmorLimitBreak.PLUS_TWO]: 0,
      [ArmorLimitBreak.PLUS_THREE]: 0,
      [ArmorLimitBreak.HIGH]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 20,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 20,
    },
    [Resource.HIGH_ARMOR_PAGES]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 0,
      [ArmorLimitBreak.NINETY_ZERO]: 0,
      [ArmorLimitBreak.NINETY_ONE]: 0,
      [ArmorLimitBreak.NINETY_TWO]: 0,
      [ArmorLimitBreak.NINETY_THREE]: 0,
      [ArmorLimitBreak.PLUS_ZERO]: 0,
      [ArmorLimitBreak.PLUS_ONE]: 0,
      [ArmorLimitBreak.PLUS_TWO]: 0,
      [ArmorLimitBreak.PLUS_THREE]: 0,
      [ArmorLimitBreak.HIGH]: 0,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 20,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 20,
    },
    [Resource.HIGH_ARMOR_NUGGETS]: {
      [ArmorLimitBreak.UNOBTAINED]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ZERO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_ONE]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_TWO]: 0,
      [ArmorLimitBreak.THIRTY_FIVE_THREE]: 0,
      [ArmorLimitBreak.NINETY_ZERO]: 0,
      [ArmorLimitBreak.NINETY_ONE]: 0,
      [ArmorLimitBreak.NINETY_TWO]: 0,
      [ArmorLimitBreak.NINETY_THREE]: 0,
      [ArmorLimitBreak.PLUS_ZERO]: 0,
      [ArmorLimitBreak.PLUS_ONE]: 0,
      [ArmorLimitBreak.PLUS_TWO]: 0,
      [ArmorLimitBreak.PLUS_THREE]: 0,
      [ArmorLimitBreak.HIGH]: 0,
      [ArmorLimitBreak.HIGH_PLUS_ZERO]: -20,
      [ArmorLimitBreak.HIGH_PLUS_ONE]: 0,
      [ArmorLimitBreak.HIGH_PLUS_TWO]: 20,
      [ArmorLimitBreak.HIGH_PLUS_THREE]: 40,
    },
  }
)

export const bloomLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [bloomLimitBreakShort[0]]: BloomLimitBreak.UNOBTAINED,
    [bloomLimitBreakShort[1]]: BloomLimitBreak.THREE,
  } as Record<string, BloomLimitBreak>,
  {
    [Resource.BLOOM_TOKENS]: {
      [BloomLimitBreak.UNOBTAINED]: 0,
      [BloomLimitBreak.THREE]: 1,
    },
    [Resource.BLOOM_FRAGMENTS]: {
      [BloomLimitBreak.UNOBTAINED]: 0,
      [BloomLimitBreak.THREE]: 60,
    },
  }
)

export const summonBoardStatusConverter = new CharacterDataEnumValueConverter(
  {
    [summonBoardStatusShort[0]]: SummonBoardStatus.NOT_STARTED,
    [summonBoardStatusShort[1]]: SummonBoardStatus.TREASURED,
    [summonBoardStatusShort[2]]: SummonBoardStatus.MASTERED,
  } as Record<string, SummonBoardStatus>,
  {
    [Resource.GEMS]: {
      [SummonBoardStatus.NOT_STARTED]: 0,
      [SummonBoardStatus.TREASURED]: -300,
      [SummonBoardStatus.MASTERED]: -300,
    },
    [Resource.TICKETS]: {
      [SummonBoardStatus.NOT_STARTED]: 0,
      [SummonBoardStatus.TREASURED]: -3,
      [SummonBoardStatus.MASTERED]: -3,
    },
    [Resource.ARMOR_TOKENS]: {
      [SummonBoardStatus.NOT_STARTED]: 0,
      [SummonBoardStatus.TREASURED]: -5,
      [SummonBoardStatus.MASTERED]: -5,
    },
  }
)

export const characterBoardStatusConverter = new CharacterDataEnumValueConverter(
  {
    [characterBoardStatusShort[0]]: CharacterBoardStatus.ZERO,
    [characterBoardStatusShort[1]]: CharacterBoardStatus.ONE,
    [characterBoardStatusShort[2]]: CharacterBoardStatus.TWO,
    [characterBoardStatusShort[3]]: CharacterBoardStatus.THREE,
  } as Record<string, CharacterBoardStatus>,
  {
    [Resource.CP]: {
      [CharacterBoardStatus.ZERO]: 0,
      [CharacterBoardStatus.ONE]: 50,
      [CharacterBoardStatus.TWO]: 100,
      [CharacterBoardStatus.THREE]: 150,
    },
    [Resource.ENHANCEMENT_POINTS]: {
      [CharacterBoardStatus.ZERO]: 0,
      [CharacterBoardStatus.ONE]: 1250,
      [CharacterBoardStatus.TWO]: 2500,
      [CharacterBoardStatus.THREE]: 5000,
    },
  }
)

export const ldBoardStatusConverter = new CharacterDataEnumValueConverter(
  {
    [ldBoardStatusShort[0]]: LdBoardStatus.ZERO,
    [ldBoardStatusShort[1]]: LdBoardStatus.ONE,
  } as Record<string, LdBoardStatus>,
  {
    [Resource.CP]: {
      [CharacterBoardStatus.ZERO]: 0,
      [CharacterBoardStatus.ONE]: 75,
    },
    [Resource.ENHANCEMENT_POINTS]: {
      [CharacterBoardStatus.ZERO]: 0,
      [CharacterBoardStatus.ONE]: 3000,
    },
  }
)

export const frBoardStatusConverter = new CharacterDataEnumValueConverter(
  {
    [ldBoardStatusShort[0]]: LdBoardStatus.ZERO,
    [ldBoardStatusShort[1]]: LdBoardStatus.ONE,
  } as Record<string, LdBoardStatus>,
  {
    [Resource.CP]: {
      [LdBoardStatus.ZERO]: 0,
      [LdBoardStatus.ONE]: 100,
    },
    [Resource.ENHANCEMENT_POINTS]: {
      [LdBoardStatus.ZERO]: 0,
      [LdBoardStatus.ONE]: 5000,
    },
  }
)

export const btWeaponLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [btWeaponLimitBreakShort[0]]: BtWeaponLimitBreak.UNOBTAINED,
    [btWeaponLimitBreakShort[1]]: BtWeaponLimitBreak.ZERO,
    [btWeaponLimitBreakShort[2]]: BtWeaponLimitBreak.PLUS_ZERO,
    [btWeaponLimitBreakShort[3]]: BtWeaponLimitBreak.PLUS_ONE,
    [btWeaponLimitBreakShort[4]]: BtWeaponLimitBreak.PLUS_TWO,
    [btWeaponLimitBreakShort[5]]: BtWeaponLimitBreak.PLUS_THREE,
  } as Record<string, BtWeaponLimitBreak>,
  {
    [Resource.CP]: {
      [BtWeaponLimitBreak.UNOBTAINED]: 0,
      [BtWeaponLimitBreak.ZERO]: 130,
      [BtWeaponLimitBreak.PLUS_ZERO]: 140,
      [BtWeaponLimitBreak.PLUS_ONE]: 143,
      [BtWeaponLimitBreak.PLUS_TWO]: 146,
      [BtWeaponLimitBreak.PLUS_THREE]: 150,
    },
    [Resource.BT_PAGES]: {
      [BtWeaponLimitBreak.UNOBTAINED]: 0,
      [BtWeaponLimitBreak.ZERO]: 0,
      [BtWeaponLimitBreak.PLUS_ZERO]: 20,
      [BtWeaponLimitBreak.PLUS_ONE]: 20,
      [BtWeaponLimitBreak.PLUS_TWO]: 20,
      [BtWeaponLimitBreak.PLUS_THREE]: 20,
    },
    [Resource.BT_NUGGETS]: {
      [BtWeaponLimitBreak.UNOBTAINED]: 0,
      [BtWeaponLimitBreak.ZERO]: 0,
      [BtWeaponLimitBreak.PLUS_ZERO]: -20,
      [BtWeaponLimitBreak.PLUS_ONE]: 0,
      [BtWeaponLimitBreak.PLUS_TWO]: 20,
      [BtWeaponLimitBreak.PLUS_THREE]: 40,
    },
  }
)

export const frLimitBreakConverter = new CharacterDataEnumValueConverter(
  {
    [standardLimitBreakShort[0]]: StandardLimitBreak.UNOBTAINED,
    [standardLimitBreakShort[1]]: StandardLimitBreak.ZERO,
    [standardLimitBreakShort[2]]: StandardLimitBreak.ONE,
    [standardLimitBreakShort[3]]: StandardLimitBreak.TWO,
    [standardLimitBreakShort[4]]: StandardLimitBreak.THREE,
  },
  {
    [Resource.POWER_STONE]: {
      [StandardLimitBreak.UNOBTAINED]: 0,
      [StandardLimitBreak.ZERO]: 0,
      [StandardLimitBreak.ONE]: 80,
      [StandardLimitBreak.TWO]: 160,
      [StandardLimitBreak.THREE]: 240,
    } as Record<StandardLimitBreak, number>,
    [Resource.CP]: {
      [StandardLimitBreak.UNOBTAINED]: 0,
      [StandardLimitBreak.ZERO]: 130,
      [StandardLimitBreak.ONE]: 132,
      [StandardLimitBreak.TWO]: 135,
      [StandardLimitBreak.THREE]: 140,
    },
    [Resource.FORCE_STONE_SHARDS]: {
      [StandardLimitBreak.UNOBTAINED]: 0,
      [StandardLimitBreak.ZERO]: 0,
      [StandardLimitBreak.ONE]: 0,
      [StandardLimitBreak.TWO]: 0,
      [StandardLimitBreak.THREE]: -40,
    }
  }
)


export const booleanConverter = new CharacterValueConverter<boolean> (
  (value: boolean) => {
    return value ? 'True' : 'False'
  },
  (value: string) => {
    return value === 'True'
  },
)
