import React, {useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { shouldGetAsset } from 'src/image-cache/image-effects'
import {padStart} from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton';
import { Summon, SummonType } from 'src/forecast/model/universal-data-model';
import {selectImage} from 'src/image-cache/image-selectors'

interface SummonImageProps {
  summon?: Summon
  dimension: string
}

const blankSummonIdentifier = "blank-summon"

export function imgScalingStyling(dimension: string): React.CSSProperties {
  return {
    maxWidth: "100%",
    maxHeight: "100%",
    height: "auto",
    width: dimension,
    minWidth: "0px",
    minHeight: "0px",
    overflowX: 'hidden',
    overflowY: 'auto',
  }
}

export default function SummonImage(props: SummonImageProps) {
  const summonImageIdentifier = props.summon ? SummonType[props.summon.name] : null

  const summonFace = useSelector(selectImage(summonImageIdentifier))
  const emptyFace = useSelector(selectImage(blankSummonIdentifier))

  const dispatch = useDispatch()
  const fetchImageCallback = useCallback((assetLocation: string, assetIdentifier: string) => {
    dispatch(shouldGetAsset(assetLocation, assetIdentifier))
  }, [dispatch])

  useEffect(() => {
    if (!!props.summon && !!summonImageIdentifier && !summonFace) {
      fetchImageCallback(`stl_beast_face_${padStart(props.summon.internalNumber.toString(), 3, '0')}.g1t.png`, summonImageIdentifier)
    }
    if (!props.summon && !emptyFace) {
      fetchImageCallback('stl_beast_face_blank.g1t.png', blankSummonIdentifier)
    }
  })

  const imgCommonStyling = (dimension: string): React.CSSProperties => {
    return Object.assign({}, imgScalingStyling(dimension), {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    })
  }

  const summonFaceJsx = !!summonImageIdentifier && !!summonFace ? <img src={summonFace} alt={summonImageIdentifier} style={Object.assign({}, imgCommonStyling(props.dimension))} /> : null
  const emptyFaceJsx = !summonFaceJsx && !!emptyFace ? <img src={emptyFace} alt="No Summon" style={Object.assign({}, imgCommonStyling(props.dimension))} /> : null
  const skeleton = !summonFaceJsx && !emptyFaceJsx ? <Skeleton variant='rect' style={Object.assign({}, imgCommonStyling(props.dimension))} /> : null

  return (<div style={Object.assign({}, { position: "relative", margin: "4px" }, imgScalingStyling(props.dimension))} >
    {summonFaceJsx}
    {emptyFaceJsx}
    {skeleton}
    <div style={{ display: 'block', paddingTop: '100%', float: 'left' }} />
  </div>)
}
