import React from 'react'
import { allColumnGroup } from 'src/planner/model/column/all-column-group'
import { plannerColumnLookupMap } from 'src/planner/model/column/group-of-groups'
import PlannerPropertyPicker from 'src/planner/planner-property-picker/planner-property-picker'
import { writePlannerCustomColumnsData } from 'src/preferences/preferences-effects'
import { getPlannerCustomColumnGroups } from 'src/preferences/preferences-selectors'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import PlannerCustomGroupSelector from '../../../planner/planner-custom-group-selector/planner-custom-group-selector'
import AbstractCustomColumnsComponent from '../abstract/abstract-custom-columns-component'

export const PlannerCustomColumnsWidget = new PreferencesWidget(
  <div>
    <AbstractCustomColumnsComponent
      getCustomColumnGroups={getPlannerCustomColumnGroups}
      writeCustomColumnGroups={writePlannerCustomColumnsData}
      dataToString={(input: string) => {
        return plannerColumnLookupMap[input]?.name
      }}
      selector={(onIdPicked) => {
        return (
          <PlannerPropertyPicker
            pickerValue={null}
            options={allColumnGroup.columns}
            onPickerValueChanged={onIdPicked}
          />
        )
      }}
    />
    <PlannerCustomGroupSelector />
  </div>
  ,
  "Custom column groups",
)
