import React from 'react'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import theme from 'src/theme';

interface PreferencesCardProps {
  widget: PreferencesWidget
}

export default function PreferencesCard(props: PreferencesCardProps) {
  const widget = props.widget

  return (
    <Card style={{ margin: theme.spacing(2) }} key={widget.title}>
      <CardHeader
        title={widget.title}
      />
      <CardContent>
        {widget.component}
      </CardContent>
    </Card>
  )
}
