import { Planner } from "src/planner/model/planner-model"
import { Tracker } from "../tracker/model/tracker-model"
import { SetPreviewedPlannerDataAction, SetPreviewedTrackerDataAction, SET_PREVIEWED_PLANNER_DATA, SET_PREVIEWED_TRACKER_DATA } from "./preferences-actions"

const initialState = {
  previewedTrackerData: null as Tracker | null,
  previewedPlannerData: null as Planner | null,
}

export type PreferencesState = typeof initialState

export function preferencesReducer(state = initialState, action: SetPreviewedTrackerDataAction | SetPreviewedPlannerDataAction) {
  switch (action.type) {
    case SET_PREVIEWED_TRACKER_DATA:
      const setPreviewedTrackerDataAction = action as SetPreviewedTrackerDataAction
      return Object.assign({}, state, {previewedTrackerData: setPreviewedTrackerDataAction.tracker})
    case SET_PREVIEWED_PLANNER_DATA:
      const setPreviewedPlannerDataAction = action as SetPreviewedPlannerDataAction
      return Object.assign({}, state, {previewedPlannerData: setPreviewedPlannerDataAction.planner})
    default:
      return state
  }
}
