import {Uuid} from 'src/forecast/model/universal-data-model'
import { TrackerColumn } from 'src/tracker/model/column/tracker-column'
import {firstColumn} from 'src/tracker/model/column/static/first-column'
import {crystalColorColumn} from 'src/tracker/model/column/static/crystal-color-column'
import {seriesColumn} from 'src/tracker/model/column/static/series-column'
import {uniq} from 'lodash/fp'

export class ColumnGroup {
  constructor(
    id: Uuid,
    name: string,
    columns: TrackerColumn[],
    dedupAndStaticColumns: boolean = true
  ) {
    this.id = id
    this.name = name
    this.columns = dedupAndStaticColumns ? augmentWithStandardStatic(...columns) : columns
  }

  id: Uuid
  name: string
  columns: TrackerColumn[]
}

const augmentWithStandardStatic = (...columns: TrackerColumn[]) => {
  const augmentColumns = columns.slice()
  augmentColumns.unshift(...standardStaticColumns)
  return uniq(augmentColumns)
}

export const standardStaticColumns = [firstColumn, seriesColumn, crystalColorColumn]
