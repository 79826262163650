import { CharacterProperty } from "./character-property"
import { createFromIdDebut } from "./date-property-debut-scheme"
import { createFromIdDefinition } from "./date-property-misc-scheme"
import { createFromIdWeaponType } from "./date-property-weapon-appearance-scheme"
import { legacyIdDefinitionLookup } from "./event-date/legacy-id-definition-lookup"
import { propertyMap } from "./group-of-groups"
import { createFromId } from "./planner-date-property"

export const getPropertyFromId = (id: string) => {
  var candidate: CharacterProperty<any> | undefined = createFromId(id)
  if (candidate !== undefined) {
    return candidate
  }
  candidate = createFromIdDebut(id)
  if (candidate !== undefined) {
    return candidate
  }
  candidate = createFromIdWeaponType(id)
  if (candidate !== undefined) {
    return candidate
  }
  candidate = createFromIdDefinition(id)
  if (candidate !== undefined) {
    return candidate
  }
  candidate = legacyIdDefinitionLookup(id)
  if (candidate !== undefined) {
    return candidate
  }
  return propertyMap[id]
}
