import React, {useCallback, useEffect} from 'react'
import {WeaponVariant} from 'src/forecast/model/universal-data-model'
import { AppState } from 'src/store/root-reducer'
import {useSelector, useDispatch} from 'react-redux'
import { shouldGetAsset } from 'src/image-cache/image-effects'
import Skeleton from '@material-ui/lab/Skeleton';

interface WeaponVariantProps {
  weaponVariant: WeaponVariant
}

function getVariantIdentifier(weaponVariant: WeaponVariant) {
  return `variant-${WeaponVariant[weaponVariant]}`
}

export default function WeaponVariantComponent(props: WeaponVariantProps) {
  const dispatch = useDispatch()
  const image = useSelector((state: AppState) => state.assets?.[getVariantIdentifier(props.weaponVariant)])

  const shouldGetAssetCallback = useCallback((weaponVariant: WeaponVariant) => {
    dispatch(shouldGetAsset(`weapon_variant/${WeaponVariant[weaponVariant]}.png`, getVariantIdentifier(weaponVariant)))
  }, [dispatch])

  useEffect(() => {
    if (!image) {
      shouldGetAssetCallback(props.weaponVariant)
    }
  })

  const imgScalingStyling: React.CSSProperties = {
    maxWidth: "100%",
    height: "auto",
    width: "32px"
  }

  const imgCommonStyling: React.CSSProperties = Object.assign({}, imgScalingStyling, {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  const skeleton = <Skeleton variant='rect' style={Object.assign({}, imgCommonStyling)} />
  const imageElement = <img src={image} alt={WeaponVariant[props.weaponVariant]} style={Object.assign({ 'zIndex': 1 }, imgCommonStyling)} />
  return (
    <div style={Object.assign({}, { position: "relative", margin: "4px" }, imgScalingStyling)} >
      {!image && skeleton}
      {!!image && imageElement}
      <div style={{ display: 'block', paddingTop: '100%', float: 'left' }} />
    </div>
  )
}
