import { createSelectorCreator, defaultMemoize } from 'reselect'
import {isEqual} from 'lodash'

// create a "selector creator" that uses lodash.isEqual instead of ===
export const createDeepEqualsSelector = createSelectorCreator(
  defaultMemoize,
  (value: any, other: any) => {
    const result = isEqual(value, other)
    return result
  }
)

export const createDeepEqualsSelectorLogged = createSelectorCreator(
  defaultMemoize,
  (value: any, other: any) => {
    const result = isEqual(value, other)
    const keys = Object.keys(value)
    console.log(`createDeepEqualsSelector: result is ${result} for keys ${JSON.stringify(keys)}`)
    return result
  }
)
