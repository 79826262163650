import { acquiredGroup } from "./acquired/acquired-pseudo-group";
import { levelsGroup } from "./levels/levels-group";
import { equipmentGroup } from "./equipment/equipment-group";
import { summonGroup } from "./summon/summon-group";
import { utilizationGroup } from "./utilization/utilization-group";
import { calculatedGroup } from "./calculated/calculated-group";
import { PropertyGroup } from "./property-group";
import { objectAssigningReducer } from "src/forecast/model/universal-data-converter";
import { staticGroup } from "./static/static-group";
import { uniqWith } from "lodash/fp"
import { CharacterProperty } from "./character-property";
import { EditableCharacterProperty } from "./editable-character-property";
import { CharacterDataValue } from "../tracker-model";

export const allocationDisplayableGroups = [acquiredGroup, levelsGroup, equipmentGroup, summonGroup]

export const allocationDisplayablePropertyList = allocationDisplayableGroups.flatMap(group => group.group)

export const panelDisplayableGroups = [acquiredGroup, levelsGroup, equipmentGroup, summonGroup, utilizationGroup, calculatedGroup]

function generatePropertyMap(...allPropertyGroups: PropertyGroup[]) {
  const dedupGroups = uniqWith((property1: CharacterProperty<any>, property2: CharacterProperty<any>) => {
    return property1.id === property2.id
  })(allPropertyGroups.flatMap(propertyGroup => propertyGroup.group))
  return dedupGroups.map(property => {
      return {
        [property.id]: property
      }
    })
    .reduce(objectAssigningReducer)
}

export const loggedOutPropertyMap = generatePropertyMap(staticGroup)

export const propertyMap = generatePropertyMap(staticGroup, ...panelDisplayableGroups)

export const propertyList = Object.values(propertyMap)

export const characterEditablePropertyList = propertyList.filter(property => property instanceof EditableCharacterProperty).map(property => property as EditableCharacterProperty<CharacterDataValue>)

function generatePropertyGroupMap(...allPropertyGroups: PropertyGroup[]) {
  return allPropertyGroups.map(propertyGroup => {
    return propertyGroup.group.map(property => {
      return {
        [property.id]: propertyGroup
      }
    }).reduce(objectAssigningReducer)
  }).reduce(objectAssigningReducer)
}

export const propertyIdToPropertyGroupMap = generatePropertyGroupMap(staticGroup, ...panelDisplayableGroups)
