import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak, Resource} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const thirtyFiveCpProperty = new WeaponDataProperty(
  "5bb0b906-c8d8-4f36-aa3d-1961547fe0b8",
  "35 CP",
  "35 CP",
  standardLimitBreakConverter,
  "35 CP",
  WeaponType.FIFTEEN_CP,
  () => getValues(StandardLimitBreak),
  [
    {resource: Resource.CP, valueToResource: (value) => {
      const valueLookupMap = {
        [StandardLimitBreak.UNOBTAINED]: 0,
        [StandardLimitBreak.ZERO]: 35,
        [StandardLimitBreak.ONE]: 40,
        [StandardLimitBreak.TWO]: 45,
        [StandardLimitBreak.THREE]: 50,
      }
      return valueLookupMap[value] || 0
    }}
  ]
)
