import { EditableCharacterProperty } from 'src/tracker/model/property/editable-character-property'
import { btProperty } from 'src/tracker/model/property/equipment/weapon/bt-property'
import { exProperty } from 'src/tracker/model/property/equipment/weapon/ex-property'
import { frProperty } from 'src/tracker/model/property/equipment/weapon/fr-property'
import { silverWeaponProperty } from 'src/tracker/model/property/equipment/weapon/silver-weapon-property'
import { weaponGroup } from 'src/tracker/model/property/equipment/weapon/weapon-group'
import { BtWeaponLimitBreak, Resource, StandardLimitBreak } from 'src/tracker/model/tracker-model'
import { CharacterResourceProperty } from '../resource-meta/character-resource-property'

export const powerStoneProperty = new CharacterResourceProperty(
  'e331aea6-983a-4403-8ba0-6096447d31bb',
  'Power Stones',
  'powerStones',
  Resource.POWER_STONE,
)

powerStoneProperty.getResourceFromCharacterDataAccessors.push(
  (characterData, negate) => {
    return weaponGroup.group.map((uncastedProperty) => {
      const property = uncastedProperty as EditableCharacterProperty<any>
      const currentVal = property.getFromCharacterData(characterData)
      var refundForSelling = 0
      if (property.name === frProperty.name) {
        if (btProperty.getFromCharacterData(characterData) > BtWeaponLimitBreak.ZERO && frProperty.getFromCharacterData(characterData) >= StandardLimitBreak.THREE) {
          refundForSelling = 80
        }
      } else if (property.name === exProperty.name || property.name === btProperty.name || property.name === silverWeaponProperty.name) {
        refundForSelling = 0
      } else if (property.getFromCharacterData(characterData) >= StandardLimitBreak.THREE) {
        refundForSelling = 4
      }
      const currentStones = property.valueToResource(currentVal, Resource.POWER_STONE)
      return currentStones - refundForSelling
    }).reduce((a, b) => a + b, 0) * (negate ? -1 : 1)
  }
)
