import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const woiProperty = new WeaponDataProperty(
  "bf428978-6062-4c5d-99a5-848c6b663b3d",
  "World of Illusions",
  "WoI",
  standardLimitBreakConverter,
  "World of Illusions",
  WeaponType.WORLD_OF_ILLUSION,
  (universalCharacterData) => {
    const weaponBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (!!universalCharacterData.universal.availableWeapons.find(weaponType => WeaponType.WORLD_OF_ILLUSION === weaponType)) {
      return weaponBreakValues
    } else {
      return weaponBreakValues.slice(0, 1)
    }
  },

)
