import React from 'react'
import {getCustomColumnGroups} from 'src/preferences/preferences-selectors'
import {writeCustomColumnsData} from 'src/preferences/preferences-effects'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import AbstractCustomColumnsComponent from '../abstract/abstract-custom-columns-component'
import { getPropertyFromId } from 'src/tracker/model/property/property-deserializer'
import { InternalSelectorComponent } from './internal-selector-component'

export const CustomColumnsWidget = new PreferencesWidget(
  <AbstractCustomColumnsComponent
    getCustomColumnGroups={getCustomColumnGroups}
    writeCustomColumnGroups={writeCustomColumnsData}
    dataToString={(input: string) => {
      return getPropertyFromId(input)?.name
    }}
    selector={(onIdPicked) => {
      return <InternalSelectorComponent onIdPicked={onIdPicked} />
    }}
  />,
  "Custom column groups",
)
