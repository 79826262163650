import { bloomProperty } from 'src/tracker/model/property/equipment/misc/bloom-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

export const bloomTokenProperty = createStandardCharacterResourceProperty(
  'b7679605-3e74-401c-b89a-1d563e8deb79',
  'Bloom Tokens',
  'bloomTokens',
  bloomProperty,
  Resource.BLOOM_TOKENS,
  new DailyResourceStats(5, 1),
)
