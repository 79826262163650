import React, {useCallback} from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { CharacterPanelTab, setCharacterPanelTabActionCreator } from './character-details-actions'
import { AppState } from 'src/store/root-reducer'
import {useDispatch, useSelector} from 'react-redux'
import CharacterImage from 'src/forecast/character-image/character-image'
import SummonPassives from 'src/character-details/summon-passives/summon-passives'
import CrystalColor from 'src/character-details/crystal-color/crystal-color'
import ExternalLinks from 'src/character-details/external-links/external-links'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Public from '@material-ui/icons/Public'
import Person from '@material-ui/icons/Person'
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import MyCharacterContent from 'src/character-details/my-character-content/my-character-content'
import WeaponVariantComponent from './weapon-variant/weapon-variant-component'
import { unsetCharacterPanel } from 'src/router/router-effects'
import { getActivePanelCharacter } from './character-details-selectors'
import Panel from 'src/utils/panel/ffootip-panel'
import { getResolvedUser } from 'src/login/login-selectors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      paddingBlock: `${theme.spacing(2)}px`
    },
    tabsFlexContainer: {
      justifyContent: 'center'
    }
  })
)

export default function CharacterDetailsPanel() {
  const classes = useStyles()
  const theme = useTheme()

  const character = useSelector(getActivePanelCharacter)
  const loggedIn = !!useSelector(getResolvedUser)
  const currentTab = useSelector((state: AppState) => state.characterDetails.currentTab)

  const dispatch = useDispatch()
  const closePanelCallback = useCallback(() => {
    dispatch(unsetCharacterPanel())
  }, [dispatch])
  const changePanelCallback = useCallback((tab: CharacterPanelTab) => {
    dispatch(setCharacterPanelTabActionCreator(tab))
  }, [dispatch])

  if (!character) {
    return null
  }

  const possibleTabs = [CharacterPanelTab.UNIVERSAL_TAB]

  if (loggedIn) {
    possibleTabs.push(CharacterPanelTab.MY_CHARACTER_TAB)
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    changePanelCallback(newValue as CharacterPanelTab)
  }

  const tabRenderingFunction = (tabs: CharacterPanelTab[]) => {
    return tabs.map(tab => {
      var icon: React.ReactElement
      switch(tab) {
        default:
          return null
        case CharacterPanelTab.UNIVERSAL_TAB:
          icon = <Public />
          break
        case CharacterPanelTab.MY_CHARACTER_TAB:
          icon = <Person />
          break
      }
      return <Tab icon={icon} label={tab} value={tab} key={tab} />
    }).filter(thing => !!thing)
  }

  const tabs = possibleTabs.length > 1 &&
    <Tabs value={currentTab} onChange={handleChange} className={classes.tabs} classes={{flexContainer: classes.tabsFlexContainer}}>
      {tabRenderingFunction(possibleTabs)}
    </Tabs>

  var tabbedContent: React.ReactNode
  switch (currentTab) {
    case CharacterPanelTab.UNIVERSAL_TAB:
    default:
      const crystalColor = <CrystalColor crystalColor={character.color} />
      const weaponVariant = <WeaponVariantComponent weaponVariant={character.weaponVariant} />
      const artifactDisplay = character?.recommendedArtifacts.map(art => {
        return (<ListItem divider={true} key={art}>
          <ListItemText primary={art} />
        </ListItem>
        )
      })

      tabbedContent = [
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key='Crystal Color'>
          <div style={{display: 'flex', padding: theme.spacing(2), justifyContent: 'center', alignItems: 'center' }}>
            <Typography>Crystal color:</Typography>
            {crystalColor}
          </div>
          <div style={{display: 'flex', padding: theme.spacing(2), justifyContent: 'center', alignItems: 'center' }}>
            <Typography>Weapon type:</Typography>
            {weaponVariant}
          </div>
        </div>,
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '16px' }} key='Recommended Artifacts'>
          <Typography variant='h6'>Recommended Artifacts:</Typography>
          <List>
            {artifactDisplay}
          </List>
        </div>,
        <SummonPassives elements={character.elementsUsed} characterSummonOverride={character.summonOverrides} key='Summon Passives'/>,
        <div style={{ display: 'flex', justifyContent: 'center' }} key='External Links'>
          <ExternalLinks characterName={character.name} />
        </div>,
      ]
      break

      case CharacterPanelTab.MY_CHARACTER_TAB:
        tabbedContent = <MyCharacterContent character={character}/>
      break
  }


  const drawerContent =
    <Container>
      {tabs}
      <Typography variant='h3' align='center' >{character.name}</Typography>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CharacterImage character={character} />
      </div>
      {tabbedContent}
    </Container>
  return <Panel closePanelCallback={closePanelCallback}>
    {drawerContent}
  </Panel>
}
