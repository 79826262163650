import React, { FunctionComponent } from "react"
import { CharacterProperty } from "../model/property/character-property"
import { EnumerableCharacterProperty } from "../model/property/enumerable-character-property"
import TrackerPickerEntry from 'src/tracker/tracker-picker-entry/tracker-picker-entry';
import { DateTime, Duration } from "luxon";
import { DateProperty } from "../model/property/date-property";
import { PlannerDateProperty } from "../model/property/planner-date-property";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { TimeRange } from "src/forecast/model/universal-data-model";
import { SpecialPropertyValues } from "../property-picker/property-picker";
import { useSelector } from "react-redux";
import { calculateValuesOfProperty } from "src/forecast/forecast-selectors";

interface TrackerValuePickerProps {
  filterPropertySelected: CharacterProperty<any> | SpecialPropertyValues
  currentValue: any
  setCurrentFilteredValue: (input: any) => void
}


export const TrackerValuePicker: FunctionComponent<TrackerValuePickerProps> = ({
  filterPropertySelected,
  currentValue,
  setCurrentFilteredValue,
}) => {
  const possibleValueGenerator = useSelector(calculateValuesOfProperty)
  if (filterPropertySelected instanceof EnumerableCharacterProperty) {
    const possibleValues = possibleValueGenerator(filterPropertySelected)
    return (
      <TrackerPickerEntry
        options={possibleValues.map(value => filterPropertySelected.valueToString(value))}
        disabled={false}
        updateFunction={(newValue) => {
          const value = filterPropertySelected.valueFromString(newValue)
          setCurrentFilteredValue(value)
        }}
        width='125px'
        value={filterPropertySelected.valueToString(currentValue)}
      />
    )
  } else if (filterPropertySelected instanceof DateProperty || filterPropertySelected instanceof PlannerDateProperty || SpecialPropertyValues.PlannerDateProp === filterPropertySelected) {
    const castedCurrentValue = currentValue as TimeRange
    return (
      <KeyboardDateTimePicker
        value={castedCurrentValue.start}
        onChange={(date) => {
          if (date !== null) {
            const timeRange = new TimeRange(
              date,
              Duration.fromObject({days: 1}),
              false,
              false,
            )
            setCurrentFilteredValue(timeRange)
          }
        }}
        format={castedCurrentValue.start.toLocaleString(DateTime.DATETIME_SHORT)}
        inputVariant='filled'
        style={{width: '225px'}}
        ampm={false}
      />
    )
  }
  return null
}
