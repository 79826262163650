import React, {FunctionComponent} from 'react'
import {useSelector} from 'react-redux'
import {isNotLoggedIn, getUserId, getResolvedUser} from 'src/login/login-selectors'
import { Redirect } from "react-router-dom";
import {LOGIN_PATH} from 'src/router/root-router'
import {AppState} from 'src/store/root-reducer'
import CircularProgress from '@material-ui/core/CircularProgress'
import Theme from 'src/theme'
import Typography from '@material-ui/core/Typography'
import {getDocumentLoaded, getDocumentError} from 'src/firestore/document/document-selectors'
import { useFirestoreConnect } from 'react-redux-firebase'

interface DocumentLoadingComponentProps {
  children: React.ReactNode,
  getDocumentLocations: ((userId: string | undefined) => string| undefined)[]
  acceptSpecifiedId?: boolean
  bypassLogin?: boolean
}

const DocumentLoadingComponent: FunctionComponent<DocumentLoadingComponentProps> = ({
  children,
  getDocumentLocations,
  acceptSpecifiedId = false,
  bypassLogin = false,
}) => {
  const notLoggedIn = useSelector(isNotLoggedIn)
  const userId = useSelector((state: AppState) => acceptSpecifiedId ? getResolvedUser(state) : getUserId(state))
  const documentLocations = getDocumentLocations.map(getDocumentLocation => getDocumentLocation(userId))
    .filter(it => !!it) as string[]
  const dataLoaded = useSelector((state: AppState) => documentLocations.map(documentLocation =>
    getDocumentLoaded(state, documentLocation))
    .reduce((a, b) => a && b, true))
  const errorFound = useSelector((state: AppState) => documentLocations.map(documentLocation =>
    getDocumentError(state, documentLocation))
    .reduce((a, b) => !!a || !!b, false))

  useFirestoreConnect(documentLocations)

  if (notLoggedIn && !userId && !bypassLogin) {
    return <Redirect to={LOGIN_PATH}/>
  }

  if (!dataLoaded) {
    return (
      <div style={{ display: "flex", justifyContent: "center", padding: Theme.spacing(2) }}>
        <CircularProgress />
      </div>
    )
  }

  if (!!errorFound) {
    return (
      <div style={{ display: "flex", justifyContent: "center", padding: Theme.spacing(2) }}>
        <Typography color='secondary'>Error when retrieving data: {errorFound}</Typography>
      </div>
    )
  }

  return (
    <>
      {children}
    </>
  )

}

export default DocumentLoadingComponent
