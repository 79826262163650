import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import firebase from 'firebase/app'
import store, {history} from 'src/store/root-reducer'
import { ThemeProvider } from '@material-ui/core/styles';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import theme from 'src/theme'
import {createFirestoreInstance} from 'redux-firestore'
import './index.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router'
import LuxonUtils from '@date-io/luxon';

document.getElementsByTagName('body')?.[0].setAttribute('style', `background-color: ${theme.palette.background.default}; margin: 0px; height: 100%`)

// redirect to my domain controlled URL
/*
if (window.location.toString() === 'https://ffootip.web.app/') {
  window.location.assign('https://ffootip.com')
}
*/

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme} >
      <Provider store={store}>
        <ReactReduxFirebaseProvider firebase={firebase} config={{}} dispatch={store.dispatch}
          createFirestoreInstance={createFirestoreInstance}>
          <MuiPickersUtilsProvider utils={LuxonUtils} >
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
