import {Uuid} from 'src/forecast/model/universal-data-model'
import { ColumnGroup } from 'src/tracker/model/column/groups/column-group'
import { CharacterData, TrackerCharacterData } from './model/tracker-model'
import { SortSpecification } from "./model/selector-model"

export const WRITE_TRACKER_DATA_FAILURE = "WRITE_TRACKER_DATA_FAILURE"
export const UPDATED_SORT_ORDER = "UPDATED_SORT_ORDER"
export const SET_COLUMN_GROUP = "SET_COLUMN_GROUP"
export const DISPLAY_ENTROPY = "DISPLAY_ENTROPY"
export const SET_EDIT_MODE = "SET_EDIT_MODE"
export const SET_GROUPED_MODE = "SET_GROUPED_MODE"
export const SET_SORT_SPECIFICATIONS = "SET_SORT_SPECIFICATIONS"
export const STORE_CHARACTER_MASS_EDIT = "STORE_CHARACTER_MASS_EDIT"
export const CLEAR_STORED_CHARACTER = "CLEAR_STORED_CHARACTER"
export const STORE_TRACKER_CHARACTER_DATA = "STORE_TRACKER_CHARACTER_DATA"
export const CLEAR_TRACKER_CHARACTER_DATA = "CLEAR_TRACKER_CHARACTER_DATA"

export const MASS_EDIT_CONFIRMATION_DIALOG_SUBTYPE = "MASS_EDIT_CONFIRMATION_DIALOG"
export const COLUMN_EDIT_CONFIRMATION_DIALOG_SUBTYPE = "COLUMN_EDIT_CONFIRMATION_DIALOG"

export function writeTrackerDataFailureActionCreator(reason: string) {
  return {
    type: WRITE_TRACKER_DATA_FAILURE,
    reason: reason,
  }
}

export function setColumnGroupActionCreator(columnGroup: ColumnGroup) {
  return {
    type: SET_COLUMN_GROUP,
    columnGroup: columnGroup
  }
}

export function displayEntropyActionCreator(display: boolean) {
  return {
    type: DISPLAY_ENTROPY,
    display: display,
  }
}

export function setEditModeActionCreator(edit: boolean) {
  return {
    type: SET_EDIT_MODE,
    editMode: edit,
  }
}

export function setGroupedModeActionCreator(groupedMode: boolean) {
  return {
    type: SET_GROUPED_MODE,
    groupedMode
  }
}

export function setSortSpecificationsActionCreator(sortSpecifications: SortSpecification[]) {
  return {
    type: SET_SORT_SPECIFICATIONS,
    sortSpecifications
  }
}

export function storeCharacterMassEditActionCreator(characterId: Uuid, characterData: CharacterData) {
  return {
    type: STORE_CHARACTER_MASS_EDIT,
    characterId: characterId,
    characterData: characterData,
  }
}

export function clearStoredCharacterActionCreator() {
  return {
    type: CLEAR_STORED_CHARACTER,
  }
}

export function storeTrackerCharacterDataActionCreator(trackerCharacterData: TrackerCharacterData) {
  return {
    type: STORE_TRACKER_CHARACTER_DATA,
    trackerCharacterData: trackerCharacterData,
  }
}

export function clearStoredTrackerCharacterDataActionCreator() {
  return {
    type: CLEAR_TRACKER_CHARACTER_DATA,
  }
}

export type WriteTrackerDataFailureAction = ReturnType<typeof writeTrackerDataFailureActionCreator>
export type SetColumnGroupAction = ReturnType<typeof setColumnGroupActionCreator>
export type DisplayEntropyAction = ReturnType<typeof displayEntropyActionCreator>
export type SetEditModeAction = ReturnType<typeof setEditModeActionCreator>
export type SetGroupedModeAction = ReturnType<typeof setGroupedModeActionCreator>
export type SetSortSpecificationsAction = ReturnType<typeof setSortSpecificationsActionCreator>
export type StoreCharacterMassEditAction = ReturnType<typeof storeCharacterMassEditActionCreator>
export type ClearStoredCharacterAction = ReturnType<typeof clearStoredCharacterActionCreator>
export type StoreTrackerCharacterDataAction = ReturnType<typeof storeTrackerCharacterDataActionCreator>
export type ClearStoredTrackerCharacterDataAction = ReturnType<typeof clearStoredTrackerCharacterDataActionCreator>
