import {ActionColumn} from 'src/tracker/model/column/tracker-column'
import {CharacterData, SummonBoardStatus, generateDefaultCharacterData} from 'src/tracker/model/tracker-model'
import { summonGroup } from '../../property/summon/summon-group'
import { objectAssigningReducer } from 'src/forecast/model/universal-data-converter'
import { EditableCharacterProperty } from '../../property/editable-character-property'
import { maxGivenCharacter } from 'src/tracker/tracker-selectors'

export const actionOptions = ["", "✅", "❌", "💎", "🧠"]

const setAllSummonBoardStatuses = (summonBoardStatus: SummonBoardStatus) => {
  return summonGroup.group.map(property => {
    const castedProperty = property as EditableCharacterProperty<any>
    return castedProperty.set({} as CharacterData, summonBoardStatus)
  }).reduce(objectAssigningReducer)
}

export const actionColumn = new ActionColumn(
  "500a3800-cc13-41fa-8bef-8b266dc56e8d",
  "85px",
  "Bulk Edit Actions",
  "Mass Edit",
  actionOptions,
  (chosenAction, universalCharacterData, region) => {
    if (chosenAction === actionOptions[1]) { // Max
      return maxGivenCharacter(universalCharacterData, region)
    } else if (chosenAction === actionOptions[2]) { // Clear
      return Object.assign({}, generateDefaultCharacterData())
    } else if (chosenAction === actionOptions[3]) { // Treasure Boards
      return Object.assign({}, setAllSummonBoardStatuses(SummonBoardStatus.TREASURED))
    } else if (chosenAction === actionOptions[4]) { // Master Boards
      return Object.assign({}, setAllSummonBoardStatuses(SummonBoardStatus.MASTERED))
    } else {
      return {} as Partial<CharacterData>
    }
  },
  (chosenAction, characterName) => {
    var actionName = null as string | null
    switch (chosenAction) {
      case actionOptions[1]:
        actionName = "max"
        break
      case actionOptions[2]:
        actionName = "clear"
        break
      case actionOptions[3]:
        actionName = "treasure all boards for"
        break
      case actionOptions[4]:
        actionName = "master all boards for"
        break
    }

    if (actionName !== null) {
      return `You have chosen to ${actionName} ${characterName}. Is that correct?`
    } else {
      return null
    }
  }
)
