export const LOGOFF_SUCCESS = "LOGOFF_SUCCESS"
export const RESET_PASSWORD_EMAIL_SENT_SUCCESS = "RESET_PASSWORD_EMAIL_SENT_SUCCESS"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const RESET_PASSWORD_DIALOG_OPEN = "RESET_PASSWORD_DIALOG_OPEN"
export const RESET_PASSWORD_DIALOG_CLOSED = "RESET_PASSWORD_DIALOG_CLOSED"
export const ACCOUNT_DELETION_CONFIRMATION_DIALOG_OPEN = "ACCOUNT_DELETION_CONFIRMATION_DIALOG_OPEN"
export const ACCOUNT_DELETION_CONFIRMATION_DIALOG_CLOSED = "ACCOUNT_DELETION_CONFIRMATION_DIALOG_CLOSED"

export function logoffSuccessActionCreator() {
  return {
    type: LOGOFF_SUCCESS,
  }
}

export function resetPasswordEmailSentSuccessActionCreator() {
  return {
    type: RESET_PASSWORD_EMAIL_SENT_SUCCESS,
  }
}

export function deleteAccountSuccessActionCreator() {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
  }
}

export function openResetPasswordDialog() {
  return {
    type: RESET_PASSWORD_DIALOG_OPEN
  }
}

export function closeResetPasswordDialog() {
  return {
    type: RESET_PASSWORD_DIALOG_CLOSED
  }
}

export function openAccountDeletionConfirmationDialog() {
  return {
    type: ACCOUNT_DELETION_CONFIRMATION_DIALOG_OPEN
  }
}

export function closeAccountDeletionConfirmationDialog() {
  return {
    type: ACCOUNT_DELETION_CONFIRMATION_DIALOG_CLOSED
  }
}


export type LogoffSuccessActionCreator = typeof logoffSuccessActionCreator
export type ResetPasswordEmailSentSuccessActionCreator = typeof resetPasswordEmailSentSuccessActionCreator
export type DeleteAccountSuccessActionCreator = typeof deleteAccountSuccessActionCreator
export type ResetPasswordDialogOpenActionCreator = typeof openResetPasswordDialog
export type ResetPasswordDialogClosedActionCreator = typeof closeResetPasswordDialog
export type AccountDeletionConfirmationDialogOpenActionCreator = typeof openAccountDeletionConfirmationDialog
export type AccountDeletionConfirmationDialogClosedActionCreator = typeof closeAccountDeletionConfirmationDialog

export type LogoffSuccessAction = ReturnType<LogoffSuccessActionCreator>
export type ResetPasswordEmailSentSuccessAction = ReturnType<ResetPasswordEmailSentSuccessActionCreator>
export type DeleteAccountSuccessAction = ReturnType<DeleteAccountSuccessActionCreator>
export type ResetPasswordDialogOpenAction = ReturnType<ResetPasswordDialogOpenActionCreator>
export type ResetPasswordDialogClosedAction = ReturnType<ResetPasswordDialogClosedActionCreator>
export type AccountDeletionConfirmationDialogOpenAction = ReturnType<AccountDeletionConfirmationDialogOpenActionCreator>
export type AccountDeletionConfirmationDialogClosedAction = ReturnType<AccountDeletionConfirmationDialogClosedActionCreator>
