import { objectAssigningReducer } from "src/forecast/model/universal-data-converter";
import { armorNuggetProperty } from "./armor-nugget-property";
import { armorPageProperty } from "./armor-page-property";
import { armorTokenProperty } from "./armor-token-property";
import { bloomFragmentProperty } from "./bloom-fragment-property";
import { bloomTokenProperty } from "./bloom-token-property";
import { btNuggetsProperty } from "./bt-nuggets-property";
import { btPagesProperty } from "./bt-pages-property";
import { btTokenProperty } from "./bt-token-property";
import { enhancementPointProperty } from "./enhancement-point-property";
import { exWeaponTokenProperty } from "./ex-weapon-token-property";
import { forceStoneShardProperty } from "./force-stone-shard-property";
import { gemProperty } from "./gem-property";
import { highArmorNuggetsProperty } from "./high-armor-nuggets-property";
import { highArmorPagesProperty } from "./high-armor-pages-property";
import { highArmorTokenProperty } from "./high-armor-token-property";
import { multiTicketProperty } from "./multi-ticket-property";
import { otherProperty } from "./other-property";
import { powerStoneProperty } from "./power-stone-property";
import { providenceCoreProperty } from "./providence-core-property";
import { ticketProperty } from "./ticket-property";
import { weaponNuggetProperty } from "./weapon-nugget-property";
import { weaponPageProperty } from "./weapon-page-property";
import { weaponTokenProperty } from "./weapon-token-property";

export const resourcesGroupWithoutOther = [gemProperty, ticketProperty, powerStoneProperty, weaponTokenProperty, armorTokenProperty,
  bloomTokenProperty, bloomFragmentProperty, weaponPageProperty, weaponNuggetProperty, armorPageProperty, armorNuggetProperty,
  enhancementPointProperty, exWeaponTokenProperty, btTokenProperty, highArmorTokenProperty, highArmorPagesProperty, highArmorNuggetsProperty,
  btPagesProperty, btNuggetsProperty, providenceCoreProperty, forceStoneShardProperty, multiTicketProperty]

export const resourcesGroup = [...resourcesGroupWithoutOther, otherProperty]

export const resourcesMap = resourcesGroup.map(property => {
  return {
    [property.resource]: property
  }
}).reduce(objectAssigningReducer)

export const dailyGroup = resourcesGroup.filter(property => !!property.dailyResourceStats)
