import {SummonBoardStatus} from 'src/tracker/model/tracker-model'
import {summonBoardStatusConverter} from 'src/tracker/model/tracker-model-converter'
import {getValues} from 'src/utils/enum-values'
import { Uuid } from 'src/forecast/model/universal-data-model'
import { EditableCharacterProperty } from '../editable-character-property'

export class SummonDataProperty extends EditableCharacterProperty<SummonBoardStatus> {
  constructor (
    id: Uuid,
    name: string,
    shortName?: string,
  ) {
    super(id, name, shortName || name, summonBoardStatusConverter, name, () => getValues(SummonBoardStatus))
  }
}
