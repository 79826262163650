import {ActionCreator} from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from 'src/store/root-reducer'
import { getFirebase } from 'react-redux-firebase'
import {logoffSuccessActionCreator, LogoffSuccessAction, resetPasswordEmailSentSuccessActionCreator, ResetPasswordEmailSentSuccessAction, deleteAccountSuccessActionCreator, DeleteAccountSuccessAction} from 'src/login/login-actions'
import {actionTypes} from 'redux-firestore'
import {openAlert} from 'src/alert/alert-actions'
import {deleteData} from 'src/tracker/tracker-effects'
import { deletePreferencesData } from 'src/preferences/preferences-effects';
import { deletePlannerData } from 'src/planner/planner-effects';

const REQUIRES_RECENT_LOGIN = "auth/requires-recent-login"

export const logoff: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, LogoffSuccessAction>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      await getFirebase().logout()
      dispatch(logoffSuccessActionCreator())
      dispatch({type: actionTypes.CLEAR_DATA})
    }
  }

export const resetPasswordWithEmailPassed: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, ResetPasswordEmailSentSuccessAction>> =
  (email: string) => {
    return async (dispatch, getState, getFirebase) => {
      resetPasswordInternal(dispatch, getFirebase, email)
    }
  }

export const resetPasswordOfCurrentUser: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, ResetPasswordEmailSentSuccessAction>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const email = getState().firebase.auth?.email
      resetPasswordInternal(dispatch, getFirebase, email)
    }
  }

const resetPasswordInternal = async (dispatch: ThunkDispatch<AppState, typeof getFirebase, ResetPasswordEmailSentSuccessAction>, getFirebaseInt: typeof getFirebase, email: string | null) => {
  if (!!email) {
    await getFirebaseInt().resetPassword(email)
    dispatch(resetPasswordEmailSentSuccessActionCreator())
    dispatch({ type: actionTypes.CLEAR_DATA })
  }
}

export const deleteAccount: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, DeleteAccountSuccessAction>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      getFirebase().auth().currentUser?.delete()
        .then(() => {
          dispatch(deleteData())
          dispatch(deletePlannerData())
          dispatch(deletePreferencesData())
          dispatch(deleteAccountSuccessActionCreator())
          dispatch({ type: actionTypes.CLEAR_DATA })
          dispatch(openAlert("Your account has been deleted."))
        })
        .catch((error) => {
          if (error.code === REQUIRES_RECENT_LOGIN) {
            dispatch(logoff())
            dispatch(openAlert("This operation can only be done recently after being logged in. You have been logged out, please log in again."))
          }
        })
    }
  }
