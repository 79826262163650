import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import generateToggle from 'src/preferences/widgets/generate-toggle'
import { getDisplayAsRefined, getDisplayFirstLdName, getHighlightAltered } from 'src/preferences/preferences-selectors'
import { writeDisplayAsRefined, writeDisplayFirstLdName, writeHighlightAltered } from 'src/preferences/preferences-effects'

const TITLE = "Miscellaneous planner toggles"

function PlannerTogglesComponent() {
  const highlightMaxed = useSelector(getHighlightAltered)
  const displayFirstLdName = useSelector(getDisplayFirstLdName)
  const displayAsRefined = useSelector(getDisplayAsRefined)

  const dispatch = useDispatch()

  const highlightAlteredCallback = useCallback((highlightAltered: boolean) => {
    dispatch(writeHighlightAltered(highlightAltered))
  }, [dispatch])
  const highlightDisplayFirstLdName = useCallback((displayFirstLdName: boolean) => {
    dispatch(writeDisplayFirstLdName(displayFirstLdName))
  }, [dispatch])
  const highlightDisplayAsRefined = useCallback((displayAsRefined: boolean) => {
    dispatch(writeDisplayAsRefined(displayAsRefined))
  }, [dispatch])


  const handleHighlightAlteredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    highlightAlteredCallback(event.target.checked)
  }
  const handleDisplayFirstLdNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    highlightDisplayFirstLdName(event.target.checked)
  }
  const handleDisplayAsRefined = (event: React.ChangeEvent<HTMLInputElement>) => {
    highlightDisplayAsRefined(event.target.checked)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {generateToggle("Highlight altered: when on, highlights any cells where resources are spent. Off means the cells will always remain gray.",
        highlightMaxed, handleHighlightAlteredChange)}
      {generateToggle("Display first character: when on, a character image appears after the title of the event that represents the first character in the event. Off means this will not be displayed.",
        displayFirstLdName, handleDisplayFirstLdNameChange)}
      {generateToggle("Display refinable materials as books/ingots: when on, any page/nugget materials will be displayed as books/ingots and any remanders will be fractional. Off means everything is displayed as pages or nuggets",
        displayAsRefined, handleDisplayAsRefined)}
    </div>
  )
}

export const PlannerTogglesWidget = new PreferencesWidget(
  <PlannerTogglesComponent />,
  TITLE,
)
