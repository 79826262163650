import { Typography, useTheme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { DateProperty, DatePropertyMode, datePropertyModeToString, DebutDateProperty, MiscDateProperty, WeaponDateProperty } from "src/tracker/model/property/date-property";
import { EnumPicker } from "./enum-picker";
import {getValues} from 'src/utils/enum-values'
import { generateDatePropertyWeaponAppearance } from "src/tracker/model/property/date-property-weapon-appearance-scheme";
import { generateDatePropertyDebut } from "src/tracker/model/property/date-property-debut-scheme";
import { getFriendlyNameFromWeaponType, WeaponType } from "src/forecast/model/weapon-type";
import { CharacterDebut, getFriendlyNameFromCharacterDebut } from "src/forecast/model/character-debut";
import { MiscDefinitionValues } from "src/tracker/model/property/misc-definition-values";
import { CharacterProperty } from "src/tracker/model/property/character-property";
import { generateDateProperty } from "src/tracker/model/property/date-property-misc-scheme";

interface TrackerPlannerDateCreatorProps {
  currentTrackerValue: CharacterProperty<any>
  onValidDateProperty: (property: DateProperty<any>) => void
}

enum EventDateSubtype {
  "Weapon", "Debut", "Misc"
}

const trackerValueToSubtype = (trackerValue: CharacterProperty<any>) => {
  if (trackerValue instanceof WeaponDateProperty) {
    return EventDateSubtype.Weapon
  } else if (trackerValue instanceof DebutDateProperty) {
    return EventDateSubtype.Debut
  } else if (trackerValue instanceof MiscDateProperty) {
    return EventDateSubtype.Misc
  }
  return undefined
}

const excludedWeaponSet = new Set([WeaponType.BRONZE, WeaponType.SILVER, WeaponType.MANIKIN, WeaponType.NT].map(value => WeaponType[value]))
const excludedDebutSet = new Set([CharacterDebut.SILVER].map(value => CharacterDebut[value]))

const getSubtypeOptions = (subtype: EventDateSubtype) => {
  switch (subtype) {
    case EventDateSubtype.Weapon:
    default:
      return (getValues(WeaponType) as string[]).filter(type => !excludedWeaponSet.has(WeaponType[type as any]))
    case EventDateSubtype.Debut:
      return (getValues(CharacterDebut) as string[]).filter(type => !excludedDebutSet.has(CharacterDebut[type as any]))
    case EventDateSubtype.Misc:
      return getValues(MiscDefinitionValues) as string[]
  }
}

const createDatePropertyFromValue = (subtype: EventDateSubtype, datePropertyMode: DatePropertyMode, value: any) => {
  switch (subtype) {
    case EventDateSubtype.Weapon:
      return generateDatePropertyWeaponAppearance(value, datePropertyMode)
    case EventDateSubtype.Debut:
      return generateDatePropertyDebut(value, datePropertyMode)
    case EventDateSubtype.Misc:
      return generateDateProperty(value, datePropertyMode)
  }
}

function getStringFromValue(value: any, subtype: EventDateSubtype): string {
  switch (subtype) {
    case EventDateSubtype.Weapon:
      return getFriendlyNameFromWeaponType(value)
    case EventDateSubtype.Debut:
      return getFriendlyNameFromCharacterDebut(value)
    case EventDateSubtype.Misc:
      return MiscDefinitionValues[value]
  }
}

export const TrackerEventDateCreator: FunctionComponent<TrackerPlannerDateCreatorProps> = ({
  currentTrackerValue,
  onValidDateProperty,
}) => {
  const theme = useTheme()
  const currentType = trackerValueToSubtype(currentTrackerValue)

  if (currentType === undefined) {
    return null
  }
  const castedProp = currentTrackerValue as DateProperty<any>

  const onValueUpdate = (value: string) => {
    onValidDateProperty(createDatePropertyFromValue(currentType, castedProp.datePropertyMode, value))
  }

  const onModeUpdate = (datePropertyMode: DatePropertyMode) => {
    onValidDateProperty(createDatePropertyFromValue(currentType, datePropertyMode, castedProp.dateValue))
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography style={{ padding: theme.spacing(1) }}>Date property: </Typography>
      <EnumPicker value={castedProp.datePropertyMode} onValueUpdated={onModeUpdate} options={getValues(DatePropertyMode) as DatePropertyMode[]} valueToString={(value) => datePropertyModeToString(value)}/>
      <EnumPicker value={castedProp.dateValue} onValueUpdated={onValueUpdate} options={getSubtypeOptions(currentType)} valueToString={(value) => getStringFromValue(value, currentType)}/>
    </div>
  )
}
