import { Character, TimeRange } from "src/forecast/model/universal-data-model"
import { CharacterData, generateDefaultCharacterData } from "../tracker-model"
import { createDefaultDeUtilization, DeUtilization } from "./de-utilization"

export class AllCharacterData {
  constructor(
    universal: Character,
    personal?: CharacterData | undefined,
    deUtilization?: DeUtilization | undefined,
    characterVersions?: [TimeRange, CharacterData][] | undefined) {
    this.universal = universal
    this.personal = personal || generateDefaultCharacterData()
    this.deUtilization = deUtilization || createDefaultDeUtilization()
    this.characterVersions = characterVersions || []
  }

  universal: Character
  personal: CharacterData
  deUtilization: DeUtilization
  characterVersions: [TimeRange, CharacterData][]
}
