import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import {defaultCharacterDataNumberConverter} from 'src/tracker/model/tracker-model-converter'
import { EditableCharacterProperty } from '../editable-character-property'

function getMaxLevel(awakened80: boolean, awakened90: boolean) {
  if (awakened90) {
    return 90
  }
  if (awakened80) {
    return 80
  }
  return 70
}

export const levelProperty = new EditableCharacterProperty(
  "9733ee4e-9d99-4910-b25a-3c5a803432e0",
  "Level",
  "Level",
  defaultCharacterDataNumberConverter,
  "Level",
  (character, when, region) => {
    const debutChecker = debutBeforeTime(region, character.universal, when)
    const awakening80AppearsBeforeNow = debutChecker(CharacterDebut.AWAKENING_80)
    const awakening90AppearsBeforeNow = debutChecker(CharacterDebut.AWAKENING_90)
    const maxLevel = getMaxLevel(awakening80AppearsBeforeNow, awakening90AppearsBeforeNow)
    return Array.from(new Array(maxLevel + 1), (x,i) => i)
  },
)
