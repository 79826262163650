import { ColumnGroup } from "../column-group";
import { armorNuggetNeededColumn } from "./armor-nugget-needed-column";
import { armorPageNeededColumn } from "./armor-page-needed-column";
import { armorTokenNeededColumn } from "./armor-token-needed-column";
import { bloomFragmentNeededColumn } from "./bloom-fragment-needed-column";
import { bloomTokenNeededColumn } from "./bloom-token-needed-column";
import { btNuggetNeededColumn } from "./bt-nugget-needed-column";
import { btPageNeededColumn } from "./bt-page-needed-column";
import { enhancementPointNeededColumn } from "./enhancement-point-needed-column";
import { forceStoneShardNeededColumn } from "./force-stone-shard-needed-column";
import { highArmorNuggetNeededColumn } from "./high-armor-nugget-needed-column";
import { highArmorPageNeededColumn } from "./high-armor-page-needed-column";
import { highArmorTokenNeededColumn } from "./high-armor-token-needed-column";
import { powerStoneNeededColumn } from "./power-stone-needed-column";
import { weaponNuggetNeededColumn } from "./weapon-nugget-needed-column";
import { weaponPageNeededColumn } from "./weapon-page-needed-column";

export const resourcesNeededColumnGroup = new ColumnGroup(
  "45864086-e6fd-4469-b283-9c1cc6ad6fab",
  "Resources needed",
  [powerStoneNeededColumn, armorTokenNeededColumn, bloomTokenNeededColumn, bloomFragmentNeededColumn, weaponPageNeededColumn, weaponNuggetNeededColumn, armorPageNeededColumn,
    armorNuggetNeededColumn, enhancementPointNeededColumn, highArmorTokenNeededColumn, highArmorPageNeededColumn, highArmorNuggetNeededColumn,
    btPageNeededColumn, btNuggetNeededColumn, forceStoneShardNeededColumn]
)
