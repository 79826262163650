import React, { FunctionComponent } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import { FilterComparator } from '../model/selector-model';
import { CharacterProperty } from '../model/property/character-property';
import { EnumerableCharacterProperty } from '../model/property/enumerable-character-property';
import { SpecialPropertyValues } from '../property-picker/property-picker';

interface PredicateComparatorSelectorProps {
  onPredicateComparatorSelected: (prop: FilterComparator) => void
  defaultValue: FilterComparator
  characterProperty?: CharacterProperty<any> | SpecialPropertyValues
  style?: React.CSSProperties
}

const PredicateComparatorSelector: FunctionComponent<PredicateComparatorSelectorProps> = ({
  onPredicateComparatorSelected,
  defaultValue,
  characterProperty,
  style = {},
}) => {
  const handleChange = (event: any, newValue: FilterComparator | null) => {
    if (newValue !== null) {
      onPredicateComparatorSelected(newValue)
    }
  }

  const possibleOptions = [
    FilterComparator["≤"],
    FilterComparator["<"],
    FilterComparator["="],
    FilterComparator["≥"],
    FilterComparator[">"],
  ]

  if (!!characterProperty && characterProperty instanceof EnumerableCharacterProperty) {
    possibleOptions.push(FilterComparator["Obt"])
    possibleOptions.push(FilterComparator["!Obt"])
  }

  return (
    <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      disableClearable
      openOnFocus
      blurOnSelect
      forcePopupIcon={false}
      style={Object.assign({ width: '75px' }, style)}
      options={possibleOptions}
      renderInput={(params) => (
        <TextField {...params} variant='filled' margin='dense'/>
      )}
      getOptionLabel={(property: FilterComparator) => FilterComparator[property]}
      onChange={handleChange}
      defaultValue={defaultValue}
    />
  )
}

export default PredicateComparatorSelector
