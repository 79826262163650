import {CharacterPanelTab, SetCharacterPanelTabAction, SET_CHARACTER_PANEL_TAB } from 'src/character-details/character-details-actions'
import {actionTypes} from 'react-redux-firebase'

const initialState = {
  currentTab: CharacterPanelTab.UNIVERSAL_TAB,
}

export type CharacterDetailsState = typeof initialState

export function characterDetailsReducer(state = initialState, action: SetCharacterPanelTabAction) {
  switch (action.type) {
    case SET_CHARACTER_PANEL_TAB:
      const castedAction = action as SetCharacterPanelTabAction
      return Object.assign({}, state, {currentTab: castedAction.tab })
    case actionTypes.LOGOUT:
      return Object.assign({}, state, {currentTab: initialState.currentTab})
    default:
      return state
  }
}
