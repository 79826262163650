import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import {defaultCharacterDataNumberConverter} from 'src/tracker/model/tracker-model-converter'
import { Resource } from '../../tracker-model'
import { EditableCharacterProperty } from '../editable-character-property'

export const forceEnhancementProperty = new EditableCharacterProperty(
  "545719b2-021e-4b51-a4be-8908d42cf2aa",
  "Force Enhancement",
  "FE",
  defaultCharacterDataNumberConverter,
  "Force Enhancement",
  (character, when, region) => {
    const forceExtensionAppearsBeforeNow = debutBeforeTime(region, character.universal, when)(CharacterDebut.FORCE_ENHANCEMENT)
    const forceExtension50AppearsBeforeNow = debutBeforeTime(region, character.universal, when)(CharacterDebut.FORCE_ENHANCEMENT_50)
    const maxLevel = forceExtension50AppearsBeforeNow ? 50 : 
      (forceExtensionAppearsBeforeNow ? 30 : 0)
    return Array.from(new Array(maxLevel + 1), (x, i) => i)
  },
  [
    {resource: Resource.ENHANCEMENT_POINTS, valueToResource: (value) => {
      return Array.from(new Array(value || 0), (x,i) => i).map(level => {
        const actualLevel = level + 1
        if (actualLevel % 5 === 0) {
          return 1000
        }
        if (actualLevel % 5 === 3) {
          return 600
        }
        return 300 as number
      }).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }},
    {resource: Resource.FORCE_STONE_SHARDS, valueToResource: (value) => {
      return Array.from(new Array(value || 0), (x,i) => i).map(level => {
        const actualLevel = level + 1
        if (actualLevel === 1) {
          return 0
        }
        if (actualLevel === 11) {
          return 20
        }
        if (actualLevel % 10 === 1) {
          return 40
        }
        return 0 as number
      }).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }},
    {resource: Resource.CP, valueToResource: (value) => {
      return Array.from(new Array(value || 0), (x,i) => i).map(level => {
        const actualLevel = level + 1
        if (actualLevel % 10 === 1) {
          return 30
        }
        return 0 as number
      }).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }},
  ]
)
