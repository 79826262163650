import { DISPLAY_NAVIGATION_DRAWER, SetDisplayNavigationDrawerAction } from './navigation-drawer-actions'

const initialState = {
  display: false,
}

export type NavigationDrawerState = typeof initialState

export function navigationDrawerReducer(state = initialState, action: SetDisplayNavigationDrawerAction) {
  switch (action.type) {
    case DISPLAY_NAVIGATION_DRAWER:
      return Object.assign({}, state, {display: action.display })
    default:
      return state
  }
}
