import { Uuid } from "src/forecast/model/universal-data-model";
import { createDefaultTierUtilization, getAllUsedIds, TierUtilization } from "./components/tier-utilization";

export function validateTierUtilization(next: TierUtilization, previous: TierUtilization, forbiddenCharacters: Uuid[]) {
  var safeNext = Object.assign({}, createDefaultTierUtilization(), next)

  // cannot be in the forbidden character set, unless the reason they are in this set is because they were in this tier previously
  const forbiddenCharactersSet = new Set(forbiddenCharacters)
  if (!!previous.character1) {
    forbiddenCharactersSet.delete(previous.character1)
  }
  if (!!previous.character2) {
    forbiddenCharactersSet.delete(previous.character2)
  }
  if (!!previous.character3) {
    forbiddenCharactersSet.delete(previous.character3)
  }
  if (!!safeNext.character1 && forbiddenCharactersSet.has(safeNext.character1)) {
    return previous
  }
  if (!!safeNext.character2 && forbiddenCharactersSet.has(safeNext.character2)) {
    return previous
  }
  if (!!safeNext.character3 && forbiddenCharactersSet.has(safeNext.character3)) {
    return previous
  }

  // must not repeat the same character more than once
  const nextCharacters = getAllUsedIds(safeNext)
  const nextCharactersSet = new Set(nextCharacters)
  if (nextCharacters.length !== nextCharactersSet.size) {
    return previous
  }

  // clear calls when you unassign a character
  if (!safeNext.character1) {
    safeNext = Object.assign({}, safeNext, {"call1": null})
  }

  if (!safeNext.character2) {
    safeNext = Object.assign({}, safeNext, {"call2": null})
  }

  if (!safeNext.character3) {
    safeNext = Object.assign({}, safeNext, {"call3": null})
  }

  return safeNext
}
