import { TotalAllocation } from "./model/planner-model";
import { CharacterAllocationDialogContext, CreateCharacterAllocationForEventAction, CREATE_CHARACTER_ALLOCATION_FOR_EVENT, DisplayCurrentResourceAccountingAction, DisplayEventsCompletedAction, DisplayScheduledIncomeAction, DISPLAY_CURRENT_RESOURCE_ACCOUNTING, DISPLAY_EVENTS_COMPLETED, DISPLAY_SCHEDULED_INCOME, SetCharacterAllocationDialogContextAction, SET_CHARACTER_ALLOCATION_DIALOG_CONTEXT } from "./planner-actions";

const initialState = {
  eventToCreateCharacterAllocationFor: undefined as TotalAllocation | undefined,
  characterAllocationDialogContext: undefined as CharacterAllocationDialogContext | undefined,
  displayEventsCompleted: false,
  displayScheduledIncome: false,
  displayCurrentResourcesAccounting: false,
}

export default function PlannerReducer(state = initialState, action: CreateCharacterAllocationForEventAction | SetCharacterAllocationDialogContextAction | DisplayEventsCompletedAction | DisplayScheduledIncomeAction | DisplayCurrentResourceAccountingAction) {
  switch (action.type) {
    case CREATE_CHARACTER_ALLOCATION_FOR_EVENT:
      const createCharacterAllocationForEventAction = action as CreateCharacterAllocationForEventAction
      return Object.assign({}, state, {eventToCreateCharacterAllocationFor: createCharacterAllocationForEventAction.totalAllocation})

    case SET_CHARACTER_ALLOCATION_DIALOG_CONTEXT:
      const setCharacterAllocationDialogContextAction = action as SetCharacterAllocationDialogContextAction
      return Object.assign({}, state, {characterAllocationDialogContext: setCharacterAllocationDialogContextAction.context, eventToCreateCharacterAllocationFor: undefined})

    case DISPLAY_EVENTS_COMPLETED:
      const setDisplayEventsAction = action as DisplayEventsCompletedAction
      return Object.assign({}, state, {displayEventsCompleted: setDisplayEventsAction.display})

    case DISPLAY_SCHEDULED_INCOME:
      const setDisplayScheduledIncomeAction = action as DisplayScheduledIncomeAction
      return Object.assign({}, state, {displayScheduledIncome: setDisplayScheduledIncomeAction.display})

    case DISPLAY_CURRENT_RESOURCE_ACCOUNTING:
      const setDisplayCurrentResourcesAccountingAction = action as DisplayCurrentResourceAccountingAction
      return Object.assign({}, state, {displayCurrentResourcesAccounting: setDisplayCurrentResourcesAccountingAction.display})

    default:
      return state
  }
}
