import {UniversalDataFoundAction,
  UNIVERSAL_DATA_FOUND,
  SearchUniversalDataResultsAction,
  SEARCH_UNIVERSAL_DATA_RESULTS,
  SEARCH_STRING,
  SearchStringAction,
  UPDATE_CURRENT_TIME,
  UpdateCurrentTimeAction,
  SET_SAVED_VIEW,
  SetSavedViewAction,
  SET_FILTER_SPECIFICATIONS,
  SetFilterSpecificationsAction} from 'src/forecast/forecast-actions'
import { UniversalData } from 'src/forecast/model/universal-data-model'
import { deserializeFilterSpecifications, FilterSpecification } from 'src/tracker/model/selector-model'
import { DateTime } from 'luxon'

const initialState = {
  universalData: {
    events: {},
    banners: {},
    weapons: {},
    characters: {},
    eventIdsByCharacterId: {},
    summons: {},
    armor: {},
    characterIdsByName: {},
    summonIdsByDisplayName: {},
  } as UniversalData,
  searchString: "",
  filterSpecifications: [] as FilterSpecification<any>[],
  filterSpecificationsSet: false,
  currentTime: DateTime.utc()
}

export type ForecastState = typeof initialState

export function forecastReducer(state = initialState, action: UniversalDataFoundAction | SearchUniversalDataResultsAction | SearchStringAction
  | SetFilterSpecificationsAction | UpdateCurrentTimeAction | SetSavedViewAction) {
  switch (action.type) {
    case UNIVERSAL_DATA_FOUND:
      return Object.assign({}, state, {universalData: (action as UniversalDataFoundAction).data})
    case SEARCH_UNIVERSAL_DATA_RESULTS:
      const typedAction = action as SearchUniversalDataResultsAction
      return Object.assign({}, state, {searchResults: typedAction.searchResults})

    case SEARCH_STRING:
      const searchAction = action as SearchStringAction
      return Object.assign({}, state, {searchString: searchAction.searchString})

    case SET_FILTER_SPECIFICATIONS:
      const setFilterSpecificationsAction = action as SetFilterSpecificationsAction
      return Object.assign({}, state, {filterSpecifications: setFilterSpecificationsAction.filterSpecifications,
        filterSpecificationsSet: true})

    case UPDATE_CURRENT_TIME:
      const updateCurrentTimeAction = action as UpdateCurrentTimeAction
      return Object.assign({}, state, {currentTime: updateCurrentTimeAction.currentTime})

    case SET_SAVED_VIEW:
      const setSavedViewAction = action as SetSavedViewAction
      const filterSpecifications = deserializeFilterSpecifications(setSavedViewAction.savedView.filterSpecifications)
      return Object.assign({}, state, {filterSpecifications,
        filterSpecificationsSet: true})

    default:
      return state
  }
}
