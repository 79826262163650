import { Uuid } from 'src/forecast/model/universal-data-model';
import { getPropertyFromId } from './property/property-deserializer';

const SPECIFICATION_SEPERATOR = "&"
const SPECIFICATIONS_SEPARATOR = ";"

export class SortSpecification {
  constructor(
    propertyId: Uuid,
    isDesc: boolean) {
    this.propertyId = propertyId;
    this.isDesc = isDesc;
    const propName = getPropertyFromId(propertyId)?.name || "PROPERTY NOT FOUND";
    const ascDesc = isDesc ? "descending" : "ascending";
    this.string = `${propName} ${ascDesc}`;
    this.serialize = `v1${SPECIFICATION_SEPERATOR}${propertyId}${SPECIFICATION_SEPERATOR}${isDesc}`
  }
  propertyId: Uuid;
  isDesc: boolean;
  string: string;
  serialize: string;
}

export const serializeSortSpecifications = (sortSpecifications: SortSpecification[]) => {
  return sortSpecifications.map(sortSpecification => sortSpecification.serialize).join(SPECIFICATIONS_SEPARATOR)
}

const deserializeSortSpecification = (serializedSortSpecification: string) => {
  const splitString = serializedSortSpecification.split(SPECIFICATION_SEPERATOR)
  if (splitString[0] !== undefined && splitString[0] === "v1") {
    if (splitString[1] !== undefined && getPropertyFromId(splitString[1]) !== undefined && splitString[2] !== undefined) {
      return new SortSpecification(splitString[1], splitString[2] === "true")
    }
  }
}

export const deserializeSortSpecifications = (serializedSortSpecifications: string) => {
  const splitSpecs = serializedSortSpecifications.split(SPECIFICATIONS_SEPARATOR)
  return splitSpecs.map(splitSpec => {
    const desSpec = deserializeSortSpecification(splitSpec)
    if (splitSpec !== "" && desSpec === undefined) {
      console.log("Error: couldn't deserialize " + splitSpec)
    }
    return desSpec
  }).filter(spec => spec !== undefined) as SortSpecification[]
}

export const addSortSpecification = (existingSortSpecifications: SortSpecification[], newSortSpecification: SortSpecification) => {
  const newSortSpecifications = existingSortSpecifications.slice()
  const existingSortSpecificationIndex = newSortSpecifications.findIndex(existingSortSpecification => existingSortSpecification.propertyId === newSortSpecification.propertyId)

  if (existingSortSpecificationIndex > -1) {
    newSortSpecifications.splice(existingSortSpecificationIndex, 1, newSortSpecification)
  } else {
    newSortSpecifications.push(newSortSpecification)
  }
  return newSortSpecifications
}

export const removeSortSpecification = (existingSortSpecifications: SortSpecification[], idToRemove: string) => {
  return existingSortSpecifications.filter(existingSortSpecification => existingSortSpecification.propertyId !== idToRemove)
}


export enum FilterComparator {
  "≤", "<", "=", "≥", ">", "Obt", "!Obt"
}

export class FilterSpecification<T> {
  constructor(
    propertyId: Uuid,
    comparatorValue: T,
    comparator: FilterComparator
  ) {
    this.propertyId = propertyId
    this.comparatorValue = comparatorValue
    this.comparator = comparator
    const property = getPropertyFromId(propertyId)
    const propName = property.name
    const predComparitor = FilterComparator[comparator]
    const compValueString = property.valueToString(comparatorValue as any)
    this.string = `${propName} ${predComparitor} ${compValueString}`
    this.serialize = `v1${SPECIFICATION_SEPERATOR}${propertyId}${SPECIFICATION_SEPERATOR}${predComparitor}${SPECIFICATION_SEPERATOR}${property.valueToSerializedString(comparatorValue)}`
  }
  propertyId: Uuid
  comparatorValue: T
  comparator: FilterComparator
  string: string
  serialize: string
}

export const serializeFilterSpecifications = (filterSpecifications: FilterSpecification<any>[]) => {
  return filterSpecifications.map(filterSpecification => filterSpecification.serialize).join(SPECIFICATIONS_SEPARATOR)
}

const deserializeFilterSpecification = (serializedFilterSpecification: string) => {
  const splitString = serializedFilterSpecification.split(SPECIFICATION_SEPERATOR)
  if (splitString[0] !== undefined && splitString[0] === "v1") {
    if (splitString[1] !== undefined && getPropertyFromId(splitString[1]) !== undefined
      && splitString[2] !== undefined && FilterComparator[splitString[2] as keyof typeof FilterComparator] !== undefined && splitString[3] !== undefined) {
        const candidateProperty = getPropertyFromId(splitString[1])
        const candidateComparitor = FilterComparator[splitString[2] as keyof typeof FilterComparator]
        if (candidateProperty !== undefined && candidateComparitor !== undefined) {
          const candidateValue = candidateProperty.serializedStringToValue(splitString[3])
          if (candidateValue !== undefined) {
            return new FilterSpecification(candidateProperty.id, candidateValue, candidateComparitor)
          }
        }
      }
  }
}

export const deserializeFilterSpecifications = (serializedFilterSpecifications: string) => {
  const splitSpecs = serializedFilterSpecifications.split(SPECIFICATIONS_SEPARATOR)
  return splitSpecs.map(splitSpec => {
    const desSpec = deserializeFilterSpecification(splitSpec)
    if (splitSpec !== "" && desSpec === undefined) {
      console.log("Error: couldn't deserialize " + splitSpec)
    }
    return desSpec
  }).filter(spec => spec !== undefined) as FilterSpecification<any>[]
}

export const addFilterSpecifications = (existingFilterSpecifications: FilterSpecification<any>[], newFilterSpecification: FilterSpecification<any>) => {
  const output = existingFilterSpecifications.slice()
  output.push(newFilterSpecification)
  return output
}

export const removeFilterSpecifications = (existingFilterSpecifications: FilterSpecification<any>[], removeFilterSpecification: FilterSpecification<any>) => {
  return existingFilterSpecifications.filter(existingFilterSpecification => existingFilterSpecification.string !== removeFilterSpecification.string)
}
