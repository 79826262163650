import React from 'react'
import { makeStyles, Theme, createStyles, } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import CloseTopRight from '../close-top-right/close-top-right';

interface PanelProps {
  children: React.ReactNode,
  closePanelCallback: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 500,
      maxWidth: '95%',
    },
  })
)

export default function Panel(props: PanelProps) {
  const classes = useStyles()

  return <Drawer anchor="right" open onClose={props.closePanelCallback} className={classes.drawer} classes={{paper: classes.drawer}}>
    <CloseTopRight closePanelCallback={props.closePanelCallback}/>
    {props.children}
  </Drawer>
}
