export enum WeaponType {
  "BRONZE",
  "SILVER",
  "FIFTEEN_CP",
  "THIRTY_FIVE_CP",
  "EX",
  "LD",
  "BT",
  "MANIKIN",
  "WORLD_OF_ILLUSION",
  "NT",
  "FR"
}

export const getFriendlyNameFromWeaponType = (weaponType: WeaponType) => {
  switch (weaponType) {
    case WeaponType.BRONZE:
      return "Bronze Weapon"
    case WeaponType.SILVER:
      return "Silver Weapon"
    case WeaponType.FIFTEEN_CP:
      return "15 CP"
    case WeaponType.THIRTY_FIVE_CP:
      return "35 CP"
    case WeaponType.EX:
      return "EX"
    case WeaponType.LD:
      return "LD"
    case WeaponType.BT:
      return "BT"
    case WeaponType.WORLD_OF_ILLUSION:
      return "World of Illusion"
    case WeaponType.MANIKIN:
      return "Manikin"
    case WeaponType.NT:
      return "NT"
    case WeaponType.FR:
      return "FR"
  }
}

export const getShortNameFromWeaponType = (weaponType: WeaponType) => {
  switch (weaponType) {
    case WeaponType.SILVER:
      return "S Wep"
    case WeaponType.FIFTEEN_CP:
      return "15 CP"
    case WeaponType.THIRTY_FIVE_CP:
      return "35 CP"
    case WeaponType.EX:
      return "EX"
    case WeaponType.LD:
      return "LD"
    case WeaponType.BT:
      return "BT"
    case WeaponType.WORLD_OF_ILLUSION:
      return "WoI"
    case WeaponType.MANIKIN:
      return "Manikin"
    case WeaponType.NT:
      return "NT"
    case WeaponType.FR:
      return "FR"
  }
}
