export const SET_CHARACTER_PANEL_TAB = "SET_CHARACTER_PANEL_TAB"

export enum CharacterPanelTab {
  UNIVERSAL_TAB = "Universal",
  MY_CHARACTER_TAB = "My Character",
}

export function setCharacterPanelTabActionCreator(tab: CharacterPanelTab) {
  return {
    type: SET_CHARACTER_PANEL_TAB,
    tab: tab,
  }
}

export type SetCharacterPanelTabActionCreator = typeof setCharacterPanelTabActionCreator
export type SetCharacterPanelTabAction = ReturnType<SetCharacterPanelTabActionCreator>
