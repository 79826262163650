import { btProperty } from 'src/tracker/model/property/equipment/weapon/bt-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const btPagesProperty = createStandardCharacterResourceProperty(
  '68c9b8be-290d-49b0-b850-bbb364d44ec5',
  'BT Pages',
  'btPages',
  btProperty,
  Resource.BT_PAGES,
)
