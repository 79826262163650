import { MiscDefinitionValues } from "../misc-definition-values"
import { bannerDefinition } from "./banner-definition"
import { majorDefinition } from "./major-definition"

export const getDefinitionFromValue = (value: MiscDefinitionValues) => {
  switch (value) {
    case MiscDefinitionValues.Banner:
      return bannerDefinition
    case MiscDefinitionValues.Major:
      return majorDefinition
  }
}
