import {bloomLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {BloomLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { EditableCharacterProperty } from '../../editable-character-property'

export const bloomProperty = new EditableCharacterProperty(
  "3bff653c-9556-4a1d-a75b-62612e69c6c1",
  "Bloom",
  "Bloom",
  bloomLimitBreakConverter,
  "Bloom",
  () => getValues(BloomLimitBreak),
)
