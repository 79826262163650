import { deserializeTimeRange, TimeRange } from "src/forecast/model/universal-data-model";
import { calculateMode, EventTimeMode } from "src/utils/event-time-utilities";
import { AllCharacterData } from "../components/all-character-data";
import { Region } from "../components/region";
import { CharacterProperty, StringDisplayPreferences } from "./character-property";
import { DateTime } from 'luxon'
import { WeaponType } from "src/forecast/model/weapon-type";
import { CharacterDebut } from "src/forecast/model/character-debut";
import { MiscDefinitionValues } from "./misc-definition-values";

const DEFAULT = "N/A"
const CURRENT = "Current"

export enum DatePropertyMode {
  ALL, FUTURE, PAST, FUTURE_AND_PRESENT, PAST_AND_PRESENT
}

export const datePropertyModeToString = (datePropertyMode: DatePropertyMode) => {
  switch (datePropertyMode) {
    case DatePropertyMode.ALL:
      return "All"
    case DatePropertyMode.FUTURE:
      return "Future"
    case DatePropertyMode.FUTURE_AND_PRESENT:
      return "Future and present"
    case DatePropertyMode.PAST:
      return "Past"
    case DatePropertyMode.PAST_AND_PRESENT:
      return "Past and present"
  }
}

export enum DateTransienceMode {
  INSTANT, ONGOING
}

export const displayTimeRange = (dateTransienceMode: DateTransienceMode) => (timeRange: TimeRange | null, stringDisplayPreferences?: StringDisplayPreferences) => {
  if (!timeRange) {
    return DEFAULT
  }
  const resolvedDisplayDetailedEstimates = stringDisplayPreferences?.displayDetailedEstimates || false
  var mode = calculateMode(timeRange)
  if (mode.eventTimeMode === EventTimeMode.TIME_TO_END && dateTransienceMode === DateTransienceMode.INSTANT) {
    mode.eventTimeMode = EventTimeMode.TIME
  }
  switch (mode.eventTimeMode) {
    case EventTimeMode.TIME_TO_END:
    case EventTimeMode.ESTIMATE_PAST:
      return CURRENT
    case EventTimeMode.ESTIMATE:
      if (resolvedDisplayDetailedEstimates) {
        return `~${timeRange.start.toLocaleString(DateTime.DATE_SHORT)}`
      } else {
        const earlyLate = timeRange.start.day <= 15 ? 'early' : 'late'
        return `~${earlyLate} ${timeRange.start.toFormat("MMM yy")}`
      }
    case EventTimeMode.TIME:
    case EventTimeMode.TIME_TO_START:
      return timeRange.start.toLocaleString(DateTime.DATE_SHORT)
  }
  return DEFAULT
}

export class DateProperty<T> extends CharacterProperty<TimeRange | null> {
  constructor(
    id: string,
    name: string,
    shortName: string,
    dateTransienceMode: DateTransienceMode,
    datePropertyMode: DatePropertyMode,
    dateValue: T,
    get: (region: Region, characterData?: AllCharacterData) => TimeRange | null,
  ) {
    super(id, name, shortName, get,
      displayTimeRange(dateTransienceMode),
      (value1, value2, comparisonFunction) => {
        const evalValue1 = value1?.start.toMillis() || Number.MAX_SAFE_INTEGER
        const evalValue2 = value2?.start.toMillis() || Number.MAX_SAFE_INTEGER
        return comparisonFunction(evalValue1, evalValue2)
      },
      timeRange => timeRange?.serialize || "Error: not defined",
      inputString => deserializeTimeRange(inputString) || null
      )
    this.dateTransienceMode = dateTransienceMode
    this.datePropertyMode = datePropertyMode
    this.dateValue = dateValue
  }
  dateTransienceMode: DateTransienceMode
  datePropertyMode: DatePropertyMode
  dateValue: any
}

export class WeaponDateProperty extends DateProperty<WeaponType> { }

export class DebutDateProperty extends DateProperty<CharacterDebut> { }

export class MiscDateProperty extends DateProperty<MiscDefinitionValues> { }
