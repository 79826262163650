import { lte } from "lodash";
import { deserializeTimeRange, TimeRange } from "src/forecast/model/universal-data-model";
import { AllCharacterData } from "../components/all-character-data";
import { CharacterProperty } from "./character-property";
import { DateTransienceMode, displayTimeRange } from "./date-property";
import { EnumerableCharacterProperty } from "./enumerable-character-property";
import { propertyMap } from "./group-of-groups";

const v1Schema = "v1"
const plannerDateSchema = "plannerdate"

enum SpecialPlannerDateValues {
  "Set in Tracker",
  "Unset"
}

const determinePlannerDatePropertySortValue = (input: TimeRange | SpecialPlannerDateValues) => {
  if (input === SpecialPlannerDateValues["Set in Tracker"]) {
    return 0
  } else if (input === SpecialPlannerDateValues["Unset"]) {
    return Number.MAX_SAFE_INTEGER
  } else {
    return input.start.toMillis()
  }
}

export class PlannerDateProperty<T> extends CharacterProperty<TimeRange | SpecialPlannerDateValues>  {
  constructor(
    trackerProperty: EnumerableCharacterProperty<T>,
    value: T
  ) {
    const id = v1Schema + ":" + plannerDateSchema + ":" + trackerProperty.id + ":" + trackerProperty.valueToString(value)
    const valueString = trackerProperty.valueToString(value)
    const name = trackerProperty.name + " " + valueString + " date"
    const shortName = trackerProperty.shortName + " " + valueString + " date"
    super(id, name, shortName, (region, allCharacterData) => {
      // already set
      const trackerValue = trackerProperty.get(region, allCharacterData)
      if (trackerProperty.valueComparator(value, trackerValue, lte)) {
        return SpecialPlannerDateValues["Set in Tracker"]
      }
      const allocationTime = allCharacterData?.characterVersions?.find(([_, allocationData]) => {
        const valueInQuestion = trackerProperty.get(region, new AllCharacterData(allCharacterData.universal, allocationData))
        return trackerProperty.valueComparator(value, valueInQuestion, lte)
      })
      if (!!allocationTime) {
        return allocationTime[0]
      }
      return SpecialPlannerDateValues.Unset

    }, (value, stringDisplayPreferences) => {
      if (value === SpecialPlannerDateValues["Set in Tracker"]) {
        return "Set in Tracker"
      } else if (value === SpecialPlannerDateValues.Unset) {
        return "Unset"
      }
      return displayTimeRange(DateTransienceMode.INSTANT)(value, stringDisplayPreferences)

    }, (value1, value2, comparisonFunction) => {
        return comparisonFunction(determinePlannerDatePropertySortValue(value1), determinePlannerDatePropertySortValue(value2))
    },
    value => {
      if (value === SpecialPlannerDateValues["Set in Tracker"]) {
        return "Set in Tracker"
      } else if (value === SpecialPlannerDateValues["Unset"]) {
        return "Unset"
      } else {
        return value.serialize
      }
    },
    serializedString => {
      if (serializedString === "Set in Tracker") {
        return SpecialPlannerDateValues["Set in Tracker"]
      } else if (serializedString === "Unset") {
        return SpecialPlannerDateValues["Unset"]
      } else {
        return deserializeTimeRange(serializedString) || SpecialPlannerDateValues["Unset"]
      }
    }
    )
    this.trackerProperty = trackerProperty
    this.value = value
  }
  trackerProperty: EnumerableCharacterProperty<T>
  value: T
}

export const createFromId = (id: string) => {
  const splitString = id.split(":")
  if (splitString[0] !== undefined && splitString[0] === v1Schema && splitString[1] !== undefined && splitString[1] === plannerDateSchema && splitString[2] !== undefined) {
    const trackerPropertyCandidate = propertyMap[splitString[2]]
    if (trackerPropertyCandidate !== undefined && trackerPropertyCandidate instanceof EnumerableCharacterProperty && splitString[3] !== undefined) {
      const trackerValueCandidate = trackerPropertyCandidate.valueFromString(splitString[3])
      if (trackerValueCandidate !== undefined) {
        return new PlannerDateProperty(trackerPropertyCandidate, trackerValueCandidate)
      }
    }
  }
  return undefined
}
