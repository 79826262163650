import React, { useCallback } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux'
import {closeCreditsActionCreator, } from 'src/irl/irl-actions'
import Close from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { getCreditsOpen } from './irl-selectors';

const CreditsModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getCreditsOpen)

  const closeCreditsCallback = useCallback(() => {
    dispatch(closeCreditsActionCreator())
  }, [dispatch])

    return (<Dialog open={isOpen} onBackdropClick={closeCreditsCallback}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Close onClick={closeCreditsCallback} style={{ padding: '8px', maxWidth: '32px' }} />
      </div>
      <Container>
        <Typography variant='h3'>Credits</Typography>
        <List>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://twitter.com/DissidiaDB'>Rem's</Link> <Link rel='noopener' href='https://dissidiadb.com/'>Dissidia DB</Link> for character data and help with questions and design discussions</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://twitter.com/TonberryTroupe'>Tonberry Troupe's</Link> <Link rel='noopener' href='https://www.tonberrytroupe.com/'>Website</Link> for summon, artifact and elements used data, as well as a reference with their forecast spreadsheet, and allowing links to their infographics. They also allowed their planner to be imported into FFOOtip's. Special shoutouts to Inkwelder for helping with the tuning of the planner and providing overall feedback.</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText>The group behind the <Link rel='noopener' href='https://docs.google.com/document/d/16uOOuBP9Rn85fX2cXNbseUnQry1b7oEQxOmTuaFbbU0/edit'>BIS artifact document</Link> for artifact and elements used data</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://www.reddit.com/user/Vash1306'>Vash1306</Link> and <Link rel='noopener' href='https://www.reddit.com/user/JakeMattAntonio/'>JakeMattAntonio</Link> visual forecasts as an influence on this project</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://github.com/domcoleman/'>Dom Coleman's</Link> <Link rel='noopener' href='https://ootracker.com/'>Opera Omnia Tracker</Link>as an inspiration for FFootip's tracker, as well as agreeing to the free import/export of data between our apps.</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://www.reddit.com/user/hearhellacopters'>hearhellacopters'</Link> <Link rel='noopener' href='https://dissidiacompendium.com/guide/'>Dissidia Compendium</Link> as a predecessors and influences on the FFOOtip tracker, as well as a source of data for the forecast, and allowing our collaberation.</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText><Link rel='noopener' href='https://www.reddit.com/user/vetheran'>Vetheran's</Link> <Link rel='noopener' href='https://www.reddit.com/r/DissidiaFFOO/comments/cv7syx/index_gl_f2p_gems_monthly_summaries_and/'>GL F2P spreadsheet</Link> as well as <Link rel='noopener' href='https://www.reddit.com/user/x2sDay/'>/u/x2sDay</Link>'s <Link rel='noopener' href='https://docs.google.com/spreadsheets/d/1r1W5cuLuj7dyWrREdPeBb3PGauRdVQpXRpyqStguVgw/'>Limited Rewards Chart</Link> as inspirations for the FFOOtip resources listing.</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText>The <Link rel='noopener' href='https://www.reddit.com/r/DissidiaFFOO'>subreddit</Link> and <Link rel='noopener' href='https://discordapp.com/invite/dffoo'>subreddit discord</Link> communities, as well as the welcoming DFFOO community at large for creating such a welcoming environment</ListItemText>
          </ListItem>
          <ListItem divider={true}>
            <ListItemText>Koei Tecmo and Square Enix for creating such a fun and generous game.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>My family and friends for all their support throughout.</ListItemText>
          </ListItem>
        </List>
      </Container>
    </Dialog>)
}

export default CreditsModal
