import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import { useTheme } from "@material-ui/core";

interface EnumPickerProps<T> {
  value: T
  onValueUpdated: (value: T) => void
  options: T[]
  valueToString: (value: T) => string
}

export const EnumPicker = <T, >({
  value,
  onValueUpdated,
  options,
  valueToString,
}: EnumPickerProps<T>) => {
  const theme = useTheme()

  const handleChange = (event: any, newValue: T | null) => {
    if (newValue !== null) {
      onValueUpdated(newValue)
    }
  }

  return (
    <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      disableClearable
      openOnFocus
      blurOnSelect
      forcePopupIcon={false}
      style={{ width: '175px', padding: theme.spacing(1) }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} variant='filled' margin='dense' />
      )}
      getOptionLabel={valueToString}
      onChange={handleChange}
      value={value === null ? undefined : value}
    />
  )
}
