import { createDefaultTierUtilization, TierUtilization, getCharacterIds } from "./tier-utilization"

export class TranscendenceTierUtilization {
  constructor(
    Left: TierUtilization,
    Right: TierUtilization,
    Center: TierUtilization,
  ) {
    this.Left = Left
    this.Right = Right
    this.Center = Center
  }
  Left: TierUtilization
  Right: TierUtilization
  Center: TierUtilization
  [key: string]: TierUtilization // really we mean TranscendenceGate though for the key type
}

export function createDefaultTranscendenceTierUtilization() {
  return new TranscendenceTierUtilization(
    createDefaultTierUtilization(),
    createDefaultTierUtilization(),
    createDefaultTierUtilization()
  )
}

export function getGateCharacterIds(transcendenceTierUtilization: TranscendenceTierUtilization) {
  return [...getCharacterIds(transcendenceTierUtilization.Left), ...getCharacterIds(transcendenceTierUtilization.Right)]
}
