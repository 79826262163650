import React, { FunctionComponent } from "react";
import { getActiveRegion } from "src/router/router-selectors";
import { AllCharacterData } from "src/tracker/model/components/all-character-data";
import { acquiredGroup } from "src/tracker/model/property/acquired/acquired-pseudo-group";
import { EnumerableCharacterProperty } from "src/tracker/model/property/enumerable-character-property";
import { PropertyGroup } from "src/tracker/model/property/property-group";
import Typography from '@material-ui/core/Typography'
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core";
import TrackerPickerEntry from "src/tracker/tracker-picker-entry/tracker-picker-entry";
import { CharacterData, generateDefaultCharacterData } from "src/tracker/model/tracker-model";
import { lte } from "lodash";
import { EditableCharacterProperty } from "src/tracker/model/property/editable-character-property";
import { DateTime } from 'luxon'

interface PropertyGroupsProps {
  propertyGroups: PropertyGroup[]
  character: AllCharacterData
  timeToEvaluateCharacterValues: DateTime
  editMode?: boolean
  previousAllocationCharacter?: AllCharacterData
  updateValueFunction?: (newCharacterData: CharacterData) => void
  editHeightLineSpacing?: boolean
}

const PropertyGroups: FunctionComponent<PropertyGroupsProps> = ({
  propertyGroups,
  character,
  timeToEvaluateCharacterValues,
  editMode = false,
  previousAllocationCharacter = new AllCharacterData(character.universal, generateDefaultCharacterData()),
  updateValueFunction = undefined,
  editHeightLineSpacing = false,
}) => {
  const theme = useTheme()
  const region = useSelector(getActiveRegion)

  const propertyGroupRenders = propertyGroups.map(propertyGroup => {
    const name = propertyGroup.name === acquiredGroup.name ? "" : propertyGroup.name

    const propertyItems = propertyGroup.group.map(property => {
      const lineName = property.name
      const lineValue = property.valueToString(property.get(region, character))
      var valueDisplay = <Typography variant='h6'>{lineValue}</Typography>
      if (property instanceof EnumerableCharacterProperty) {
        const options = property.options(character, timeToEvaluateCharacterValues, region)
        if (options.length <= 1) {
          return null
        }
        if (!!editMode && property instanceof EditableCharacterProperty && !!updateValueFunction) {
          const differenceOptions = options.filter(option => {
            return property.valueComparator(property.get(region, previousAllocationCharacter), option, lte)
          }).map(value => {
            return property.valueToString(value)
          })
          valueDisplay =
            <TrackerPickerEntry
              options={differenceOptions}
              width="75px"
              disabled={false}
              updateFunction={(newValue) => {
                const resolvedValue = property.valueFromString(newValue)
                const result = property.set(character.personal, resolvedValue)
                updateValueFunction(result)
              }}
              value={lineValue}
            />
        }
      }
      const resolvedHeight = editHeightLineSpacing ? '33px' : 'auto'
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: resolvedHeight, alignItems: 'center' }} key={lineName}>
          <Typography variant='h6'><b>{lineName}:</b></Typography>
          {valueDisplay}
        </div>
      )
    }).filter(jsx => !!jsx)

    return (
      <div key={name}>
        <Typography variant='h5' style={{ textAlign: 'center', padding: theme.spacing(2) }}>{name}</Typography>
        <div>
          {propertyItems}
        </div>
      </div>
    )

  })
  return <>
    {propertyGroupRenders}
  </>
}

export default PropertyGroups
