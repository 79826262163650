import { btPagesProperty } from "../../property/resource/bt-pages-property";
import { ResourceColumn } from "../planner-column";

export const btPagesColumn = new ResourceColumn(
  'BT Pages',
  'stl_limit_break_item_15_cropped.png',
  'btPages',
  btPagesProperty,
  undefined,
  'stl_limit_break_item_16_cropped.png',
  'btBooks',
)
