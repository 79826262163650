import React, {useCallback, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from 'src/store/root-reducer'
import {closeResetPasswordDialog} from 'src/login/login-actions'
import {resetPasswordWithEmailPassed} from 'src/login/login-effects'

export default function ResetPasswordDialog() {
  const open = useSelector((state: AppState) => state.login.resetPasswordDialogOpen)
  const dispatch = useDispatch()

  const [emailField, setEmailField] = useState("")

  const closeResetPasswordDialogCallback = useCallback(() => {
    dispatch(closeResetPasswordDialog())
  }, [dispatch])
  const submitPasswordResetEmailRequestCallback = useCallback(() => {
    dispatch(resetPasswordWithEmailPassed(emailField))
    dispatch(closeResetPasswordDialog())
  }, [dispatch, emailField])

  const changeEmailField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailField(event.target.value)
  }

  return (
    <Dialog open={open} onClose={closeResetPasswordDialogCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Send reset password email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the email address you registered with, and if an account exists, an email will be sent allowing the password to be reset.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          onChange={changeEmailField}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeResetPasswordDialogCallback} color="primary">
          Cancel
          </Button>
        <Button onClick={submitPasswordResetEmailRequestCallback} color="primary">
          Submit
          </Button>
      </DialogActions>
    </Dialog>
  )
}
