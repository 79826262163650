import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {exWeaponLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {ExWeaponLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const exProperty = new WeaponDataProperty(
  "e86ebc52-6328-4519-8757-0e5831db89e5",
  "EX",
  "EX",
  exWeaponLimitBreakConverter,
  "EX",
  WeaponType.EX,
  (characterData, when, region) => {
    const debutChecker = debutBeforeTime(region, characterData.universal, when)
    const exAppearsBeforeNow = debutChecker(CharacterDebut.EX)
    const exRealizationAppearsBeforeNow = debutChecker(CharacterDebut.EX_REALIZATION)
    const allExWeaponLimitBreakValues = getValues(ExWeaponLimitBreak) as ExWeaponLimitBreak[]
    if (exRealizationAppearsBeforeNow && exAppearsBeforeNow) {
      return allExWeaponLimitBreakValues
    } else if (exAppearsBeforeNow) {
      return allExWeaponLimitBreakValues.slice(0, 5)
    } else {
      return allExWeaponLimitBreakValues.slice(0, 1)
    }
  }
)
