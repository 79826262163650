import React, { FunctionComponent } from "react";
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import { useTheme } from "@material-ui/core";

interface CloseTopRightProps {
  closePanelCallback: () => void
}

const CloseTopRight: FunctionComponent<CloseTopRightProps> = ({
  closePanelCallback
}) => {
  const theme = useTheme()
  return <div style={{ display: 'flex', justifyContent: 'flex-end' }} data-testid='close'>
    <IconButton onClick={closePanelCallback} style={{ padding: theme.spacing(1), maxWidth: '32px' }} >
      <Close />
    </IconButton>
  </div>
}

export default CloseTopRight
