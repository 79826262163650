import { bloomProperty } from 'src/tracker/model/property/equipment/misc/bloom-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

export const bloomFragmentProperty = createStandardCharacterResourceProperty(
  '9fe55d56-c2c4-4b98-ae6c-46f7af70bbb9',
  'Bloom Fragments',
  'bloomFragments',
  bloomProperty,
  Resource.BLOOM_FRAGMENTS,
  new DailyResourceStats(5, 5),
)
