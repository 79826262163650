import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak, Resource} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const ntProperty = new WeaponDataProperty(
  "b452d74d-5499-427b-8ae5-d966745eccfc",
  "NT",
  "NT",
  standardLimitBreakConverter,
  "NT",
  WeaponType.NT,
  (universalCharacterData) => {
    const weaponBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (!!universalCharacterData.universal.availableWeapons.find(weaponType => WeaponType.NT === weaponType)) {
      return weaponBreakValues
    } else {
      return weaponBreakValues.slice(0, 1)
    }
  },
  [
    {resource: Resource.CP, valueToResource: (value) => {
      const valueLookupMap = {
        [StandardLimitBreak.UNOBTAINED]: 0,
        [StandardLimitBreak.ZERO]: 35,
        [StandardLimitBreak.ONE]: 40,
        [StandardLimitBreak.TWO]: 45,
        [StandardLimitBreak.THREE]: 50,
      }
      return valueLookupMap[value] || 0
    }}
  ]
)
