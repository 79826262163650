import { DateTransienceMode } from "./date-property"
import { EventAppearance } from "src/forecast/model/universal-data-model";
import { MiscDefinitionValues } from "./misc-definition-values";

export class DatePropertyEventDefinition {
  constructor(
    id: MiscDefinitionValues,
    name: string,
    dateTransienceMode: DateTransienceMode,
    eventAppearanceValuePredicate: (appearance: EventAppearance) => boolean,
  ) {
    this.id = id
    this.name = name
    this.dateTransienceMode = dateTransienceMode
    this.eventAppearanceValuePredicate = eventAppearanceValuePredicate
  }
  id: MiscDefinitionValues
  name: string
  dateTransienceMode: DateTransienceMode
  eventAppearanceValuePredicate: (appearance: EventAppearance) => boolean
}
