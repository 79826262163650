import React, {useCallback} from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import {useDispatch} from 'react-redux'
import {openCreditsActionCreator} from 'src/irl/irl-actions'
import {navigateTo} from 'src/router/router-effects'
import {CONTACT_US_PATH} from 'src/router/root-router'

export default function Disclaimer() {
  const dispatch = useDispatch()
  const dispatchNavigation = useCallback((path: string) => {
    dispatch(navigateTo(path))
  }, [dispatch])
  const openCreditsCallback = useCallback(() => {
    dispatch(openCreditsActionCreator())
  }, [dispatch])

  return (<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <Typography variant='body2' color='textPrimary' align='center'>This website has no official affiliation with Dissidia Final Fantasy: Opera Omnia or its creators. All assets property of Koei Tecmo and Square Enix. <Link onClick={openCreditsCallback}>Thanks to everyone who helped make this possible (click for credits)!</Link></Typography>
    <Typography variant='body2' align='center'><Link href="https://www.iubenda.com/privacy-policy/87718912/full-legal">See our privacy policy.</Link></Typography>
    <Typography variant='body2' align='center'><Link onClick={() => dispatchNavigation(CONTACT_US_PATH)}>Contact Us</Link></Typography>
  </div>)
}
