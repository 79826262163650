import {AppState} from 'src/store/root-reducer'
import {Banner, Character, Event, TimeRange, Uuid} from 'src/forecast/model/universal-data-model'
import {CharacterData, EntropyUtilization, defaultEntropyUtilization, Tracker, TrackerCharacterData, Resource, generateDefaultCharacterData, TranscendenceUtilization, createDefaultTranscendenceUtilization, TranscendenceGate, createDefaultTracker, instanceOfCharacterData, ExWeaponLimitBreak, StandardLimitBreak, BtWeaponLimitBreak, numberOfSetEntropyTiers} from 'src/tracker/model/tracker-model'
import {objectAssigningReducer} from 'src/forecast/model/universal-data-converter'
import {augmentDisplayableColumnGroups } from 'src/tracker/model/column/groups/group-of-groups'
import {convertToColumnGroup} from 'src/preferences/model/preferences-model-converters'
import {getCustomColumnGroups, getGreyAcquiredCharacters, getDefaultEditMode, getPreviewedTrackerData, isPreviewedTrackerData, getImportTrackerBreadcrumbDismissed, getDefaultSavedView, getSavedViews} from 'src/preferences/preferences-selectors'
import { standardGroup } from 'src/tracker/model/column/groups/standard-group'
import {getUniversalCharacterData, getEventTime, memoizedGetAllDeUtilizedCharacters, getDebutHappenedBeforeOrOnEvent, getBannersFromEvent} from 'src/forecast/forecast-selectors'
import {getUserId, isNotLoggedIn, getResolvedUser, viewingOthersData, isSpecifiedId} from 'src/login/login-selectors'
import { pullableGroup } from './model/property/equipment/weapon/weapon-group'
import { EditMode } from './tracker-reducer'
import { EnumerableCharacterProperty } from './model/property/enumerable-character-property'
import { lt, set } from 'lodash'
import { createDeepEqualsSelector } from 'src/utils/create-deep-equals-selector'
import { datesGroup } from './model/column/groups/dates-group'
import { executeSwap, TierUtilization } from './model/components/tier-utilization'
import { createDefaultTranscendenceTierUtilization, TranscendenceTierUtilization } from './model/components/transcendence-tier-utilization'
import { validateTranscendenceTier } from './model/transcendence-tier-validator'
import { validateTierUtilization } from './model/tier-utilization-validator'
import { CharacterTranscendenceUtilization } from './model/components/character-transcendence-utilization'
import { AllCharacterData } from './model/components/all-character-data'
import { DeUtilization } from './model/components/de-utilization'
import { Region } from './model/components/region'
import { getActiveRegion } from 'src/router/router-selectors'
import { panelDisplayableGroups } from 'src/tracker/model/property/group-of-groups'
import { EditableCharacterProperty } from './model/property/editable-character-property'
import { WeaponBadgesImagesToShow } from 'src/forecast/character-image/weapon-badges'
import { WeaponBadgeLevel } from 'src/forecast/character-image/weapon-badge'
import { exProperty } from './model/property/equipment/weapon/ex-property'
import { btProperty } from './model/property/equipment/weapon/bt-property'
import { ldProperty } from './model/property/equipment/weapon/ld-property'
import { valuesSet } from './model/tracker-model'
import { DateTime } from 'luxon'
import { frProperty } from './model/property/equipment/weapon/fr-property'
import { WeaponType } from 'src/forecast/model/weapon-type'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import { deserializeSortSpecifications } from './model/selector-model'

export const TRACKER_COLLECTION_NAME = "tracker"

export function getTrackerDocumentLocation(userId: string | undefined) {
  if (!userId) {
    return undefined
  }
  return `${TRACKER_COLLECTION_NAME}/${userId}`
}

export function getTrackerDocument(state: AppState, useResolvedUser: boolean = true, usePreviewedData: boolean = true): Tracker {
  const previewedTrackerData = getPreviewedTrackerData(state)
  if (!!usePreviewedData && previewedTrackerData !== null) {
    return previewedTrackerData
  }
  const resolvedUser = useResolvedUser ? getResolvedUser(state) : getUserId(state)
  if (!resolvedUser) {
    return createDefaultTracker()
  }
  return state.firestore.data?.[TRACKER_COLLECTION_NAME]?.[resolvedUser] || createDefaultTracker()
}

export function getAllCharactersMap(state: AppState) {
  const region = getActiveRegion(state)
  return (getTrackerDocument(state)?.[region]?.characters || {}) as TrackerCharacterData
}

export const getCharacterData = (characterId: Uuid) => (state: AppState) => {
  return getAllCharactersMap(state)?.[characterId] || generateDefaultCharacterData()
}

export function getEntropyData(state: AppState) {
  const region = getActiveRegion(state)
  const trackerDocument = getTrackerDocument(state)
  const result = trackerDocument?.[region]?.utilization?.Entropy
  return Object.assign({}, defaultEntropyUtilization, result)
}

export function getTranscendenceData(state: AppState) {
  const region = getActiveRegion(state)
  const trackerDocument = getTrackerDocument(state)
  const result = trackerDocument?.[region]?.utilization?.Transcendence
  return Object.assign({}, createDefaultTranscendenceUtilization(), result)
}

export function getSharedData(state: AppState) {
  return getTrackerDocument(state, false)?.shared || false
}

export function createDocumentMergableTrackerCharacterData(data: TrackerCharacterData, region: Region) {
  return {[region]: {"characters": data }} as Partial<Tracker>
}

export function createDocumentMergableCharacterData(region: Region, idDataPairs: [Uuid, CharacterData][]) {
  const result = {[region]: {"characters": {}}} as Partial<Tracker>
  idDataPairs.forEach(([id, data]) => {
    set(result, `${region}.characters.${id}`, data)
  })
  return result
}

export function createDocumentMergableEntropyDataFromCompoments(state: AppState, region: Region, entropyTier: string, tierUtilizationPropertyName: string, id: Uuid | null) {
  const previousTierUtilization = getEntropyData(state)[entropyTier]
  const requestedTierUtilization = executeSwap(previousTierUtilization, tierUtilizationPropertyName, id)
  const deUtilization = memoizedGetAllDeUtilizedCharacters()(state)
  const usedIds = filterDeUtilizationMapForEntropyUsedIds(deUtilization)
  const validatedTierUtilization = validateTierUtilization(requestedTierUtilization, previousTierUtilization, usedIds)
  return {[region]: {"utilization": { "Entropy": { [entropyTier]: validatedTierUtilization}}}} as Partial<Tracker>
}

export function createDocumentMergableTranscendenceData(state: AppState, region: Region, transcendenceTier: string, gate: TranscendenceGate, tierUtilizationPropertyName: string, id: Uuid | null) {
  const gateName = TranscendenceGate[gate]
  const previousTranscendenceTierUtilization = getTranscendenceData(state)[transcendenceTier]
  const previousTierUtilization = previousTranscendenceTierUtilization[gateName]
  const requestedTierUtilization = executeSwap(previousTierUtilization, tierUtilizationPropertyName, id)
  const requestedTranscendenceTierUtilization = Object.assign({}, createDefaultTranscendenceTierUtilization(), previousTranscendenceTierUtilization, { [gateName]: requestedTierUtilization}) as TranscendenceTierUtilization
  const deUtilization = memoizedGetAllDeUtilizedCharacters()(state)
  const usedIds = filterDeUtilizationMapForTranscendenceUsedIds(deUtilization)
  const validatedTrascendenceTierUtilization = validateTranscendenceTier(requestedTranscendenceTierUtilization, previousTranscendenceTierUtilization, usedIds)
  return {[region]: {"utilization": { "Transcendence": { [transcendenceTier]: validatedTrascendenceTierUtilization}}}} as Partial<Tracker>
}

export function createDocumentMergableSharedData(shared: boolean) {
  return {shared: shared} as Partial<Tracker>
}

function getTiersFromTierUtilization<T>(tierUtilization: TierUtilization, payload: T) {
  const result = {} as Record<Uuid, T>
  if (!!tierUtilization.character1) {
    result[tierUtilization.character1] = payload
  }
  if (!!tierUtilization.character2) {
    result[tierUtilization.character2] = payload
  }
  if (!!tierUtilization.character3) {
    result[tierUtilization.character3] = payload
  }
  return result
}

export const createGetAllDeUtilizedCharacters = () => createDeepEqualsSelector(
  (state: AppState) => getEntropyData(state),
  (state: AppState) => getTranscendenceData(state),
  (utilizationData: EntropyUtilization, transcendenceData: TranscendenceUtilization) => {
    const entropyLookup = Object.keys(utilizationData).map(tierString => {
      const tierUtilization = utilizationData[tierString]
      const tierNumber = parseInt(tierString, 10)
      return getTiersFromTierUtilization(tierUtilization, tierNumber)
    }).reduce(objectAssigningReducer, {})

    const transcendenceLookup = Object.keys(transcendenceData).map(tierString => {
      const entropyTierUtilization = transcendenceData[tierString]
      const tierNumber = parseInt(tierString, 10)
      const result = {} as Record<Uuid, CharacterTranscendenceUtilization>
      // Center is processed first so it overrides the others
      [TranscendenceGate.Center, TranscendenceGate.Left, TranscendenceGate.Right].forEach(gate => {
        const gateName = TranscendenceGate[gate]
        const tierUtilization = entropyTierUtilization[gateName]
        const tierUtilizationValues = getTiersFromTierUtilization(tierUtilization, new CharacterTranscendenceUtilization(tierNumber, gate))
        Object.keys(tierUtilizationValues).forEach(id => {
          if (!result[id]) {
            result[id] = tierUtilizationValues[id]
          }
        })
      })
      return result
    }).reduce(objectAssigningReducer, {})

    return Array.from(new Set([...Object.keys(entropyLookup), ...Object.keys(transcendenceLookup)])).map(id =>{
      const entropyValue = entropyLookup[id]
      const transcendenceValue = transcendenceLookup[id]
      return {[id]: new DeUtilization(entropyValue, transcendenceValue)}
    }).reduce(objectAssigningReducer, {}) as Record<Uuid, DeUtilization>
  }
)

export function getDisplayEntropy(state: AppState) {
  return state.tracker.displayEntropy
}

export function getGroupedMode(state: AppState) {
  return state.tracker.groupedMode
}

export function getSortSpecification(state: AppState) {
  //return state.tracker.sortSpecification
  const sortSpecificationSet = state.tracker.sortSpecificationsSet
  if (sortSpecificationSet) {
    return state.tracker.sortSpecification
  } else {
    const defaultSavedViewId = getDefaultSavedView(state)
    if (defaultSavedViewId === undefined) {
      return []
    }
    const savedViews = getSavedViews(state)
    const defaultSavedView = savedViews.find(savedView => savedView.id === defaultSavedViewId)
    if (defaultSavedView === undefined) {
      return []
    }
    return deserializeSortSpecifications(defaultSavedView.sortSpecifications)
  }
}

export function getColumnGroups(state: AppState) {
  const customColumnGroups = getCustomColumnGroups(state)
  const customColumnGroupsConverted = customColumnGroups.map(convertToColumnGroup)
  return augmentDisplayableColumnGroups(...customColumnGroupsConverted)
}

export function getSelectedColumnGroup(state: AppState) {
  const columnGroupId = state.tracker.columnGroupId
  const allColumnGroups = getColumnGroups(state)
  const resolvedUser = getResolvedUser(state)
  const defaultGroup = !!resolvedUser ? standardGroup : datesGroup
  return allColumnGroups.find(columnGroup => columnGroup.id === columnGroupId) || defaultGroup
}

export function isEditable(state: AppState) {
  if (!isEditModeToggleable(state)) {
    return false
  }
  switch (state.tracker.editMode) {
    case EditMode.READ_ONLY:
      return false
    case EditMode.EDITABLE:
      return true
    case EditMode.UNSET:
    default:
      return getDefaultEditMode(state)
  }
}

export const isEditModeToggleable = (state: AppState) => {
  return !(viewingOthersData(state) || isNotLoggedIn(state))
}

export function getFluffyMoment(time: DateTime) {
  return time.plus({minute: 1})
}

export function greyCharacterImage(state: AppState, characterId: Uuid, event: Event, banner: Banner) {
  const region = getActiveRegion(state)
  const characterUniversalData = getUniversalCharacterData(characterId)(state)
  const characterUserData = getCharacterData(characterId)(state)
  const unifiedData = new AllCharacterData(characterUniversalData, characterUserData, undefined)
  const containedWeaponTypes = banner.weapons
    .filter(weapon => weapon.character.id === characterId)
    .map(weapon => weapon.type)
  const relevantProperties = pullableGroup.filter(property => !!containedWeaponTypes.find(weaponType => weaponType === property.weaponType))
  const fluffyMoment = getFluffyMoment(getEventTime(region, event).start)

  function acquiredPredicate(property: EnumerableCharacterProperty<any>) {
    const minVal = property.getMin(unifiedData, fluffyMoment, region)
    const val = property.get(region, unifiedData)
    return property.valueComparator(minVal, val, lt)
  }

  function maxedPredicate(property: EnumerableCharacterProperty<any>) {
    const maxVal = property.getMax(unifiedData, fluffyMoment, region)
    const minVal = property.getMin(unifiedData, fluffyMoment, region)
    const currentVal = property.get(region, unifiedData)
    const currentStones = property.valueToResource(currentVal, Resource.POWER_STONE)
    const maxStones = property.valueToResource(maxVal, Resource.POWER_STONE)
    return maxStones - currentStones <= 0 && currentVal !== minVal
  }

  switch (getGreyAcquiredCharacters(state)) {
    case "Acquired":
    default:
      return relevantProperties
        .every(acquiredPredicate)
    case "Maxed":
      return relevantProperties
        .every(maxedPredicate)
    case "Never":
      return false
  }
}

export function getStoredCharacterId(state: AppState) {
  return state.tracker.storedCharacterId
}

export function getStoredCharacterData(state: AppState) {
  return state.tracker.storedCharacterData
}

export function getStoredTrackerCharacterData(state: AppState) {
  return state.tracker.storedTrackerCharacterData
}

export function filterDeUtilizationMapForEntropyUsedIds(deUtilizationMap: Record<Uuid, DeUtilization>) {
  return Object.keys(deUtilizationMap).filter(id => {
    return !!deUtilizationMap[id]?.entropyUtilization
  })
}

export function filterDeUtilizationMapForTranscendenceUsedIds(deUtilizationMap: Record<Uuid, DeUtilization>) {
  return Object.keys(deUtilizationMap).filter(id => {
    return !!deUtilizationMap[id]?.transcendenceUtilization
  })
}

export function getAllCharactersForEvent(event: Event, region: Region) {
  return Array.from(new Set(getBannersFromEvent(event, region).flatMap(banner => banner.weapons)
    .map(weapon => weapon.character)))
}

export function maxGivenCharacter(universalCharacterData: Character, region: Region, when: DateTime = DateTime.utc()) {
  var outputData = {} as CharacterData
  panelDisplayableGroups.flatMap(propertyGroup => propertyGroup.group)
    .filter((property) => property instanceof EditableCharacterProperty)
    .map((property) => property as EditableCharacterProperty<any>)
    .forEach(property => {
      const maxOption = property.getMax(new AllCharacterData(universalCharacterData), when, region)
      outputData = property.set(outputData, maxOption)
    })
  return outputData
}

export const getWeaponBadgesFromAdditionalInfo = (additionalCharacterInfo: [Event, (Banner | undefined)] | CharacterData | undefined, region: Region, character: Character | undefined, eventTimeOfFr: TimeRange) => {
  const defaultReturn = new WeaponBadgesImagesToShow(
    WeaponBadgeLevel.NONE,
    false,
    WeaponBadgeLevel.NONE,
    false,
  )
  if (additionalCharacterInfo === undefined) {
    return defaultReturn
  } else if (instanceOfCharacterData(additionalCharacterInfo)) {
    const exValue = exProperty.getFromCharacterData(additionalCharacterInfo)
    const pagesUsed = exProperty.valueToResource(exValue, Resource.WEAPON_PAGES)
    const btValue = btProperty.getFromCharacterData(additionalCharacterInfo)
    const btPagesUsed = btProperty.valueToResource(btValue, Resource.BT_PAGES)
    const ldValue = ldProperty.getFromCharacterData(additionalCharacterInfo)
    const frValue = frProperty.getFromCharacterData(additionalCharacterInfo)
    return new WeaponBadgesImagesToShow(
      (pagesUsed > 0) ? WeaponBadgeLevel.PLUS : ((exValue > ExWeaponLimitBreak.UNOBTAINED) ? WeaponBadgeLevel.NORMAL : WeaponBadgeLevel.NONE),
      ldValue > StandardLimitBreak.UNOBTAINED,
      (btPagesUsed > 0) ? WeaponBadgeLevel.PLUS : ((btValue > BtWeaponLimitBreak.UNOBTAINED) ? WeaponBadgeLevel.NORMAL : WeaponBadgeLevel.NONE),
      frValue > StandardLimitBreak.UNOBTAINED,
    )
  } else {
    if (character === undefined) {
      return defaultReturn
    }
    const event = additionalCharacterInfo[0]
    const exPlus = getDebutHappenedBeforeOrOnEvent(region, character, event.id, CharacterDebut.EX_REALIZATION)
    const btPlus = getDebutHappenedBeforeOrOnEvent(region, character, event.id, CharacterDebut.BT_REALIZATION)
    const weapons = getBannersFromEvent(event, region)
      .filter(banner => {
        const bannerId = additionalCharacterInfo[1]?.id
        if (bannerId === undefined) {
          return true
        }
        return banner.id === bannerId
      })
      .flatMap(banner => banner.weapons)
    const exContained = weapons.filter(weapon => weapon.character.id === character.id && weapon.type === WeaponType.EX).length > 0
    const ldContained = weapons.filter(weapon => weapon.character.id === character.id && weapon.type === WeaponType.LD).length > 0
    const btContained = weapons.filter(weapon => weapon.character.id === character.id && weapon.type === WeaponType.BT).length > 0
    const frContained = weapons.filter(weapon => weapon.character.id === character.id && weapon.type === WeaponType.FR).length > 0
    const frDebuted = eventTimeOfFr.start.toMillis() <= getEventTime(region, event).start.toMillis()
    const exValueToShow = frDebuted || !exContained ? WeaponBadgeLevel.NONE :
      (exPlus ? WeaponBadgeLevel.PLUS : WeaponBadgeLevel.NORMAL)
    return new WeaponBadgesImagesToShow(
      exValueToShow,
      ldContained,
      (btPlus && btContained) ? WeaponBadgeLevel.PLUS : ((btContained) ? WeaponBadgeLevel.NORMAL : WeaponBadgeLevel.NONE),
      frContained,
    )
  }
}

export const createShouldShowImportBreadcrumb = () => {
  return createDeepEqualsSelector(
    (state: AppState) => getTrackerDocument(state),
    (state: AppState) => isPreviewedTrackerData(state),
    (state: AppState) => isNotLoggedIn(state),
    (state: AppState) => isSpecifiedId(state),
    (state: AppState) => getImportTrackerBreadcrumbDismissed(state),
    (tracker: Tracker, isPreviewedData: boolean, isNotLoggedIn: boolean, isSpecifiedId: boolean, importBreadcrumbDismissed: boolean) => {
      if (isPreviewedData || isNotLoggedIn || isSpecifiedId || importBreadcrumbDismissed) {
        return false
      }

      const glCharacterValuesChanged = !tracker?.GL?.characters ? 0 : Object.values(tracker.GL.characters).map(valuesSet).reduce((a, b) => a + b, 0)
      const jpCharacterValuesChanged = !tracker?.JP?.characters ? 0 : Object.values(tracker.JP.characters).map(valuesSet).reduce((a, b) => a + b, 0)
      const entropyTiersSetGl = !tracker?.GL?.utilization?.Entropy ? 0 : numberOfSetEntropyTiers(tracker.GL.utilization.Entropy)
      const entropyTiersSetJp = !tracker?.JP?.utilization?.Entropy ? 0 : numberOfSetEntropyTiers(tracker.JP.utilization.Entropy)
      const entropyTierWeight = 4 // assume 4 actions are done per entropy tier
      const calculatedValue = glCharacterValuesChanged + jpCharacterValuesChanged + (entropyTiersSetGl + entropyTiersSetJp) * entropyTierWeight
      return calculatedValue <= 50
    }
  )
}
