import { Planner } from "src/planner/model/planner-model"
import { Tracker } from "src/tracker/model/tracker-model"

export const WRITE_PREFERENCES_DATA_FAILURE = "WRITE_PREFERENCES_DATA_FAILURE"
export const SET_PREVIEWED_TRACKER_DATA = "SET_PREVIEWED_TRACKER_DATA"
export const SET_PREVIEWED_PLANNER_DATA = "SET_PREVIEWED_PLANNER_DATA"

export function writePreferencesDataFailureActionCreator(reason: string) {
  return {
    type: WRITE_PREFERENCES_DATA_FAILURE,
    reason: reason,
  }
}

export function setPreviewedTrackerDataActionCreator(tracker: Tracker | null) {
  return {
    type: SET_PREVIEWED_TRACKER_DATA,
    tracker
  }
}

export function setPreviewedPlannerDataActionCreator(planner: Planner | null) {
  return {
    type: SET_PREVIEWED_PLANNER_DATA,
    planner
  }
}

export type WritePreferencesDataFailureAction = ReturnType<typeof writePreferencesDataFailureActionCreator>
export type SetPreviewedTrackerDataAction = ReturnType<typeof setPreviewedTrackerDataActionCreator>
export type SetPreviewedPlannerDataAction = ReturnType<typeof setPreviewedPlannerDataActionCreator>
