import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import DocumentLoadingComponent from 'src/utils/document-loading-component'
import {getUserId} from 'src/login/login-selectors'
import {getSharedData, getTrackerDocumentLocation} from 'src/tracker/tracker-selectors'
import {writeSharableData} from 'src/tracker/tracker-effects'
import Theme from 'src/theme'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import IconButton from '@material-ui/core/IconButton'
import Assignment from '@material-ui/icons/Assignment'
import { SHARED_ID_ARG } from 'src/router/root-router'
import { getPlannerDocumentLocation, getSharedDataPlanner } from 'src/planner/planner-selectors'

function ShareUserDataComponent() {
  const userId = useSelector(getUserId)
  const shareableUrl = `https://ffootip.com/tracker?${SHARED_ID_ARG}=${userId}`
  const shareableUrl2 = `https://ffootip.com/planner?${SHARED_ID_ARG}=${userId}`

  const sharedDataTrackerEnabled = useSelector(getSharedData)
  const sharedDataPlannerEnabled = useSelector(getSharedDataPlanner)
  const sharedDataEnabled = sharedDataPlannerEnabled && sharedDataTrackerEnabled

  const dispatch = useDispatch()
  const shareableCallback = useCallback((shareable: boolean) => {
    dispatch(writeSharableData(shareable))
  }, [dispatch])

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    shareableCallback(event.target.checked)
  };

  return (
    <DocumentLoadingComponent getDocumentLocations={[getTrackerDocumentLocation, getPlannerDocumentLocation]}>
      <div>
        <FormControlLabel
          control={
            <Switch
              name="Share user data"
              color="primary"
              checked={sharedDataEnabled}
              onChange={handleToggleChange}
            />
          }
          label="Share user data for both your tracker and planner"
          style={{ paddingInline: Theme.spacing(2) }}
        />
        {sharedDataEnabled &&
          <Typography>
            Your shareable link is: {shareableUrl}
            <IconButton onClick={() => navigator.clipboard.writeText(shareableUrl)}>
              <Assignment />
            </IconButton>
            or {shareableUrl2}
            <IconButton onClick={() => navigator.clipboard.writeText(shareableUrl2)}>
              <Assignment />
            </IconButton>
          </Typography>
        }
      </div>
    </DocumentLoadingComponent>
  )
}

export const ShareUserDataWidget = new PreferencesWidget(
  <ShareUserDataComponent />,
  "Share user data",
)
