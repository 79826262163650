import { EnumerableCharacterProperty } from '../enumerable-character-property'
import { CharacterValueConverter } from 'src/tracker/model/tracker-model-converter'

const stringConverter = new CharacterValueConverter<string>(
  (value) => value,
  (value) => value,
)

export const nameProperty = new EnumerableCharacterProperty<string>(
  "84e56177-531d-495f-ab1e-a2e39274b966",
  "Name",
  "Name",
  stringConverter,
  (region, character) => {
    return character?.universal.name || ""
  },
  () => {
    return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  }
)
