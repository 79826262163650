import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak, Resource} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const silverWeaponProperty = new WeaponDataProperty(
  "06f20b92-83a2-43b8-931d-7efa908429d1",
  "Silver Weapon",
  "4* Weapon",
  standardLimitBreakConverter,
  "Silver Weapon",
  WeaponType.SILVER,
  (universalCharacterData) => {
    const weaponBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (!!universalCharacterData.universal.availableWeapons.find(weaponType => WeaponType.SILVER === weaponType)) {
      return weaponBreakValues
    } else {
      return weaponBreakValues.slice(0, 1)
    }
  },
  [
    {resource: Resource.CP, valueToResource: (value) => {
      const valueLookupMap = {
        [StandardLimitBreak.UNOBTAINED]: 0,
        [StandardLimitBreak.ZERO]: 10,
        [StandardLimitBreak.ONE]: 12,
        [StandardLimitBreak.TWO]: 15,
        [StandardLimitBreak.THREE]: 20,
      }
      return valueLookupMap[value] || 0
    }}
  ]
)
