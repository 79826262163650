import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import { getHighlightMaxed, getShowFutureCharacters } from 'src/preferences/preferences-selectors'
import { writeHighlightMaxed, writeShowFutureCharacters } from 'src/preferences/preferences-effects'
import generateToggle from 'src/preferences/widgets/generate-toggle'

const TITLE = "Miscellaneous tracker toggles"

function TrackerTogglesComponent() {
  const highlightMaxed = useSelector(getHighlightMaxed)
  const showFutureCharacters = useSelector(getShowFutureCharacters)

  const dispatch = useDispatch()

  const highlightMaxedCallback = useCallback((highlightMax: boolean) => {
    dispatch(writeHighlightMaxed(highlightMax))
  }, [dispatch])
  const handleHighlightMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    highlightMaxedCallback(event.target.checked)
  }

  const showFutureCharactersCallback = useCallback((showFutureCharacters: boolean) => {
    dispatch(writeShowFutureCharacters(showFutureCharacters))
  }, [dispatch])
  const handleShowFutureCharactersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    showFutureCharactersCallback(event.target.checked)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {generateToggle("Highlight maxed: when on, highlights maxed cells for characters, and if all maxable cells are highlighted, the whole row will be. Off means it will always be grey.",
        highlightMaxed, handleHighlightMaxChange)}
      {generateToggle("Show future characters: when on, future characters will be visible in the tracker. Off means future characters will be hidden",
        showFutureCharacters, handleShowFutureCharactersChange)}
    </div>
  )
}

export const TrackerTogglesWidget = new PreferencesWidget(
  <TrackerTogglesComponent />,
  TITLE,
)
