import {forecastReducer} from 'src/forecast/forecast-reducer'
import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import {characterDetailsReducer} from 'src/character-details/character-details-reducer'
import { imageReducer, imageActionSanitizer, imageStateSanitizer } from 'src/image-cache/image-reducer';
import {irlReducer} from 'src/irl/irl-reducer'
import firebase from 'firebase/app'
import 'firebase/firestore'
import firebaseConfig from 'src/firebase-config'
import { firebaseReducer, getFirebase } from 'react-redux-firebase'
import {createBrowserHistory} from 'history'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {firestoreReducer} from 'redux-firestore'
import trackerReducer from 'src/tracker/tracker-reducer'
import loginReducer from 'src/login/login-reducer'
import alertReducer from 'src/alert/alert-reducer'
import { eventDetailsReducer } from 'src/event-details/event-details-reducer';
import { navigationDrawerReducer } from 'src/navigation-drawer/navigation-drawer-reducer';
import PlannerReducer from 'src/planner/planner-reducer';
import { preferencesReducer } from 'src/preferences/preferences-reducer';

firebase.initializeApp(firebaseConfig)
firebase.firestore()

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  forecast: forecastReducer,
  characterDetails: characterDetailsReducer,
  assets: imageReducer,
  irl: irlReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  router: connectRouter(history),
  tracker: trackerReducer,
  login: loginReducer,
  alert: alertReducer,
  eventDetails: eventDetailsReducer,
  navigationDrawer: navigationDrawerReducer,
  planner: PlannerReducer,
  preferences: preferencesReducer,
})

const composeEnhancers = composeWithDevTools({
  actionSanitizer: imageActionSanitizer as any,
  stateSanitizer: imageStateSanitizer as any,
})
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(
  routerMiddleware(history),
  thunk.withExtraArgument(getFirebase))))

export type AppState = ReturnType<typeof rootReducer>

export default store
