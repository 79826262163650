import { Resource } from 'src/tracker/model/tracker-model'
import { BannerResourceProperty } from '../resource-meta/banner-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

export const ticketProperty = new BannerResourceProperty(
  '18507af8-8822-4794-ae85-baf105193aea',
  'Tickets',
  'tickets',
  Resource.TICKETS,
  true,
  new DailyResourceStats(1, 1),
)
