import React  from 'react'
import DocumentLoadingComponent from 'src/utils/document-loading-component'
import {CustomColumnsWidget} from 'src/preferences/widgets/tracker/custom-columns-component';
import Disclaimer from 'src/irl/disclaimer'
import {getPreferencesDocumentLocation} from 'src/preferences/preferences-selectors'
import Paper from '@material-ui/core/Paper';
import { PREFERENCES_PATH } from 'src/router/root-router'
import NavigationTabs, {NavigationTab} from 'src/topbar/navigation-tabs/navigation-tabs'
import Event from '@material-ui/icons/Event'
import GridOn from '@material-ui/icons/GridOn'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import AttachMoney from '@material-ui/icons/AttachMoney'
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import {AppState, history} from 'src/store/root-reducer'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import {GreyAcquiredCharactersWidget} from 'src/preferences/widgets/forecast/grey-acquired-characters-component'
import PreferencesCard from 'src/preferences/preferences-card/preferences-card'
import { TrackerTogglesWidget } from './widgets/tracker/tracker-toggles-component';
import { DisplayDetailedEstimatesWidget } from './widgets/general/display-detailed-estimates-component';
import { ForecastTogglesWidget } from './widgets/forecast/forecast-toggles-component';
import { useSelector } from 'react-redux';
import { getPathAndQueryForNewPath } from 'src/router/router-selectors';
import { GeneralTogglesWidget } from './widgets/general/general-toggles-component';
import { ShareUserDataWidget } from './widgets/general/share-user-data-component';
import { PlannerTogglesWidget } from './widgets/planner/planner-toggles-component';
import { ImportDataWidget } from './widgets/tracker/import-data-component';
import { ImportPlannerDataWidget } from './widgets/planner/import-planner-data-component';
import { PlannerCustomColumnsWidget } from './widgets/planner/planner-custom-columns-component';

export const PREFERENCES_TRACKER_TAB = `${PREFERENCES_PATH}/tracker`
export const PREFERENCES_PLANNER_TAB = `${PREFERENCES_PATH}/planner`

export default function PreferencesComponent() {
  const PREFERENCES_GENERAL_TAB = `${PREFERENCES_PATH}/general`
  const PREFERENCES_FORECAST_TAB = `${PREFERENCES_PATH}/forecast`

  const redirectRootPath = useSelector((state: AppState) => getPathAndQueryForNewPath(state, PREFERENCES_GENERAL_TAB))

  const generalComponents = [DisplayDetailedEstimatesWidget, ShareUserDataWidget, GeneralTogglesWidget]
  const forecastComponents = [GreyAcquiredCharactersWidget, ForecastTogglesWidget]
  const trackerComponents = [CustomColumnsWidget, TrackerTogglesWidget, ImportDataWidget]
  const plannerComponents = [PlannerTogglesWidget, ImportPlannerDataWidget, PlannerCustomColumnsWidget]

  const renderedPreferenceWidgets = (widgets: PreferencesWidget[]) => widgets.map(widget => {
    return (
      <PreferencesCard widget={widget} key={widget.title}/>
    )
  })

  const navigationTabList = [
    new NavigationTab(PREFERENCES_GENERAL_TAB, "General", <AllInclusiveIcon/>),
    new NavigationTab(PREFERENCES_FORECAST_TAB, "Forecast", <Event/>),
    new NavigationTab(PREFERENCES_TRACKER_TAB, "Tracker", <GridOn/>),
    new NavigationTab(PREFERENCES_PLANNER_TAB, "Planner", <AttachMoney />)
  ]

  const preferencesNav = (
    <Paper>
      <NavigationTabs tabs={navigationTabList}/>
    </Paper>
  )

  return (
    <DocumentLoadingComponent getDocumentLocations={[getPreferencesDocumentLocation]}>
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: 0 }}>
          {preferencesNav}
        </div>
        <div style={{ flexGrow: 1, height: '100%', overflowY: 'auto' }}>
          <ConnectedRouter history={history} >
            <Switch>
              <Route exact path={[PREFERENCES_PATH]}>
                <Redirect to={redirectRootPath} />
              </Route>
              <Route exact path={[PREFERENCES_GENERAL_TAB]}>
                {renderedPreferenceWidgets(generalComponents)}
              </Route>
              <Route exact path={[PREFERENCES_FORECAST_TAB]}>
                {renderedPreferenceWidgets(forecastComponents)}
              </Route>
              <Route exact path={[PREFERENCES_TRACKER_TAB]}>
                {renderedPreferenceWidgets(trackerComponents)}
              </Route>
              <Route exact path={[PREFERENCES_PLANNER_TAB]}>
                {renderedPreferenceWidgets(plannerComponents)}
              </Route>
            </Switch>
          </ConnectedRouter>
          <Disclaimer />
        </div>
      </div>
    </DocumentLoadingComponent>
  )
}
