import { forceStoneShardProperty } from "../../property/resource/force-stone-shard-property";
import { ResourceColumn } from "../planner-column";

export const forceStoneShardsColumn = new ResourceColumn(
  'Force Stone Shards',
  'stl_link_powerup_item_1_cropped.png',
  'forceStoneShards',
  forceStoneShardProperty,
  undefined,
  'stl_link_powerup_item_2_cropped.png',
  'forceStones',
)
