import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import {defaultCharacterDataNumberConverter} from 'src/tracker/model/tracker-model-converter'
import { Resource } from '../../tracker-model'
import { EditableCharacterProperty } from '../editable-character-property'

function getMaxLevel(awakened80: boolean, awakened90: boolean) {
  if (awakened90) {
    return 90
  }
  if (awakened80) {
    return 80
  }
  return 70
}

export const crystalLevelProperty = new EditableCharacterProperty(
  "c133dcb7-80c2-41e1-9c42-23b1b7fb7a09",
  "Crystal Level",
  "C. Level",
  defaultCharacterDataNumberConverter,
  "Crystal Level",
  (character, when, region) => {
    const debutChecker = debutBeforeTime(region, character.universal, when)
    const awakening80AppearsBeforeNow = debutChecker(CharacterDebut.AWAKENING_80)
    const awakening90AppearsBeforeNow = debutChecker(CharacterDebut.AWAKENING_90)
    const maxLevel = getMaxLevel(awakening80AppearsBeforeNow, awakening90AppearsBeforeNow)
    return Array.from(new Array(maxLevel + 1), (x,i) => i)
  },
  [
    {resource: Resource.CP, valueToResource: (value) => {
      var cp = 0
      if (value >= 83) {
        cp += 40
      }
      if (value >= 73) {
        cp += 30
      }
      if (value >= 63) {
        cp += 20
      }
      if (value >= 53) {
        cp += 50
      }
      return cp
    }}
  ]
)
