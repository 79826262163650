import React, {useCallback} from 'react'
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Theme from 'src/theme'
import {useDispatch} from 'react-redux'
import {navigateTo, removeSharedId} from 'src/router/router-effects'
import {useSelector} from 'react-redux'
import { isPreviewedPlannerData, isPreviewedTrackerData } from 'src/preferences/preferences-selectors';
import { PREFERENCES_PLANNER_TAB, PREFERENCES_TRACKER_TAB } from '../../preferences/preferences-component';
import { viewingOthersData } from 'src/login/login-selectors';

export default function AlertBar() {
  const areViewingOthersData = useSelector(viewingOthersData)
  const previewingTrackerData = useSelector(isPreviewedTrackerData)
  const previewingPlannerData = useSelector(isPreviewedPlannerData)
  const dispatch = useDispatch()
  const removeSharedIdCallback = useCallback(() => {
    dispatch(removeSharedId())
  }, [dispatch])
  const returnToPreferencesCallback = useCallback((preferencesTabPath: string) => () => {
    dispatch(navigateTo(preferencesTabPath))
  }, [dispatch])

  var alertContents = [] as JSX.Element[]
  if (previewingTrackerData) {
    alertContents.push(<Typography style={{ margin: Theme.spacing(2) }} key='importTracker'>You are previewing imported tracker data. <Link onClick={returnToPreferencesCallback(PREFERENCES_TRACKER_TAB)}>When you are finished, click here to return to preferences to save or discard.</Link></Typography>)
  }
  if (previewingPlannerData) {
    alertContents.push(<Typography style={{ margin: Theme.spacing(2) }} key='importPlanner'>You are previewing imported planner data. <Link onClick={returnToPreferencesCallback(PREFERENCES_PLANNER_TAB)}>When you are finished, click here to return to preferences to save or discard.</Link></Typography>)
  }
  if (alertContents.length === 0 && areViewingOthersData) {
    alertContents.push(<Typography style={{margin: Theme.spacing(2)}} key='viewingOthers'>You are viewing another person's data. <Link onClick={removeSharedIdCallback}>Click here to return to your data.</Link></Typography>)
  }

  if (alertContents.length === 0) {
    return null
  }

  return (
    <div style={{ position: 'sticky', display: 'flex', alignItems: 'center', backgroundColor: Theme.palette.primary.light, flexDirection: 'column'}}>
      {alertContents}
    </div>
  )
}
