import { Series } from 'src/forecast/model/universal-data-model'
import { EnumerableCharacterProperty } from '../enumerable-character-property'
import { CharacterValueConverter } from 'src/tracker/model/tracker-model-converter'
import { getValues } from 'src/utils/enum-values'
import { eq } from 'lodash'

export class SeriesValue {
  constructor(
    series: Series,
    orderInSeries: number,
  ) {
    this.series = series
    this.orderInSeries = orderInSeries
  }
  series: Series
  orderInSeries: number
}

const filterOrderInSeries = -1

const seriesValueConverter = new CharacterValueConverter<SeriesValue>(
  (value) => Series[value.series],
  (string) => {
    return {
      series: Series[string as keyof typeof Series],
      orderInSeries: filterOrderInSeries // Special flag, to capture that this encapsulates the whole series if necessary
    }
  },
  (value1, value2, comparitorFunction) => {
    const seriesEquivalent = eq(value1.series, value2.series)
    if (!(value1.orderInSeries === filterOrderInSeries || value2.orderInSeries === filterOrderInSeries) && seriesEquivalent) {
      return comparitorFunction(value1.orderInSeries, value2.orderInSeries)
    }
    return comparitorFunction(value1.series, value2.series)
  }
)

export const seriesProperty = new EnumerableCharacterProperty<SeriesValue>(
  "4b94b213-fafe-470f-b382-24e667536904",
  "Series",
  "Series",
  seriesValueConverter,
  (region, character) => {
    return new SeriesValue(character?.universal.series || Series.I, character?.universal.orderInSeries || 0)
  },
  () => getValues(Series).map(seriesValue => {
    const castedSeriesValue = seriesValue as number
    return new SeriesValue(castedSeriesValue, 0)
  })
)
