import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Theme from 'src/theme'
import Divider from '@material-ui/core/Divider';

export default function generateToggle(title: string, on: boolean, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void) {
    return [
      <FormControlLabel key='label'
        control={
          <Switch
            name={title}
            color="primary"
            checked={on}
            onChange={handleChange}
          />
        }
        label={title}
        style={{ paddingInline: Theme.spacing(2) }}
      />,
      <Divider key='divider'/>
    ]
  }
