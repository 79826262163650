import React, {useCallback, useMemo} from 'react'
import Panel from 'src/utils/panel/ffootip-panel'
import { useSelector, useDispatch } from 'react-redux'
import {getActivePanelEvent} from 'src/event-details/event-details-selectors'
import { unsetEventPanel } from 'src/router/router-effects'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppState } from 'src/store/root-reducer'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { EventPanelTab, setEventPanelTabActionCreator } from './event-details-actions'
import AttachMoney from '@material-ui/icons/AttachMoney'
import AccountBox from '@material-ui/icons/AccountBox'
import ResourcesTab from './resources-tab/resources-tab'
import Container from '@material-ui/core/Container'
import { createGetTotalAllocations } from 'src/planner/planner-selectors'
import BannersTab from './banners-tab/banners-tab'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      paddingBlock: `${theme.spacing(2)}px`
    },
    tabsFlexContainer: {
      justifyContent: 'center'
    }
  })
)

export default function EventDetailsPanel() {
  const classes = useStyles()

  const event = useSelector(getActivePanelEvent)
  const currentTab = useSelector((state: AppState) => state.eventDetails.currentTab)
  const memoizedGetTotalAllocation = useMemo(createGetTotalAllocations, [])
  const totalAllocations = useSelector(memoizedGetTotalAllocation)

  const dispatch = useDispatch()
  const closePanelCallback = useCallback(() => {
    dispatch(unsetEventPanel())
  }, [dispatch])
  const changePanelCallback = useCallback((tab: EventPanelTab) => {
    dispatch(setEventPanelTabActionCreator(tab))
  }, [dispatch])
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    changePanelCallback(newValue as EventPanelTab)
  }

  if (!event) {
    return null
  }

  const totalAllocation = totalAllocations.totalAllocationsByEventId[event.id]

  const tabRenderingFunction = (tab: EventPanelTab) => {
    var icon: React.ReactElement
      switch(tab) {
        default:
          return null
        case EventPanelTab.BANNERS_TAB:
          icon = <AccountBox />
          break
        case EventPanelTab.RESOURCES_TAB:
          icon = <AttachMoney />
          break
      }
    return <Tab icon={icon} label={tab} value={tab} key={tab} />
  }

  const renderTabContent = (tab: EventPanelTab) => {
    switch (tab) {
      case EventPanelTab.BANNERS_TAB:
        return <BannersTab event={event} bannerAllocations={totalAllocation?.eventAllocation.bannerAllocations}/>
      case EventPanelTab.RESOURCES_TAB:
        return <ResourcesTab event={event} totalAllocation={totalAllocation}/>
    }
  }

  return <Panel closePanelCallback={closePanelCallback}>
    <Container>
      <Typography variant='h3' align='center' >{event.name}</Typography>
      <Tabs value={currentTab} onChange={handleChange} className={classes.tabs} classes={{ flexContainer: classes.tabsFlexContainer }}>
        {tabRenderingFunction(EventPanelTab.BANNERS_TAB)}
        {tabRenderingFunction(EventPanelTab.RESOURCES_TAB)}
      </Tabs>
      {renderTabContent(currentTab)}
    </Container>
  </Panel>
}
