import React, { FunctionComponent } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import { ControlledPickerProps } from '../scheduled-income-dialog/scheduled-income-dialog-contents/advanced-schedule-specification';
import { PlannerProperty } from '../model/property/static/planner-property';

interface ResourcePickerProps extends ControlledPickerProps<PlannerProperty> {
  options: PlannerProperty[]
}

const PlannerPropertyPicker: FunctionComponent<ResourcePickerProps> = ({
  pickerValue,
  onPickerValueChanged,
  options,
}) => {

  const handleChange = (event: any, newValue: PlannerProperty | null) => {
    onPickerValueChanged(newValue || null)
  }

  const internalOptions = options.slice() as any[]
  internalOptions.unshift(null)

  return (
    <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      disableClearable
      openOnFocus
      blurOnSelect
      forcePopupIcon={false}
      style={Object.assign({ width: '200px' })}
      options={internalOptions}
      renderInput={(params) => (
        <TextField {...params} variant='filled' margin='dense' />
      )}
      getOptionLabel={(resource: PlannerProperty | null) => !resource ? "None": resource.name}
      onChange={handleChange}
      value={pickerValue === null ? undefined : pickerValue}
    />
  )

}

export default PlannerPropertyPicker
