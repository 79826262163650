import {CharacterData, CharacterDataValue} from 'src/tracker/model/tracker-model'
import {Character, Uuid, TimeRange} from 'src/forecast/model/universal-data-model'
import {CharacterProperty, StringDisplayPreferences} from 'src/tracker/model/property/character-property'
import { EditableCharacterProperty } from '../property/editable-character-property'
import { EnumerableCharacterProperty } from '../property/enumerable-character-property'
import { DateProperty } from '../property/date-property'
import { AllCharacterData } from '../components/all-character-data'
import { Region } from '../components/region'

export enum ColumnType {
  "NAME", "PICKER", "COLOR", "WEAPON", "ACTION", "TEXT", "DATE"
}

export class TrackerColumn {
  constructor(
    id: Uuid,
    width: string,
    name: string,
    shortName: string,
    type: ColumnType,
    ) {
      this.id = id
      this.width = width
      this.name = name
      this.shortName = shortName
      this.type = type
    }
  id: Uuid
  width: string
  name: string
  shortName: string
  type: ColumnType
}

export class AccessibleColumn<T> extends TrackerColumn {
  constructor(
    width: string,
    type: ColumnType,
    property: CharacterProperty<T>,
  ) {
    super(property.id, width, property.name, property.shortName, type)
    this.property = property
  }
  accessor(value: AllCharacterData, region: Region, stringDisplayPreferences?: StringDisplayPreferences) {
    return this.property.getString(region, value, stringDisplayPreferences)
  }
  property: CharacterProperty<T>
}

export class FilterableColumn<T> extends AccessibleColumn<T> {
  constructor(
    width: string,
    type: ColumnType,
    property: EnumerableCharacterProperty<T>,
  ) {
    super(width, type, property)
    this.property = property
  }
  property: EnumerableCharacterProperty<T>
}

export class PickerColumn<T extends CharacterDataValue> extends FilterableColumn<T> {
  constructor(
    width: string,
    property: EditableCharacterProperty<T>,
  ) {
    super(width, ColumnType.PICKER, property)
    this.property = property
  }
  property: EditableCharacterProperty<T>

  reducer(cData: CharacterData, newValue: string) {
    const resolvedValue = this.property.valueFromString(newValue)
    const result = this.property.set(cData, resolvedValue)
    return result
  }
}

export class ActionColumn extends TrackerColumn {
  constructor(
    id: Uuid,
    width: string,
    name: string,
    shortName: string,
    options: string[],
    howToChange: (chosenAction: string, universalCharacterData: Character, region: Region) => Partial<CharacterData>,
    getMessage: (chosenAction: string, characterName: string) => string | null
  ) {
    super(id, width, name, shortName, ColumnType.ACTION)
    this.options = options
    this.howToChange = howToChange
    this.getMessage = getMessage
  }

  options: string[]
  howToChange: (chosenAction: string, universalCharacterData: Character, region: Region) => Partial<CharacterData>
  getMessage: (chosenAction: string, characterName: string) => string | null
}

export class DateColumn extends AccessibleColumn<TimeRange | null> {
  constructor(
    width: string,
    property: DateProperty<any>
  ) {
    super(width, ColumnType.DATE, property)
  }
}
