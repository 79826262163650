import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {acquiredColumn} from 'src/tracker/model/column/variable/acquired/acquired-column'
import {fifteenCpColumn} from 'src/tracker/model/column/variable/equipment/weapon/fifteen-cp-column'
import {thirtyFiveCpColumn} from 'src/tracker/model/column/variable/equipment/weapon/thirty-five-cp-column'
import {exColumn} from 'src/tracker/model/column/variable/equipment/weapon/ex-column'
import {armorColumn} from 'src/tracker/model/column/variable/equipment/armor/armor-column'
import {bloomColumn} from 'src/tracker/model/column/variable/equipment/misc/bloom-column'
import {levelColumn} from 'src/tracker/model/column/variable/levels/level-column'
import {crystalLevelColumn} from 'src/tracker/model/column/variable/levels/crystal-level-column'
import {ifritColumn} from 'src/tracker/model/column/variable/summon/ifrit-column'
import {shivaColumn} from 'src/tracker/model/column/variable/summon/shiva-column'
import {ramuhColumn} from 'src/tracker/model/column/variable/summon/ramuh-column'
import {leviathanColumn} from 'src/tracker/model/column/variable/summon/leviathan-column'
import {brothersColumn} from 'src/tracker/model/column/variable/summon/brothers-column'
import {pandemoniumColumn} from 'src/tracker/model/column/variable/summon/pandemonium-column'
import {diabolosColumn} from 'src/tracker/model/column/variable/summon/diabolos-column'
import {characterBoardColumn} from 'src/tracker/model/column/variable/levels/character-board-column'
import {entropyColumn} from 'src/tracker/model/column/variable/utilization/entropy-column'
import { alexanderColumn } from '../variable/summon/alexander-column'
import { btColumn } from '../variable/equipment/weapon/bt-column'
import { ldColumn } from '../variable/equipment/weapon/ld-column'
import { odinColumn } from '../variable/summon/odin-column'
import { bahamutColumn } from '../variable/summon/bahamut-column'
import { ldBoardColumn } from '../variable/levels/ld-board-column'
import { transcendenceColumn } from '../variable/utilization/transcendence-column'
import { frColumn } from '../variable/equipment/weapon/fr-column'
import { forceEnhancementColumn } from '../variable/levels/force-enhancement-column'
import { frBoardColumn } from '../variable/levels/fr-board-column'

export const standardGroup = new ColumnGroup(
  '3a36867d-d93e-4c44-aba3-81bf5fece8c7',
  'Standard',
  [acquiredColumn, levelColumn, crystalLevelColumn, characterBoardColumn, ldBoardColumn, forceEnhancementColumn,
    frBoardColumn, fifteenCpColumn, thirtyFiveCpColumn, exColumn, ldColumn, btColumn, frColumn, armorColumn,
    bloomColumn, ifritColumn, shivaColumn, ramuhColumn, leviathanColumn, brothersColumn, pandemoniumColumn,
    diabolosColumn, alexanderColumn, odinColumn, bahamutColumn, entropyColumn, transcendenceColumn]
)
