export const DISPLAY_NAVIGATION_DRAWER = "DISPLAY_NAVIGATION_DRAWER"

export function setDisplayNavigationDrawerActionCreator(display: boolean) {
  return {
    type: DISPLAY_NAVIGATION_DRAWER,
    display: display,
  }
}

export type SetDisplayNavigationDrawerActionCreator = typeof setDisplayNavigationDrawerActionCreator
export type SetDisplayNavigationDrawerAction = ReturnType<SetDisplayNavigationDrawerActionCreator>
