import {ActionCreator} from 'redux'
import { AppState } from 'src/store/root-reducer'
import {push, CallHistoryMethodAction} from 'connected-react-router'
import { SHARED_ID_ARG, CHARACTER_PANEL_NAME, EVENT_PANEL_ID, REGION_ARG, } from './root-router';
import { ThunkAction } from 'redux-thunk';
import { getActiveRegion, getCurrentPath, getPathAndQueryForNewPath, getPathForDeleteQueryParamValue } from './router-selectors';
import { Region } from 'src/tracker/model/components/region';

export const navigateTo: ActionCreator<ThunkAction<void, AppState, null, CallHistoryMethodAction>> =
  (path: string) => {
    return (dispatch, getState) => {
      const val = getPathAndQueryForNewPath(getState(), path)
      dispatch(push(val))
    }
  }

export const toggleRegion: ActionCreator<ThunkAction<void, AppState, null, CallHistoryMethodAction>> =
  () => {
    return (dispatch, getState) => {
      const region = getActiveRegion(getState())
      const otherRegion = region === Region.GL ? Region.JP : Region.GL
      const val = getCurrentPath(getState(), (query) => {
        query[REGION_ARG] = otherRegion
        return query
      })
      dispatch(push(val))
    }
  }

export const unsetCharacterPanel: ActionCreator<ThunkAction<void, AppState, null, CallHistoryMethodAction>> =
  () => {
    return (dispatch, getState) => {
      const pathString = getPathForDeleteQueryParamValue(getState(), CHARACTER_PANEL_NAME)
      dispatch(push(pathString))
    }
  }

export const removeSharedId: ActionCreator<ThunkAction<void, AppState, null, CallHistoryMethodAction>> =
  () => {
    return (dispatch, getState) => {
      const pathString = getPathForDeleteQueryParamValue(getState(), SHARED_ID_ARG)
      dispatch(push(pathString))
    }
  }

export const unsetEventPanel: ActionCreator<ThunkAction<void, AppState, null, CallHistoryMethodAction>> =
  () => {
    return (dispatch, getState) => {
      const pathString = getPathForDeleteQueryParamValue(getState(), EVENT_PANEL_ID)
      dispatch(push(pathString))
    }
  }
