import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {Action, ActionCreator} from 'redux'
import { getFirebase } from 'react-redux-firebase'
import { AppState } from 'src/store/root-reducer'
import {setPreviewedPlannerDataActionCreator, setPreviewedTrackerDataActionCreator, WritePreferencesDataFailureAction, writePreferencesDataFailureActionCreator} from 'src/preferences/preferences-actions'
import {getUserId} from 'src/login/login-selectors'
import {getPreferencesDocument,
  getCustomColumnGroups,
  PREFERENCES_COLLECTION_NAME,
  createDocumentMergableCustomColumnGroups,
  createDocumentMergableGreyAcquiredCharacters,
  createDocumentMergableDefaultEditMode,
  createDocumentMergableHighlightMaxed,
  createDocumentMergableShowFutureCharacters,
  createDocumentMergableDisplayDetailedEstimates,
  createDocumentMergableShowLocalTime,
  createDocumentMergableDefaultRegion,
  createDocumentMergableHighlightAltered,
  createDocumentMergableDisplayFirstLdName,
  createDocumentMergableImportTrackerBreadcrumbDismissed,
  createDocumentMergableImportPlannerBreadcrumbDismissed,
  createDocumentMergablePlannerCustomColumnGroups,
  getPlannerCustomColumnGroups,
  createDocumentMergablePlannerDefaultColumnGroups,
  createDocumentMergableDisplayAsRefined,
  getSavedViews,
  createDocumentMergableSavedViews,
  createDocumentMergabledefaultSavedView,
  } from 'src/preferences/preferences-selectors'
import {Preferences, CustomColumnGroups, GreyAcquiredCharacters} from 'src/preferences/model/preferences-model'
import {customColumnGroupsValidator, savedViewsValidator} from 'src/preferences/model/preferences-model-validators'
import {mergeWith} from 'lodash/fp'
import { Region } from 'src/tracker/model/components/region';
import { ootracker_universal_additional_data, ootracker_universal_character_data, ootracker_user, ootracker_user_data, ootracker_user_de_data } from 'src/tracker/model/ootracker/ootracker-model';
import { transformToTracker } from 'src/tracker/model/ootracker/to-ffootip-transformer';
import { getTrackerDocument } from 'src/tracker/tracker-selectors';
import { getAllCharacterData, getBannersById, getCharacterIdsByName, getEventsById, getSummonIdsByName, getSummons } from 'src/forecast/forecast-selectors';
import { displayEntropyActionCreator } from 'src/tracker/tracker-actions';
import { importTonberryTroupeCsv } from 'src/planner/model/tonberrytroupe/tonberry-troupe-csv-import';
import { getPlannerDocument } from 'src/planner/planner-selectors';
import { Uuid } from 'src/forecast/model/universal-data-model';
import { SavedView } from './model/saved-view';

export type PreferencesThunk = ActionCreator<ThunkAction<void, AppState, typeof getFirebase, WritePreferencesDataFailureAction>>

export const writeCustomColumnsData: PreferencesThunk =
  (customColumnGroups: CustomColumnGroups) => {
    return async (dispatch, getState, getFirebase) => {
      const validatedData = customColumnGroupsValidator(customColumnGroups, getCustomColumnGroups(getState()))
      writePreferencesData(createDocumentMergableCustomColumnGroups(validatedData), dispatch, getState, getFirebase)
    }
  }

export const writeGreyAcquiredCharacters: PreferencesThunk =
  (greyAcquiredCharcters: GreyAcquiredCharacters) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableGreyAcquiredCharacters(greyAcquiredCharcters), dispatch, getState, getFirebase)
    }
  }

export const writeDefaultEditMode: PreferencesThunk =
  (defaultEditMode: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableDefaultEditMode(defaultEditMode), dispatch, getState, getFirebase)
    }
  }

export const writeHighlightMaxed: PreferencesThunk =
  (highlightMaxed: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableHighlightMaxed(highlightMaxed), dispatch, getState, getFirebase)
    }
  }

export const writeShowFutureCharacters: PreferencesThunk =
  (showFutureCharacters: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableShowFutureCharacters(showFutureCharacters), dispatch, getState, getFirebase)
    }
  }

export const writeDisplayDetailedEstimates: PreferencesThunk =
  (displayDetailedEstimates: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableDisplayDetailedEstimates(displayDetailedEstimates), dispatch, getState, getFirebase)
    }
  }

export const writeShowLocalTime: PreferencesThunk =
  (showLocalTime: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableShowLocalTime(showLocalTime), dispatch, getState, getFirebase)
    }
  }

export const writeDefaultRegion: PreferencesThunk =
  (region: Region) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableDefaultRegion(region), dispatch, getState, getFirebase)
    }
  }

export const writeHighlightAltered: PreferencesThunk =
  (highlightAltered: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableHighlightAltered(highlightAltered), dispatch, getState, getFirebase)
    }
  }

export const writeDisplayFirstLdName: PreferencesThunk =
  (displayFirstLdName: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableDisplayFirstLdName(displayFirstLdName), dispatch, getState, getFirebase)
    }
  }

export const writeDisplayAsRefined: PreferencesThunk =
  (displayAsRefined: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableDisplayAsRefined(displayAsRefined), dispatch, getState, getFirebase)
    }
  }


export const writeImportTrackerBreadcrumbDismissed: PreferencesThunk =
  (importBreadcrumbDismissed: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableImportTrackerBreadcrumbDismissed(importBreadcrumbDismissed), dispatch, getState, getFirebase)
    }
  }

export const writeImportPlannerBreadcrumbDismissed: PreferencesThunk =
  (importBreadcrumbDismissed: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergableImportPlannerBreadcrumbDismissed(importBreadcrumbDismissed), dispatch, getState, getFirebase)
    }
  }

export const writeSavedViews: PreferencesThunk =
  (savedViews: SavedView[]) => {
    return async (dispatch, getState, getFirebase) => {
      const validatedData = savedViewsValidator(savedViews, getSavedViews(getState()))
      writePreferencesData(createDocumentMergableSavedViews(validatedData), dispatch, getState, getFirebase)
    }
  }

export const writeDefaultSavedView: PreferencesThunk =
  (defaultSavedView: Uuid) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergabledefaultSavedView(defaultSavedView), dispatch, getState, getFirebase)
    }
  }

export const lookupOotrackerIdThunk: ActionCreator<ThunkAction<void, AppState, void, Action<any>>> =
  (friendId: string, region: Region) => {
    return async (dispatch, getState) => {
      const regionString = region === Region.GL ? 'Gl' : 'Jp'
      const userIdQuery = `query user($friendId: String!) { user(where: {friendId${regionString}: {_eq: $friendId}}) { id displayNameGl displayNameJp friendIdGl friendIdJp }}`
      const body = JSON.stringify([{
        query: userIdQuery,
        variables: {
          friendId: friendId,
        },
      }])
      callOotracker(body)
      .then(data => {
        const castedData = data as ootracker_user[]
        const user = castedData[0]?.data.user[0]
        if (user === undefined) {
          return null
        } else {
          return user
        }
      })
      .then(user => {
        if (!!user) {
          getOotrackerData(user.id, getState(), dispatch)
        }
      })
    }
  }

const getOotrackerData =
  (userId: string, state: AppState, dispatch: ThunkDispatch<AppState, void, Action<any>>) => {
    const characterQuery = 'fragment character on character {  id  name  equipment(order_by: {equipmentType: {displayOrder: asc}}){ id  equipmentTypeId }  __typename}query characterAll {  character {    ...character    __typename  }}'
    const additionalDataQuery = 'query additionalData { equipmentType(order_by: {displayOrder: asc}) {    equipmentSlot    id    name  __typename }   summon(order_by: {boardOrder: asc}) {    id    name    __typename  }}'
    const userDataQuery = 'fragment userCharacterBase on user_character { awakening  characterBoardEx  characterBoardFr  characterBoardLd  characterBoardS1  characterBoardS2  characterId  id  level  force region __typename } fragment userEquipmentBase on user_equipment { characterId  equipmentId  id  level  userCharacterId region __typename } fragment userSummonBase on user_summon { characterId  collectedNodes  id  summonId  userCharacterId region __typename } query userCharacterOfUser($userId: uuid!) { userCharacter(where: { userId: { _eq: $userId }}) {    ...userCharacterBase    __typename } userEquipment(where: { userId: { _eq: $userId }}) {    ...userEquipmentBase    __typename } userSummon(where: { userId: { _eq: $userId }}) {    ...userSummonBase    __typename } }'
    const userDeQuery = 'fragment userFeodStage on user_feod {  id  region  summonId  tierNumber  userCharacter1Id  userCharacter2Id  userCharacter3Id  __typename}query userFeodAll($userId: uuid!) {  userFeod(where: {userId: {_eq: $userId}}, order_by: {tierNumber: asc}) {    ...userFeodStage    __typename  }}'
    const body = JSON.stringify([
      {
        query: characterQuery,
        operationName: 'characterAll',
        variables: {}
      },
      {
        query: additionalDataQuery,
        operationName: 'additionalData',
        variables: {}
      },
      {
        query: userDataQuery,
        operationName: 'userCharacterOfUser',
        variables: {
          userId: userId
        }
      },
      {
        query: userDeQuery,
        operationName: 'userFeodAll',
        variables: {
          userId: userId
        }
      },
    ])
    callOotracker(body)
      .then(data => {
        const value = data as [ootracker_universal_character_data, ootracker_universal_additional_data, ootracker_user_data, ootracker_user_de_data]
        const output = transformToTracker(value[0], value[1], value[2], value[3], getTrackerDocument(state, false),
          getCharacterIdsByName(state), getAllCharacterData(state), getSummonIdsByName(state), getSummons(state))
        dispatch(setPreviewedTrackerDataActionCreator(output))
        dispatch(displayEntropyActionCreator(true))
      })
  }

const callOotracker = (body: string) => {
  const url = 'https://us-central1-ffootip.cloudfunctions.net/ootrackerProxy'
  return fetch(url, {
    method: 'POST',
    body: body,
  }).then(r => r.json())
}

const writePreferencesData = (updatedPreferencePartial: Partial<Preferences>, dispatch: ThunkDispatch<AppState, typeof getFirebase, WritePreferencesDataFailureAction>, getState: () => AppState, firebaseInt: typeof getFirebase) => {
  const userId = getUserId(getState())
  if (!userId) {
    dispatch(writePreferencesDataFailureActionCreator("User not logged in"))
  } else {
    const preferenceData = getPreferencesDocument(getState())
    const mergedTrackerData = mergeWith((value, srcValue, key, object, source) => {
      if (key === "customColumnGroups" || key === "savedViews") {
        return srcValue
      }
    })(preferenceData)(updatedPreferencePartial)
    firebaseInt().firestore().collection(PREFERENCES_COLLECTION_NAME).doc(userId)
      .set(mergedTrackerData)
  }
}

export const deletePreferencesData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, any>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const userId = getUserId(getState())
      if (!userId) {
        dispatch(writePreferencesDataFailureActionCreator("User not logged in"))
      } else {
        getFirebase().firestore().collection(PREFERENCES_COLLECTION_NAME).doc(userId).delete()
      }
    }
  }

export const parseTonberryTroupeCsv: ActionCreator<ThunkAction<void, AppState, void, Action<any>>> =
  (csvFile: File | null) => {
    return async (dispatch, getState) => {
      if (csvFile !== null) {
        const importedTracker = await importTonberryTroupeCsv(csvFile, getPlannerDocument(getState(), false), getEventsById(getState()), getBannersById(getState()))
        dispatch(setPreviewedPlannerDataActionCreator(importedTracker))
      }
    }
  }

export const writePlannerCustomColumnsData: PreferencesThunk =
  (customColumnGroups: CustomColumnGroups) => {
    return async (dispatch, getState, getFirebase) => {
      const validatedData = customColumnGroupsValidator(customColumnGroups, getPlannerCustomColumnGroups(getState()))
      writePreferencesData(createDocumentMergablePlannerCustomColumnGroups(validatedData), dispatch, getState, getFirebase)
    }
  }

export const writePlannerDefaultColumnsData: PreferencesThunk =
  (value: Uuid) => {
    return async (dispatch, getState, getFirebase) => {
      writePreferencesData(createDocumentMergablePlannerDefaultColumnGroups(value), dispatch, getState, getFirebase)
    }
  }
