import {ActionCreator} from 'redux'
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'src/store/root-reducer'
//import firebase from 'firebase/app'
//import 'firebase/storage'
import { AssetFoundAction, assetFoundActionCreator } from './image-actions';

export const shouldGetAsset: ActionCreator<ThunkAction<void, AppState, null, AssetFoundAction>> =
  (bucketLocation: string, bucketId: string) => {
    return async dispatch => {
      try {
        // TODO, would be nice to add storage, but I can't figure out caching yet
        //const ref = firebase.storage().ref(bucketLocation)
        //const url = await ref.getDownloadURL();
        const url = require(`../img/${bucketLocation}`)
        dispatch(assetFoundActionCreator(bucketId, url))
      } catch(error) {
        console.log(`Error was ${JSON.stringify(error)}`)
      }
    }
  }
