import {ActionCreator} from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from 'src/store/root-reducer'
import { getFirebase } from 'react-redux-firebase'
import { BannerAllocation, CurrentResourceAccounting, Planner, ResourcesList } from './model/planner-model';
import { WritePlannerDataFailureAction, writePlannerDataFailureActionCreator } from './planner-actions';
import {getSpecifiedId, getUserId} from 'src/login/login-selectors'
import { createCharacterAllocationPlannerData, createDocumentMergableBannerAllocation, createDocumentMergableCurrentResourceAccounting, createDocumentMergableMiscellaneousAllocations, createDocumentMergableSharedData, getPlannerDocument, PLANNER_COLLECTION_NAME } from './planner-selectors';
import {cloneDeep, merge} from 'lodash'
import { Uuid } from 'src/forecast/model/universal-data-model';
import { CharacterData } from 'src/tracker/model/tracker-model';
import { getPreviewedPlannerData, isPreviewedPlannerData } from 'src/preferences/preferences-selectors';
import { SetPreviewedPlannerDataAction, setPreviewedPlannerDataActionCreator } from 'src/preferences/preferences-actions';

type TrackerThunk = ActionCreator<ThunkAction<void, AppState, typeof getFirebase, WritePlannerDataFailureAction>>

export const writeBannerAllocationData: TrackerThunk =
  (bannerAllocation: BannerAllocation, eventId: Uuid, bannerId: Uuid) => {
    return async (dispatch, getState, getFirebase) => {
      writePlannerData(createDocumentMergableBannerAllocation(getState(), bannerAllocation, eventId, bannerId), dispatch, getState, getFirebase)
    }
  }

export const writeCharacterAllocationData: TrackerThunk =
  (characterAllocation: CharacterData | undefined, eventId: Uuid, characterId: Uuid) => {
    return async (dispatch, getState, getFirebase) => {
      writeRawPlannerData(createCharacterAllocationPlannerData(getState(), characterAllocation, eventId, characterId), dispatch, getState, getFirebase)
    }
  }

export const writeMiscellaneousAllocationsData: TrackerThunk =
  (miscellaneousAllocations: ResourcesList, eventId: Uuid) => {
    return async (dispatch, getState, getFirebase) => {
      writePlannerData(createDocumentMergableMiscellaneousAllocations(getState(), miscellaneousAllocations, eventId), dispatch, getState, getFirebase)
    }
  }

export const writePlannerSharableData: TrackerThunk =
  (shareable: boolean) => {
    return async (dispatch, getState, getFirebase) => {
      writePlannerData(createDocumentMergableSharedData(shareable), dispatch, getState, getFirebase, true)
    }
  }

export const writeCurrentResourcesAccountingData: TrackerThunk =
  (currentResourceAccounting: CurrentResourceAccounting) => {
    return async (dispatch, getState, getFirebase) => {
      writePlannerData(createDocumentMergableCurrentResourceAccounting(getState(), currentResourceAccounting), dispatch, getState, getFirebase)
    }
  }

export const writeOperationsEventsData: TrackerThunk =
  (operations: ((planner: Planner) => Planner)[]) => {
    return async (dispatch, getState, getFirebase) => {
      var planner = cloneDeep(getPlannerDocument(getState(), false) || ({} as Planner))
      operations.forEach(operation => {
        planner = operation(planner)
      })
      writeRawPlannerData(planner, dispatch, getState, getFirebase)
    }
  }

export const commitPreviewedPlannerData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, SetPreviewedPlannerDataAction>> =
  () => {
    return async (dispatch, getState, firebaseInt) => {
      const storedPlannerData = JSON.parse(JSON.stringify(getPreviewedPlannerData(getState())))
      if (!!storedPlannerData) {
        writeRawPlannerData(storedPlannerData, dispatch, getState, firebaseInt, true, true)
        dispatch(setPreviewedPlannerDataActionCreator(null))
      }
    }
  }

export const writePlannerData = (createDocumentMergableData: Partial<Planner>, dispatch: ThunkDispatch<AppState, typeof getFirebase, WritePlannerDataFailureAction>, getState: () => AppState, firebaseInt: typeof getFirebase, ignoreSpecifiedId: boolean = false) => {
    const trackerData = getPlannerDocument(getState(), false)
    const mergedTrackerData = merge({}, trackerData, createDocumentMergableData)
    writeRawPlannerData(mergedTrackerData, dispatch, getState, firebaseInt, ignoreSpecifiedId)
}

const writeRawPlannerData = (plannerData: Planner, dispatch: ThunkDispatch<AppState, typeof getFirebase, WritePlannerDataFailureAction | SetPreviewedPlannerDataAction>, getState: () => AppState, firebaseInt: typeof getFirebase, ignoreSpecifiedId: boolean = false,
  ignorePreviewedData: boolean = false) => {
  const userId = getUserId(getState())
  const idIsSpecified = !ignoreSpecifiedId && !!getSpecifiedId(getState())
  if (!userId) {
    dispatch(writePlannerDataFailureActionCreator("User not logged in"))
  } else if (idIsSpecified) {
    dispatch(writePlannerDataFailureActionCreator("Tried to edit other user's data"))
  } else {
    if (!ignorePreviewedData && isPreviewedPlannerData(getState())) { // if we're in preview, set the previewed data to change instead
      dispatch(setPreviewedPlannerDataActionCreator(plannerData))
    } else {
      firebaseInt().firestore().collection(PLANNER_COLLECTION_NAME).doc(userId)
        .set(plannerData)
    }
  }
}

export const deletePlannerData: ActionCreator<ThunkAction<void, AppState, typeof getFirebase, any>> =
  () => {
    return async (dispatch, getState, getFirebase) => {
      const userId = getUserId(getState())
      if (!userId) {
        dispatch(writePlannerDataFailureActionCreator("User not logged in"))
      } else {
        getFirebase().firestore().collection(PLANNER_COLLECTION_NAME).doc(userId).delete()
      }
    }
  }
