import { exProperty } from 'src/tracker/model/property/equipment/weapon/ex-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const weaponPageProperty = createStandardCharacterResourceProperty(
  '3538967c-3f5b-42fe-ade7-dfcef953ff81',
  'Weapon Pages',
  'weaponPages',
  exProperty,
  Resource.WEAPON_PAGES,
)
