import React, {useCallback, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { getFilterSpecifications, calculateValuesOfProperty, returnValueOrValidValue } from 'src/forecast/forecast-selectors';
import { setFilterSpecificationsActionCreator } from 'src/forecast/forecast-actions';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add'
import PredicateComparatorSelector from 'src/tracker/filter-comparator-selector/filter-comparator-selector';
import { CharacterProperty } from 'src/tracker/model/property/character-property';
import TrackerPickerEntry from 'src/tracker/tracker-picker-entry/tracker-picker-entry';
import { SortSpecification, FilterComparator, FilterSpecification, addSortSpecification, removeSortSpecification, removeFilterSpecifications, addFilterSpecifications } from "src/tracker/model/selector-model";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getSortSpecification } from 'src/tracker/tracker-selectors';
import { TimeRange, Uuid, } from 'src/forecast/model/universal-data-model';
import { setSortSpecificationsActionCreator } from 'src/tracker/tracker-actions';
import { TrackerValuePicker } from 'src/tracker/tracker-value-picker/tracker-value-picker';
import { DateProperty } from 'src/tracker/model/property/date-property';
import { getActiveRegion } from 'src/router/router-selectors';
import { DateTime, Duration } from 'luxon';
import { acquiredProperty } from 'src/tracker/model/property/acquired/acquired-property';
import MetaPropertyPicker from '../meta-property-picker/meta-property-picker';
import { PlannerDateProperty } from 'src/tracker/model/property/planner-date-property';
import { isNotLoggedIn } from 'src/login/login-selectors';
import { SavedViewComponent } from '../saved-view-component/saved-view-component';

const DEFAULT_PREDICATE_COMPARATOR = FilterComparator["="]

export default function FilterComponent() {
  const [predicateComparator, setPredicateComparatorSelected] = useState(DEFAULT_PREDICATE_COMPARATOR)
  const [propertySelected, setPropertySelected] = useState(acquiredProperty as CharacterProperty<any>)
  const [currentSortDescending, setCurrentSortDescending] = useState(false)

  const theme = useTheme()

  const filterSpecifications = useSelector(getFilterSpecifications).slice()
  const sortSpecifications = useSelector(getSortSpecification)

  const isNotLoggedInVal = useSelector(isNotLoggedIn)
  const possibleValuesGenerator = useSelector(calculateValuesOfProperty)

  const [currentFilteredValue, setCurrentFilteredValue] = useState(false as any)

  const region = useSelector(getActiveRegion)

  const dispatch = useDispatch()

  const addFilterSpecificationCallback = useCallback((filterSpecification: FilterSpecification<any>) => {
    dispatch(setFilterSpecificationsActionCreator(addFilterSpecifications(filterSpecifications, filterSpecification)))
  }, [dispatch, filterSpecifications])
  const removePredicateCallback = useCallback((filterSpecification: FilterSpecification<any>) => {
    dispatch(setFilterSpecificationsActionCreator(removeFilterSpecifications(filterSpecifications, filterSpecification)))
  }, [dispatch, filterSpecifications])
  const clearPredicatesCallback = useCallback(() => {
    dispatch(setFilterSpecificationsActionCreator([]))
  }, [dispatch])

  const addSortSpecificationCallback = useCallback((sortSpecification: SortSpecification) => {
    dispatch(setSortSpecificationsActionCreator(addSortSpecification(sortSpecifications, sortSpecification)))
  }, [dispatch, sortSpecifications])
  const removeSortSpecificationCallback = useCallback((propertyId: Uuid) => {
    dispatch(setSortSpecificationsActionCreator(removeSortSpecification(sortSpecifications, propertyId)))
  }, [dispatch, sortSpecifications])
  const clearSortsCallback = useCallback(() => {
    dispatch(setSortSpecificationsActionCreator([]))
  }, [dispatch])

  const deleteFilterButtonClick = (filterSpec: FilterSpecification<any>) => {
    return () => removePredicateCallback(filterSpec)
  }
  const deleteSortButtonClick = (sortSpecification: SortSpecification) => {
    return () => removeSortSpecificationCallback(sortSpecification.propertyId)
  }

  const predicateComparatorSelected = (filterComp: FilterComparator) => {
    setPredicateComparatorSelected(filterComp)
  }
  const propertySelectedChanged = (propSelected: CharacterProperty<any>) => {
    setPropertySelected(propSelected)
    if (propSelected instanceof DateProperty || propSelected instanceof PlannerDateProperty) {
      if (!(currentFilteredValue instanceof TimeRange)) {
        const hourOfEvent = region === "GL" ? 2 : 6 // JP starts at 6
        setCurrentFilteredValue(new TimeRange(DateTime.utc().set({ hour: hourOfEvent }).startOf('hour'), Duration.fromObject({days: 1}), false, false))
      }
    } else {
      const amendedValue = returnValueOrValidValue(currentFilteredValue, possibleValuesGenerator(propSelected))
      if (amendedValue !== currentFilteredValue) {
        setCurrentFilteredValue(amendedValue)
      }
    }
  }

  const addFilterClicked = () => {
    addFilterSpecificationCallback(new FilterSpecification(propertySelected.id, currentFilteredValue, predicateComparator))
  }

  const addSortClicked = () => {
    addSortSpecificationCallback(new SortSpecification(propertySelected.id, currentSortDescending))
  }

  const renderedList = filterSpecifications.map((filterSpecification, index) => {
    return (
      <ListItem divider key={index}>
        <ListItemText primary={filterSpecification.string} />
        <IconButton onClick={deleteFilterButtonClick(filterSpecification)}>
          <Delete />
        </IconButton>
      </ListItem>
    )
  })

  const renderFilterClearAllButton = filterSpecifications.length > 0

  const renderedSortSpecifications = sortSpecifications.map((sortSpecification, index) => {
    return (
      <ListItem divider key={index}>
        <ListItemText primary={sortSpecification.string} />
        <IconButton onClick={deleteSortButtonClick(sortSpecification)}>
          <Delete />
        </IconButton>
      </ListItem>
    )
  })

  const renderSortClearAllButton = sortSpecifications.length > 0
  const filterAddSpacing = theme.spacing(1)
  const propertyPicker = <MetaPropertyPicker onPropertyUpdated={propertySelectedChanged} style={{padding: filterAddSpacing}} property={propertySelected} />

  return (
    <div style={{padding: theme.spacing(1), maxWidth: '700px'}}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <List style={{ maxWidth: '300px', alignSelf: 'center' }}>
              {renderedList}
            </List>
            {renderFilterClearAllButton &&
              <Button onClick={clearPredicatesCallback}>
                Clear all
              </Button>}
            <Typography>Add Filter:</Typography>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {propertyPicker}
              <Typography style={{ padding: filterAddSpacing }}>Character is</Typography>
              <PredicateComparatorSelector defaultValue={DEFAULT_PREDICATE_COMPARATOR} onPredicateComparatorSelected={predicateComparatorSelected} style={{ padding: filterAddSpacing }} characterProperty={propertySelected}/>
              <TrackerValuePicker filterPropertySelected={propertySelected} currentValue={currentFilteredValue} setCurrentFilteredValue={setCurrentFilteredValue}/>
              <IconButton onClick={addFilterClicked} style={{ padding: filterAddSpacing }} >
                <Add />
              </IconButton>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Sort</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <List style={{ maxWidth: '300px', alignSelf: 'center' }}>
              {renderedSortSpecifications}
            </List>
            {renderSortClearAllButton &&
              <Button onClick={clearSortsCallback}>
                Clear all
              </Button>}
            <Typography>Add Sort:</Typography>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {propertyPicker}
              <TrackerPickerEntry
                options={["Ascending", "Descending"]}
                disabled={false}
                updateFunction={(newValue) => {
                  const value = "Descending" === newValue
                  setCurrentSortDescending(value)
                }}
                width='125px'
                value={currentSortDescending ? "Descending" : "Ascending"}
              />
              <IconButton onClick={addSortClicked} style={{ padding: filterAddSpacing }} >
                <Add />
              </IconButton>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled={isNotLoggedInVal}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Save/Load</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SavedViewComponent />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
