import { DatePropertyMode, DateTransienceMode, WeaponDateProperty } from "./date-property"
import { Region } from "../components/region";
import { AllCharacterData } from "../components/all-character-data";
import { getEventAppearanceValue } from "src/utils/event-time-utilities";
import { getFriendlyNameFromWeaponType, getShortNameFromWeaponType, WeaponType } from "src/forecast/model/weapon-type";

const v1Schema = "v1"
const typeSchema = "eventdateweaponappearance"

export const datePropertyModeToPrefix = (input: DatePropertyMode) => {
  switch (input) {
    case DatePropertyMode.ALL:
    default:
      return ""
    case DatePropertyMode.FUTURE:
    case DatePropertyMode.FUTURE_AND_PRESENT:
      return "Next"
    case DatePropertyMode.PAST:
    case DatePropertyMode.PAST_AND_PRESENT:
      return "Previous"
  }
}

export const generateDatePropertyWeaponAppearance = (weaponType: WeaponType, datePropertyMode: DatePropertyMode) => {
  const id = v1Schema + ":" + typeSchema + ":" + WeaponType[weaponType] + ":" + DatePropertyMode[datePropertyMode]
  const prefix = datePropertyModeToPrefix(datePropertyMode)
  const name = prefix + " " + getFriendlyNameFromWeaponType(weaponType) + " date"
  const shortName = prefix + " " + getShortNameFromWeaponType(weaponType) + " date"
  const allCharacterDataToProperty = (region: Region, character: AllCharacterData | undefined) => getEventAppearanceValue(character,
    (eventAppearance) => eventAppearance.weaponsContained.find(containedWeaponType => containedWeaponType === weaponType) !== undefined,
    datePropertyMode, region)
  return new WeaponDateProperty(id, name, shortName, DateTransienceMode.ONGOING, datePropertyMode, weaponType, allCharacterDataToProperty)
}

export const createFromIdWeaponType = (id: string) => {
  const splitString = id.split(":")
  if (splitString[0] !== undefined && splitString[0] === v1Schema && splitString[1] !== undefined && splitString[1] === typeSchema && splitString[2] !== undefined && splitString[3] !== undefined) {
    const candidateWeaponType = WeaponType[splitString[2] as keyof typeof WeaponType]
    const modeCandidate = DatePropertyMode[splitString[3] as keyof typeof DatePropertyMode]
    if (candidateWeaponType !== undefined && modeCandidate !== undefined) {
      return generateDatePropertyWeaponAppearance(candidateWeaponType, modeCandidate)
    }
  }
  return undefined
}
