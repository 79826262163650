import React, {useState, useCallback} from 'react'
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { fade, Theme } from '@material-ui/core/styles';
import appTheme from 'src/theme'
import {useDispatch, useSelector} from 'react-redux'
import { searchStringActionCreator } from 'src/forecast/forecast-actions';
import { getSearchString } from 'src/forecast/forecast-selectors';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton'
import FilterList from '@material-ui/icons/FilterList'
import FilterComponent from '../filter-component/filter-component';

function searchStyle(theme: Theme, isHover: boolean): React.CSSProperties {
  const fadeValue = isHover ? 0.25 : 0.15
  return {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, fadeValue),
    position: 'relative',
    height: '36px',
  }
}

function searchIconStyle(theme: Theme): React.CSSProperties {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    padding: theme.spacing(0, 1),
    pointerEvents: 'none',
  }
}

function searchInputStyle(theme: Theme): React.CSSProperties {
  return {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
  }
}

export default function SearchBar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch()
  const [isHover, setIsHover] = useState(false)
  const setHover = () => setIsHover(true)
  const setOutHover = () => setIsHover(false)
  const currentSearchString = useSelector(getSearchString)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const changeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchStringActionCreator(event.target.value))
  }, [dispatch])

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={searchStyle(appTheme, isHover)} onMouseEnter={setHover} onMouseLeave={setOutHover}>
        <div style={searchIconStyle(appTheme)}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search..."
          inputProps={{ style: searchInputStyle(appTheme) }}
          onChange={changeCallback}
          defaultValue={currentSearchString}
        />
      </div>
      <IconButton style={{padding: appTheme.spacing(1)}}
        onClick={handleClick}
      >
        <FilterList />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{style: {backgroundColor: appTheme.palette.background.default}}}
      >
        <FilterComponent />
      </Popover>
    </div>
  )
}
