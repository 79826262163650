import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { shouldGetAsset } from 'src/image-cache/image-effects'
import { selectImage } from 'src/image-cache/image-selectors'
import Skeleton from '@material-ui/lab/Skeleton';
import Link from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core';

interface ExternalLinkProps {
  characterName: string
  imageName: string
  description: string
  urlRetriever: (input: string) => string
}

const ExternalLink: FunctionComponent<ExternalLinkProps> = ({
  characterName,
  imageName,
  description,
  urlRetriever,
}) => {
  const theme = useTheme()
  const image = useSelector(selectImage(imageName))

  const dispatch = useDispatch()
  const dispatchCallback = useCallback((imageLocation: string, imageIdentifier: string) => {
    dispatch(shouldGetAsset(imageLocation, imageIdentifier))
  },
  [dispatch])

  useEffect(() => {
    if (!image) {
      dispatchCallback(imageName, imageName)
    }
  })

  const imgScalingStyling: React.CSSProperties = {
    maxWidth: "100%",
    height: "auto",
    width: "32px"
  }

  const imgCommonStyling: React.CSSProperties = Object.assign({}, imgScalingStyling, {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  const rootImageRender = !!image ?
    <img src={image} alt={description} style={Object.assign({}, imgCommonStyling)} /> :
    <Skeleton variant='rect' style={Object.assign({}, imgCommonStyling)} />
  const link = <Link rel='noopener' href={urlRetriever(characterName)} target='_blank'>
    {rootImageRender}
  </Link>

  return (
    <div style={Object.assign({}, { position: "relative", marginInline: theme.spacing(1) }, imgScalingStyling)} >
      {link}
      <div style={{ display: 'block', paddingTop: '100%', float: 'left' }} />
    </div>
  )
}

export default ExternalLink
