import { Uuid } from 'src/forecast/model/universal-data-model'
import { ValueComparator } from '../tracker-model-converter'
import { eq, lte } from 'lodash'
import { AllCharacterData } from '../components/all-character-data'
import { Region } from '../components/region'

export class StringDisplayPreferences {
  constructor(
    displayDetailedEstimates: boolean,
  ) {
    this.displayDetailedEstimates = displayDetailedEstimates
  }
  displayDetailedEstimates: boolean
}

export class CharacterProperty<T> {
  constructor(
    id: Uuid,
    name: string,
    shortName: string,
    get: (region: Region, characterData?: AllCharacterData) => T,
    valueToString: (value: T, stringDisplayPreferences?: StringDisplayPreferences) => string,
    valueComparator: ValueComparator<T, any>,
    valueToSerializedString: (value: T) => string,
    serializedStringToValue: (string: string) => T
  ) {
    this.id = id
    this.name = name
    this.shortName = shortName
    this.get = get
    this.valueToString = valueToString
    this.valueComparator = valueComparator
    this.getString = (region, character, stringDisplayPreferences) => valueToString(get(region, character), stringDisplayPreferences)
    this.sortOrdering = (character1, character2, region) => {
      const value1 = get(region, character1)
      const value2 = get(region, character2)
      if (valueComparator(value1, value2, eq)) {
        return 0
      }
      return valueComparator(value1, value2, lte) ? -1 : 1
    }
    this.valueToSerializedString = valueToSerializedString
    this.serializedStringToValue = serializedStringToValue
  }
  id: Uuid
  name: string
  shortName: string
  get: (region: Region, characterData?: AllCharacterData) => T
  getString: (region: Region, characterData?: AllCharacterData, stringDisplayPreferences?: StringDisplayPreferences) => string
  valueToString: (value: T, stringDisplayPreferences?: StringDisplayPreferences) => string
  valueComparator: ValueComparator<T, any>
  sortOrdering: (character1: AllCharacterData, character2: AllCharacterData, region: Region) => number
  valueToSerializedString: (value: T) => string
  serializedStringToValue: (string: string) => T
}
