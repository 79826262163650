import { characterBoardProperty } from 'src/tracker/model/property/levels/character-board-property'
import { forceEnhancementProperty } from 'src/tracker/model/property/levels/force-enhancement-property'
import { frBoardProperty } from 'src/tracker/model/property/levels/fr-board-property'
import { ldBoardProperty } from 'src/tracker/model/property/levels/ld-board-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { MogPassMode } from '../../mog-pass/mog-pass-mode'
import { CharacterResourceProperty, createStandardCharacterDataAccessor } from '../resource-meta/character-resource-property'

export const enhancementPointProperty = new CharacterResourceProperty(
  '3be89ab5-ea73-4155-a447-42a4496517e8',
  'Enhancement Points',
  'enhancementPoints',
  Resource.ENHANCEMENT_POINTS,
  null,
  (allocation, negate) => {
    var amount = 0
    switch (allocation.mogPassMode) {
      case MogPassMode.NONE:
      default:
        amount = 5000
        break
      case MogPassMode.BASE:
        amount = 15000
        break
      case MogPassMode.PREMIUM:
        amount = 30000
        break
    }
    return amount * (!!negate ? -1 : 1) * allocation.event.eventResources.enhanceableEvents
  }
)

enhancementPointProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(characterBoardProperty, Resource.ENHANCEMENT_POINTS))
enhancementPointProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(ldBoardProperty, Resource.ENHANCEMENT_POINTS))
enhancementPointProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(forceEnhancementProperty, Resource.ENHANCEMENT_POINTS))
enhancementPointProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(frBoardProperty, Resource.ENHANCEMENT_POINTS))
