import React, { FunctionComponent } from 'react';
import { BtWeaponBadge, ExWeaponBadge, FrWeaponBadge, LdWeaponBadge, WeaponBadgeLevel } from './weapon-badge';

export class WeaponBadgesImagesToShow {
  constructor(
    ex: WeaponBadgeLevel,
    ld: boolean,
    bt: WeaponBadgeLevel,
    fr: boolean,
  ) {
    this.ex = ex
    this.ld = ld
    this.bt = bt
    this.fr = fr
  }
  ex: WeaponBadgeLevel
  ld: boolean
  bt: WeaponBadgeLevel
  fr: boolean
}

interface WeaponBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  imagesToShow: WeaponBadgesImagesToShow
  style?: React.CSSProperties
}

const WeaponBadges: FunctionComponent<WeaponBadgeProps> = ({
  imagesToShow,
  style = {},
}) => {
  return (<div style={Object.assign({ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }, style)}>
    <FrWeaponBadge level={imagesToShow.fr ? WeaponBadgeLevel.NORMAL : WeaponBadgeLevel.NONE} />
    <BtWeaponBadge level={imagesToShow.bt}/>
    <LdWeaponBadge level={imagesToShow.ld ? WeaponBadgeLevel.NORMAL : WeaponBadgeLevel.NONE} />
    <ExWeaponBadge level={imagesToShow.ex}/>
  </div>)
}

export default WeaponBadges
