import React, {FunctionComponent, useState} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import { ObjectWithId } from '../abstract/abstract-custom-columns-component'
import { useTheme } from '@material-ui/core'
import MetaPropertyPicker from 'src/topbar/meta-property-picker/meta-property-picker'
import { acquiredProperty } from 'src/tracker/model/property/acquired/acquired-property'
import { CharacterProperty } from 'src/tracker/model/property/character-property'

interface InternalSelectorComponentProps {
  onIdPicked: (input: ObjectWithId | null) => void
}

export const InternalSelectorComponent: FunctionComponent<InternalSelectorComponentProps> = ({
  onIdPicked
}) => {
  const theme = useTheme()
  const [currentProperty, setCurrentProperty] = useState(acquiredProperty as CharacterProperty<any>)

  const addClicked = () => {
    onIdPicked(currentProperty)
  }

  const addSpacing = theme.spacing(1)
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <MetaPropertyPicker onPropertyUpdated={setCurrentProperty} property={currentProperty}/>
      <IconButton onClick={addClicked} style={{ padding: addSpacing }}>
        <Add />
      </IconButton>
    </div>
  )
}
