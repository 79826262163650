import {CharacterData, generateDefaultCharacterData} from 'src/tracker/model/tracker-model'
import { Character } from 'src/forecast/model/universal-data-model'
import { characterEditablePropertyList } from './property/group-of-groups'
import { lt } from 'lodash'
import { acquiredProperty } from './property/acquired/acquired-property'
import { AllCharacterData } from './components/all-character-data'
import { Region } from './components/region'
import { DateTime } from 'luxon'

export function characterDataValidator(input: CharacterData, previous: CharacterData | undefined, universalData: Character, when: DateTime, region: Region) {
  previous = Object.assign({}, generateDefaultCharacterData(), previous)
  input = Object.assign({}, generateDefaultCharacterData(), input)

  var output = Object.assign({}, input)
  const outputAllCharacterData = new AllCharacterData(universalData, output, undefined)

  const crystalLevelDiffers = input["Crystal Level"] !== previous?.["Crystal Level"]
  const levelDiffers = input.Level !== previous?.Level
  const acquiredSetToFalse = !input["Acquired"] && previous["Acquired"]
  if (acquiredSetToFalse ||
    acquiredProperty.options(outputAllCharacterData, when, region).length <= 1) { // cannot acquire this character yet!
    return Object.assign({}, generateDefaultCharacterData())
  } else {
    output["Acquired"] = true
  }

  if (crystalLevelDiffers && !levelDiffers) {
    if (input["Crystal Level"] < 81) {
      output.Level = Math.min(output.Level, 80)
    }
    if (input["Crystal Level"] < 71) {
      output.Level = Math.min(output.Level, 70)
    }
    if (input["Crystal Level"] < 61) {
      output.Level = Math.min(output.Level, 60)
    }
    if (input["Crystal Level"] < 51) {
      output.Level = Math.min(output.Level, 50)
    }

    if (input["Crystal Level"] > 0) {
      output.Level = Math.max(output.Level, 1)
    }
    if (input["Crystal Level"] <= 0) {
      output.Level = 0
    }
  }

  if (levelDiffers && !crystalLevelDiffers) {
    if (input.Level > 50) {
      output["Crystal Level"] = Math.max(output["Crystal Level"], 51)
    }
    if (input.Level > 60) {
      output["Crystal Level"] = Math.max(output["Crystal Level"], 61)
    }
    if (input.Level > 70) {
      output["Crystal Level"] = Math.max(output["Crystal Level"], 71)
    }
    if (input.Level > 80) {
      output["Crystal Level"] = Math.max(output["Crystal Level"], 81)
    }

    if (input.Level > 0) {
      output["Crystal Level"] = Math.max(output["Crystal Level"], 1)
    }

    if (input.Level <= 0) {
      output["Crystal Level"] = 0
    }
  }

  characterEditablePropertyList.forEach(property => {
    const max = property.getMax(outputAllCharacterData, when, region)
    const value = property.get(region, outputAllCharacterData)
    if (property.valueComparator(max, value, lt)) {
      output = property.set(outputAllCharacterData.personal, max)
    }
  })

  return output
}
