import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {levelsGroup} from 'src/tracker/model/column/groups/levels-group'
import {acquiredGroup} from 'src/tracker/model/column/groups/acquired-pseudo-group'
import {equipmentGroup} from 'src/tracker/model/column/groups/equipment-group'
import {summonBoardsGroup} from 'src/tracker/model/column/groups/summon-boards-group'
import {datesGroup} from 'src/tracker/model/column/groups/dates-group'
import { calculatedGroup } from './calculated-group'
import { staticGroup } from 'src/tracker/model/column/groups/static-group'
import { utilizationGroup } from 'src/tracker/model/column/groups/utilization-group'

export const allGroup = new ColumnGroup(
  '454d22d5-fbde-425c-a706-9aebb1cb576a',
  'All',
  [...staticGroup.columns, ...acquiredGroup.columns, ...levelsGroup.columns, ...equipmentGroup.columns,
    ...summonBoardsGroup.columns, ...utilizationGroup.columns, ...calculatedGroup.columns,
    ...datesGroup.columns]
)
