import React, { FunctionComponent, useCallback } from "react";
import PropertyPicker, { PropertyPickerProperty, SpecialPropertyValues } from 'src/tracker/property-picker/property-picker';
import { CharacterProperty } from 'src/tracker/model/property/character-property';
import { Typography, useTheme } from "@material-ui/core";
import { acquiredProperty } from "src/tracker/model/property/acquired/acquired-property";
import { TrackerEventDateCreator } from "../tracker-event-date-creator/tracker-event-date-creator";
import { useSelector } from "react-redux";
import { calculateValuesOfProperty, returnValueOrValidValue } from "src/forecast/forecast-selectors";
import { EnumerableCharacterProperty } from "src/tracker/model/property/enumerable-character-property";
import { PlannerDateProperty } from "src/tracker/model/property/planner-date-property";
import { TrackerValuePicker } from "src/tracker/tracker-value-picker/tracker-value-picker";
import { allocationDisplayablePropertyList } from "src/tracker/model/property/group-of-groups";
import { DateProperty, DatePropertyMode } from "src/tracker/model/property/date-property";
import { generateDatePropertyWeaponAppearance } from "src/tracker/model/property/date-property-weapon-appearance-scheme";
import { WeaponType } from "src/forecast/model/weapon-type";
import { CharacterDebut } from "src/forecast/model/character-debut";
import { MiscDefinitionValues } from "src/tracker/model/property/misc-definition-values";
import { generateDatePropertyDebut } from "src/tracker/model/property/date-property-debut-scheme";
import { generateDateProperty } from "src/tracker/model/property/date-property-misc-scheme";

interface MetaPropertyPickerProps {
  property: CharacterProperty<any>
  onPropertyUpdated: (property: CharacterProperty<any>) => void
  style?: React.CSSProperties
}

const MetaPropertyPicker: FunctionComponent<MetaPropertyPickerProps> = ({
  property,
  onPropertyUpdated,
  style = {},
}) => {
  const theme = useTheme()
  const possibleValueGenerator = useSelector(calculateValuesOfProperty)

  const addSpacing = theme.spacing(1)

  const onOuterPropertyPicked = useCallback((pickedValue: CharacterProperty<any> | SpecialPropertyValues) => {
    const existingDatePropMode = property instanceof DateProperty ? property.datePropertyMode : DatePropertyMode.FUTURE
    if (pickedValue === SpecialPropertyValues.CharacterWeaponDateProp) {
      onPropertyUpdated(generateDatePropertyWeaponAppearance(WeaponType.LD, existingDatePropMode))
    } else if (pickedValue === SpecialPropertyValues.CharacterDebutDateProp) {
      onPropertyUpdated(generateDatePropertyDebut(CharacterDebut.LD, existingDatePropMode))
    } else if (pickedValue === SpecialPropertyValues.CharacterMiscDateProp) {
      onPropertyUpdated(generateDateProperty(MiscDefinitionValues.Banner, DatePropertyMode.FUTURE))
    } else if (pickedValue === SpecialPropertyValues.PlannerDateProp) {
      onPropertyUpdated(new PlannerDateProperty(acquiredProperty, false))
    } else if (pickedValue instanceof CharacterProperty) {
      onPropertyUpdated(pickedValue)
    }
  }, [property, onPropertyUpdated])

  const updateUpstream = useCallback((property: EnumerableCharacterProperty<any>, value: any) => {
    value = returnValueOrValidValue(value, possibleValueGenerator(property))
    const newDateProperty = new PlannerDateProperty(property, value)
    onOuterPropertyPicked(newDateProperty)
  }, [possibleValueGenerator, onOuterPropertyPicked])

  const updatePropertyConversion = (trackerProperty: PropertyPickerProperty) => {
    if (property instanceof PlannerDateProperty && trackerProperty instanceof EnumerableCharacterProperty) {
      updateUpstream(trackerProperty, property.value)
    }
  }

  const updateValue = (value: any) => {
    if (property instanceof PlannerDateProperty) {
      updateUpstream(property.trackerProperty, value)
    }
  }

  const trackerPlannerDateCreator = property instanceof PlannerDateProperty && (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography style={{ padding: theme.spacing(1) }}>The date when characters reach</Typography>
      <PropertyPicker onPropertyPicked={updatePropertyConversion} style={{ padding: theme.spacing(1) }} visibleOptions={allocationDisplayablePropertyList} property={property.trackerProperty}/>
      <TrackerValuePicker filterPropertySelected={property.trackerProperty} currentValue={property.value} setCurrentFilteredValue={updateValue} />
    </div>
  )

  return (
    <div style={Object.assign({ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }, style)}>
      <PropertyPicker onPropertyPicked={onOuterPropertyPicked} style={{ padding: addSpacing }} property={property}/>
      {trackerPlannerDateCreator}
      <TrackerEventDateCreator currentTrackerValue={property} onValidDateProperty={onPropertyUpdated}/>
    </div>
  )
}

export default MetaPropertyPicker
