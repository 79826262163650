import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {levelColumn} from 'src/tracker/model/column/variable/levels/level-column'
import {crystalLevelColumn} from 'src/tracker/model/column/variable/levels/crystal-level-column'
import {characterBoardColumn} from 'src/tracker/model/column/variable/levels/character-board-column'
import { ldBoardColumn } from '../variable/levels/ld-board-column'
import { forceEnhancementColumn } from '../variable/levels/force-enhancement-column'
import { frBoardColumn } from '../variable/levels/fr-board-column'

export const levelsGroup = new ColumnGroup(
  '44adf741-3ff5-402d-8f25-f14972bb8447',
  'Levels',
  [levelColumn, crystalLevelColumn, characterBoardColumn, ldBoardColumn, forceEnhancementColumn, frBoardColumn]
)
