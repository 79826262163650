import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import { getShowLocalTime} from 'src/preferences/preferences-selectors'
import { writeShowLocalTime} from 'src/preferences/preferences-effects'
import generateToggle from 'src/preferences/widgets/generate-toggle'

const TITLE = "Miscellaneous forecast toggles"

function ForecastTogglesComponent() {
  const showLocalTime = useSelector(getShowLocalTime)

  const dispatch = useDispatch()

  const defaultShowLocalTimeCallback = useCallback((localTimeVal: boolean) => {
    dispatch(writeShowLocalTime(localTimeVal))
  }, [dispatch])
  const handleShowLocalTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    defaultShowLocalTimeCallback(event.target.checked)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {generateToggle("Show local time: In addition to showing a timer until when an event starts/ends, will also show the time in your browser's timezone of the start/end of the event.",
        showLocalTime, handleShowLocalTimeChange)}
    </div>
  )
}

export const ForecastTogglesWidget = new PreferencesWidget(
  <ForecastTogglesComponent />,
  TITLE,
)
