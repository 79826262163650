import { Uuid } from "src/forecast/model/universal-data-model"
import { EditableCharacterProperty } from "src/tracker/model/property/editable-character-property"
import { summonGroup } from "src/tracker/model/property/summon/summon-group"
import { Resource, SummonBoardStatus } from "src/tracker/model/tracker-model"
import { BannerAllocation } from "../../planner-model"
import { CharacterResourceProperty } from "./character-resource-property"
import { DailyResourceStats } from "./resource-property"

export class BannerResourceProperty extends CharacterResourceProperty {
  constructor(
    id: Uuid,
    name: string,
    resourceName: string,
    trackerResource: Resource,
    displayInBannerContext: boolean = true,
    dailyResourceStats: DailyResourceStats | null = null
  ) {
    super(id, name, resourceName, trackerResource, dailyResourceStats)
    this.getResourceFromCharacterDataAccessors.push(
      (characterData, negate) => {
        return summonGroup.group.map(property => {
          const castedProperty = property as EditableCharacterProperty<SummonBoardStatus>
          const value = castedProperty.getFromCharacterData(characterData)
          return castedProperty.valueToResource(value, trackerResource) * (!!negate ? -1 : 1)
        }).reduce((a, b) => a + b, 0)
      })
    this.getResourceFromBannerAllocation = (specificAllocation: BannerAllocation, negate?: boolean) => {
        return (specificAllocation[resourceName] || 0) * (!!negate ? -1 : 1)
      }
    this.setResourceFromBannerAllocation = (specificAllocation: BannerAllocation, newValue: number) => {
      return Object.assign({}, specificAllocation, {[resourceName]: newValue})
    }
    this.expenditureAccessors.push((totalAllocation, negate) =>
      Object.values(totalAllocation.eventAllocation.bannerAllocations).map(bannerAllocation => {
        return this.getResourceFromBannerAllocation(bannerAllocation, negate)
      }).reduce((a, b) => a + b, 0)
    )
    this.displayInBannerContext = displayInBannerContext
  }
  getResourceFromBannerAllocation: (specificAllocation: BannerAllocation, negate?: boolean) => number
  setResourceFromBannerAllocation: (specificAllocation: BannerAllocation, newValue: number) => BannerAllocation
  displayInBannerContext: boolean
}
