import { PropertyGroup } from "../property-group";
import { ifritProperty } from "./ifrit-property";
import { shivaProperty } from "./shiva-property";
import { ramuhProperty } from "./ramuh-property";
import { leviathanProperty } from "./leviathan-property";
import { brothersProperty } from "./brothers-property";
import { pandemoniumProperty } from "./pandemonium-property";
import { diabolosProperty } from "./diabolos-property";
import { alexanderProperty } from "./alexander-property";
import { odinProperty } from "./odin-property";
import { bahamutProperty } from "./bahamut-property";

export const summonGroup = new PropertyGroup("Summon Boards", [ifritProperty, shivaProperty, ramuhProperty, leviathanProperty, brothersProperty, pandemoniumProperty, diabolosProperty, alexanderProperty, odinProperty, bahamutProperty])
