import { CharacterDebut } from "src/forecast/model/character-debut";
import { DateTransienceMode } from "../date-property";
import { DatePropertyEventDefinition } from "../date-property-event-definition";
import { MiscDefinitionValues } from "../misc-definition-values";

const majorCountedDebuts = new Set([CharacterDebut.ACQUIRABLE, CharacterDebut.AWAKENING_60,
  CharacterDebut.EX, CharacterDebut.EX_REALIZATION, CharacterDebut.LD,
  CharacterDebut.LD_CHARACTER_BOARD, CharacterDebut.BT_REALIZATION, CharacterDebut.BT,
  CharacterDebut.AWAKENING_90,
  CharacterDebut.FR])

export const majorDefinition = new DatePropertyEventDefinition(
  MiscDefinitionValues.Major,
  "Major",
  DateTransienceMode.ONGOING,
  (appearance) => appearance.debuts.find(debut => majorCountedDebuts.has(debut)) !== undefined,
)
