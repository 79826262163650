import {booleanConverter} from 'src/tracker/model/tracker-model-converter'
import { EditableCharacterProperty } from '../editable-character-property'
import { acquirableOptionFunction } from './acquirable-option-function'

export const acquiredProperty = new EditableCharacterProperty(
  "047a0f6e-11b9-4764-8764-362a63cb3242",
  "Acquired",
  "Acquired",
  booleanConverter,
  "Acquired",
  acquirableOptionFunction,
)
