import { weaponNuggetProperty } from "../../property/resource/weapon-nugget-property";
import { ResourceColumn } from "../planner-column";

export const weaponNuggetsColumn = new ResourceColumn(
  'Weapon Nuggets',
  'stl_limit_break_item_4_cropped.png',
  'weaponNuggets',
  weaponNuggetProperty,
  undefined,
  'stl_limit_break_item_8_cropped.png',
  'weaponIngots',
)
