import {CharacterData, CharacterDataValue, generateDefaultCharacterData} from 'src/tracker/model/tracker-model'
import {CharacterValueConverter} from 'src/tracker/model/tracker-model-converter'
import { Uuid } from 'src/forecast/model/universal-data-model'
import { EnumerableCharacterProperty, ResourceRetrievalFunction } from './enumerable-character-property'
import { AllCharacterData } from '../components/all-character-data'
import { Region } from '../components/region'
import { DateTime } from 'luxon'

export class EditableCharacterProperty<T extends CharacterDataValue> extends EnumerableCharacterProperty<T> {
  constructor(
    id: Uuid,
    name: string,
    shortName: string,
    converter: CharacterValueConverter<T>,
    propertyName: string,
    options: (universalCharacterData: AllCharacterData, when: DateTime, region: Region) => T[],
    resourceRetrievalOverrides: ResourceRetrievalFunction<T>[] = []
  ) {
    const getFunction = (region: Region, character?: AllCharacterData) => {
      return (character?.personal[propertyName] || generateDefaultCharacterData()[propertyName]) as T
    }
    super(id, name, shortName, converter, getFunction, options, resourceRetrievalOverrides)
    this.set = (characterData, newValue) => {
      return Object.assign({}, characterData, { [propertyName]: newValue })
    }
    this.getFromCharacterData = (characterData: CharacterData) => {
      return characterData[propertyName] as T
    }
  }
  set: (characterData: CharacterData, newValue: T) => CharacterData
  getFromCharacterData: (characterData: CharacterData) => T
}
