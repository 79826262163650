import { armorProperty } from 'src/tracker/model/property/equipment/armor/armor-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const highArmorPagesProperty = createStandardCharacterResourceProperty(
  'cfd73b9d-c069-485e-8c62-b1d8bd4dbf19',
  'High Armor Pages',
  'highArmorPages',
  armorProperty,
  Resource.HIGH_ARMOR_PAGES,
)
