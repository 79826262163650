import {CustomColumnGroups, CustomColumnGroup} from 'src/preferences/model/preferences-model'
import { deserializeFilterSpecifications, deserializeSortSpecifications, serializeFilterSpecifications, serializeSortSpecifications } from 'src/tracker/model/selector-model'
import { SavedView } from './saved-view'

export const CUSTOM_GROUP_LIMIT = 3
export const NAME_SIZE_LIMIT = 128
export const COLUMNS_IN_GROUP_LIMIT = 64

export const SAVED_VIEWS_LIMIT = 5
export const FILTERS_LIMIT = 20

export function customColumnGroupsValidator(input: CustomColumnGroups, previous: CustomColumnGroups) {
  var output = input.slice()
  if (Object.keys(input).length > CUSTOM_GROUP_LIMIT) {
    output = previous.slice()
  }

  output = output.map(inputGroup => {
    const prevGroup = previous.find(previousGroup => previousGroup.id === inputGroup.id) || null
    return customColumnGroupValidator(inputGroup, prevGroup)
  })
  return output
}

function customColumnGroupValidator(input: CustomColumnGroup, previous: CustomColumnGroup | null) {
  const output = Object.assign({}, input)
  if (input.name.length > NAME_SIZE_LIMIT) {
    output.name = output.name.substring(0, NAME_SIZE_LIMIT)
  }

  if (input.columns.length > COLUMNS_IN_GROUP_LIMIT) {
    output.columns = output.columns.slice(0, COLUMNS_IN_GROUP_LIMIT)
  }
  return output
}

export function savedViewsValidator(savedViews: SavedView[], previous: SavedView[]) {
  var output = savedViews.slice()
  if (Object.keys(savedViews).length > SAVED_VIEWS_LIMIT) {
    output = previous.slice()
  }

  return output.map((inputGroup, index) => {
    const prevGroup = previous[index] || null
    return savedViewValidator(inputGroup, prevGroup)
  })
}

function savedViewValidator(input: SavedView, previous: SavedView) {
  const output = Object.assign({}, input)
  if (input.name.length > NAME_SIZE_LIMIT) {
    output.name = output.name.substring(0, NAME_SIZE_LIMIT)
  }

  const filterSpecs = deserializeFilterSpecifications(input.filterSpecifications)
  if (filterSpecs.length > FILTERS_LIMIT) {
    const truncatedFilterSpecs = filterSpecs.slice(0, FILTERS_LIMIT)
    output.filterSpecifications = serializeFilterSpecifications(truncatedFilterSpecs)
  }

  const sortSpecs = deserializeSortSpecifications(input.sortSpecifications)
  if (sortSpecs.length > FILTERS_LIMIT) {
    const truncatedSortSpecs = sortSpecs.slice(0, FILTERS_LIMIT)
    output.sortSpecifications = serializeSortSpecifications(truncatedSortSpecs)
  }
  return output
}
