import React, { FunctionComponent, useCallback } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from "react-redux";
import { isEditable, isEditModeToggleable } from "../tracker-selectors";
import { useTheme } from "@material-ui/core";
import { setEditModeActionCreator } from "../tracker-actions";

interface EditToggleProps {

}

const EditToggle: FunctionComponent<EditToggleProps> = () => {
  const theme = useTheme()
  const editMode = useSelector(isEditable)
  const editModeToggleEnabled = useSelector(isEditModeToggleable)
  const title = "Editable"

  const dispatch = useDispatch()
  const setEditModeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditModeActionCreator(event.target.checked))
  }, [dispatch])

    return <FormControlLabel
      control={
        <Switch
          name={title}
          color="primary"
          checked={editMode}
          onChange={setEditModeCallback}
          disabled={!editModeToggleEnabled}
        />
      }
      label={title}
      style={{ paddingInline: theme.spacing(2) }}
      disabled={!editModeToggleEnabled}
    />
}

export default EditToggle
