import { Resource } from 'src/tracker/model/tracker-model'
import { BannerResourceProperty } from '../resource-meta/banner-resource-property'

export const multiTicketProperty = new BannerResourceProperty(
  'fd132eec-aa1a-422f-807d-0d9d5ea47296',
  'Multi-Draw Tickets',
  'multiTickets',
  Resource.MULTI_TICKETS,
  true,
)
