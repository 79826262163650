import React, {useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from 'src/store/root-reducer'
import {closeAccountDeletionConfirmationDialog} from 'src/login/login-actions'
import {deleteAccount} from 'src/login/login-effects'

export default function AccountDeletionConfirmationDialog() {
  const open = useSelector((state: AppState) => state.login.accountDeletionConfirmationDialogOpen)
  const dispatch = useDispatch()

  const closeAccountDeletionConfirmationDialogCallback = useCallback(() => {
    dispatch(closeAccountDeletionConfirmationDialog())
  }, [dispatch])
  const submitAccountDeletionConfirmationDialogCallback = useCallback(() => {
    dispatch(closeAccountDeletionConfirmationDialog())
    dispatch(deleteAccount())
  }, [dispatch])

  return (
    <Dialog open={open} onClose={closeAccountDeletionConfirmationDialogCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Are you sure you want to delete your account?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This cannot be undone. All data will be deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAccountDeletionConfirmationDialogCallback} color="primary">
          Cancel
          </Button>
        <Button onClick={submitAccountDeletionConfirmationDialogCallback} color="secondary">
          Submit
          </Button>
      </DialogActions>
    </Dialog>
  )
}
