import { btNuggetsProperty } from "../../property/resource/bt-nuggets-property";
import { ResourceColumn } from "../planner-column";

export const btNuggetsColumn = new ResourceColumn(
  'BT Nuggets',
  'stl_limit_break_item_17_cropped.png',
  'btNuggets',
  btNuggetsProperty,
  undefined,
  'stl_limit_break_item_18_cropped.png',
  'btIngots',
)
