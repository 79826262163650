import React, {useCallback, useState} from 'react'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import {useDispatch, useSelector} from 'react-redux'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core'
import { parseTonberryTroupeCsv } from '../../preferences-effects';
import { getPreviewedPlannerData } from 'src/preferences/preferences-selectors';
import { commitPreviewedPlannerData } from '../../../planner/planner-effects';
import { setPreviewedPlannerDataActionCreator } from 'src/preferences/preferences-actions';
import Link from '@material-ui/core/Link';
import { PLANNER_PATH } from 'src/router/root-router';
import { navigateTo } from 'src/router/router-effects';
import DismissableSnackbar from 'src/utils/dismissable-snackbar/dismissable-snackbar';

const TITLE = "Import data"

function ImportPlannerDataComponent() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const importedData = useSelector(getPreviewedPlannerData)

  const [csvFile, setCsvFile] = useState(null as File | null)
  const [csvFileName, setCsvFileName] = useState("")
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)

  const importTtCsv = useCallback(() => {
    dispatch(parseTonberryTroupeCsv(csvFile))
  }, [dispatch, csvFile])
  const commitPreviewedImportDataCallback = useCallback(() => {
    dispatch(commitPreviewedPlannerData())
    setOpenSuccessSnackbar(true)
  }, [dispatch])
  const clearImportedData = useCallback(() => {
    dispatch(setPreviewedPlannerDataActionCreator(null))
  }, [dispatch])
  const goToPlannerCallback = useCallback(() => {
    dispatch(navigateTo(PLANNER_PATH))
  }, [dispatch])

  const changeCsvFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCsvFile(event.target.files?.[0] || null)
    setCsvFileName(event.target.value)
  }

  const importedDataReadout = (importedData === null) ? null :
    <div style={{paddingTop: theme.spacing(2)}}>
      <Typography>Your imported data is now displayed in the <Link onClick={goToPlannerCallback}>planner section</Link> of the app. To accept or reject it, choose the options below.</Typography>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <Button variant='contained' color='primary' style={{margin: theme.spacing(2)}} onClick={commitPreviewedImportDataCallback}>Commit previewed data (This will overwrite your previously existing data)</Button>
        <Button variant='contained' style={{margin: theme.spacing(2)}} onClick={clearImportedData}>Cancel data import (This will return you to the pre-import state)</Button>
      </div>
    </div>

  return <div>
    <Typography variant="h6">Import data from Tonberry Troupe tracker</Typography>
    <Typography>{"Download a .csv of your Tonberry Troupe tracker by going to File -> Download -> Comma-separated values (.csv, current sheet). Then browse to your file below and upload. "}</Typography>
    <div style={{display: 'flex'}}>
      <TextField
        style={{paddingInline: theme.spacing(2)}}
        variant='filled'
        type="file"
        value={csvFileName}
        onChange={changeCsvFile}
        inputProps={{
          accept: ".csv"
        }}
      />
      <Button variant='contained' onClick={importTtCsv}>OK</Button>
    </div>
    {importedDataReadout}
    <DismissableSnackbar open={openSuccessSnackbar} setOpen={setOpenSuccessSnackbar} text="Data successfully commited" />
  </div>
}

export const ImportPlannerDataWidget = new PreferencesWidget(
  <ImportPlannerDataComponent />,
  TITLE,
)
