export const CLOSE_CREDITS = "CLOSE_CREDITS"
export const OPEN_CREDITS = "OPEN_CREDITS"

export function closeCreditsActionCreator() {
  return {
    type: CLOSE_CREDITS,
  }
}

export function openCreditsActionCreator() {
  return {
    type: OPEN_CREDITS,
  }
}

export type CloseCreditsActionCreator = typeof closeCreditsActionCreator
export type OpenCreditsActionCreator = typeof openCreditsActionCreator
export type CloseCreditsAction = ReturnType<CloseCreditsActionCreator>
export type OpenCreditsAction = ReturnType<OpenCreditsActionCreator>
