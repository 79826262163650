import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak, Resource} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { WeaponType } from 'src/forecast/model/weapon-type'
import { CharacterDebut } from 'src/forecast/model/character-debut'

export const ldProperty = new WeaponDataProperty(
  "56df61cc-25e7-40ff-80aa-d38df8e160ef",
  "LD",
  "LD",
  standardLimitBreakConverter,
  "LD",
  WeaponType.LD,
  (characterData, when, region) => {
    const ldWeaponAppearsBeforeNow = debutBeforeTime(region, characterData.universal, when)(CharacterDebut.LD)
    const allLdWeaponLimitBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (ldWeaponAppearsBeforeNow) {
      return allLdWeaponLimitBreakValues
    } else {
      return allLdWeaponLimitBreakValues.slice(0, 1)
    }
  },
  [
    {resource: Resource.CP, valueToResource: (value) => {
      const valueLookupMap = {
        [StandardLimitBreak.UNOBTAINED]: 0,
        [StandardLimitBreak.ZERO]: 90,
        [StandardLimitBreak.ONE]: 92,
        [StandardLimitBreak.TWO]: 95,
        [StandardLimitBreak.THREE]: 100,
      }
      return valueLookupMap[value] || 0
    }}
  ]
)
