import { defaultEntropyUtilization } from "../../tracker-model"
import { EnumerableCharacterProperty } from "../enumerable-character-property"
import { defaultCharacterDataNumberConverter } from "../../tracker-model-converter"

export const entropyProperty = new EnumerableCharacterProperty<number>(
  "5f353254-66b3-4e5f-82cf-7a624696b414",
  "Dimension's End: Entropy",
  "Entropy",
  defaultCharacterDataNumberConverter,
  (region, character) => (character?.deUtilization?.entropyUtilization || 0),
  () => {
    const entropyList = Object.keys(defaultEntropyUtilization).map(defaultCharacterDataNumberConverter.valueFromString)
    entropyList.unshift(0)
    return entropyList
  }
)
