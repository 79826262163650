import {Banner, Event} from 'src/forecast/model/universal-data-model'
import React, {FunctionComponent} from 'react';
import {default as CharacterImage, imgScalingStyling} from 'src/forecast/character-image/character-image'

interface BannerRowProps {
  banner: Banner
  event: Event
}

const BannerRow: FunctionComponent<BannerRowProps> = ({
  banner,
  event
}) => {
  const characterMap = banner.weapons?.map(weapon => {
    return { [weapon.character.id]: weapon.character }
  }).reduce((accumulator, current) => Object.assign({}, accumulator, current), {})

  const charactersInBanner = Object.keys(characterMap).length
  if (charactersInBanner <= 0) {
    return null
  }

  // TODO, perhaps: use milestones to determine that we can go to 3 here, when looking at historical banners
  const requiredIcons = 4

  let placeholder: JSX.Element[] = []
  for (let i = charactersInBanner; i < requiredIcons; i++) {
    placeholder.push(<div style={Object.assign({}, { position: "relative", margin: "4px" }, imgScalingStyling("128px"))} key={`placeholder ${i}`} />)
  }
  const characterImages = Object.values(characterMap).map(character => {
    return (
      <CharacterImage
        key={character.id}
        character={character}
        clickable={true}
        shouldGreyIfAcquired
        additionalCharacterInfo={[event, banner]}
      />
    )
  })
  return <div style={{ display: 'flex', minWidth: '0px' }}>
    {characterImages}
    {placeholder}
  </div>
}

export default BannerRow
