import React, {useCallback, useState} from 'react'
import { PreferencesWidget } from 'src/preferences/widgets/preferences-widget'
import {useDispatch, useSelector} from 'react-redux'
import { lookupOotrackerIdThunk } from 'src/preferences/preferences-effects'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core'
import { getDefaultRegion, getPreviewedTrackerData } from 'src/preferences/preferences-selectors'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Region } from 'src/tracker/model/components/region'
import { numberOfSetEntropyTiers } from 'src/tracker/model/tracker-model'
import { setPreviewedTrackerDataActionCreator } from 'src/preferences/preferences-actions'
import { commitPreviewedData } from 'src/tracker/tracker-effects'
import { navigateTo } from 'src/router/router-effects'
import { TRACKER_PATH } from 'src/router/root-router'
import Link from '@material-ui/core/Link';
import DismissableSnackbar from 'src/utils/dismissable-snackbar/dismissable-snackbar'

const TITLE = "Import data"

function ImportDataComponent() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const defaultRegion = useSelector(getDefaultRegion)
  const importedData = useSelector(getPreviewedTrackerData)

  const [friendCode, setFriendCode] = useState("")
  const [friendCodeRegion, setFriendCodeRegion] = useState(defaultRegion)
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)

  const lookupOotrackerIdCallback = useCallback(() => {
    dispatch(lookupOotrackerIdThunk(friendCode, friendCodeRegion))
  }, [dispatch, friendCode, friendCodeRegion])
  const commitPreviewedImportDataCallback = useCallback(() => {
    dispatch(commitPreviewedData())
    setOpenSuccessSnackbar(true)
  }, [dispatch])
  const clearImportedData = useCallback(() => {
    dispatch(setPreviewedTrackerDataActionCreator(null))
  }, [dispatch])
  const goToTrackerCallback = useCallback(() => {
    dispatch(navigateTo(TRACKER_PATH))
  }, [dispatch])

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const region = event.target.checked ? Region.JP : Region.GL
    setFriendCodeRegion(region)
  };
  const changeFriendCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFriendCode(event.target.value)
  }

  const importedDataReadout = (importedData === null) ? null :
    <div style={{paddingTop: theme.spacing(2)}}>
      <Typography>Your imported data is now displayed in the <Link onClick={goToTrackerCallback}>tracker section</Link> of the app. To accept or reject it, choose the options below.</Typography>
      <Typography>Imported:</Typography>
      <Typography>GL characters: {Object.keys(importedData.GL.characters).length}</Typography>
      <Typography>JP characters: {Object.keys(importedData.JP.characters).length}</Typography>
      <Typography>GL entropy tiers: {numberOfSetEntropyTiers(importedData.GL.utilization.Entropy)}</Typography>
      <Typography>JP entropy tiers: {numberOfSetEntropyTiers(importedData.JP.utilization.Entropy)}</Typography>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <Button variant='contained' color='primary' onClick={commitPreviewedImportDataCallback} style={{margin: theme.spacing(2)}}>Commit previewed data (This will overwrite your previously existing data)</Button>
        <Button variant='contained' onClick={clearImportedData} style={{margin: theme.spacing(2)}}>Cancel data import (This will return you to the pre-import state)</Button>
      </div>
    </div>

  return <div>
    <Typography variant="h6">Import data from ootracker</Typography>
    <Typography>Enter your friend code and region and press OK. If you have data for both regions, either code and region pair will import data for both regions. Make sure you have entered your friend code into ootracker if you're having issues.</Typography>
    <div style={{display: 'flex'}}>
      <TextField
        style={{paddingInline: theme.spacing(2)}}
        margin='dense'
        variant='filled'
        type="text"
        value={friendCode}
        onChange={changeFriendCode}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            name={friendCodeRegion}
            checked={friendCodeRegion === Region.JP}
            onChange={handleToggleChange}
          />
        }
        label={friendCodeRegion}
      />
      <Button variant='contained' onClick={lookupOotrackerIdCallback}>OK</Button>
    </div>
    {importedDataReadout}
    <DismissableSnackbar open={openSuccessSnackbar} setOpen={setOpenSuccessSnackbar} text="Data successfully commited" />
  </div>
}

export const ImportDataWidget = new PreferencesWidget(
  <ImportDataComponent />,
  TITLE,
)
