import {SET_COLUMN_GROUP,
  SetColumnGroupAction,
  DisplayEntropyAction,
  DISPLAY_ENTROPY,
  SET_EDIT_MODE,
  SetEditModeAction,
  STORE_CHARACTER_MASS_EDIT,
  StoreCharacterMassEditAction,
  ClearStoredCharacterAction,
  CLEAR_STORED_CHARACTER,
  STORE_TRACKER_CHARACTER_DATA,
  StoreTrackerCharacterDataAction,
  ClearStoredTrackerCharacterDataAction,
  CLEAR_TRACKER_CHARACTER_DATA,
  SetGroupedModeAction,
  SET_GROUPED_MODE,
  SetSortSpecificationsAction,
  SET_SORT_SPECIFICATIONS} from 'src/tracker/tracker-actions'
import { CharacterData, TrackerCharacterData } from './model/tracker-model'
import { deserializeSortSpecifications, SortSpecification } from "./model/selector-model"
import { Uuid } from 'src/forecast/model/universal-data-model'
import { SetSavedViewAction, SET_SAVED_VIEW } from 'src/forecast/forecast-actions'

export enum EditMode {
  UNSET,
  READ_ONLY,
  EDITABLE,
}

const initialState = {
  sortSpecification: [] as SortSpecification[],
  sortSpecificationsSet: false,
  columnGroupId: null as Uuid | null,
  displayEntropy: false,
  editMode: EditMode.UNSET,
  storedCharacterId: null as Uuid | null,
  storedCharacterData: null as CharacterData | null,
  storedTrackerCharacterData: null as TrackerCharacterData | null,
  groupedMode: false,
}

type TrackerState = typeof initialState

export default function trackerReducer(state = initialState, action: SetColumnGroupAction | DisplayEntropyAction | SetEditModeAction
  | StoreCharacterMassEditAction | ClearStoredCharacterAction | StoreTrackerCharacterDataAction
  | ClearStoredTrackerCharacterDataAction | SetGroupedModeAction | SetSavedViewAction | SetSortSpecificationsAction): TrackerState {
  switch (action.type) {
    case SET_COLUMN_GROUP:
      const setColumnGroupAction = action as SetColumnGroupAction
      return Object.assign({}, state, {columnGroupId: setColumnGroupAction.columnGroup.id})

    case DISPLAY_ENTROPY:
      const displayEntropyAction = action as DisplayEntropyAction
      return Object.assign({}, state, {displayEntropy: displayEntropyAction.display})

    case SET_EDIT_MODE:
      const setEditModeAction = action as SetEditModeAction
      const assignableEditMode = setEditModeAction.editMode ? EditMode.EDITABLE : EditMode.READ_ONLY
      return Object.assign({}, state, {editMode: assignableEditMode})

    case STORE_CHARACTER_MASS_EDIT:
      const storeCharacterMassEditAction = action as StoreCharacterMassEditAction
      return Object.assign({}, state, { storedCharacterId: storeCharacterMassEditAction.characterId,
        storedCharacterData: storeCharacterMassEditAction.characterData})

    case CLEAR_STORED_CHARACTER:
      return Object.assign({}, state, { storedCharacterId: null as Uuid | null, storedCharacterData: null as CharacterData | null})

    case STORE_TRACKER_CHARACTER_DATA:
      const storedTrackerCharacterDataAction = action as StoreTrackerCharacterDataAction
      return Object.assign({}, state, { storedTrackerCharacterData: storedTrackerCharacterDataAction.trackerCharacterData})

    case CLEAR_TRACKER_CHARACTER_DATA:
      return Object.assign({}, state, { storedTrackerCharacterData: null as TrackerCharacterData | null})

    case SET_GROUPED_MODE:
      const groupedModeAction = action as SetGroupedModeAction
      return Object.assign({}, state, {groupedMode: groupedModeAction.groupedMode})

    case SET_SORT_SPECIFICATIONS:
      const setSortSpecificationsAction = action as SetSortSpecificationsAction
      return Object.assign({}, state, { sortSpecification: setSortSpecificationsAction.sortSpecifications,
        sortSpecificationsSet: true
      })

    case SET_SAVED_VIEW:
      const setSavedViewAction = action as SetSavedViewAction
      const sortSpecification = deserializeSortSpecifications(setSavedViewAction.savedView.sortSpecifications)
      return Object.assign({}, state, {sortSpecification,
        sortSpecificationsSet: true})

    default:
      return state
  }
}
