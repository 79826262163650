import { PropertyGroup } from "../property-group";
import { generateDatePropertyWeaponAppearance } from "../date-property-weapon-appearance-scheme";
import { DatePropertyMode } from "../date-property";
import { generateDatePropertyDebut } from "../date-property-debut-scheme";
import { CharacterDebut } from "src/forecast/model/character-debut";
import { WeaponType } from "src/forecast/model/weapon-type";
import { MiscDefinitionValues } from "../misc-definition-values";
import { generateDateProperty } from "../date-property-misc-scheme";

export const datesGroup = new PropertyGroup("Dates", [
  generateDateProperty(MiscDefinitionValues.Banner, DatePropertyMode.FUTURE),
  generateDateProperty(MiscDefinitionValues.Banner, DatePropertyMode.PAST),
  generateDateProperty(MiscDefinitionValues.Major, DatePropertyMode.FUTURE),
  generateDateProperty(MiscDefinitionValues.Major, DatePropertyMode.PAST_AND_PRESENT),
  generateDatePropertyDebut(CharacterDebut.LD, DatePropertyMode.ALL),
  generateDatePropertyWeaponAppearance(WeaponType.LD, DatePropertyMode.FUTURE),
  generateDatePropertyWeaponAppearance(WeaponType.LD, DatePropertyMode.PAST),
  generateDatePropertyDebut(CharacterDebut.BT, DatePropertyMode.ALL),
  generateDatePropertyWeaponAppearance(WeaponType.BT, DatePropertyMode.FUTURE),
  generateDatePropertyWeaponAppearance(WeaponType.BT, DatePropertyMode.PAST),
  generateDatePropertyDebut(CharacterDebut.FR, DatePropertyMode.ALL),
  generateDatePropertyWeaponAppearance(WeaponType.FR, DatePropertyMode.FUTURE),
  generateDatePropertyWeaponAppearance(WeaponType.FR, DatePropertyMode.PAST),
  generateDatePropertyDebut(CharacterDebut.LD_CHARACTER_BOARD, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.AWAKENING_90, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.HIGH_ARMOR_REALIZATION, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.BT_REALIZATION, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.FORCE_ENHANCEMENT, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.FR_CHARACTER_BOARD, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.FR_CHARACTER_BOARD, DatePropertyMode.FUTURE),
  generateDatePropertyDebut(CharacterDebut.FORCE_ENHANCEMENT_50, DatePropertyMode.ALL),
  generateDatePropertyDebut(CharacterDebut.FORCE_ENHANCEMENT_50, DatePropertyMode.FUTURE),
])
