import { Resource } from 'src/tracker/model/tracker-model'
import { DailyResourceStats, ResourceProperty } from '../resource-meta/resource-property'

export const otherProperty = new ResourceProperty(
  'b3789c4e-f1c3-4397-87c3-560636642776',
  'Other',
  null,
  Resource.OTHER,
  new DailyResourceStats(1, 1),
)
