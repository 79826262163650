export const SET_EVENT_PANEL_TAB = "SET_EVENT_PANEL_TAB"

export enum EventPanelTab {
  BANNERS_TAB = "Banners",
  RESOURCES_TAB = "Resources",
}

export function setEventPanelTabActionCreator(tab: EventPanelTab) {
  return {
    type: SET_EVENT_PANEL_TAB,
    tab: tab,
  }
}

export type SetEventPanelTabActionCreator = typeof setEventPanelTabActionCreator
export type SetEventPanelTabAction = ReturnType<SetEventPanelTabActionCreator>
