import { CharacterProperty } from "./character-property"

export class PropertyGroup {
  constructor(
    name: string,
    group: CharacterProperty<any>[],
  ) {
    this.name = name
    this.group = group
  }
  name: string
  group: CharacterProperty<any>[]
}
