import {CloseCreditsAction, OpenCreditsAction, CLOSE_CREDITS, OPEN_CREDITS} from 'src/irl/irl-actions'

const initialState = {
  creditsOpen: false,
}

export type IrlState = typeof initialState

export function irlReducer(state = initialState, action: CloseCreditsAction | OpenCreditsAction) {
  switch (action.type) {
    case CLOSE_CREDITS:
      return Object.assign({}, state, {creditsOpen: false})
    case OPEN_CREDITS:
      return Object.assign({}, state, {creditsOpen: true})
    default:
      return state
  }
}
