import { Uuid } from "src/forecast/model/universal-data-model"
import { Resource } from "src/tracker/model/tracker-model"
import { ResourcesList, TotalAllocation } from "../../planner-model"
import { PlannerProperty } from "../static/planner-property"

export class DailyResourceStats {
  constructor(
    dailyTokensPerRedeem: number,
    resourcesPerRedeem: number,
  ) {
    this.dailyTokensPerRedeem = dailyTokensPerRedeem
    this.resourcesPerRedeem = resourcesPerRedeem
  }
  dailyTokensPerRedeem: number
  resourcesPerRedeem: number
}

export class ResourceProperty extends PlannerProperty {
  constructor(
    id: Uuid,
    name: string,
    resourceName: string | null,
    resource: Resource,
    dailyResourceStats: DailyResourceStats | null = null,
    eventAccessor?: (totalAllocation: TotalAllocation, negate?: boolean) => number,
  ) {
    super(id, name)
    this.resource = resource
    this.dailyResourceStats = dailyResourceStats
    this.resourcesListAccessor = (currentResources?: ResourcesList, negate?: boolean) => {
      if (resourceName === null || currentResources === undefined) {
        return 0
      }
      return (currentResources[resourceName] || 0) * (!!negate ? -1 : 1)
    }
    this.resourcesListReducer = (currentResources: ResourcesList, newValue: number) => {
      if (resourceName === null) {
        return Object.assign({}, currentResources)
      }
      return Object.assign({}, currentResources, {[resourceName]: newValue})
    }
    this.eventAccessor = eventAccessor === undefined ? (totalAllocation: TotalAllocation, negate?: boolean) => {
      if (resourceName === null) {
        return 0
      }
      return (totalAllocation.event.eventResources[resourceName] || 0) * (!!negate ? -1 : 1)
    } : eventAccessor
    this.dailyResourceAccessor = (totalAllocation: TotalAllocation, negate?: boolean) => {
      return this.resourcesListAccessor(totalAllocation.dailyResources) * (!!negate ? - 1 : 1)
    }
    this.totalIncomeAccessor = (totalAllocation, negate) => {
      return this.eventAccessor(totalAllocation, negate) + this.dailyResourceAccessor(totalAllocation, negate)
    }

    this.totalExpenditureAccessor = (totalAllocation, negate?: boolean) => {
      return this.expenditureAccessors.map(expenditureAccessor => {
        return expenditureAccessor(totalAllocation, !!negate)
      }).reduce((a, b) => a + b, 0)
    }
    this.netEventAccessor = (totalAllocation: TotalAllocation, negate?: boolean) => {
      return this.totalIncomeAccessor(totalAllocation, negate) - this.totalExpenditureAccessor(totalAllocation, negate)
    }
    this.resourcesAfterEventAccessor = (totalAllocation: TotalAllocation, negate?: boolean) => {
      return this.resourcesListAccessor(totalAllocation.currentResources) + this.netEventAccessor(totalAllocation, negate)
    }
    this.expenditureAccessors = [((totalAllocation, negate) => this.resourcesListAccessor(totalAllocation.eventAllocation.miscellaneousAllocations, negate))]
  }
  resource: Resource
  resourcesListAccessor: (currentResources?: ResourcesList, negate?: boolean) => number
  eventAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  dailyResourceAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  totalIncomeAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  totalExpenditureAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  netEventAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  resourcesAfterEventAccessor: (totalAllocation: TotalAllocation, negate?: boolean) => number
  resourcesListReducer: (currentResources: ResourcesList, newValue: number) => ResourcesList
  expenditureAccessors: ((totalAllocation: TotalAllocation, negate: boolean) => number)[]
  dailyResourceStats: DailyResourceStats | null
}
