import { btProperty } from 'src/tracker/model/property/equipment/weapon/bt-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'

export const btNuggetsProperty = createStandardCharacterResourceProperty(
  '43180ec5-8ff7-47d8-b94d-61008df57f5b',
  'BT Nuggets',
  'btNuggets',
  btProperty,
  Resource.BT_NUGGETS,
)
