import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import { DateProperty } from '../../property/date-property'
import { datesGroup as propertyDatesGroup } from '../../property/dates/dates-group'
import { DateColumn } from '../tracker-column'

export const datesGroup = new ColumnGroup(
  '0f89acec-36b7-4b21-abf1-cba2a0be801d',
  'Dates',
  propertyDatesGroup.group.map(property => new DateColumn("125px", property as DateProperty<any>))
)
