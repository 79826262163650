import { Uuid } from "src/forecast/model/universal-data-model"

export class PlannerProperty {
  constructor(
    id: Uuid,
    name: string,
  ) {
    this.id = id
    this.name = name
  }
  id: Uuid
  name: string
}
