import React, {FunctionComponent, useCallback, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createGetAllDeUtilizedCharacters, getFluffyMoment, getTrackerDocumentLocation, isEditable} from 'src/tracker/tracker-selectors'
import DocumentLoadingComponent from 'src/utils/document-loading-component'
import { panelDisplayableGroups } from 'src/tracker/model/property/group-of-groups'
import { Character, Uuid } from 'src/forecast/model/universal-data-model'
import { AllCharacterData } from 'src/tracker/model/components/all-character-data'
import PropertyGroups from '../property-groups/property-groups'
import { createGetTotalAllocations, EventCharacterDataPair } from 'src/planner/planner-selectors'
import { CharacterData, generateDefaultCharacterData } from 'src/tracker/model/tracker-model'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, useTheme } from '@material-ui/core'
import { isString } from 'lodash'
import Typography from '@material-ui/core/Typography'
import { getActiveRegion } from 'src/router/router-selectors'
import { getEventTime } from 'src/forecast/forecast-selectors'
import { TRACKER_SPECIAL_VALUE } from 'src/planner/model/planner-model'
import EditToggle from 'src/tracker/edit-toggle/edit-toggle'
import { writeCharacterData } from 'src/tracker/tracker-effects'
import { DateTime } from 'luxon'
import {characterDataValidator} from 'src/tracker/model/character-data-validator'

interface MyCharacterContentProps {
  character: Character
}

const DEFAULT_DATA = "No data"

const MyCharacterContent: FunctionComponent<MyCharacterContentProps> = ({
  character
}) => {
  const theme = useTheme()
  const memoizedGetAllDeUtilizedCharacters = useMemo(createGetAllDeUtilizedCharacters, [])
  const characterDeUtilization = useSelector(memoizedGetAllDeUtilizedCharacters)
  const memoizedGetCharacterVersions = useMemo(createGetTotalAllocations, [])
  const characterVersions = useSelector(memoizedGetCharacterVersions).characterAllocationsByCharacterIdByEventId[character.id] || [new EventCharacterDataPair(DEFAULT_DATA, generateDefaultCharacterData())]
  const editable = useSelector(isEditable)

  const region = useSelector(getActiveRegion)
  const numberOfAllocations = characterVersions.length
  const [characterUserData, setCharacterUserData] = useState(characterVersions[0])
  const isTrackerData = characterUserData.event === TRACKER_SPECIAL_VALUE
  const timeToEvaluateCharacter = typeof characterUserData.event === 'string' ? DateTime.utc() : getFluffyMoment(getEventTime(region, characterUserData.event).start)
  const characterId = character.id

  const dispatch = useDispatch()
  const updateCharacterData = useCallback((characterData: CharacterData) => {
    const now = DateTime.utc()
    const validatedCharacterData = characterDataValidator(characterData, characterUserData.characterData, character, now, region)
    const pair: [Uuid, CharacterData] = [characterId, validatedCharacterData]
    setCharacterUserData(new EventCharacterDataPair(TRACKER_SPECIAL_VALUE, validatedCharacterData))
    dispatch(writeCharacterData([pair], now))
  }, [dispatch, characterId, characterUserData, region, character])

  const handleChange = (event: any, newValue: EventCharacterDataPair | null) => {
    if (!!newValue) {
      setCharacterUserData(newValue)
    }
  }

  const allCharacterData = new AllCharacterData(character, characterUserData.characterData, characterDeUtilization[characterId])

  const characterVersionDropdown = (numberOfAllocations > 1 &&
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Typography style={{ margin: theme.spacing(1) }}>Character version: </Typography>
      <div style={{flexGrow: 1, flexBasis: '0px'}}>
        <Autocomplete
          autoHighlight
          selectOnFocus
          clearOnEscape
          disableClearable
          openOnFocus
          blurOnSelect
          forcePopupIcon={false}
          options={characterVersions}
          renderInput={(params) => (
            <TextField {...params} variant='filled' margin='dense' />
          )}
          getOptionLabel={(pair: EventCharacterDataPair) => {
            return isString(pair.event) ? "Tracker Data" : pair.event.name
          }}
          onChange={handleChange}
          value={characterUserData}
        />
      </div>
    </div>
  )

  const editToggle = isTrackerData ? <EditToggle /> : null

  return (
    <DocumentLoadingComponent
      getDocumentLocations={[getTrackerDocumentLocation]}
      bypassLogin
      acceptSpecifiedId
      >
      <div>
        {characterVersionDropdown}
        {editToggle}
        <PropertyGroups propertyGroups={panelDisplayableGroups} character={allCharacterData} timeToEvaluateCharacterValues={timeToEvaluateCharacter}
          updateValueFunction={editable ? updateCharacterData : undefined} editMode={editable && isTrackerData} />
      </div>
    </DocumentLoadingComponent>
  )
}

export default MyCharacterContent
