import React, {FunctionComponent} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Delete from '@material-ui/icons/Delete'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import theme from 'src/theme';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ColumnListItemNumberPicker from './column-list-item-number-picker/column-list-item-number-picker';

export class NumberPickerProps {
  constructor(
    input: number,
    onNumberChanged: (input: number) => void,
  ) {
    this.input = input
    this.onNumberChanged = onNumberChanged
  }
  input: number
  onNumberChanged: (input: number) => void
}

interface ColumnListItemProps {
  name: string
  removeFunction?: () => void
  swapUpFunction?: () => void
  swapDownFunction?: () => void
  highlight?: boolean
  numberPickerProps?: NumberPickerProps | boolean
  sidecarText?: string
}

const ColumnListItem: FunctionComponent<ColumnListItemProps> = ({
  name,
  removeFunction = undefined,
  swapUpFunction = undefined,
  swapDownFunction = undefined,
  highlight = false,
  numberPickerProps = undefined,
  sidecarText = undefined
}) => {
  const renderWithCell = (element: JSX.Element, minWidth?: string) => {
    return <TableCell style={{minWidth: minWidth}}>
      {element}
    </TableCell>
  }

  const renderWithFunctionOrDisabled = (onClickFunction: (() => void) | undefined, icon: JSX.Element, noRender: boolean) => {
    if (!!onClickFunction) {
      return renderWithCell(
        <IconButton onClick={onClickFunction}>
          {icon}
        </IconButton>
      )
    } else if (noRender) {
      return renderWithCell(<div/>)
    } else {
      return renderWithCell(
        <IconButton disabled>
          {icon}
        </IconButton>
      )
    }
  }

  const makeNumberPicker = () => {
    if (!!numberPickerProps) {
      if (numberPickerProps instanceof NumberPickerProps) {
        return renderWithCell(
          <ColumnListItemNumberPicker value={numberPickerProps.input} onChange={numberPickerProps.onNumberChanged} />, "50px")
      } else {
        return renderWithCell(<div/>, "50px")
      }
    }
    return null
  }

  return (
    <TableRow style={{backgroundColor: highlight ? theme.palette.primary.light : 'transparent'}}>
      {renderWithCell(<Typography>{name}</Typography>)}
      {makeNumberPicker()}
      {renderWithFunctionOrDisabled(removeFunction, <Delete />, true)}
      {renderWithFunctionOrDisabled(swapUpFunction, <KeyboardArrowUpIcon />, false)}
      {renderWithFunctionOrDisabled(swapDownFunction, <KeyboardArrowDownIcon />, false)}
      {sidecarText !== undefined &&
        renderWithCell(<div>
          <Typography>{sidecarText}</Typography>
        </div>, '200px')
      }
    </TableRow>
  )
}

export default ColumnListItem
