import React, {useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import {useSelector, useDispatch} from 'react-redux'
import {AppState} from 'src/store/root-reducer'
import {closeAlert, CloseType, AlertType} from 'src/alert/alert-actions'
import { MASS_EDIT_CONFIRMATION_DIALOG_SUBTYPE, clearStoredCharacterActionCreator, COLUMN_EDIT_CONFIRMATION_DIALOG_SUBTYPE, clearStoredTrackerCharacterDataActionCreator } from 'src/tracker/tracker-actions';
import { writeStoredCharacterData, writeAllCharacterData } from 'src/tracker/tracker-effects';

export default function AlertComponent() {
  const open = useSelector((state: AppState) => state.alert.open)
  const message = useSelector((state: AppState) => state.alert.message)
  const type = useSelector((state: AppState) => state.alert.alertType)
  const subtype = useSelector((state: AppState) => state.alert.subtype)
  const dispatch = useDispatch()

  const closeAlertCallback = useCallback((closeType: CloseType) => {
    // TODO: this hack is an oof
    if (subtype === MASS_EDIT_CONFIRMATION_DIALOG_SUBTYPE) {
      if (closeType === CloseType.OK) {
        dispatch(writeStoredCharacterData())
      } else {
        dispatch(clearStoredCharacterActionCreator())
      }
    } else if (subtype === COLUMN_EDIT_CONFIRMATION_DIALOG_SUBTYPE) {
      if (closeType === CloseType.OK) {
        dispatch(writeAllCharacterData())
      } else {
        dispatch(clearStoredTrackerCharacterDataActionCreator())
      }
    }
    dispatch(closeAlert(closeType, subtype))
  }, [dispatch, subtype])

  const closeAlertDoubleCallback = (closeType: CloseType) => {
    return () => {
      closeAlertCallback(closeType)
    }
  }

  return (
    <Dialog open={open} onClose={closeAlertCallback} aria-labelledby="form-dialog-message">
      <DialogContent>
        <DialogContentText id="form-dialog-message">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAlertDoubleCallback(CloseType.OK)} color="primary">
          OK
        </Button>
        {type === AlertType.OK_CANCEL &&
          <Button onClick={closeAlertDoubleCallback(CloseType.CANCEL)} >
            Cancel
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

