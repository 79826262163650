import {CharacterDataValue} from 'src/tracker/model/tracker-model'
import {CharacterValueConverter} from 'src/tracker/model/tracker-model-converter'
import {Uuid} from 'src/forecast/model/universal-data-model'
import { EditableCharacterProperty } from './editable-character-property'
import { ResourceRetrievalFunction } from './enumerable-character-property'
import { AllCharacterData } from '../components/all-character-data'
import { Region } from '../components/region'
import { DateTime } from 'luxon'
import { WeaponType } from 'src/forecast/model/weapon-type'

export class WeaponDataProperty<T extends CharacterDataValue> extends EditableCharacterProperty<T> {
  constructor(
    id: Uuid,
    name: string,
    shortName: string,
    converter: CharacterValueConverter<T>,
    propertyName: string,
    weaponType: WeaponType,
    options: (universalCharacterData: AllCharacterData, when: DateTime, region: Region) => T[],
    resourceRetrievalOverrides: ResourceRetrievalFunction<T>[] = []
  ) {
    super(id, name, shortName, converter, propertyName, options, resourceRetrievalOverrides)
    this.weaponType = weaponType
  }
  weaponType: WeaponType
}
