import {
  RESET_PASSWORD_DIALOG_OPEN,
  ResetPasswordDialogOpenAction,
  RESET_PASSWORD_DIALOG_CLOSED,
  ResetPasswordDialogClosedAction,
  ACCOUNT_DELETION_CONFIRMATION_DIALOG_OPEN,
  AccountDeletionConfirmationDialogOpenAction,
  ACCOUNT_DELETION_CONFIRMATION_DIALOG_CLOSED,
  AccountDeletionConfirmationDialogClosedAction,
} from 'src/login/login-actions'

const initialState = {
  resetPasswordDialogOpen: false,
  accountDeletionConfirmationDialogOpen: false,
}

export default function loginReducer(state = initialState,
  action: ResetPasswordDialogOpenAction | ResetPasswordDialogClosedAction | AccountDeletionConfirmationDialogOpenAction | AccountDeletionConfirmationDialogClosedAction) {
  switch (action.type) {
    case RESET_PASSWORD_DIALOG_OPEN:
      return Object.assign({}, state, {resetPasswordDialogOpen: true})
    case RESET_PASSWORD_DIALOG_CLOSED:
      return Object.assign({}, state, {resetPasswordDialogOpen: false})
    case ACCOUNT_DELETION_CONFIRMATION_DIALOG_OPEN:
      return Object.assign({}, state, {accountDeletionConfirmationDialogOpen: true})
    case ACCOUNT_DELETION_CONFIRMATION_DIALOG_CLOSED:
      return Object.assign({}, state, {accountDeletionConfirmationDialogOpen: false})
    default:
      return state
  }
}
