import {armorLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {ArmorLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { EditableCharacterProperty } from '../../editable-character-property'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'

export const armorProperty = new EditableCharacterProperty(
  "329f715d-daa9-43a9-bccd-c50831bf52de",
  "Armor",
  "Armor",
  armorLimitBreakConverter,
  "Armor",
  (universalCharacterData, when, region) => {
    const debutChecker = debutBeforeTime(region, universalCharacterData.universal, when)
    const highArmorAppearsBeforeNow = debutChecker(CharacterDebut.HIGH_ARMOR)
    const highArmorRealizationAppearsBeforeNow = debutChecker(CharacterDebut.HIGH_ARMOR_REALIZATION)
    const allArmorLimitBreakValues = getValues(ArmorLimitBreak) as ArmorLimitBreak[]
    if (highArmorRealizationAppearsBeforeNow) {
      return allArmorLimitBreakValues
    } if (highArmorAppearsBeforeNow) {
      return allArmorLimitBreakValues.slice(0, 14)
    } else {
      return allArmorLimitBreakValues.slice(0, 13)
    }
  },
)
