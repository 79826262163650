import {AppState} from 'src/store/root-reducer'
import {Preferences, CustomColumnGroups, GreyAcquiredCharacters} from 'src/preferences/model/preferences-model'
import {getUserId} from 'src/login/login-selectors'
import { Region } from 'src/tracker/model/components/region'
import { Uuid } from 'src/forecast/model/universal-data-model'
import { SavedView } from './model/saved-view'
import { StringDisplayPreferences } from 'src/tracker/model/property/character-property'

export const PREFERENCES_COLLECTION_NAME = "preferences"

export function getPreferencesDocumentLocation(userId: string | undefined) {
  if (!userId) {
    return undefined
  }
  return `${PREFERENCES_COLLECTION_NAME}/${userId}`
}

export function getPreferencesDocument(state: AppState) {
  const loggedInUser = getUserId(state)
  if (!loggedInUser) {
    return undefined
  }
  return state.firestore.data?.preferences?.[loggedInUser] as Preferences | undefined
}

export const getPreviewedTrackerData = (state: AppState) => {
  return state.preferences.previewedTrackerData
}

export const isPreviewedTrackerData = (state: AppState) => {
  return !!getPreviewedTrackerData(state)
}

export const getPreviewedPlannerData = (state: AppState) => {
  return state.preferences.previewedPlannerData
}

export const isPreviewedPlannerData = (state: AppState) => {
  return !!getPreviewedPlannerData(state)
}

export function getCustomColumnGroups(state: AppState) {
  return getPreferencesDocument(state)?.tracker?.customColumnGroups || [] as CustomColumnGroups
}

export function createDocumentMergableCustomColumnGroups(customColumnGroups: CustomColumnGroups) {
  return {
    tracker: {
      customColumnGroups: customColumnGroups
    }
  } as Partial<Preferences>
}

export function getGreyAcquiredCharacters(state: AppState) {
  return getPreferencesDocument(state)?.forecast?.greyAcquiredCharacters || 'Acquired' as GreyAcquiredCharacters
}

export function createDocumentMergableGreyAcquiredCharacters(greyAcquiredCharacters: GreyAcquiredCharacters) {
  return {
    forecast: {
      greyAcquiredCharacters: greyAcquiredCharacters
    }
  } as Partial<Preferences>
}

export function getDefaultEditMode(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.tracker?.defaultEditMode
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableDefaultEditMode(defaultEditMode: boolean) {
  return {
    tracker: {
      defaultEditMode: defaultEditMode
    }
  } as Partial<Preferences>
}

export function getHighlightMaxed(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.tracker?.highlightMaxed
  if (storeValue === undefined) {
    return true
  }
  return storeValue
}

export function createDocumentMergableHighlightMaxed(highlightMaxed: boolean) {
  return {
    tracker: {
      highlightMaxed: highlightMaxed
    }
  } as Partial<Preferences>
}

export function getShowFutureCharacters(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.tracker?.showFutureCharacters
  if (storeValue === undefined) {
    return true
  }
  return storeValue
}

export function createDocumentMergableShowFutureCharacters(showFutureCharacters: boolean) {
  return {
    tracker: {
      showFutureCharacters
    }
  } as Partial<Preferences>
}

export function getDisplayDetailedEstimates(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.general?.displayDetailedEstimates
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableDisplayDetailedEstimates(displayDetailedEstimates: boolean) {
  return {
    general: {
      displayDetailedEstimates
    }
  } as Partial<Preferences>
}

export function getShowLocalTime(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.forecast?.showLocalTime
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableShowLocalTime(showLocalTime: boolean) {
  return {
    forecast: {
      showLocalTime
    }
  } as Partial<Preferences>
}

export function getDefaultRegion(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.general?.defaultRegion
  if (storeValue === undefined) {
    return Region.GL
  }
  return storeValue
}

export function createDocumentMergableDefaultRegion(region: Region) {
  return {
    general: {
      defaultRegion: region
    }
  } as Partial<Preferences>
}

export function getHighlightAltered(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.planner?.highlightAltered
  if (storeValue === undefined) {
    return true
  }
  return storeValue
}

export function createDocumentMergableHighlightAltered(highlightAltered: boolean) {
  return {
    planner: {
      highlightAltered: highlightAltered
    }
  } as Partial<Preferences>
}

export function getDisplayFirstLdName(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.planner?.displayFirstLdName
  if (storeValue === undefined) {
    return true
  }
  return storeValue
}

export function createDocumentMergableDisplayFirstLdName(displayFirstLdName: boolean) {
  return {
    planner: {
      displayFirstLdName
    }
  } as Partial<Preferences>
}

export function getImportTrackerBreadcrumbDismissed(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.tracker?.importBreadcrumbDismissed
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableImportTrackerBreadcrumbDismissed(importBreadcrumbDismissed: boolean) {
  return {
    tracker: {
      importBreadcrumbDismissed
    }
  } as Partial<Preferences>
}

export function getImportPlannerBreadcrumbDismissed(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.planner?.importBreadcrumbDismissed
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableImportPlannerBreadcrumbDismissed(importBreadcrumbDismissed: boolean) {
  return {
    planner: {
      importBreadcrumbDismissed
    }
  } as Partial<Preferences>
}

export function getPlannerCustomColumnGroups(state: AppState) {
  return getPreferencesDocument(state)?.planner?.customColumnGroups || [] as CustomColumnGroups
}

export function createDocumentMergablePlannerCustomColumnGroups(customColumnGroups: CustomColumnGroups) {
  return {
    planner: {
      customColumnGroups: customColumnGroups
    }
  } as Partial<Preferences>
}

export function getPlannerDefaultColumnGroup(state: AppState) {
  return getPreferencesDocument(state)?.planner?.defaultColumnGroup
}

export function createDocumentMergablePlannerDefaultColumnGroups(value: Uuid) {
  return {
    planner: {
      defaultColumnGroup: value
    }
  } as Partial<Preferences>
}

export function getDisplayAsRefined(state: AppState) {
  const storeValue = getPreferencesDocument(state)?.planner?.displayAsRefined
  if (storeValue === undefined) {
    return false
  }
  return storeValue
}

export function createDocumentMergableDisplayAsRefined(displayAsRefined: boolean) {
  return {
    planner: {
      displayAsRefined
    }
  } as Partial<Preferences>
}

export function getSavedViews(state: AppState) {
  return getPreferencesDocument(state)?.general?.savedViews || []
}

export function createDocumentMergableSavedViews(savedViews: SavedView[]) {
  return {
    general: {
      savedViews: savedViews
    }
  } as Partial<Preferences>
}

export function getDefaultSavedView(state: AppState) {
  return getPreferencesDocument(state)?.general?.defaultSavedView
}

export function createDocumentMergabledefaultSavedView(defaultSavedView: Uuid) {
  return {
    general: {
      defaultSavedView: defaultSavedView
    }
  } as Partial<Preferences>
}

export function getStringDisplayPreferences(state: AppState) {
  return new StringDisplayPreferences(getDisplayDetailedEstimates(state))
}
