import React, {FunctionComponent, useState, ChangeEvent, useCallback, useEffect} from 'react'
import { TextField, useTheme, Typography, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterSpecifications } from 'src/forecast/forecast-selectors';
import { getSortSpecification } from 'src/tracker/tracker-selectors';
import { getDefaultSavedView, getSavedViews } from 'src/preferences/preferences-selectors';
import { writeDefaultSavedView, writeSavedViews } from 'src/preferences/preferences-effects';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import { setSavedViewActionCreator } from 'src/forecast/forecast-actions';
import { serializeSavedView } from 'src/preferences/model/saved-view';
import Delete from '@material-ui/icons/Delete'
import { deserializeFilterSpecifications, deserializeSortSpecifications } from 'src/tracker/model/selector-model';
import { isEqual } from 'lodash';

export const SavedViewComponent: FunctionComponent = () => {
  const theme = useTheme()

  const [saveViewName, setSaveViewName] = useState("Saved view")
  const [savedView, setSavedView] = useState(undefined as string | undefined)

  const dispatch = useDispatch()

  const savedViews = useSelector(getSavedViews)
  const filterSpecifications = useSelector(getFilterSpecifications).slice()
  const sortSpecifications = useSelector(getSortSpecification)
  const defaultSavedViewId = useSelector(getDefaultSavedView)

  const onSaveCallback = useCallback(() => {
    const newSavedViews = savedViews.slice()
    const newSavedView = serializeSavedView(saveViewName, filterSpecifications, sortSpecifications)
    newSavedViews.push(newSavedView)
    dispatch(writeSavedViews(newSavedViews))
    setSavedView(newSavedView.id)
  }, [dispatch, savedViews, filterSpecifications, sortSpecifications, saveViewName])
  const onLoadCallback = useCallback((savedViewId: string) => {
    const savedView = savedViews.find(innerSavedView => innerSavedView.id === savedViewId)
    setSavedView(savedViewId)
    if (savedView !== undefined) {
      setSaveViewName(savedView.name)
      dispatch(setSavedViewActionCreator(savedView))
    }
  }, [dispatch, savedViews])
  const onDeleteCallback = useCallback(() => {
    if (savedView !== undefined) {
      const newSavedViews = savedViews.slice()
      const indexToRemove = savedViews.findIndex(viewInQuestion => viewInQuestion.id === savedView)
      if (indexToRemove !== -1) {
        newSavedViews.splice(indexToRemove, 1)
      }
      setSavedView(undefined)
      dispatch(writeSavedViews(newSavedViews))
    }
  }, [dispatch, savedView, savedViews])
  const onSetDefault = useCallback(() => {
    if (savedView !== undefined) {
      dispatch(writeDefaultSavedView(savedView))
    }
  }, [dispatch, savedView])
  const onUnsetDefault = useCallback(() => {
    dispatch(writeDefaultSavedView(null))
  }, [dispatch])

  useEffect(() => {
    if (savedView !== undefined) {
      const resolvedSavedView = savedViews.find(innerSavedView => savedView === innerSavedView.id)
      if (resolvedSavedView !== undefined) {
        if (savedViews.find(currentIteratedView => currentIteratedView.id === savedView) === undefined
          || !isEqual(filterSpecifications, deserializeFilterSpecifications(resolvedSavedView.filterSpecifications)) ||
          !isEqual(sortSpecifications, deserializeSortSpecifications(resolvedSavedView.sortSpecifications))) {
          setSavedView(undefined)
        }
      }
    }
  }, [savedViews, savedView, filterSpecifications, sortSpecifications])

  const onSaveValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSaveViewName(event.target.value)
  }
  const onLoadGroup = (event: any, newValue: string) => {
    onLoadCallback(newValue)
  }

  const resolvedSavedView = savedViews.find(innerSavedView => savedView === innerSavedView.id)
  const savedViewTabs = Object.values(savedViews).map(savedView => {
    return (
      <Tab key={savedView.name} label={savedView.name} value={savedView.id} />
    )
  })

  const deleteComponent = resolvedSavedView !== undefined && <div style={{display: 'flex', alignItems: 'center'}}>
    <Typography>Selected view is called {resolvedSavedView.name}</Typography>
    <IconButton onClick={onDeleteCallback}>
      <Delete />
    </IconButton>
    {defaultSavedViewId === resolvedSavedView.id
    ? <Button variant="contained" onClick={onUnsetDefault}>Unset default</Button> :
    <Button variant="contained" onClick={onSetDefault}>Set as default</Button>}
  </div>

  const loadSavedViews = savedViews.length > 0 && <div>
        <Typography>Load saved view:</Typography>
        <Tabs onChange={onLoadGroup} value={!!savedView && savedView} variant="scrollable" scrollButtons="auto">
          {savedViewTabs}
        </Tabs>
        {deleteComponent}
      </div>

  const saveViews = <div style={{display: 'flex', alignItems: 'center', columnGap: theme.spacing(1)}}>
        <Typography>Save current filters and sort as:</Typography>
        <TextField variant='filled' margin='dense' value={saveViewName} onChange={onSaveValueChange} />
        <IconButton onClick={onSaveCallback}>
          <SaveIcon />
        </IconButton>
      </div>

  return (
    <div style={{overflowX: "auto"}}>
      {saveViews}
      {loadSavedViews}
    </div>
  )
}
