import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {entropyColumn} from 'src/tracker/model/column/variable/utilization/entropy-column'
import { transcendenceColumn } from '../variable/utilization/transcendence-column'

export const utilizationGroup = new ColumnGroup(
  '1b4e2532-23b9-4ef5-bd14-5db634e196bb',
  'Utilization',
  [entropyColumn, transcendenceColumn]
)

