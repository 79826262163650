import {Event, EventSize} from 'src/forecast/model/universal-data-model'
import Typography from '@material-ui/core/Typography'
import React, { FunctionComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { TotalAllocation } from 'src/planner/model/planner-model';
import { ResourceColumn } from 'src/planner/model/column/planner-column';
import { ResourceProperty } from 'src/planner/model/property/resource-meta/resource-property';
import ResourceImage from '../resource-image/resource-image';
import ResourceValueDisplay from 'src/planner/resource-value-display/resource-value-display';
import { allResourcesColumnGroup } from 'src/planner/model/column/resource/resources-column-group';
import { getDisplayAsRefined } from 'src/preferences/preferences-selectors';
import { useSelector } from 'react-redux';

interface ResourcesTabProps {
  event: Event
  totalAllocation: TotalAllocation
}

function addEventResourceRender(column: ResourceColumn<ResourceProperty>, totalAllocation: TotalAllocation, displayAsRefined: boolean) {
  const dimension = '38px'
  const resolvedValue = column.property.eventAccessor(totalAllocation)

  const refinedResourceMode = displayAsRefined && !!column.realizableImageLocator
  return (
    <TableRow key={column.name}>
      <TableCell>
        <ResourceImage column={column} dimension={dimension}/>
      </TableCell>
      <TableCell>
        <ResourceValueDisplay value={resolvedValue} refinedResourceMode={refinedResourceMode}/>
      </TableCell>
      <TableCell>
        <ResourceValueDisplay value={column.property.totalExpenditureAccessor(totalAllocation, true)} refinedResourceMode={refinedResourceMode}/>
      </TableCell>
      <TableCell>
        <ResourceValueDisplay value={column.property.netEventAccessor(totalAllocation, false)} refinedResourceMode={refinedResourceMode}/>
      </TableCell>
    </TableRow>
  )
}

const ResourcesTab: FunctionComponent<ResourcesTabProps> = ({
  event,
  totalAllocation
}) => {
  const displayAsRefined = useSelector(getDisplayAsRefined)
  const shouldShowChocoboardDisclaimer = event.eventSize === EventSize.END_MONTH || event.eventSize === EventSize.MID_MONTH

  const resourceRenders = allResourcesColumnGroup.columns.map(column => {
    return addEventResourceRender(column, totalAllocation, displayAsRefined)
  })

  const chocoboardDisclaimer = shouldShowChocoboardDisclaimer && <Typography>Event income below includes resources from chocoboard starting around the time of this event.</Typography>

  return (
    <div>
      {chocoboardDisclaimer}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Resource</TableCell>
            <TableCell>Event income</TableCell>
            <TableCell>Total expendature</TableCell>
            <TableCell>Net income</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceRenders}
        </TableBody>
      </Table>
    </div>
  )
}

export default ResourcesTab
