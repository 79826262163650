import { groupBy } from "lodash";
import { objectAssigningReducer } from "src/forecast/model/universal-data-converter";
import { Character, Summon, Uuid } from "src/forecast/model/universal-data-model";
import { characterDataValidator } from "../character-data-validator";
import { Region } from "../components/region";
import { TierUtilization } from "../components/tier-utilization";
import { CharacterBoardStatus, CharacterData, createDefaultTrackerRegionData, createDefaultTrackerUtilizationData, defaultEntropyUtilization, EntropyUtilization, generateDefaultCharacterData, LdBoardStatus, SummonBoardStatus, Tracker, TrackerCharacterData } from "../tracker-model";
import { ootracker_universal_additional_data, ootracker_universal_character_data, ootracker_user_data, ootracker_user_de_data, summon } from "./ootracker-model";
import { DateTime } from 'luxon'

interface ArmorHolder {
  thirtyFiveCp: number,
  ninetyCp: number,
  ninetyCpPlus: number,
  highArmor: number,
  highArmorPlus: number,
}

const createEmptyArmorHolder = () => {
  return {
    thirtyFiveCp: 0,
    ninetyCp: 0,
    ninetyCpPlus: 0,
    highArmor: 0,
    highArmorPlus: 0,
  } as ArmorHolder
}

interface RealizableWeaponHolder {
  baseWeapon: number,
  realizedWeapon: number,
}

const createEmptyRealizableWeaponHolder = () => {
  return {
    baseWeapon: 0,
    realizedWeapon: 0,
  } as RealizableWeaponHolder
}

export const transformToTracker = (
  universalCharacterData: ootracker_universal_character_data,
  additionalUniversalData: ootracker_universal_additional_data,
  userData: ootracker_user_data,
  userDeData: ootracker_user_de_data,
  tracker: Tracker,
  ffootipCharacterByName: Record<string, Uuid>,
  ffootipCharactersById: Record<Uuid, Character>,
  ffootipSummonsByName: Record<string, Uuid>,
  ffootipSummonsById: Record<Uuid, Summon>,
  when: DateTime = DateTime.utc()
): Tracker => {
  const output = Object.assign({}, tracker)

  const characterDataTransformer = transformToTrackerCharacterData(universalCharacterData, additionalUniversalData, userData, ffootipCharacterByName,
    ffootipCharactersById,  ffootipSummonsByName, ffootipSummonsById)

  const glTrackerRegionData = Object.assign({}, output?.GL) || createDefaultTrackerRegionData()
  glTrackerRegionData.characters = characterDataTransformer(Region.GL, when)

  const jpTrackerRegionData = Object.assign({}, output?.JP) || createDefaultTrackerRegionData()
  jpTrackerRegionData.characters = characterDataTransformer(Region.JP, when)

  const entropyDataTransformer = transformToEntropyUtilization(universalCharacterData, additionalUniversalData, userData, userDeData, ffootipCharacterByName,
    ffootipCharactersById,  ffootipSummonsByName, ffootipSummonsById)

  const glTrackerUtilizationData = Object.assign({}, glTrackerRegionData.utilization) || createDefaultTrackerUtilizationData()
  glTrackerUtilizationData.Entropy = entropyDataTransformer(Region.GL)
  glTrackerRegionData.utilization = glTrackerUtilizationData

  const jpTrackerUtilizationData = Object.assign({}, jpTrackerRegionData.utilization) || createDefaultTrackerUtilizationData()
  jpTrackerUtilizationData.Entropy = entropyDataTransformer(Region.JP)
  jpTrackerRegionData.utilization = jpTrackerUtilizationData

  output.GL = glTrackerRegionData
  output.JP = jpTrackerRegionData
  return output
}

const transformToTrackerCharacterData = (
  universalCharacterData: ootracker_universal_character_data,
  additionalUniversalData: ootracker_universal_additional_data,
  userData: ootracker_user_data,
  ffootipCharacterByName: Record<string, Uuid>,
  ffootipCharactersById: Record<Uuid, Character>,
  ffootipSummonsByName: Record<string, Uuid>,
  ffootipSummonsById: Record<Uuid, Summon>) =>
  (region: Region,
  when: DateTime = DateTime.utc()
): TrackerCharacterData => {

  const characterDataByOotrackerCharacterId = userData.data.userCharacter
  .filter(userCharacter => region.valueOf().toLowerCase() === userCharacter.region)
  .map(ootrackerData => {
    const ffootipData: CharacterData = generateDefaultCharacterData()
    ffootipData.Acquired = true
    ffootipData.Level = ootrackerData.level
    ffootipData["Crystal Level"] = ootrackerData.awakening
    var regularBoardsCleared = 0
    regularBoardsCleared += ootrackerData.characterBoardS1 > 0 ? 1 : 0
    regularBoardsCleared += ootrackerData.characterBoardS2 > 0 ? 1 : 0
    regularBoardsCleared += ootrackerData.characterBoardEx > 0 ? 1 : 0
    ffootipData["Character Boards"] = regularBoardsCleared as CharacterBoardStatus
    ffootipData["Character LD Boards"] = ootrackerData.characterBoardLd > 0 ? 1 : 0 as LdBoardStatus
    ffootipData["Character FR Boards"] = ootrackerData.characterBoardFr > 0 ? 1 : 0 as LdBoardStatus
    ffootipData["Force Enhancement"] = ootrackerData.force
    return {
      [ootrackerData.characterId]: ffootipData
    }
  }).reduce(objectAssigningReducer, {})

  const equipmentTypeIdToNameMap = additionalUniversalData.data.equipmentType.map(equipmentType => {
    return {
      [equipmentType.id]: equipmentType.name
    }
  }).reduce(objectAssigningReducer, {})

  const equipmentIdToEquipmentTypeMap = universalCharacterData.data.character.flatMap(character => character.equipment)
    .map(equipment => {
      return {
        [equipment.id]: equipmentTypeIdToNameMap[equipment.equipmentTypeId]
      }
    }).reduce(objectAssigningReducer, {})

  // map equipment data
  Object.entries(groupBy(userData.data.userEquipment, userEquipment => userEquipment.characterId))
  .forEach(([characterId, userEquipmentList]) => {
    const inProgressData = characterDataByOotrackerCharacterId[characterId]
    const armorData: ArmorHolder = createEmptyArmorHolder()
    const exData: RealizableWeaponHolder = createEmptyRealizableWeaponHolder()
    const btData: RealizableWeaponHolder = createEmptyRealizableWeaponHolder()

    userEquipmentList
      .filter(userEquipment => region.valueOf().toLowerCase() === userEquipment.region)
      .forEach(userEquipment => {
        const equipmentId = userEquipment.equipmentId
        const equipmentType = equipmentIdToEquipmentTypeMap[equipmentId]
        switch (equipmentType) {
          case undefined:
            console.log(`Error: didn't recognize user_equipment with equipmentId=${equipmentId}`)
            break
          case "Bloom Stone":
            inProgressData.Bloom = userEquipment.level > 0 ? 1 : 0
            break
          case "4★ Armor":
            inProgressData["Silver Armor"] = userEquipment.level
            break
          case "4★":
            inProgressData["Silver Weapon"] = userEquipment.level
            break
          case "15CP":
            inProgressData["15 CP"] = userEquipment.level
            break
          case "35CP HG":
            armorData.thirtyFiveCp = userEquipment.level
            break
          case "90CP HG":
            armorData.ninetyCp = userEquipment.level
            break
          case "WoI":
            inProgressData["World of Illusions"] = userEquipment.level
            break
          case "90CP HG+":
            armorData.ninetyCpPlus = userEquipment.level
            break
          case "35CP":
            inProgressData["35 CP"] = userEquipment.level
            break
          case "Manikin":
            inProgressData.Manikin = userEquipment.level
            break
          case "7★ HG":
            armorData.highArmor = userEquipment.level
            break
          case "7★ HG+":
            armorData.highArmorPlus = userEquipment.level
            break
          case "NT Bonus":
            inProgressData.NT = userEquipment.level
            break
          case "EX":
            exData.baseWeapon = userEquipment.level
            break
          case "EX+":
            exData.realizedWeapon = userEquipment.level
            break
          case "LD":
            inProgressData.LD = userEquipment.level
            break
          case "BT":
            btData.baseWeapon = userEquipment.level
            break
          case "BT+":
            btData.realizedWeapon = userEquipment.level
            break
          case "FR":
            inProgressData.FR = userEquipment.level
            break
        }

        if (armorData.highArmorPlus > 0) {
          inProgressData.Armor = armorData.highArmorPlus + 13
        } else if (armorData.highArmor > 0) {
          inProgressData.Armor = 13
        } else if (armorData.ninetyCpPlus > 0) {
          inProgressData.Armor = armorData.ninetyCpPlus + 8
        } else if (armorData.ninetyCp > 0) {
          inProgressData.Armor = armorData.ninetyCp + 4
        } else if (armorData.thirtyFiveCp > 0) {
          inProgressData.Armor = armorData.thirtyFiveCp
        } else {
          inProgressData.Armor = 0
        }

        if (exData.realizedWeapon > 0) {
          inProgressData.EX = exData.realizedWeapon + 4
        } else if (exData.baseWeapon > 0) {
          inProgressData.EX = exData.baseWeapon
        } else {
          inProgressData.EX = 0
        }

        if (btData.realizedWeapon > 0) {
          inProgressData.BT = btData.realizedWeapon + 1
        } else if (btData.baseWeapon > 0) {
          inProgressData.BT = 1
        } else {
          inProgressData.BT = 0
        }
      })
  })

  const ootrackerSummonIdToFfootipSummon = createOotrackerSummonIdToFfootipSummonMap(additionalUniversalData.data.summon,
    ffootipSummonsByName, ffootipSummonsById)

  // map summon data
  userData.data.userSummon
    .filter(userSummon => region.valueOf().toLowerCase() === userSummon.region)
    .forEach(userSummon => {
      const inProgressData = characterDataByOotrackerCharacterId[userSummon.characterId]
      const value = mapSummonNodesToFfootip(userSummon.collectedNodes)
      const ffootipSummon = ootrackerSummonIdToFfootipSummon[userSummon.summonId]
      if (ffootipSummon === undefined) {
        console.log(`Error: couldn't recognize user_summon id=${userSummon.id} with summonId=${userSummon.summonId}`)
      } else {
        inProgressData[ffootipSummon.displayName] = value
      }
    })

  const ootrackerCharacterIdToFfootipUniversalCharacterMap = createOotrackerCharacterIdToFfotipUniversalCharacterMap(universalCharacterData,
    ffootipCharacterByName, ffootipCharactersById)

  // map character data to ffootip characters
  const ffootipCharacterEntityRetriever = getMapOutputFromInput("Ootracker characterId", "FFootip Character", ootrackerCharacterIdToFfootipUniversalCharacterMap)
  return Object.entries(characterDataByOotrackerCharacterId).map(([ootrackerCharacterId, characterData]) => {
    const ffootipCharacter = ffootipCharacterEntityRetriever(ootrackerCharacterId)
    if (!ffootipCharacter) {
      return {}
    }
    return {
      [ffootipCharacter.id]: characterDataValidator(characterData, undefined, ffootipCharacter, when, region)
    }
  }).reduce(objectAssigningReducer, {})
}

const createOotrackerCharacterIdToFfotipUniversalCharacterMap = (
  universalCharacterData: ootracker_universal_character_data,
  ffootipCharacterByName: Record<string, Uuid>,
  ffootipCharactersById: Record<Uuid, Character>,
) => {
  return universalCharacterData.data.character.map(ootrackerCharacter => {
    const ffootipCharacterId = ffootipCharacterByName[mapOotrackerCharacterNameToFfootipName(ootrackerCharacter.name)]
    if (ffootipCharacterId === undefined) {
      console.log(`Error: could not find a FFootip character corresponding to characterId=${ootrackerCharacter.id} name=${ootrackerCharacter.name}`)
      return {}
    }
    return {
      [ootrackerCharacter.id]: ffootipCharactersById[ffootipCharacterId]
    }
  }).reduce(objectAssigningReducer, {})
}

const createOotrackerSummonIdToFfootipSummonMap = (
  ootrackerSummons: summon[],
  ffootipSummonsByName: Record<string, Uuid>,
  ffootipSummonsById: Record<Uuid, Summon>
) => {
  return ootrackerSummons.map(summon => {
    const ffootipName = mapOotrackerSummonNameToFfootipName(summon.name)
    const ffootipSummonId = ffootipSummonsByName[ffootipName]
    if (ffootipSummonId === undefined) {
      console.log(`Error: could not find a FFootip summon corresponding to summonId=${summon.id} name=${summon.name}`)
      return {}
    }
    return {
      [summon.id]: ffootipSummonsById[ffootipSummonId]
    }
  }).reduce(objectAssigningReducer, {})
}

const mapSummonNodesToFfootip = (summonNodes: number) => {
  if (summonNodes >= 56) {
    return SummonBoardStatus.MASTERED
  } else if (summonNodes > 0) {
    return SummonBoardStatus.TREASURED
  }
  return SummonBoardStatus.NOT_STARTED
}

const mapOotrackerCharacterNameToFfootipName = (name: string) => {
  if (name === "Leonhart") {
    return "Leon"
  }
  return name
}

const mapOotrackerSummonNameToFfootipName = (name: string) => {
  if (name === "Brothers") {
    return "The Brothers"
  }
  return name
}

const transformToEntropyUtilization = (
  universalCharacterData: ootracker_universal_character_data,
  additionalUniversalData: ootracker_universal_additional_data,
  userData: ootracker_user_data,
  userDeData: ootracker_user_de_data,
  ffootipCharacterByName: Record<string, Uuid>,
  ffootipCharactersById: Record<Uuid, Character>,
  ffootipSummonsByName: Record<string, Uuid>,
  ffootipSummonsById: Record<Uuid, Summon>) =>
  (region: Region,
): EntropyUtilization => {
  const ootrackerCharacterIdToFfootipUniversalCharacterMap = createOotrackerCharacterIdToFfotipUniversalCharacterMap(universalCharacterData,
    ffootipCharacterByName, ffootipCharactersById)
  const ootrackerSummonIdToFfootipSummon = createOotrackerSummonIdToFfootipSummonMap(additionalUniversalData.data.summon,
    ffootipSummonsByName, ffootipSummonsById)
  const ootrackerUserCharacterIdToCharacterId = userData.data.userCharacter.map(userCharacter => {
    return {
      [userCharacter.id]: userCharacter.characterId
    }
  }).reduce(objectAssigningReducer, {})

  const ffootipCharacterEntityRetriever = getMapOutputFromInput("Ootracker characterId", "FFootip Character", ootrackerCharacterIdToFfootipUniversalCharacterMap)
  const ffootipSummonEntityRetriever = getMapOutputFromInput("Ootracker summonId", "FFootip Summon", ootrackerSummonIdToFfootipSummon)
  const ootrackerCharacterIdRetriever = getMapOutputFromInput("userCharacterId", "characterId", ootrackerUserCharacterIdToCharacterId)
  const importedData = userDeData.data.userFeod
  .filter(userFeod => region.valueOf().toLowerCase() === userFeod.region)
  .map(userFeod => {
    const characterId1 = ootrackerCharacterIdRetriever(userFeod.userCharacter1Id)
    const characterId2 = ootrackerCharacterIdRetriever(userFeod.userCharacter2Id)
    const characterId3 = ootrackerCharacterIdRetriever(userFeod.userCharacter3Id)
    const character1 = ffootipCharacterEntityRetriever(characterId1)?.id || null
    const character2 = ffootipCharacterEntityRetriever(characterId2)?.id || null
    const character3 = ffootipCharacterEntityRetriever(characterId3)?.id || null
    const summon = ffootipSummonEntityRetriever(userFeod.summonId)?.id || null
    return {
      [userFeod.tierNumber.toString()]: new TierUtilization(character1, character2, character3, summon, null, null, null)
    }
  }).reduce(objectAssigningReducer, {})

  return Object.assign({}, defaultEntropyUtilization, importedData)
}

const getMapOutputFromInput = <T>(
  inputEntityType: string,
  outputEntityType: string,
  map: Record<string, T>,
) => (ootrackerId: string | null) => {
  if (!ootrackerId) {
    return null
  }
  const output = map[ootrackerId]
  if (!output) {
    console.log(`Couldn't find a ${outputEntityType} which corresponded to ${inputEntityType}=${ootrackerId}`)
    return null
  }
  return output
}
