import {
  OpenAlertAction,
  ALERT_OPEN,
  CloseAlertAction,
  ALERT_CLOSED,
  AlertType,
} from 'src/alert/alert-actions'

const initialState = {
  open: false,
  message: "",
  alertType: AlertType.OK,
  subtype: "",
}

export default function alertReducer(state = initialState, action: OpenAlertAction | CloseAlertAction) {
  switch (action.type) {
    case ALERT_OPEN:
      const castedAction = action as OpenAlertAction
      return Object.assign({}, state, {open: true, message: castedAction.message, alertType: castedAction.alertType, subtype: castedAction.subtype})
    case ALERT_CLOSED:
      return Object.assign({}, state, {open: false, message: "", subtype: ""})
    default:
      return state
  }
}

