import { Resource } from 'src/tracker/model/tracker-model'
import { DailyResourceStats, ResourceProperty } from '../resource-meta/resource-property'

export const weaponTokenProperty = new ResourceProperty(
  '52939beb-9f70-40d3-8b27-cc6f30c6d002',
  'Weapon Tokens',
  'weaponTokens',
  Resource.WEAPON_TOKENS,
  new DailyResourceStats(5, 5),
)
