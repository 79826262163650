import React, { useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import {useSelector, useDispatch} from 'react-redux'
import { getColumnGroups, getSelectedColumnGroup } from '../planner-selectors'
import { ColumnGroup } from '../model/column/column-group'
import { writePlannerDefaultColumnsData } from 'src/preferences/preferences-effects'

const PlannerCustomGroupSelector = () => {
  const dispatch = useDispatch()
  const columnGroups = useSelector(getColumnGroups)
  const selectedColumnGroup = useSelector(getSelectedColumnGroup)

  const setColumnGroupCallback = useCallback((newColumnGroup: ColumnGroup<any>) => {
    dispatch(writePlannerDefaultColumnsData(newColumnGroup.id))
  }, [dispatch])

  const handleChange = (event: any, newValue: ColumnGroup<any> | null) => {
    if (!!newValue) {
      setColumnGroupCallback(newValue)
    }
  }

    return <Autocomplete
      autoHighlight
      selectOnFocus
      clearOnEscape
      openOnFocus
      disableClearable
      renderInput={(params) => <TextField {...params} variant='outlined' label='Default column group' margin='dense' style={{width: '200px'}}/>}
      options={columnGroups}
      getOptionLabel={(columnGroup: ColumnGroup<any>) => columnGroup.name}
      onChange={handleChange}
      value={selectedColumnGroup}
      style={{width: '200px'}}
    />
}

export default PlannerCustomGroupSelector
