import {characterBoardStatusConverter} from 'src/tracker/model/tracker-model-converter'
import {CharacterBoardStatus} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { EditableCharacterProperty } from '../editable-character-property'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'

export const characterBoardProperty = new EditableCharacterProperty(
  "bf495104-dfde-4707-be6c-a205e6148d7d",
  "Character Boards",
  "C. Boards",
  characterBoardStatusConverter,
  "Character Boards",
  (universalCharacterData, when, region) => {
    const cBoardsAppearBeforeNow = debutBeforeTime(region, universalCharacterData.universal, when)(CharacterDebut.CHARACTER_BOARD)
    const allCBoardValues = getValues(CharacterBoardStatus) as CharacterBoardStatus[]
    if (cBoardsAppearBeforeNow) {
      return allCBoardValues
    } else {
      return allCBoardValues.slice(0, 1)
    }
  }
)
