import {Uuid} from 'src/forecast/model/universal-data-model'
import { ResourceProperty } from '../property/resource-meta/resource-property'
import { PlannerProperty } from '../property/static/planner-property'

export class PlannerColumn {
  constructor(
    id: Uuid,
    width: string,
    name: string,
    shortName: string,
    ) {
      this.id = id
      this.width = width
      this.name = name
      this.shortName = shortName
    }
  id: Uuid
  width: string
  name: string
  shortName: string
}

export const createDefaultPlannerColumn = (property: PlannerProperty, width: string, shortName: string) => {
  return new PlannerColumn(property.id, width, property.name, shortName)
}

export class ResourceColumn<T extends ResourceProperty> extends PlannerColumn {
  constructor(
    shortName: string,
    imageName: string,
    imageLocator: string,
    property: T,
    width: string = "75px",
    realizableImageName?: string,
    realizableImageLocator?: string,
    ) {
      super(property.id, width, property.name, shortName)
      this.imageName = imageName
      this.imageLocator = imageLocator
      this.property = property
      this.realizableImageName = realizableImageName
      this.realizableImageLocator = realizableImageLocator
    }
  imageName: string
  imageLocator: string
  property: T
  realizableImageName: string | undefined
  realizableImageLocator: string | undefined
}

export function isResourceColumn(object: any): object is ResourceColumn<ResourceProperty> {
  return !!object.imageName && !!object.imageLocator
}

export interface RealizableResourceColumn<T extends ResourceProperty> extends ResourceColumn<T> {
  realizableImageName: string,
  realizableImageLocator: string,
}

export function isRealizableResourceColumn(object: any): object is RealizableResourceColumn<ResourceProperty> {
  return !!object.realizableImageName && !!object.realizableImageLocator
}
