import { BannerResourceProperty } from "../../property/resource-meta/banner-resource-property";
import { CharacterResourceProperty } from "../../property/resource-meta/character-resource-property";
import { ColumnGroup } from "../column-group";
import { ResourceColumn } from "../planner-column";
import { armorNuggetsColumn } from "./armor-nuggets-column";
import { armorPageColumn } from "./armor-page-column";
import { armorTokenColumn } from "./armor-token-column";
import { bloomFragmentColumn } from "./bloom-fragment-column";
import { bloomTokenColumn } from "./bloom-token-column";
import { btNuggetsColumn } from "./bt-nuggets-column";
import { btPagesColumn } from "./bt-pages-column";
import { btTokenColumn } from "./bt-token-column";
import { enhancementPointColumn } from "./enhancement-point-column";
import { exWeaponTokenColumn } from "./ex-weapon-token-column";
import { forceStoneShardsColumn } from "./force-stone-shards-column";
import { gemColumn } from "./gem-column";
import { highArmorNuggetsColumn } from "./high-armor-nuggets-column";
import { highArmorPagesColumn } from "./high-armor-pages-column";
import { highArmorTokenColumn } from "./high-armor-token-column";
import { multiTicketColumn } from "./multi-ticket-column";
import { powerStoneColumn } from "./power-stone-column";
import { providenceCoreColumn } from "./providence-core-column";
import { ticketColumn } from "./ticket-column";
import { weaponNuggetsColumn } from "./weapon-nuggets-column";
import { weaponPageColumn } from "./weapon-page-column";
import { weaponTokenColumn } from "./weapon-token-column";

export const allResourcesColumnGroup = new ColumnGroup(
  "487f3d7e-0ccf-4bb8-a250-0b3d6f7cad0a",
  "Resources",
  [gemColumn, ticketColumn, powerStoneColumn, weaponTokenColumn, armorTokenColumn, bloomTokenColumn, bloomFragmentColumn, weaponPageColumn, weaponNuggetsColumn, armorPageColumn,
    armorNuggetsColumn, enhancementPointColumn, exWeaponTokenColumn, btTokenColumn, highArmorTokenColumn, highArmorPagesColumn, highArmorNuggetsColumn,
    btPagesColumn, btNuggetsColumn, providenceCoreColumn, forceStoneShardsColumn, multiTicketColumn]
)

export const characterResourcesColumnGroup = allResourcesColumnGroup.columns.filter(column => column.property instanceof CharacterResourceProperty) as ResourceColumn<CharacterResourceProperty>[]

export const bannerResourcesColumnGroup = allResourcesColumnGroup.columns.filter(column => column.property instanceof BannerResourceProperty) as ResourceColumn<BannerResourceProperty>[]
