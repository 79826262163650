import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {StandardLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { frLimitBreakConverter } from 'src/tracker/model/tracker-model-converter'
import { WeaponType } from 'src/forecast/model/weapon-type'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'

export const frProperty = new WeaponDataProperty(
  "f74fc755-6b95-4dfb-aa70-b6b7376a86c3",
  "FR",
  "FR",
  frLimitBreakConverter,
  "FR",
  WeaponType.FR,
  (characterData, when, region) => {
    const frWeaponAppearsBeforeNow = debutBeforeTime(region, characterData.universal, when)(CharacterDebut.FR)
    const allFrWeaponLimitBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (frWeaponAppearsBeforeNow) {
      return allFrWeaponLimitBreakValues
    } else {
      return allFrWeaponLimitBreakValues.slice(0, 1)
    }
  }
)
