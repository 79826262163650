import { EventPanelTab, SetEventPanelTabAction, SET_EVENT_PANEL_TAB } from './event-details-actions'

const initialState = {
  currentTab: EventPanelTab.BANNERS_TAB,
}

export type EventDetailsState = typeof initialState

export function eventDetailsReducer(state = initialState, action: SetEventPanelTabAction) {
  switch (action.type) {
    case SET_EVENT_PANEL_TAB:
      const castedAction = action as SetEventPanelTabAction
      return Object.assign({}, state, {currentTab: castedAction.tab })
    default:
      return state
  }
}
