import { frProperty } from 'src/tracker/model/property/equipment/weapon/fr-property'
import { forceEnhancementProperty } from 'src/tracker/model/property/levels/force-enhancement-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { CharacterResourceProperty, createStandardCharacterDataAccessor } from '../resource-meta/character-resource-property'

export const forceStoneShardProperty = new CharacterResourceProperty(
  '5804726d-c461-4538-b55b-451f13386f8e',
  'Force Stone Shards',
  'forceStoneShards',
  Resource.FORCE_STONE_SHARDS,
)

forceStoneShardProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(forceEnhancementProperty, Resource.FORCE_STONE_SHARDS))
forceStoneShardProperty.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(frProperty, Resource.FORCE_STONE_SHARDS))
