import {ColumnGroup} from 'src/tracker/model/column/groups/column-group'
import {fifteenCpColumn} from 'src/tracker/model/column/variable/equipment/weapon/fifteen-cp-column'
import {thirtyFiveCpColumn} from 'src/tracker/model/column/variable/equipment/weapon/thirty-five-cp-column'
import {exColumn} from 'src/tracker/model/column/variable/equipment/weapon/ex-column'
import {armorColumn} from 'src/tracker/model/column/variable/equipment/armor/armor-column'
import {bloomColumn} from 'src/tracker/model/column/variable/equipment/misc/bloom-column'
import {silverWeaponColumn} from 'src/tracker/model/column/variable/equipment/weapon/silver-weapon-column'
import {manikinWeaponColumn} from 'src/tracker/model/column/variable/equipment/weapon/manikin-weapon-column'
import {woiColumn} from 'src/tracker/model/column/variable/equipment/weapon/woi-column'
import {ntColumn} from 'src/tracker/model/column/variable/equipment/weapon/nt-column'
import {silverArmorColumn} from 'src/tracker/model/column/variable/equipment/armor/silver-armor-column'
import { ldColumn } from '../variable/equipment/weapon/ld-column'
import { btColumn } from '../variable/equipment/weapon/bt-column'
import { frColumn } from '../variable/equipment/weapon/fr-column'

export const equipmentGroup = new ColumnGroup(
  'd63b068b-dfb8-4ad5-9c96-1a979a1d042d',
  'Equipment',
  [fifteenCpColumn, thirtyFiveCpColumn, exColumn, ldColumn, btColumn, frColumn, armorColumn, bloomColumn, silverWeaponColumn,
    manikinWeaponColumn, woiColumn, ntColumn, silverArmorColumn],
)
