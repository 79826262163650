import { AppState } from 'src/store/root-reducer';
import queryString, {ParsedQuery} from 'query-string'
import { CHARACTER_PANEL_NAME, EVENT_PANEL_ID, REGION_ARG } from './root-router';
import { getDefaultRegion } from 'src/preferences/preferences-selectors';
import { Region } from 'src/tracker/model/components/region';

export function getPageName(state: AppState) {
  return "/" + (state.router.location.pathname.split("/")[1] || "")
}

function getQueryParamValue(state: AppState, paramName: string) {
  return queryString.parse(state.router.location.search)[paramName] as string | undefined
}

export function getCurrentPath(state: AppState, queryAlteringFunction?: (query: ParsedQuery) => ParsedQuery) {
  const path = state.router.location.pathname
  var query = queryString.parse(state.router.location.search)
  if (!!queryAlteringFunction) {
    query = queryAlteringFunction(query)
  }
  var queryOutput = queryString.stringify(query)
  if (!!queryOutput) {
    queryOutput = "?" + queryOutput
  }
  return `${path}${queryOutput}`
}

function getPathForSetQueryParamValue(state: AppState, queryKey: string, queryValue: string) {
  return getCurrentPath(state, (query) => {
    query[queryKey] = queryValue
    return query
  })
}

export function getPathForDeleteQueryParamValue(state: AppState, queryKey: string) {
  return getCurrentPath(state, (query) => {
    delete query[queryKey]
    return query
  })
}

export function getActivePanelCharacterName(state: AppState) {
  return getQueryParamValue(state, CHARACTER_PANEL_NAME)
}

export function getPathWithActivePanelCharacterNameQuery(state: AppState, characterName: string) {
  return getPathForSetQueryParamValue(state, CHARACTER_PANEL_NAME, characterName)
}

export function getActivePanelEventId(state: AppState) {
  return getQueryParamValue(state, EVENT_PANEL_ID)
}

export const getPathWithActivePanelEventIdQuery = (eventId: string) => (state: AppState) => {
  return getPathForSetQueryParamValue(state, EVENT_PANEL_ID, eventId)
}

export function getActiveRegion(state: AppState) {
  const stringRegion = getQueryParamValue(state, REGION_ARG)
  if (!!stringRegion) {
    return Region[stringRegion as keyof typeof Region]
  }
  return getDefaultRegion(state)
}

export function getPathAndQueryForNewPath(state: AppState, path: string) {
  const query = state.router.location.search
  return `${path}${query}`
}
