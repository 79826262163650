import { TotalAllocation } from "../../planner-model";
import { CharacterResourceProperty } from "../../property/resource-meta/character-resource-property";
import { PlannerColumn, ResourceColumn } from "../planner-column";

export class NeededColumn extends PlannerColumn {
  constructor(
    id: string,
    resourceColumn: ResourceColumn<CharacterResourceProperty>,
    shortName: string,
  ) {
    super(id, "80px", resourceColumn.property.name + " Needed", shortName)
    this.property = resourceColumn.property
    this.resourceColumn = resourceColumn
    this.getNeededAfterEvent = (totalAllocation) => this.property.resourcesListAccessor(totalAllocation.resourcesNeededInCharacters)
  }
  property: CharacterResourceProperty
  resourceColumn: ResourceColumn<CharacterResourceProperty>
  getNeededAfterEvent: (totalAllocation: TotalAllocation) => number
}
