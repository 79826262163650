import {Banner, Character, Event, Milestone} from 'src/forecast/model/universal-data-model'
import React, {FunctionComponent, useEffect, useCallback, useMemo} from 'react';
import { AppState } from 'src/store/root-reducer'
import {useSelector, useDispatch} from 'react-redux'
import WeaponBadges from './weapon-badges';
import { shouldGetAsset } from 'src/image-cache/image-effects'
import {isArray, padStart} from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton';
import {selectImage} from 'src/image-cache/image-selectors'
import './character-image.css'
import {createCharacterSearchedFor, getTimeOfMilestone} from 'src/forecast/forecast-selectors'
import {getWeaponBadgesFromAdditionalInfo, greyCharacterImage} from 'src/tracker/tracker-selectors'
import { Link } from 'react-router-dom';
import { getActiveRegion, getPathWithActivePanelCharacterNameQuery } from 'src/router/router-selectors';
import { CharacterData } from 'src/tracker/model/tracker-model';

interface CharacterImageProps {
  character?: Character,
  clickable?: boolean,
  dimension?: string,
  shouldOverrideHeight?: boolean,
  additionalCharacterInfo?: [Event, (Banner | undefined)] | CharacterData,
  shouldGreyIfAcquired?: boolean,
  shouldHighlight?: boolean,
}

const backgroundLocator = 'greyBackground'
const borderLocator = 'greyBorder'
const highlightLocator = 'highlightChara'
const greyLocator = 'charaGrey'

export function imgScalingStyling(dimension: string, shouldOverrideHeight: boolean = false): React.CSSProperties {
  return {
    maxWidth: "100%",
    maxHeight: "100%",
    height: shouldOverrideHeight ? dimension : "auto",
    width: dimension,
    minWidth: "0px",
    minHeight: "0px",
  }
}

const CharacterImage: FunctionComponent<CharacterImageProps> = ({
  character,
  clickable = false,
  dimension = "128px",
  shouldOverrideHeight = false,
  additionalCharacterInfo,
  shouldGreyIfAcquired = false,
  shouldHighlight = true,
}) => {
  const region = useSelector(getActiveRegion)
  const charaBackground = useSelector(selectImage(backgroundLocator))
  const charaBorder = useSelector(selectImage(borderLocator))
  const charaHighlight = useSelector(selectImage(highlightLocator))
  const charaFace = useSelector(selectImage(character?.id || null))
  const charaGrey = useSelector(selectImage(greyLocator))
  const frDebutTime = useSelector(getTimeOfMilestone(Milestone.SHINRYU))
  const characterId = character?.id
  const weaponBadgesImagesToShow = getWeaponBadgesFromAdditionalInfo(additionalCharacterInfo, region, character, frDebutTime)
  const memoizedCharacterSearchedFor = useMemo(createCharacterSearchedFor, [])
  const isHighlighted = useSelector((state: AppState) => clickable && !!characterId && shouldHighlight && memoizedCharacterSearchedFor(state, characterId))
  const greyOut = useSelector((state: AppState) => {
    if (!!additionalCharacterInfo && isArray(additionalCharacterInfo) && !!character && !!shouldGreyIfAcquired && additionalCharacterInfo[1] !== undefined) {
      return greyCharacterImage(state, character.id, additionalCharacterInfo[0], additionalCharacterInfo[1])
    }
    return false
  })
  const characterPanelPath = useSelector((state: AppState) => {
    if (!!character) {
      return getPathWithActivePanelCharacterNameQuery(state, character.name)
    }
  })

  const dispatch = useDispatch()
  const dispatchShouldGetAsset = useCallback((location: string, id: string) => {
    dispatch(shouldGetAsset(location, id))
  }, [dispatch])

  useEffect(() => {
    if (!charaBackground) {
      dispatchShouldGetAsset('still_chara_face_27.png', backgroundLocator)
    }
    if (!charaBorder) {
      dispatchShouldGetAsset('still_chara_face_18.png', borderLocator)
    }
    if (!charaHighlight) {
      dispatchShouldGetAsset('still_chara_face_22.png', highlightLocator)
    }
    if (!charaGrey) {
      dispatchShouldGetAsset('still_chara_face_26.png', greyLocator)
    }
    if (!charaFace && !!character) {
      dispatchShouldGetAsset(`chara_face/stl_chara_face_${padStart(character.internalNumber.toString(), 8, '0')}.g1t.png`, character.id)
    }
  })

  const loaded = !!charaBackground && !!charaBorder && !!charaHighlight

  const imgCommonStyling = (dimension: string, shouldOverrideHeight: boolean) => {
    return Object.assign({}, imgScalingStyling(dimension, shouldOverrideHeight), {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }) as React.CSSProperties
  }

  const renderStackedImageComponent = (image: string | null, alt: string, className: string | undefined = undefined) => {
    return loaded && !!image && <img src={image} alt={alt} style={Object.assign(imgCommonStyling(dimension, shouldOverrideHeight))} className={className} />
  }

  const skeleton = !loaded && <Skeleton variant='rect' style={Object.assign({}, imgCommonStyling(dimension, shouldOverrideHeight))}/>
  const charaFaceComponent = renderStackedImageComponent(charaFace, character?.name || "No character")
  const charaBackgroundComponent = renderStackedImageComponent(charaBackground, '')
  const charaBorderComponent = renderStackedImageComponent(charaBorder, '')
  const greyOutImage = greyOut && renderStackedImageComponent(charaGrey, '')
  const charaHighlightComponent = renderStackedImageComponent(charaHighlight, '', 'imgHighlightStyling')
  const fullImageStyle = Object.assign({}, {position: "relative", margin: "4px"}, imgScalingStyling(dimension, shouldOverrideHeight))
  const imageComponents = (<>
    {skeleton}
    {charaBackgroundComponent}
    {charaFaceComponent}
    {charaBorderComponent}
    {greyOutImage}
    {isHighlighted && charaHighlightComponent}
    <div style={{ display: 'block', paddingTop: '100%', float: 'left' }} />
    <WeaponBadges imagesToShow={weaponBadgesImagesToShow} style={{ position: 'absolute', right: 0, bottom: 0, maxWidth: '40%' }} />
  </>)

  if (clickable) {
    const resolvedPath = characterPanelPath as string
    return (
      <Link style={fullImageStyle} to={resolvedPath} >
        {imageComponents}
      </Link>
    )
  } else {
    return (
      <div style={fullImageStyle}>
        {imageComponents}
      </div>
    )
  }
}

export default CharacterImage
