import { highArmorNuggetsProperty } from "../../property/resource/high-armor-nuggets-property";
import { ResourceColumn } from "../planner-column";

export const highArmorNuggetsColumn = new ResourceColumn(
  'HA Nuggets',
  'stl_limit_break_item_13_cropped.png',
  'highArmorNuggets',
  highArmorNuggetsProperty,
  undefined,
  'stl_limit_break_item_14_cropped.png',
  'highArmorIngots',
)
