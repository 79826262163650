import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'
import {getValues} from 'src/utils/enum-values'
import {frBoardStatusConverter, ldBoardStatusConverter} from 'src/tracker/model/tracker-model-converter'
import { LdBoardStatus } from '../../tracker-model'
import { EditableCharacterProperty } from '../editable-character-property'

export const frBoardProperty = new EditableCharacterProperty(
  "c2e07323-c048-486f-8ac1-527b9ba1544c",
  "Character FR Boards",
  "FR Boards",
  frBoardStatusConverter,
  "Character FR Boards",
  (character, when, region) => {
    const cBoardsAppearBeforeNow = debutBeforeTime(region, character.universal, when)(CharacterDebut.FR_CHARACTER_BOARD)
    const allLdBoardValues = getValues(LdBoardStatus) as LdBoardStatus[]
    if (cBoardsAppearBeforeNow) {
      return allLdBoardValues
    } else {
      return allLdBoardValues.slice(0, 1)
    }
  }
)
