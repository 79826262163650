import { weaponPageProperty } from "../../property/resource/weapon-page-property";
import { ResourceColumn } from "../planner-column";

export const weaponPageColumn = new ResourceColumn(
  'Weapon Pages',
  'stl_limit_break_item_3_cropped.png',
  'weaponPages',
  weaponPageProperty,
  undefined,
  'stl_limit_break_item_7_cropped.png',
  'weaponBooks',
)
