import React, {useCallback} from 'react';
import firebase from 'firebase'
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useFirebase } from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import theme from 'src/theme'
import {logoff, resetPasswordOfCurrentUser} from 'src/login/login-effects'
import ResetPasswordDialog from 'src/login/reset-password-dialog/reset-password-dialog'
import {openResetPasswordDialog, openAccountDeletionConfirmationDialog} from 'src/login/login-actions'
import Disclaimer from 'src/irl/disclaimer'
import AccountDeletionConfirmationDialog from 'src/login/account-deletion-confirmation-dialog/account-deletion-confirmation-dialog'
import { getAuth } from './login-selectors';

export default function LoginComponent() {
  const loginState = useSelector(getAuth)
  const dispatch = useDispatch()
  const firebaseInstance = useFirebase()

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // TODO: someday the typing on this will suck less
      signInSuccessWithAuthResult: (authResult: any) => {
        return ((firebaseInstance as any).handleRedirectResult(authResult) as boolean)
      }
    }
  }

  const logoutClickCallback = useCallback(() => {
    dispatch(logoff())
  }, [dispatch])
  const resetPasswordClickCallback = useCallback(() => {
    dispatch(resetPasswordOfCurrentUser())
  }, [dispatch])
  const deleteUserClickCallback = useCallback(() => {
    dispatch(openAccountDeletionConfirmationDialog())
  }, [dispatch])
  const openResetPasswordDialogCallback = useCallback(() => {
    dispatch(openResetPasswordDialog())
  }, [dispatch])

  const buttonStyle = {
    maxWidth: "100%",
    width: "500px",
    margin: theme.spacing(1)
  } as React.CSSProperties

  return (
    <div style={{flexGrow: 1}}>
      <Card style={{ margin: theme.spacing(2), padding: theme.spacing(2) }}>
        {!loginState.isEmpty ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography>Logged in as: {loginState.email}</Typography>
            <Button variant="contained" onClick={logoutClickCallback} style={buttonStyle}>Log out</Button>
            <Button variant="contained" onClick={resetPasswordClickCallback} style={buttonStyle}>Send password reset email</Button>
            <Button variant="contained" onClick={deleteUserClickCallback} color="secondary" style={buttonStyle}>Delete user (This cannot be undone)</Button>
            <AccountDeletionConfirmationDialog />
          </div>
          :
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Typography>Log in or register below</Typography>
            <Typography>Before registering, take a look at <Link href="https://www.iubenda.com/privacy-policy/87718912/full-legal">our privacy policy</Link></Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseInstance.auth()} />
            <Typography>Forgot your password? <Button onClick={openResetPasswordDialogCallback} color="primary">Request a password reset email.</Button></Typography>
            <ResetPasswordDialog />
          </div>
        }
      </Card>
      <Disclaimer />
    </div>
  )
}
