import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {btWeaponLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {BtWeaponLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { WeaponType } from 'src/forecast/model/weapon-type'
import { CharacterDebut } from 'src/forecast/model/character-debut'

export const btProperty = new WeaponDataProperty(
  "9086c080-8e3c-4a52-9b70-ad5b9a55e7e2",
  "BT",
  "BT",
  btWeaponLimitBreakConverter,
  "BT",
  WeaponType.BT,
  (universalCharacterData, when, region) => {
    const debutChecker = debutBeforeTime(region, universalCharacterData.universal, when)
    const btAppearsBeforeNow = debutChecker(CharacterDebut.BT)
    const btRealizationAppearsBeforeNow = debutChecker(CharacterDebut.BT_REALIZATION)
    const allBtWeaponLimitBreakValues = getValues(BtWeaponLimitBreak) as BtWeaponLimitBreak[]
    if (btRealizationAppearsBeforeNow) {
      return allBtWeaponLimitBreakValues
    } else if (btAppearsBeforeNow) {
      return allBtWeaponLimitBreakValues.slice(0, 2)
    } else {
      return allBtWeaponLimitBreakValues.slice(0, 1)
    }
  }
)
