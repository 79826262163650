import { WeaponVariant } from 'src/forecast/model/universal-data-model'
import { EnumerableCharacterProperty } from '../enumerable-character-property'
import { CharacterValueConverter } from 'src/tracker/model/tracker-model-converter'
import { getValues } from 'src/utils/enum-values'

const weaponVariantConverter = new CharacterValueConverter<WeaponVariant>(
  (value) => {
    switch (value) {
      case WeaponVariant.DAGGER:
        return "Dagger"
      case WeaponVariant.SWORD:
        return "Sword"
      case WeaponVariant.GREATSWORD:
        return "Greatsword"
      case WeaponVariant.STAFF:
        return "Staff"
      case WeaponVariant.GUN:
        return "Gun"
      case WeaponVariant.FIST:
        return "Fist"
      case WeaponVariant.THROWN:
        return "Thrown"
      case WeaponVariant.SPEAR:
        return "Spear"
      case WeaponVariant.BOW:
        return "Bow"
      case WeaponVariant.WHIP:
        return "Whip"
      case WeaponVariant.MISC:
        return "Misc"
    }
  },
  (value) => {
    switch (value) {
      case "Dagger":
      default:
        return WeaponVariant.DAGGER
      case "Sword":
        return WeaponVariant.SWORD
      case "Greatsword":
        return WeaponVariant.GREATSWORD
      case "Staff":
        return WeaponVariant.STAFF
      case "Gun":
        return WeaponVariant.GUN
      case "Fist":
        return WeaponVariant.FIST
      case "Thrown":
        return WeaponVariant.THROWN
      case "Spear":
        return WeaponVariant.SPEAR
      case "Bow":
        return WeaponVariant.BOW
      case "Whip":
        return WeaponVariant.WHIP
      case "Misc":
        return WeaponVariant.MISC
    }
  }
)

export const weaponVariantProperty = new EnumerableCharacterProperty<WeaponVariant>(
  "060c5bc9-f966-4b7f-9a58-2b419f7815d9",
  "Weapon Type",
  "Wep.",
  weaponVariantConverter,
  (region, character) => {
    return character?.universal.weaponVariant || WeaponVariant.DAGGER
  },
  () => getValues(WeaponVariant)
)
