import { DatePropertyMode, DateTransienceMode, DebutDateProperty } from "./date-property"
import { Region } from "../components/region";
import { AllCharacterData } from "../components/all-character-data";
import { getEventAppearanceValue } from "src/utils/event-time-utilities";
import { datePropertyModeToPrefix } from "./date-property-weapon-appearance-scheme";
import { CharacterDebut, getFriendlyNameFromCharacterDebut, getShortNameFromCharacterDebut } from "src/forecast/model/character-debut";

const v1Schema = "v1"
const typeSchema = "eventdatedebut"

export const resolveDatePropertyMode = (input: DatePropertyMode) => {
  if (input === DatePropertyMode.FUTURE_AND_PRESENT) {
    return DatePropertyMode.FUTURE
  } else if (input === DatePropertyMode.PAST) {
    return DatePropertyMode.PAST_AND_PRESENT
  }
  return input
}

export const generateDatePropertyDebut = (debut: CharacterDebut, datePropertyMode: DatePropertyMode) => {
  const resolvedDatePropertyMode = resolveDatePropertyMode(datePropertyMode)
  const id = v1Schema + ":" + typeSchema + ":" + CharacterDebut[debut] + ":" + DatePropertyMode[resolvedDatePropertyMode]
  const prefix = datePropertyModeToPrefix(resolvedDatePropertyMode)
  const name = prefix + " " + getFriendlyNameFromCharacterDebut(debut) + " debut"
  const shortName = prefix + " " + getShortNameFromCharacterDebut(debut) + " debut"
  const allCharacterDataToProperty = (region: Region, character: AllCharacterData | undefined) => getEventAppearanceValue(character,
    (eventAppearance) => eventAppearance.debuts.find(containedDebut => containedDebut === debut) !== undefined,
    resolvedDatePropertyMode, region)
  return new DebutDateProperty(id, name, shortName, DateTransienceMode.INSTANT, resolvedDatePropertyMode, debut, allCharacterDataToProperty)
}

export const createFromIdDebut = (id: string) => {
  const splitString = id.split(":")
  if (splitString[0] !== undefined && splitString[0] === v1Schema && splitString[1] !== undefined && splitString[1] === typeSchema && splitString[2] !== undefined && splitString[3] !== undefined) {
    const candidateDebut = CharacterDebut[splitString[2] as keyof typeof CharacterDebut]
    const modeCandidate = DatePropertyMode[splitString[3] as keyof typeof DatePropertyMode]
    if (candidateDebut !== undefined && modeCandidate !== undefined) {
      return generateDatePropertyDebut(candidateDebut, modeCandidate)
    }
  }
  return undefined
}
