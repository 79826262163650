import { merge } from "lodash"
import { objectAssigningReducer } from "src/forecast/model/universal-data-converter"
import { Event, Uuid } from "src/forecast/model/universal-data-model"
import { CharacterData, Resource } from "src/tracker/model/tracker-model"
import { TOKENS_PER_DAY } from "../planner-selectors"
import { resourcesGroup, resourcesMap } from "./property/resource/resources-group"
import { btTokenProperty } from "./property/resource/bt-token-property"
import { MogPassMode } from "./mog-pass/mog-pass-mode"
import { gemProperty } from "./property/resource/gem-property"
import { exWeaponTokenProperty } from "./property/resource/ex-weapon-token-property"

export interface Planner {
  "GL": PlannerRegionData
  "JP": PlannerRegionData
  shared: boolean
}

export interface PlannerRegionData {
  eventAllocations: EventAllocations
  scheduledIncome: ScheduledIncome
  currentResourceAccounting: CurrentResourceAccounting
}

export const createDefaultPlannerRegionData: () => PlannerRegionData = () => {
  return {
    eventAllocations: {},
    scheduledIncome: createDefaultScheduledIncome(),
    currentResourceAccounting: createDefaultCurrentResourceAccounting(),
  }
}

export class ResourcesList {
  constructor(
    gems: number,
    tickets: number,
    weaponPages: number,
    armorPages: number,
    weaponNuggets: number,
    armorNuggets: number,
    btTokens: number,
    highArmorTokens: number,
    bloomTokens: number,
    bloomFragments: number,
    armorTokens: number,
    weaponTokens: number,
    exWeaponTokens: number,
    highArmorPages: number,
    highArmorNuggets: number,
    btPages: number,
    btNuggets: number,
    enhancementPoints: number,
    providenceCores: number,
    forceStoneShards: number,
    multiTickets: number,
    powerStones: number,
  ) {
    this.gems = gems
    this.tickets = tickets
    this.weaponPages = weaponPages
    this.armorPages = armorPages
    this.weaponNuggets = weaponNuggets
    this.armorNuggets = armorNuggets
    this.btTokens = btTokens
    this.highArmorTokens = highArmorTokens
    this.bloomTokens = bloomTokens
    this.bloomFragments = bloomFragments
    this.armorTokens = armorTokens
    this.weaponTokens = weaponTokens
    this.exWeaponTokens = exWeaponTokens
    this.highArmorPages = highArmorPages
    this.highArmorNuggets = highArmorNuggets
    this.btPages = btPages
    this.btNuggets = btNuggets
    this.enhancementPoints = enhancementPoints
    this.providenceCores = providenceCores
    this.forceStoneShards = forceStoneShards
    this.multiTickets = multiTickets
    this.powerStones = powerStones
  }
  gems: number
  tickets: number
  weaponPages: number
  armorPages: number
  weaponNuggets: number
  armorNuggets: number
  btTokens: number
  highArmorTokens: number
  bloomTokens: number
  bloomFragments: number
  armorTokens: number
  weaponTokens: number
  exWeaponTokens: number
  highArmorPages: number
  highArmorNuggets: number
  btPages: number
  btNuggets: number
  enhancementPoints: number
  providenceCores: number
  forceStoneShards: number
  multiTickets: number
  powerStones: number
  [key: string]: number
}

export const createEmptyResourceList = () => new ResourcesList(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0)

export class TotalAllocation {
  constructor(
    event: Event,
    currentResources: ResourcesList,
    eventAllocation: EventAllocation,
    characterIdToPreviousCharacterAllocation: Record<Uuid, PreviousCharacterAllocation>,
    dailyResources: ResourcesList,
    mogPassMode: MogPassMode,
    resourcesNeededInCharacters: ResourcesList,
    displayRefinedMaterials: boolean,
  ) {
    this.event = event
    this.currentResources = currentResources
    this.eventAllocation = eventAllocation
    this.characterIdToPreviousCharacterAllocation = characterIdToPreviousCharacterAllocation
    this.dailyResources = dailyResources
    this.mogPassMode = mogPassMode
    this.resourcesNeededInCharacters = resourcesNeededInCharacters
    this.displayRefinedMaterials = displayRefinedMaterials
  }
  event: Event
  currentResources: ResourcesList
  eventAllocation: EventAllocation
  // The key is the id of the character, the characterData holds the previous character data before this event, the eventIdentifier
  // holds the event of the previous seen allocation, or the special value 'TRACKER' if there was no previous event.
  characterIdToPreviousCharacterAllocation: Record<Uuid, PreviousCharacterAllocation>
  dailyResources: ResourcesList
  mogPassMode: MogPassMode
  resourcesNeededInCharacters: ResourcesList
  displayRefinedMaterials: boolean
}

export const TRACKER_SPECIAL_VALUE = "Tracker Data"
export const UNASSIGNED_SPECIAL_VALUE = "Unassigned"

export class PreviousCharacterAllocation {
  constructor(
    characterData: CharacterData,
    eventIdentifier: string,
  ) {
    this.characterData = characterData
    this.eventIdentifier = eventIdentifier
  }
  characterData: CharacterData
  eventIdentifier: string
}

export type EventAllocations = Record<Uuid, EventAllocation>

export class EventAllocation {
  constructor(
    bannerAllocations?: BannerAllocations,
    characterAllocations?: CharacterAllocations,
    miscellaneousAllocations?: ResourcesList,
    markedComplete?: boolean,
  ) {
    this.bannerAllocations = bannerAllocations || {}
    this.characterAllocations = characterAllocations || {}
    this.miscellaneousAllocations = miscellaneousAllocations || createEmptyResourceList()
    this.markedComplete = markedComplete === undefined ? false : markedComplete
  }
  bannerAllocations: BannerAllocations
  characterAllocations: CharacterAllocations
  miscellaneousAllocations: ResourcesList
  markedComplete: boolean
}

export function createDefaultEventAllocation(event?: Event) {
  if (!event) {
    return new EventAllocation({}, {}, undefined)
  }
  const bannerAllocations = event.banners.map(banner => {
    return {
      [banner.id]: createDefaultBannerAllocation()
    }
  }).reduce(objectAssigningReducer, {})
  return new EventAllocation(bannerAllocations, {}, undefined)
}

export type BannerAllocations = Record<Uuid, BannerAllocation>

export class BannerAllocation {
  constructor(
    gems: number,
    tickets: number,
    multiTickets: number,
  ) {
    this.gems = gems
    this.tickets = tickets
    this.multiTickets = multiTickets
  }
  gems: number
  tickets: number
  multiTickets: number
  [key: string]: number
}

export function createDefaultBannerAllocation() {
  return new BannerAllocation(0, 0, 0)
}

export type CharacterAllocations = Record<Uuid, CharacterData>

export class ScheduledIncome {
  constructor(
    tokenIncome: DailyTokenIncome,
    mogPassSettings: MogPassSettings,
  ) {
    this.tokenIncome = tokenIncome
    this.mogPassSettings = mogPassSettings
  }
  tokenIncome: DailyTokenIncome
  mogPassSettings: MogPassSettings
}

export const createDefaultScheduledIncome = () => {
  return new ScheduledIncome(createDefaultDailyTokenIncome(), createDefaultMogPassSettings())
}

export class RepeatingScheduleSpecification<T> {
  constructor(
    schedule: T[],
    lastWrite: number,
    tokensInSchedule: number,
  ) {
    this.schedule = schedule
    this.lastWrite = lastWrite
    this.tokensInSchedule = tokensInSchedule
  }
  schedule: T[]
  lastWrite: number // ms since epoch
  tokensInSchedule: number
}

export class DailyTokenIncome extends RepeatingScheduleSpecification<DailyTokenIncomeScheduleNode> {
}

export class MogPassSettings extends RepeatingScheduleSpecification<MogPassScheduleNode> {
}

const createDefaultDailyTokenIncome = () => {
  return new DailyTokenIncome([], 0, 0)
}

export const createDefaultMogPassSettings = () => {
  return new MogPassSettings([], 0, 0)
}

class SchedulePosition<T> {
  constructor(
    nodeIndex: number,
    time: number,
    tokens: number,
    identifier: T
  ) {
    this.nodeIndex = nodeIndex
    this.time = time
    this.tokens = tokens
    this.identifier = identifier
  }
  nodeIndex: number
  time: number
  tokens: number
  identifier: T
}

export class DailyIncomeScheduleWrapper<T, N> {
  constructor(
    schedule: T[],
    nodeToIdentifier: (node: T) => N,
    nodeToTimes: (node: T) => number,
    nodeIdentifierToTokensPerRedeem: (node: N) => number,
    tokensPerDay: number = 1,
  ) {
    this.schedule = schedule
    this.nodeToIdentifier = nodeToIdentifier
    this.nodeToTimes = nodeToTimes
    this.nodeIdentifierToTokensPerRedeem = nodeIdentifierToTokensPerRedeem
    this.tokensPerDay = tokensPerDay
    this.totalTokens = this.calculateTotalTokensInSchedule()

    this.schedulePositionData = []
    this.indexToPositionMap = [0]
    var currentNode = 0
    var currentTime = 0
    var currentToken = 0
    while (currentNode < this.schedule.length) {
      const scheduleNode = this.schedule[currentNode]
      const identifier = this.nodeToIdentifier(scheduleNode)
      const currentTokenTotal = this.schedulePositionData.length
      this.schedulePositionData.push(new SchedulePosition(currentNode, currentTime, currentToken, identifier))
      currentToken++
      if (currentToken >= (this.nodeIdentifierToTokensPerRedeem(identifier))) {
        currentToken = 0
        currentTime++
      }
      if (currentTime >= this.nodeToTimes(scheduleNode)) {
        currentTime = 0
        currentNode++
        this.indexToPositionMap.push(currentTokenTotal)
      }
    }
  }

  schedule: T[]
  nodeToIdentifier: (node: T) => N
  nodeToTimes: (node: T) => number
  nodeIdentifierToTokensPerRedeem: (node: N) => number
  tokensPerDay: number
  totalTokens: number
  schedulePositionData: SchedulePosition<N>[]
  indexToPositionMap: number[]
  calculateTotalTokensInSchedule = () => {
    return this.schedule.map(scheduleNode =>
      this.nodeIdentifierToTokensPerRedeem(this.nodeToIdentifier(scheduleNode)) * this.nodeToTimes(scheduleNode)
    ).reduce((a, b) => a + b, 0)
  }
  getDaysUsedFromIndex = (index: number) => {
    return (this.indexToPositionMap[index] + 1) / this.tokensPerDay
  }
}

export const createTokenDailyIncomeScheduleWrapper = (schedule: DailyTokenIncomeScheduleNode[]) => new DailyIncomeScheduleWrapper(schedule,
  scheduleInt => scheduleInt.resource, scheduleInt => scheduleInt.times, resource => {
    const resourceProperty = resourcesMap[resource]
    return resourceProperty.dailyResourceStats?.dailyTokensPerRedeem || 0
  }, TOKENS_PER_DAY)

export const createMogPassScheduleWrapper = (schedule: MogPassScheduleNode[]) => new DailyIncomeScheduleWrapper(schedule,
  scheduleInt => getMogPassModeFromNodeSafe(scheduleInt), scheduleInt => scheduleInt.times, mode => mode === MogPassMode.NONE ? 1 : 30)

export const calculateResourcesGainedBetweenTokenAmounts = (scheduleWrapper: DailyIncomeScheduleWrapper<DailyTokenIncomeScheduleNode, Resource>, startTokens: number, endTokens: number) => {
  var resources = createEmptyResourceList()
  if (scheduleWrapper.totalTokens <= 0) {
    return resources
  }
  for (var i = startTokens; i < endTokens; i++) {
    const repeatedPosition = i % scheduleWrapper.totalTokens
    const nextPosition = (i + 1) % scheduleWrapper.totalTokens
    const currentPositionData = scheduleWrapper.schedulePositionData[repeatedPosition]
    const nextPositionData = scheduleWrapper.schedulePositionData[nextPosition]
    if (currentPositionData.nodeIndex !== nextPositionData.nodeIndex
      || currentPositionData.time !== nextPositionData.time
      || nextPosition < repeatedPosition
      || scheduleWrapper.totalTokens === 1) {
      const property = resourcesMap[currentPositionData.identifier]
      const resourceGained = (property.dailyResourceStats?.resourcesPerRedeem || 0)
      const previousResource = property.resourcesListAccessor(resources)
      resources = property.resourcesListReducer(resources, resourceGained + previousResource)
    }
  }
  return resources
}

export const calculateResourcesBetweenMogPassRenewals = (scheduleWrapper: DailyIncomeScheduleWrapper<MogPassScheduleNode, MogPassMode>, startDays: number, endDays: number) => {
  var resources = createEmptyResourceList()
  if (scheduleWrapper.totalTokens <= 0) {
    return resources
  }
  for (var i = startDays; i < endDays; i++) {
    const repeatedPosition = i % scheduleWrapper.totalTokens
    const nextPosition = (i + 1) % scheduleWrapper.totalTokens
    const currentPositionData = scheduleWrapper.schedulePositionData[repeatedPosition]
    const nextPositionData = scheduleWrapper.schedulePositionData[nextPosition]
    if (currentPositionData.nodeIndex !== nextPositionData.nodeIndex
      || currentPositionData.time !== nextPositionData.time
      || nextPosition < repeatedPosition
      || scheduleWrapper.totalTokens === 1) {
      const resourceGained = currentPositionData.identifier === MogPassMode.PREMIUM ? 10 : 0
      const previousResource = btTokenProperty.resourcesListAccessor(resources)
      resources = btTokenProperty.resourcesListReducer(resources, resourceGained + previousResource)

      const gemsGained = currentPositionData.identifier === MogPassMode.NONE ? 0 : currentPositionData.identifier === MogPassMode.BASE ? 610 : 4640
      const previousGems = gemProperty.resourcesListAccessor(resources)
      resources = gemProperty.resourcesListReducer(resources, gemsGained + previousGems)

      const exTokensGained = currentPositionData.identifier === MogPassMode.PREMIUM ? 5 : 0
      const previousExTokens = exWeaponTokenProperty.resourcesListAccessor(resources)
      resources = exWeaponTokenProperty.resourcesListReducer(resources, exTokensGained + previousExTokens)
    }
  }
  return resources
}

export const mogPassModeAtDay = (scheduleWrapper: DailyIncomeScheduleWrapper<MogPassScheduleNode, MogPassMode>, daysFromWrite: number) => {
  const repeatedPosition = daysFromWrite % scheduleWrapper.totalTokens
  const currentPositionData = scheduleWrapper.schedulePositionData[repeatedPosition]
  return currentPositionData?.identifier || MogPassMode.NONE
}

export class DailyTokenIncomeScheduleNode {
  constructor(
    resource: Resource,
    times: number,
  ) {
    this.resource = resource
    this.times = times
  }
  resource: Resource
  times: number
}

export class CurrentResourceAccounting {
  constructor(
    currentHeld: ResourcesList,
    inBox: ResourcesList,
    charactersMissingNTicketTreasures: number[],
    charactersMissingNGemTreasures: number[],
    charactersMissingNArmorTreasures: number[],
    mogTokensAllocatedForBtTokens: number,
    btDupes: number,
    btTokensInTranscendence: number,
    unclaimedIllusionBoards: UnclaimedIllusionBoards,
    wholeRealizationMaterials: WholeRealizationMaterials,
    leftoverSpiritusRewards: LeftoverSpiritusRewards,
  ) {
    this.currentHeld = currentHeld
    this.inBox = inBox
    this.charactersMissingNTicketTreasures = charactersMissingNTicketTreasures
    this.charactersMissingNGemTreasures = charactersMissingNGemTreasures
    this.charactersMissingNArmorTreasures = charactersMissingNArmorTreasures
    this.mogTokensAllocatedForBtTokens = mogTokensAllocatedForBtTokens
    this.btDupes = btDupes
    this.btTokensInTranscendence = btTokensInTranscendence
    this.unclaimedIllusionBoards = unclaimedIllusionBoards
    this.wholeRealizationMaterials = wholeRealizationMaterials
    this.leftoverSpiritusRewards = leftoverSpiritusRewards
  }
  currentHeld: ResourcesList
  inBox: ResourcesList
  charactersMissingNTicketTreasures: number[]
  charactersMissingNGemTreasures: number[]
  charactersMissingNArmorTreasures: number[]
  mogTokensAllocatedForBtTokens: number
  btDupes: number
  btTokensInTranscendence: number
  unclaimedIllusionBoards: UnclaimedIllusionBoards
  wholeRealizationMaterials: WholeRealizationMaterials
  leftoverSpiritusRewards: LeftoverSpiritusRewards
}

const missingNTreasuresToValue = (missingNTreasures: number[] | undefined, valuePer: number) => {
  if (missingNTreasures === undefined) {
    return 0
  }
  return missingNTreasures.map((value, index) => {
    return value * valuePer * (index + 1)
  }).reduce((a, b) => a + b, 0)
}

const getRealizableValue = (realizationMaterials: WholeRealizationMaterials) => (resource: Resource) => {
  return (realizationMaterials[resource] || 0) * 20
}
const getIllusionValue = (illusionBoards: UnclaimedIllusionBoards) => (resourceBeforeTen: number, resourceAfterTen: number) => {
  return Object.values(illusionBoards).map(remainingBoards => {
    return [...Array(remainingBoards)].map((_, index) => {
      if (index < 20 /* not 10 here, 20, because we're doing REMAINING boards, not completed boards */) {
        return resourceAfterTen
      } else if (index < 30) {
        return resourceBeforeTen
      }
      return 0
    }).reduce((a, b) => a + b, 0)
  }).reduce((a, b) => a + b, 0)
}

const getSpiritusValue = (spiritusLeftovers: LeftoverSpiritusRewards) => (accessorString: string, multiplier: number) => {
  return Object.values(spiritusLeftovers).map(singularLeftover => {
    return singularLeftover[accessorString] * multiplier
  }).reduce((a, b) => a + b, 0)
}

export const currentResourceAccountingToList = (currentResourceAccountingUnsafe: CurrentResourceAccounting | undefined) => {
  const currentResourceAccounting = merge(createDefaultCurrentResourceAccounting(), currentResourceAccountingUnsafe)
  const realizableValueAccessor = getRealizableValue(currentResourceAccounting.wholeRealizationMaterials)
  const illusionValueAccessor = getIllusionValue(currentResourceAccounting.unclaimedIllusionBoards)
  const spiritusValueAccessor = getSpiritusValue(currentResourceAccounting.leftoverSpiritusRewards)
  var output = createEmptyResourceList()
  resourcesGroup.forEach(property => {
    const currentValue = property.resourcesListAccessor(output)
    const currentValueHeld = property.resourcesListAccessor(currentResourceAccounting.currentHeld)
    const currentValueInBox = property.resourcesListAccessor(currentResourceAccounting.inBox)
    output = property.resourcesListReducer(output, currentValue + currentValueHeld + currentValueInBox)
  })

  const gems = output.gems + missingNTreasuresToValue(currentResourceAccounting.charactersMissingNGemTreasures, 300) +
    illusionValueAccessor(1000, 1500) + spiritusValueAccessor("gemChests", 10000)
  const tickets = output.tickets + missingNTreasuresToValue(currentResourceAccounting.charactersMissingNTicketTreasures, 3) +
    illusionValueAccessor(12, 14) + spiritusValueAccessor("ticketChests", 100) + spiritusValueAccessor("leftoverKeys", 3)
  const weaponPages = output.weaponPages + realizableValueAccessor(Resource.WEAPON_PAGES)
  const armorPages = output.armorPages + realizableValueAccessor(Resource.ARMOR_PAGES)
  const weaponNuggets = output.weaponNuggets + realizableValueAccessor(Resource.WEAPON_NUGGETS) + illusionValueAccessor(2, 4)
  const armorNuggets = output.armorNuggets + realizableValueAccessor(Resource.ARMOR_NUGGETS) + illusionValueAccessor(2, 4)
  const btTokens = output.btTokens + currentResourceAccounting.mogTokensAllocatedForBtTokens * 5 + currentResourceAccounting.btDupes * 5 + currentResourceAccounting.btTokensInTranscendence
  const armorTokens = output.armorTokens + missingNTreasuresToValue(currentResourceAccounting.charactersMissingNArmorTreasures, 5)
  const highArmorPages = output.highArmorPages + realizableValueAccessor(Resource.HIGH_ARMOR_PAGES)
  const highArmorNuggets = output.highArmorNuggets + realizableValueAccessor(Resource.HIGH_ARMOR_NUGGETS)
  const btPages = output.btPages + realizableValueAccessor(Resource.BT_PAGES)
  const btNuggets =  output.btNuggets + realizableValueAccessor(Resource.BT_NUGGETS)
  const enhancementPoints = currentResourceAccounting.currentHeld.enhancementPoints
  const forceStoneShards = output.forceStoneShards + realizableValueAccessor(Resource.FORCE_STONE_SHARDS) +
    spiritusValueAccessor("forceStoneShardChests", 20)
  const powerStones = output.powerStones + realizableValueAccessor(Resource.POWER_STONE)
  return new ResourcesList(gems, tickets, weaponPages, armorPages, weaponNuggets, armorNuggets, btTokens, output.highArmorTokens, output.bloomTokens, output.bloomFragments,
    armorTokens, output.weaponTokens, output.exWeaponTokens, highArmorPages, highArmorNuggets, btPages, btNuggets, enhancementPoints, output.providenceCores, forceStoneShards,
    output.multiTickets, powerStones)
}

type UnclaimedIllusionBoards = Record<string, number>
type WholeRealizationMaterials = Record<string, number>
type LeftoverSpiritusRewards = Record<string, LeftoverSpiritusReward>

export const createDefaultCurrentResourceAccounting = () => {
  const tenLongArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  return new CurrentResourceAccounting(createEmptyResourceList(), createEmptyResourceList(), tenLongArray.slice(), tenLongArray.slice(), tenLongArray.slice(), 0, 0, 0, {}, {}, {})
}

export class MogPassScheduleNode {
  constructor(
    mogPassEnabled: boolean,
    times: number,
    mode: MogPassMode,
  ) {
    this.mogPassEnabled = mogPassEnabled
    this.times = times
    this.mode = mode
  }
  mogPassEnabled: boolean
  times: number
  mode: MogPassMode
}

export const getMogPassModeFromNode = (node: MogPassScheduleNode) => {
  if (node.mode !== undefined) {
    return node.mode
  } else if (node.mogPassEnabled === true) {
    return MogPassMode.BASE
  } else if (node.mogPassEnabled === false) {
    return MogPassMode.NONE
  }
}

export const getMogPassModeFromNodeSafe = (node: MogPassScheduleNode) => {
  const determinedMode = getMogPassModeFromNode(node)
  return determinedMode === undefined ? MogPassMode.NONE : determinedMode
}

export class LeftoverSpiritusReward {
  constructor(
    gemChests: number,
    ticketChests: number,
    forceStoneShardChests: number,
    leftoverKeys: number,
  ) {
    this.gemChests = gemChests
    this.ticketChests = ticketChests
    this.forceStoneShardChests = forceStoneShardChests
    this.leftoverKeys = leftoverKeys
  }
  gemChests: number
  ticketChests: number
  forceStoneShardChests: number
  leftoverKeys: number
  [key: string]: number
}

export const createDefaultLeftoverSpiritusReward = () => {
  return new LeftoverSpiritusReward(0, 0, 0, 0)
}
