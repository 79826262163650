import {ldBoardStatusConverter} from 'src/tracker/model/tracker-model-converter'
import {LdBoardStatus} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { EditableCharacterProperty } from '../editable-character-property'
import { debutBeforeTime } from 'src/forecast/forecast-selectors'
import { CharacterDebut } from 'src/forecast/model/character-debut'

export const ldBoardProperty = new EditableCharacterProperty(
  "a3a2cdcb-6e35-4419-a66a-ecf0549d2ea8",
  "Character LD Boards",
  "LD Boards",
  ldBoardStatusConverter,
  "Character LD Boards",
  (character, when, region) => {
    const cBoardsAppearBeforeNow = debutBeforeTime(region, character.universal, when)(CharacterDebut.LD_CHARACTER_BOARD)
    const allLdBoardValues = getValues(LdBoardStatus) as LdBoardStatus[]
    if (cBoardsAppearBeforeNow) {
      return allLdBoardValues
    } else {
      return allLdBoardValues.slice(0, 1)
    }
  }
)
