import {Event} from 'src/forecast/model/universal-data-model'
import React, { FunctionComponent } from 'react'
import BannerRow from 'src/forecast/banner-row/banner-row'
import { BannerAllocations } from 'src/planner/model/planner-model'
import { bannerResourcesColumnGroup } from 'src/planner/model/column/resource/resources-column-group'
import ResourceImage from '../resource-image/resource-image'
import ResourceValueDisplay from 'src/planner/resource-value-display/resource-value-display'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { getActiveRegion } from 'src/router/router-selectors'
import { getBannersFromEvent } from 'src/forecast/forecast-selectors'

interface BannersTabProps {
  event: Event,
  bannerAllocations: BannerAllocations | undefined,
}

const BannersTab: FunctionComponent<BannersTabProps> = ({
  event,
  bannerAllocations,
}) => {
  const region = useSelector(getActiveRegion)
  const bannerRows = getBannersFromEvent(event, region)
    .map(banner => {
    const pictureRow =
      <BannerRow
        key='Banner images'
        banner={banner}
        event={event}
      />
    if (!bannerAllocations) {
      return pictureRow
    }
    const bannerAllocation = bannerAllocations[banner.id]
    if (!bannerAllocation) {
      return pictureRow
    }
    const propertyColumns = bannerResourcesColumnGroup
      .filter(column => column.property.displayInBannerContext)
      .map(column => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }} key={column.id} >
            <ResourceImage column={column} key='image' />
            <ResourceValueDisplay value={column.property.getResourceFromBannerAllocation(bannerAllocation)} key='value' />
          </div>
        )
      })
    return (
    <div key={banner.id}>
      {pictureRow}
      <Typography align='center'>Banner expenditures</Typography>
      {propertyColumns}
    </div>)
  })

  return <>
    {bannerRows}
  </>
}

export default BannersTab
