import React, { useState } from 'react'
import { isEqual } from 'lodash';
import ColumnListItem, { NumberPickerProps } from './column-list-item/column-list-item';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

export class InsertionListNumberPickerProps<T> {
  constructor(
    accessor: (input: T) => number,
    reducer: (input: T, newValue: number, index: number) => T,
  ) {
    this.accessor = accessor
    this.reducer = reducer
  }
  accessor: (input: T) => number
  reducer: (input: T, newValue: number, index: number) => T
}

interface InsertionListProps<T> {
  dataList: T[]
  newDataListConsumer: (newList: T[]) => void
  dataToString: (data: T) => string
  positionObserver: (position: number) => void
  numberPickerProps?: InsertionListNumberPickerProps<T>
  sidecarTextGenerator?: (list: T[], index: number) => string
}

const insertionPointSpecialValue = "SPECIAL_VALUE"

class InsertionListState<T> {
  constructor(
    dataList: T[],
    insertionPosition: number,
  ) {
    this.dataList = dataList
    this.insertionPosition = Math.min(dataList.length, insertionPosition)

    this.internalDataList = this.dataList.slice() as (T | string)[]
    this.internalDataList.splice(this.insertionPosition, 0, insertionPointSpecialValue)
  }
  dataList: T[]
  insertionPosition: number
  internalDataList: (T | string)[]

  setNewDataList = (dataList: T[]) => {
    return new InsertionListState(
      dataList,
      this.insertionPosition,
    )
  }

  setInsertionPosition = (insertionPosition: number) => {
    return new InsertionListState(
      this.dataList,
      insertionPosition,
    )
  }
}

const InsertionList = <T extends any>({
  dataList,
  newDataListConsumer,
  dataToString,
  positionObserver,
  numberPickerProps = undefined,
  sidecarTextGenerator = undefined,
}: InsertionListProps<T>) => {
  const [insertionListState, setInsertionListState] = useState(new InsertionListState(dataList, 0))

  if (dataList !== insertionListState.dataList) {
    setInsertionListState(state => state.setNewDataList(dataList))
  }

  const updatePosition = (newDataList: (T | string)[]) => {
    const newOutputList = newDataList.filter(columnId => columnId !== insertionPointSpecialValue) as T[]
    const newPosition = newDataList.findIndex(columnId => columnId === insertionPointSpecialValue)
    const newState = new InsertionListState(newOutputList, newPosition)
    if (!isEqual(newOutputList, insertionListState.dataList)) {
      newDataListConsumer(newOutputList)
    }

    if (newPosition !== insertionListState.insertionPosition) {
      positionObserver(newPosition)
    }

    if (!isEqual(newState, insertionListState)) {
      setInsertionListState(newState)
    }
  }

  const removeFromColumnGroupList = (indexToRemove: number) => {
    return () => {
      const newDataList = insertionListState.internalDataList.slice()
      newDataList.splice(indexToRemove, 1)

      updatePosition(newDataList)
    }
  }

  const swapColumnInColumnAndInsertionPointListOnClick = (index: number, destinationIndex: number) => {
    const newDataList = insertionListState.internalDataList.slice()
    if (0 <= index && index < newDataList.length && 0 <= destinationIndex && destinationIndex < newDataList.length) {
      return () => {
        const swap = newDataList[destinationIndex]
        newDataList[destinationIndex] = newDataList[index]
        newDataList[index] = swap

        updatePosition(newDataList)
      }
    }
  }


  const editableColumnsInGroup = insertionListState.internalDataList.map((data, index) => {
    const swapUpFunction = swapColumnInColumnAndInsertionPointListOnClick(index, index - 1)
    const swapDownFunction = swapColumnInColumnAndInsertionPointListOnClick(index, index + 1)
    const placeholderSidecar = !!sidecarTextGenerator ? "" : undefined
    const placeholderNumberPicker = !!numberPickerProps ? true : false
    if (data === insertionPointSpecialValue) {
      return (
        <ColumnListItem
          key='Insertion Point'
          name='Columns inserted here'
          swapUpFunction={swapUpFunction}
          swapDownFunction={swapDownFunction}
          highlight
          sidecarText={placeholderSidecar}
          numberPickerProps={placeholderNumberPicker}
        />
      )
    } else {
      const castedData = data as T
      const name = dataToString(castedData)
      const columnKey = `${name} + ${index}`
      const trueIndex = insertionListState.insertionPosition < index ? index - 1 : index
      const rowNumberPickerProps = !!numberPickerProps ? new NumberPickerProps(
        numberPickerProps.accessor(castedData),
        (input) => numberPickerProps.reducer(castedData, input, trueIndex)
      ) : undefined
      const sidecarText = !!sidecarTextGenerator ? sidecarTextGenerator(insertionListState.dataList, trueIndex) : undefined
      return (
        <ColumnListItem
          key={columnKey}
          name={name}
          removeFunction={removeFromColumnGroupList(index)}
          swapUpFunction={swapUpFunction}
          swapDownFunction={swapDownFunction}
          numberPickerProps={rowNumberPickerProps}
          sidecarText={sidecarText}
        />
      )
    }
  })

  return (
    <Table>
      <TableBody>
        {editableColumnsInGroup}
      </TableBody>
    </Table>
  )

}

export default InsertionList
