import React, {useCallback} from 'react'
import { makeStyles, Theme, createStyles, useTheme, } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer'
import {useSelector, useDispatch} from 'react-redux'
import { AppState } from 'src/store/root-reducer'
import { setDisplayNavigationDrawerActionCreator } from './navigation-drawer-actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Event from '@material-ui/icons/Event'
import {navigateTo, toggleRegion} from 'src/router/router-effects'
import {FORECAST_PATH, LOGIN_PATH, NEWS_PATH, PREFERENCES_PATH, PRIVACY_POLICY_PATH, TRACKER_PATH, CONTACT_US_PATH, PLANNER_PATH} from 'src/router/root-router'
import GridOn from '@material-ui/icons/GridOn'
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle'
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AttachMoney from '@material-ui/icons/AttachMoney'
import {getPageName} from 'src/router/router-selectors'
import { getLoggedInEmail, isLoggedIn } from 'src/login/login-selectors';
import {logoff} from 'src/login/login-effects'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { getActiveRegion } from 'src/router/router-selectors'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Region } from 'src/tracker/model/components/region';

export class NavigationTab {
  constructor(
    route: string,
    label: string,
    icon: React.ReactElement,
  ) {
    this.route = route
    this.label = label
    this.icon = icon
  }
  route: string
  label: string
  icon: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 500,
      maxWidth: '85%',
    },
  })
)

const SPECIAL_LOGOUT = "SPECIAL_LOGOUT"

export default function NavigationDrawer() {
  const classes = useStyles()
  const theme = useTheme()

  const displayNavigationPanel = useSelector((state: AppState) => state.navigationDrawer.display)
  const currentTab = useSelector(getPageName)
  const loggedIn = useSelector(isLoggedIn)
  const email = useSelector(getLoggedInEmail)
  const region = useSelector(getActiveRegion)

  const dispatch = useDispatch()
  const hideNavPanelCallback = useCallback(() => {
    dispatch(setDisplayNavigationDrawerActionCreator(false))
  }, [dispatch])

  const dispatchNavigation = useCallback((path: string) => {
    if (path === SPECIAL_LOGOUT) {
      dispatch(logoff())
    } else {
      dispatch(navigateTo(path))
    }
    dispatch(setDisplayNavigationDrawerActionCreator(false))
  }, [dispatch])
  const dispatchToggleRegion = useCallback(() => {
    dispatch(toggleRegion())
  }, [dispatch])

  const toggleRegionEventHandler = () => {
    dispatchToggleRegion()
  };

  const emailDisplayComponent = !loggedIn ? null :
    <Typography variant="subtitle1">{email}</Typography>

  const regionToggle =
    <FormControlLabel key='label'
      control={
        <Switch
          name={region}
          color="primary"
          checked={region === Region.JP}
          onChange={toggleRegionEventHandler}
        />
      }
      label={region}
    />

  const tabs = [
    new NavigationTab(FORECAST_PATH, "Forecast", <Event/>),
    new NavigationTab(TRACKER_PATH, "Tracker", <GridOn/>),
    new NavigationTab(PLANNER_PATH, "Planner", <AttachMoney />),
  ]

  if (loggedIn) {
    tabs.push(new NavigationTab(PREFERENCES_PATH, "Preferences", <SettingsIcon/>))
    tabs.push(new NavigationTab(LOGIN_PATH, "Account Settings", <AccountCircle/>))
  } else {
    tabs.push(new NavigationTab(LOGIN_PATH, "Login", <AccountCircle/>))
  }
  tabs.push(new NavigationTab(NEWS_PATH, "News", <AnnouncementIcon />))
  if (loggedIn) {
    tabs.push(new NavigationTab(SPECIAL_LOGOUT, "Log out", <MeetingRoomIcon />))
  }
  tabs.push(new NavigationTab(PRIVACY_POLICY_PATH, "Privacy Policy", <VerifiedUserIcon/>))
  tabs.push(new NavigationTab(CONTACT_US_PATH, "Contact Us", <AlternateEmailIcon/>))

  return <Drawer anchor="left" open={displayNavigationPanel} onClose={hideNavPanelCallback} className={classes.drawer} classes={{paper: classes.drawer}}>
      <div style={{ padding: theme.spacing(2) }}>
        <Typography variant='h5'>FFOOtip</Typography>
        {emailDisplayComponent}
        {regionToggle}
      </div>
    <Divider />
    <List>
      {tabs.map(tab => {
        const selected = currentTab === tab.route
        return <ListItem button key={tab.label} onClick={() => dispatchNavigation(tab.route)} selected={selected}>
          <ListItemIcon>{tab.icon}</ListItemIcon>
          <ListItemText primary={tab.label} />
        </ListItem>
      })}
    </List>
  </Drawer>
}
