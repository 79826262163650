import { armorProperty } from 'src/tracker/model/property/equipment/armor/armor-property'
import { Resource } from 'src/tracker/model/tracker-model'
import { createStandardCharacterResourceProperty } from '../resource-meta/character-resource-property'
import { DailyResourceStats } from '../resource-meta/resource-property'

export const armorNuggetProperty = createStandardCharacterResourceProperty(
  '92e1aad5-fb1a-4c41-bdc4-5aaa7456c942',
  'Armor Nuggets',
  'armorNuggets',
  armorProperty,
  Resource.ARMOR_NUGGETS,
  new DailyResourceStats(2, 1),
)
