import {AppState} from 'src/store/root-reducer'
import {getResolvedUser, getUserId, isNotLoggedIn, isSpecifiedId} from 'src/login/login-selectors'
import { BannerAllocation, createDefaultEventAllocation, EventAllocation, EventAllocations, Planner, ResourcesList, PlannerRegionData, PreviousCharacterAllocation, TotalAllocation, TRACKER_SPECIAL_VALUE, createDefaultScheduledIncome, ScheduledIncome, createEmptyResourceList, CurrentResourceAccounting, createDefaultCurrentResourceAccounting, currentResourceAccountingToList, createTokenDailyIncomeScheduleWrapper, calculateResourcesGainedBetweenTokenAmounts, createMogPassScheduleWrapper, mogPassModeAtDay, calculateResourcesBetweenMogPassRenewals, createDefaultMogPassSettings, UNASSIGNED_SPECIAL_VALUE } from './model/planner-model'
import { createDeepEqualsSelector } from 'src/utils/create-deep-equals-selector'
import { getAllCharacterData, getBannersFromEvent, getCharacterIdsFromEvent, getEventById, getEventsById, getEventTime, memoizedGetAllOrderedEvents, memoizedGetFilteredEvents } from 'src/forecast/forecast-selectors'
import { Character, Event, Uuid } from 'src/forecast/model/universal-data-model'
import { Region } from 'src/tracker/model/components/region'
import { getActiveRegion } from 'src/router/router-selectors'
import { getAllCharactersForEvent, getAllCharactersMap, getFluffyMoment, maxGivenCharacter } from 'src/tracker/tracker-selectors'
import { CharacterData, generateDefaultCharacterData, TrackerCharacterData } from 'src/tracker/model/tracker-model'
import { defaultMemoize } from 'reselect'
import { objectAssigningReducer } from 'src/forecast/model/universal-data-converter'
import { cloneDeep, merge, set } from 'lodash'
import { resourcesGroup } from './model/property/resource/resources-group'
import { gemProperty } from './model/property/resource/gem-property'
import { getDisplayAsRefined, getImportPlannerBreadcrumbDismissed, getPlannerCustomColumnGroups, getPlannerDefaultColumnGroup, getPreviewedPlannerData, isPreviewedPlannerData } from 'src/preferences/preferences-selectors'
import { DateTime } from 'luxon'
import { convertToPlannerColumnGroup } from 'src/preferences/model/preferences-model-converters'
import { augmentDisplayableColumnGroups } from './model/column/group-of-groups'
import { allColumnGroup } from './model/column/all-column-group'
import { nameColumn } from './model/column/name-column'
import { CharacterResourceProperty } from './model/property/resource-meta/character-resource-property'

export const TOKENS_PER_DAY = 2
export const DAILY_GEMS_PER_DAY = 100
export const PLANNER_COLLECTION_NAME = "planner"

export function getPlannerDocumentLocation(userId: string | undefined) {
  if (!userId) {
    return undefined
  }
  return `${PLANNER_COLLECTION_NAME}/${userId}`
}

export function getPlannerDocument(state: AppState, useResolvedUser: boolean = true, usePreviewedData: boolean = true) {
  const previewedPlannerData = getPreviewedPlannerData(state)
  if (!!usePreviewedData && previewedPlannerData !== null) {
    return previewedPlannerData
  }
  const resolvedUser = useResolvedUser ? getResolvedUser(state) : getUserId(state)
  if (!resolvedUser) {
    return undefined
  }
  return state.firestore.data?.[PLANNER_COLLECTION_NAME]?.[resolvedUser] as Planner | undefined
}

function getPlannerRegionData(state: AppState) {
  const region = getActiveRegion(state)
  return (getPlannerDocument(state)?.[region] || {}) as PlannerRegionData
}

export function getCurrentResources(state: AppState): CurrentResourceAccounting {
  return merge(createDefaultCurrentResourceAccounting(), getPlannerRegionData(state).currentResourceAccounting)
}

export function getEventAllocations(state: AppState) {
  const region = getActiveRegion(state)
  const universalEvents = getEventsById(state)
  return Object.entries(getPlannerRegionData(state).eventAllocations || {})
  .map(([eventId, eventAllocation]) => {
    const universalEvent = universalEvents[eventId]
    if (universalEvent === undefined) {
      return {}
    }
    const bannerIds = new Set(getBannersFromEvent(universalEvent, region).map(banner => banner.id))
    const bannerAllocations = Object.entries(eventAllocation.bannerAllocations)
      .filter(([bannerId, _]) => bannerIds.has(bannerId))
      .map(([bannerId, bannerAllocation]) => {
        return {
          [bannerId]: bannerAllocation
        }
      }).reduce(objectAssigningReducer, {})
    return {
      [eventId]: Object.assign({}, createDefaultEventAllocation(), new EventAllocation(bannerAllocations,
        eventAllocation.characterAllocations, eventAllocation.miscellaneousAllocations, eventAllocation.markedComplete))
    }
  }).reduce(objectAssigningReducer, {}) as EventAllocations
}

export function createPlannerCurrentResourcesOperation(data: ResourcesList, region: Region) {
  return (planner: Planner) => {
    set(planner, `${region}.currentResourceAccounting.currentHeld`, data)
    return planner
  }
}

export function createPlannerEventAllocationOperation(data: EventAllocation, region: Region, eventId: Uuid) {
  return (planner: Planner) => {
    const writableNewEventAllocation = JSON.parse(JSON.stringify(data))
    set(planner, `${region}.eventAllocations.${eventId}`, writableNewEventAllocation)
    return planner
  }
}

export function createScheduledIncomeOperation(data: ScheduledIncome, region: Region, now: DateTime = DateTime.utc()) {
  return (planner: Planner) => {
    const writableScheduledIncome = JSON.parse(JSON.stringify(data)) as ScheduledIncome
    writableScheduledIncome.tokenIncome.lastWrite = now.valueOf()
    writableScheduledIncome.mogPassSettings.lastWrite = now.valueOf()
    planner[region].scheduledIncome = writableScheduledIncome
    return planner
  }
}

export function createDocumentMergableBannerAllocation(state: AppState, data: BannerAllocation, eventId: Uuid, bannerId: Uuid) {
  const region = getActiveRegion(state)
  const event = getEventById(eventId)(state)
  const previousEventAllocation = getEventAllocations(state)[eventId] || createDefaultEventAllocation(event)
  const newBannerAllocations = Object.assign({}, previousEventAllocation.bannerAllocations, {[bannerId]: data})
  const newEventAllocation = new EventAllocation(newBannerAllocations, previousEventAllocation.characterAllocations, previousEventAllocation.miscellaneousAllocations)
  // TODO unhack this hack to get the data writable
  const writableNewEventAllocation = JSON.parse(JSON.stringify(newEventAllocation))
  return {[region]: {"eventAllocations": { [eventId]: writableNewEventAllocation }}} as Partial<Planner>
}

export function createCharacterAllocationPlannerData(state: AppState, data: CharacterData | undefined, eventId: Uuid, characterId: Uuid) {
  const region = getActiveRegion(state)
  const event = getEventById(eventId)(state)
  const previousEventAllocation = getEventAllocations(state)[eventId] || createDefaultEventAllocation(event)
  const newCharacterAllocations = Object.assign({}, previousEventAllocation.characterAllocations, {[characterId]: data})
  if (!data) { // delete case
    delete newCharacterAllocations[characterId]
  }
  const newEventAllocation = new EventAllocation(previousEventAllocation.bannerAllocations, newCharacterAllocations, previousEventAllocation.miscellaneousAllocations)
  // TODO unhack this hack to get the data writable
  const writableNewEventAllocation = JSON.parse(JSON.stringify(newEventAllocation))
  const plannerData = cloneDeep(getPlannerDocument(state, false)) || {} as Planner
  set(plannerData, `${region}.eventAllocations.${eventId}`, writableNewEventAllocation)
  return plannerData
}

export function createDocumentMergableMiscellaneousAllocations(state: AppState, data: ResourcesList, eventId: Uuid) {
  const region = getActiveRegion(state)
  const event = getEventById(eventId)(state)
  const previousEventAllocation = getEventAllocations(state)[eventId] || createDefaultEventAllocation(event)
  const newMiscellaneousAllocations = Object.assign({}, previousEventAllocation.miscellaneousAllocations, data)
  const newEventAllocation = new EventAllocation(previousEventAllocation.bannerAllocations, previousEventAllocation.characterAllocations, newMiscellaneousAllocations)
  // TODO unhack this hack to get the data writable
  const writableNewEventAllocation = JSON.parse(JSON.stringify(newEventAllocation))
  return {[region]: {"eventAllocations": { [eventId]: writableNewEventAllocation }}} as Partial<Planner>
}

export function createDocumentMergableCurrentResourceAccounting(state: AppState, data: CurrentResourceAccounting) {
  const region = getActiveRegion(state)
  const previousResourceAccounting = getCurrentResources(state)
  const newResourceAccounting = Object.assign({}, previousResourceAccounting, data)
  // TODO unhack this hack to get the data writable
  const writableNewResourceAccounting = JSON.parse(JSON.stringify(newResourceAccounting))
  return {[region]: {"currentResourceAccounting": writableNewResourceAccounting }} as Partial<Planner>
}

export function createDocumentMergableSharedData(shared: boolean) {
  return {shared: shared} as Partial<Planner>
}

export function getSharedDataPlanner(state: AppState) {
  return getPlannerDocument(state, false)?.shared || false
}

export class EventCharacterDataPair {
  constructor(
    event: Event | string,
    characterData: CharacterData,
  ) {
    this.event = event
    this.characterData = characterData
  }
  event: Event | string
  characterData: CharacterData
}

export class TotalAllocationsReturn {
  constructor(
    totalAllocationsByEventId: Record<Uuid, TotalAllocation>,
    characterAllocationsByCharacterIdByEventId: Record<Uuid, EventCharacterDataPair[]>,
    initialInvestedResources: ResourcesList,
  ) {
    this.totalAllocationsByEventId = totalAllocationsByEventId
    this.characterAllocationsByCharacterIdByEventId = characterAllocationsByCharacterIdByEventId
    this.initialInvestedResources = initialInvestedResources
  }
  totalAllocationsByEventId: Record<Uuid, TotalAllocation>
  // list of pairs should be sorted by increasing date time
  characterAllocationsByCharacterIdByEventId: Record<Uuid, EventCharacterDataPair[]>
  initialInvestedResources: ResourcesList
}

export class CalculateResourcesAfterEventSettings {
  constructor (
    bannersToAdd: Uuid[],
    charactersToAdd: Uuid[],
    addMiscellaneous: boolean,
    addIncome: boolean,
  ) {
    this.bannersToAdd = bannersToAdd
    this.charactersToAdd = charactersToAdd
    this.addMiscellaneous = addMiscellaneous
    this.addIncome = addIncome
  }
  bannersToAdd: Uuid[]
  charactersToAdd: Uuid[]
  addMiscellaneous: boolean
  addIncome: boolean
}

export function calculateResourcesAfterEvent(totalAllocation: TotalAllocation, reverseOperation: boolean) {
  const {currentResources} = totalAllocation
  var newResources = Object.assign({}, currentResources)
  resourcesGroup.forEach(resourceProperty => {
    const newResourceAmount = resourceProperty.resourcesAfterEventAccessor(totalAllocation, reverseOperation)
    newResources = resourceProperty.resourcesListReducer(newResources, newResourceAmount)
  })
  return newResources
}

class ResourcesInvestedInReturn {
  constructor(
    resourcesInCharacters: ResourcesList,
    maxedCharacterResources: ResourcesList,
  ) {
    this.resourcesInCharacters = resourcesInCharacters
    this.maxedCharacterResources = maxedCharacterResources
  }
  resourcesInCharacters: ResourcesList
  maxedCharacterResources: ResourcesList
}

const getResourcesInvestedInCharacters = (characterIdsToCheck: Uuid[], currentInvestmentMap: Record<Uuid, PreviousCharacterAllocation>, previousInvestmentMap: Record<Uuid, PreviousCharacterAllocation>,
  previousReturn: ResourcesInvestedInReturn,
  universalCharacterData: Record<Uuid, Character>, region: Region, when: DateTime, initialMode: boolean = false, eventId: Uuid = "initial") => {
  var resourcesInCharacters = createEmptyResourceList()
  var maxedCharacterResources = createEmptyResourceList()
  characterIdsToCheck.forEach(characterId => {
    const character = universalCharacterData[characterId]
    const currentCharacterInvestment = currentInvestmentMap[characterId]
    const previousCharacterInvestment = previousInvestmentMap[characterId]
    const resolvedCurrentCharacter = currentCharacterInvestment?.characterData || generateDefaultCharacterData()
    const resolvedPreviousCharacter = previousCharacterInvestment?.characterData || generateDefaultCharacterData()
    const previousMaxCharacter = initialMode ? generateDefaultCharacterData() : maxGivenCharacter(character, region, when.minus({minute: 1}))
    const newMaxCharacter = maxGivenCharacter(character, region, getFluffyMoment(when))
    resourcesGroup.forEach(resourceProperty => {
      if (resourceProperty instanceof CharacterResourceProperty) {
        if (currentCharacterInvestment?.eventIdentifier !== previousCharacterInvestment?.eventIdentifier) {
          const previousValue = resourceProperty.resourcesListAccessor(resourcesInCharacters)
          const oldValue = resourceProperty.getResourceFromCharacterData(resolvedPreviousCharacter)
          const newValue = resourceProperty.getResourceFromCharacterData(resolvedCurrentCharacter)
          resourcesInCharacters = resourceProperty.resourcesListReducer(resourcesInCharacters, previousValue + newValue - oldValue)
        }

        const previousValueMax = resourceProperty.resourcesListAccessor(maxedCharacterResources)
        const oldMaxValue = resourceProperty.getResourceFromCharacterData(previousMaxCharacter)
        const newMaxValue = resourceProperty.getResourceFromCharacterData(newMaxCharacter)
        maxedCharacterResources = resourceProperty.resourcesListReducer(maxedCharacterResources, previousValueMax + newMaxValue - oldMaxValue)
      }
    })
  })
  Object.keys(resourcesInCharacters).forEach(key => {
    resourcesInCharacters[key] = resourcesInCharacters[key] + previousReturn.resourcesInCharacters[key]
    maxedCharacterResources[key] = maxedCharacterResources[key] + previousReturn.maxedCharacterResources[key]
  })
  return new ResourcesInvestedInReturn(resourcesInCharacters, maxedCharacterResources)
}

const combineResourcesInvestedInWithResourcesAfterEvent = (resourcesInvestedIn: ResourcesInvestedInReturn, resourcesAfterEvent: ResourcesList) => {
  const output = createEmptyResourceList()
  Object.keys(output).forEach(key => {
    output[key] = resourcesInvestedIn.maxedCharacterResources[key] - resourcesInvestedIn.resourcesInCharacters[key] - resourcesAfterEvent[key]
  })
  return output
}

export function createGetTotalAllocations() {
  return createDeepEqualsSelector(
    (state: AppState) => memoizedGetAllOrderedEvents()(state),
    (state: AppState) => getCurrentResources(state),
    (state: AppState) => getEventAllocations(state),
    (state: AppState) => getAllCharactersMap(state),
    (state: AppState) => getScheduledIncome(state),
    (state: AppState) => getActiveRegion(state),
    (state: AppState) => getAllCharacterData(state),
    (state: AppState) => getDisplayAsRefined(state),
    (events: Event[], currentResourceAccounting: CurrentResourceAccounting, eventAllocations: EventAllocations, trackerCharacterData: TrackerCharacterData,
      scheduledIncome: ScheduledIncome, region: Region, universalCharacterData: Record<Uuid, Character>, displayAsRefined: boolean) => {
      const now = DateTime.utc()
      var currentResources = currentResourceAccountingToList(currentResourceAccounting)

      const characterAllocationsByCharacterIdByEventId = {} as Record<Uuid, EventCharacterDataPair[]>

      const previousCharacterResourceMap = Object.entries(trackerCharacterData).map(([characterId, characterData]) => {
        characterAllocationsByCharacterIdByEventId[characterId] = [new EventCharacterDataPair(TRACKER_SPECIAL_VALUE, characterData)]
        return {
          [characterId]: new PreviousCharacterAllocation(characterData, TRACKER_SPECIAL_VALUE)
        }
      }).reduce(objectAssigningReducer, {})

      var resourcesInvestedInReturn = getResourcesInvestedInCharacters(Object.keys(universalCharacterData), previousCharacterResourceMap, {},
        new ResourcesInvestedInReturn(createEmptyResourceList(), createEmptyResourceList()), universalCharacterData, region, DateTime.utc(), true)
      const initialInvestedResources = combineResourcesInvestedInWithResourcesAfterEvent(resourcesInvestedInReturn, currentResources)

      const mogPassSettings = getMogPassSettings(scheduledIncome)
      var currentTokensInDailySchedule = (getDaysSinceWrite(scheduledIncome, now) * TOKENS_PER_DAY) + scheduledIncome.tokenIncome.tokensInSchedule
      var currentDaysFromWrittenMogPassPosition = getDaysSinceLastWriteMogPass(scheduledIncome, now) + mogPassSettings.tokensInSchedule
      var previousDate = DateTime.utc()
      const scheduleData = createTokenDailyIncomeScheduleWrapper(scheduledIncome.tokenIncome.schedule)
      const mogPassScheduleData = createMogPassScheduleWrapper(mogPassSettings.schedule)
      const totalAllocationsByEventId = events.map(event => {
        var newResources = Object.assign({}, currentResources)
        const eventAllocation = eventAllocations[event.id] || createDefaultEventAllocation(event)

        const characterResourceFromPrevious = getAllCharactersForEvent(event, region).map(character => {
          return {
            [character.id]: previousCharacterResourceMap[character.id]
          }
        }).reduce(objectAssigningReducer, {})
        Object.keys(universalCharacterData).forEach(characterId => {
          if (!characterResourceFromPrevious[characterId]) {
            characterResourceFromPrevious[characterId] = new PreviousCharacterAllocation(generateDefaultCharacterData(), UNASSIGNED_SPECIAL_VALUE)
          }
        })


        var dailyResources = createEmptyResourceList()
        var mogPassMode = mogPassModeAtDay(mogPassScheduleData, currentDaysFromWrittenMogPassPosition)
        const characterIds = getCharacterIdsFromEvent(event, region)
        const previousInvestments: Record<Uuid, PreviousCharacterAllocation> = {}
        characterIds.forEach(characterId => {
          previousInvestments[characterId] = previousCharacterResourceMap[characterId]
        })
        // do not update resources, or the previous character resource map, or calculate daily resources, if the event has been marked complete
        if (!eventAllocation.markedComplete) {

          const eventStart = getEventTime(region, event).start
          if (eventStart > previousDate) {
            // add selected daily resources
            const dailyResetsSinceLastDate = resetsBetweenTimes(previousDate, eventStart)
            const tokenPositionAfterEvent = currentTokensInDailySchedule + dailyResetsSinceLastDate * TOKENS_PER_DAY
            dailyResources = calculateResourcesGainedBetweenTokenAmounts(scheduleData, currentTokensInDailySchedule, tokenPositionAfterEvent)

            // add daily gems
            const dailyGemsSinceLastEvent = DAILY_GEMS_PER_DAY * dailyResetsSinceLastDate
            dailyResources = gemProperty.resourcesListReducer(dailyResources, dailyGemsSinceLastEvent)

            // calculate mog pass mode
            const daysAtEvent = currentDaysFromWrittenMogPassPosition + dailyResetsSinceLastDate
            mogPassMode = mogPassModeAtDay(mogPassScheduleData, daysAtEvent)

            // calculate BT tokens from mog pass
            dailyResources = calculateResourcesBetweenMogPassRenewals(mogPassScheduleData, currentDaysFromWrittenMogPassPosition, daysAtEvent)

            previousDate = eventStart
            currentTokensInDailySchedule = tokenPositionAfterEvent
            currentDaysFromWrittenMogPassPosition = daysAtEvent

           }

          newResources = calculateResourcesAfterEvent({
            event, currentResources, eventAllocation,
            characterIdToPreviousCharacterAllocation: characterResourceFromPrevious,
            dailyResources, mogPassMode,
            resourcesNeededInCharacters: createEmptyResourceList(), // shouldn't need this for calculating resources, so just setting to empty for now
            displayRefinedMaterials: displayAsRefined,
            },
            false)
          currentResources = newResources

          // update previous character resource map
          Object.entries(eventAllocation.characterAllocations).forEach(([characterId, characterAllocation]) => {
            previousCharacterResourceMap[characterId] = new PreviousCharacterAllocation(characterAllocation, event.id)
          })

          resourcesInvestedInReturn = getResourcesInvestedInCharacters(characterIds, previousCharacterResourceMap, previousInvestments,
            resourcesInvestedInReturn, universalCharacterData, region, getEventTime(region, event).start, false, event.id)
        }


        return {[event.id]: new TotalAllocation(event, newResources, eventAllocation, characterResourceFromPrevious, dailyResources, mogPassMode,
          combineResourcesInvestedInWithResourcesAfterEvent(resourcesInvestedInReturn, newResources), displayAsRefined)}
      }).reduce(objectAssigningReducer, {})

      events.forEach(event => {
        Object.entries(eventAllocations[event.id]?.characterAllocations || []).forEach(([characterId, characterData]) => {
          const pairs = characterAllocationsByCharacterIdByEventId[characterId] || []
          pairs.push(new EventCharacterDataPair(event, characterData))
          characterAllocationsByCharacterIdByEventId[characterId] = pairs
        })
      })
      return new TotalAllocationsReturn(totalAllocationsByEventId, characterAllocationsByCharacterIdByEventId, initialInvestedResources)
    }
  )
}

const memoizedGetTotalAllocations = defaultMemoize(createGetTotalAllocations)

export class GetFilteredTotalAllocationsResult {
  constructor(
    totalAllocations: TotalAllocation[],
    initialInvestedResources: ResourcesList,
  ) {
    this.totalAllocations = totalAllocations
    this.initialInvestedResources = initialInvestedResources
  }
  totalAllocations: TotalAllocation[]
  initialInvestedResources: ResourcesList
}

export function createGetFilteredTotalAllocations() {
  return createDeepEqualsSelector(
    (state: AppState) => memoizedGetTotalAllocations()(state),
    (state: AppState) => memoizedGetFilteredEvents()(state, true),
    (totalAllocations: TotalAllocationsReturn, filteredEvents: Event[]) => {
      return new GetFilteredTotalAllocationsResult(filteredEvents.map(event => {
        return totalAllocations.totalAllocationsByEventId[event.id]
      }).filter(it => !!it)
      .filter(totalAllocation => !totalAllocation.eventAllocation.markedComplete), totalAllocations.initialInvestedResources)
    }
  )
}

export function getEventForCharacterAllocationCreation(state: AppState) {
  return state.planner.eventToCreateCharacterAllocationFor
}

export function getCharacterAllocationDialogContext(state: AppState) {
  return state.planner.characterAllocationDialogContext
}

export function getDisplayEventsCompleted(state: AppState) {
  return state.planner.displayEventsCompleted
}

export function getScheduledIncome(state: AppState) {
  return getPlannerRegionData(state).scheduledIncome || createDefaultScheduledIncome()
}

export function getDisplayScheduledIncome(state: AppState) {
  return state.planner.displayScheduledIncome
}

export function getDisplayCurrentResourcesAccounting(state: AppState) {
  return state.planner.displayCurrentResourcesAccounting
}

function getDaysSinceWrite(scheduledIncome: ScheduledIncome, now: DateTime = DateTime.utc()) {
  const lastWrite = scheduledIncome.tokenIncome.lastWrite
  const lastWriteDate = DateTime.fromMillis(lastWrite)
  return resetsBetweenTimes(lastWriteDate, now)
}

function getDaysSinceLastWriteMogPass(scheduledIncome: ScheduledIncome, now: DateTime = DateTime.utc()) {
  const mogPassSettings = getMogPassSettings(scheduledIncome)
  const lastWrite = mogPassSettings.lastWrite
  const lastWriteDate = DateTime.fromMillis(lastWrite)
  return resetsBetweenTimes(lastWriteDate, now)
}

function getMogPassSettings(scheduledIncome: ScheduledIncome) {
  return scheduledIncome.mogPassSettings || createDefaultMogPassSettings()
}

function resetsBetweenTimes(earlierMoment: DateTime, laterMoment: DateTime) {
  const resetOnEarlierMoment = earlierMoment.set({hour: 2}).startOf('hour')
  return Math.floor(laterMoment.diff(resetOnEarlierMoment).as('days'))
}

export const shouldShowImportPlannerBreadcrumb = (state: AppState) => {
  const isPreviewedData = isPreviewedPlannerData(state)
  const isNotLogged = isNotLoggedIn(state)
  const isSpecified = isSpecifiedId(state)
  const isBreadcrumbDismissed = getImportPlannerBreadcrumbDismissed(state)
  return !(isPreviewedData || isNotLogged || isSpecified || isBreadcrumbDismissed)
}

export function getColumnGroups(state: AppState) {
  const customColumnGroups = getPlannerCustomColumnGroups(state)
  const customColumnGroupsConverted = customColumnGroups.map(convertToPlannerColumnGroup)
  return augmentDisplayableColumnGroups(...customColumnGroupsConverted)
}

export function getSelectedColumnGroup(state: AppState) {
  const preferenceDefault = getPlannerDefaultColumnGroup(state)
  const allColumnGroups = getColumnGroups(state)
  return allColumnGroups.find(columnGroup => columnGroup.id === preferenceDefault) || allColumnGroup
}

export function getSelectedColumnGroupPresentableColumns(state: AppState) {
  const columns = getSelectedColumnGroup(state).columns
  return [nameColumn, ...columns]
}
