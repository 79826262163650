import React from 'react';
import CharacterDetailsPanel from './character-details/character-details-panel';
import CreditsModal from 'src/irl/credits-modal'
import TopBar from 'src/topbar/topbar-component'
import {RootRouter} from 'src/router/root-router'
import AlertComponent from 'src/alert/alert-component'
import AlertBar from 'src/topbar/alert-bar/alert-bar'
import EventDetailsPanel from './event-details/event-details-panel';
import NavigationDrawer from './navigation-drawer/navigation-drawer-component';
import useEssentialData from './utils/use-essential-data/use-essential-data';

function App() {
  useEssentialData()

  return (<div style={{display: 'flex', flexDirection:'column', height: '100%'}}>
    <div style={{flexGrow: 0}}>
      <TopBar />
    </div>
    <AlertBar />
    {/*cool flexbox trick minHeight is 0 otherwise it doesn't actually shrink*/}
    <div style={{flexGrow: 1, minHeight: '0', display:'flex'}}>
      <RootRouter />
    </div>
    <EventDetailsPanel />
    <CharacterDetailsPanel />
    <CreditsModal />
    <AlertComponent />
    <NavigationDrawer />
  </div>
  );
}

export default App;
