import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const fifteenCpProperty = new WeaponDataProperty(
  "cc1accdb-a59b-47c4-91a7-5dd0e5f7f011",
  "15 CP",
  "15 CP",
  standardLimitBreakConverter,
  "15 CP",
  WeaponType.FIFTEEN_CP,
  () => getValues(StandardLimitBreak),
)
