import {WeaponDataProperty} from 'src/tracker/model/property/weapon-data-property'
import {standardLimitBreakConverter} from 'src/tracker/model/tracker-model-converter'
import {StandardLimitBreak} from 'src/tracker/model/tracker-model'
import {getValues} from 'src/utils/enum-values'
import { WeaponType } from 'src/forecast/model/weapon-type'

export const manikinProperty = new WeaponDataProperty(
  "56c18cca-a342-42ef-840d-f1b326930614",
  "Manikin",
  "Manikin",
  standardLimitBreakConverter,
  "Manikin",
  WeaponType.MANIKIN,
  (universalCharacterData) => {
    const weaponBreakValues = getValues(StandardLimitBreak) as StandardLimitBreak[]
    if (!!universalCharacterData.universal.availableWeapons.find(weaponType => WeaponType.MANIKIN === weaponType)) {
      return weaponBreakValues
    } else {
      return weaponBreakValues.slice(0, 1)
    }
  },

)
