import { Uuid } from "src/forecast/model/universal-data-model"
import { TotalAllocation } from "./model/planner-model"

export const WRITE_PLANNER_DATA_FAILURE = "WRITE_PLANNER_DATA_FAILURE"
export const CREATE_CHARACTER_ALLOCATION_FOR_EVENT = "CREATE_CHARACTER_ALLOCATION_FOR_EVENT"
export const SET_CHARACTER_ALLOCATION_DIALOG_CONTEXT = "SET_CHARACTER_ALLOCATION_DIALOG_CONTEXT"
export const DISPLAY_EVENTS_COMPLETED = "DISPLAY_EVENTS_COMPLETED"
export const DISPLAY_SCHEDULED_INCOME = "DISPLAY_SCHEDULED_INCOME"
export const DISPLAY_CURRENT_RESOURCE_ACCOUNTING = "DISPLAY_CURRENT_RESOURCE_ACCOUNTING"

export function writePlannerDataFailureActionCreator(reason: string) {
  return {
    type: WRITE_PLANNER_DATA_FAILURE,
    reason: reason,
  }
}

export function createCharacterAllocationForEventActionCreator(totalAllocation: TotalAllocation | undefined) {
  return {
    type: CREATE_CHARACTER_ALLOCATION_FOR_EVENT,
    totalAllocation,
  }
}

export class CharacterAllocationDialogContext {
  constructor(
    totalAllocation: TotalAllocation,
    characterId: Uuid,
  ) {
    this.totalAllocation = totalAllocation
    this.characterId = characterId
  }
  totalAllocation: TotalAllocation
  characterId: Uuid
}

export function setCharacterAllocationDialogContextActionCreator(context: CharacterAllocationDialogContext | undefined) {
  return {
    type: SET_CHARACTER_ALLOCATION_DIALOG_CONTEXT,
    context
  }
}

export function displayEventsCompletedActionCreator(display: boolean) {
  return {
    type: DISPLAY_EVENTS_COMPLETED,
    display
  }
}

export function displayScheduledIncomeActionCreator(display: boolean) {
  return {
    type: DISPLAY_SCHEDULED_INCOME,
    display
  }
}

export function displayCurrentResourceAccountingActionCreator(display: boolean) {
  return {
    type: DISPLAY_CURRENT_RESOURCE_ACCOUNTING,
    display
  }
}

export type WritePlannerDataFailureAction = ReturnType<typeof writePlannerDataFailureActionCreator>
export type CreateCharacterAllocationForEventAction = ReturnType<typeof createCharacterAllocationForEventActionCreator>
export type SetCharacterAllocationDialogContextAction = ReturnType<typeof setCharacterAllocationDialogContextActionCreator>
export type DisplayEventsCompletedAction = ReturnType<typeof displayEventsCompletedActionCreator>
export type DisplayScheduledIncomeAction = ReturnType<typeof displayScheduledIncomeActionCreator>
export type DisplayCurrentResourceAccountingAction = ReturnType<typeof displayCurrentResourceAccountingActionCreator>
