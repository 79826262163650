import {Resource} from 'src/tracker/model/tracker-model'
import { crystalLevelProperty } from "../levels/crystal-level-property";
import { characterBoardProperty } from "../levels/character-board-property";
import { weaponGroup } from "../equipment/weapon/weapon-group";
import { PropertyGroup } from "../property-group";
import { armorGroup } from "../equipment/armor/armor-group";
import { EnumerableCharacterProperty } from '../enumerable-character-property';
import { defaultCharacterDataNumberConverter } from '../../tracker-model-converter';
import { ldBoardProperty } from '../levels/ld-board-property';
import { AllCharacterData } from '../../components/all-character-data';
import { Region } from '../../components/region';
import { DateTime } from 'luxon'
import { forceEnhancementProperty } from '../levels/force-enhancement-property';
import { frBoardProperty } from '../levels/fr-board-property';

function getCpFromProperty<T extends number>(character: AllCharacterData | undefined, property: EnumerableCharacterProperty<T>, when: DateTime, useMaxValue: boolean, region: Region) {
  const valueToUse = useMaxValue && !!character ? property.getMax(character, when, region) : property.get(region, character)
  return property.valueToResource(valueToUse, Resource.CP)
}

function getGroupCp(character: AllCharacterData | undefined, propertyGroup: PropertyGroup, when: DateTime, useMaxValue: boolean, region: Region) {
  const maximizedCp = propertyGroup.group.map(property => {
    const castedProperty = property as EnumerableCharacterProperty<any>
    return getCpFromProperty(character, castedProperty, when, useMaxValue, region)
  })
  .reduce((a, b) => Math.max(a, b))
  return Math.max(1, maximizedCp)
}

function calculateCp(region: Region, character?: AllCharacterData, when: DateTime = DateTime.utc(), useMaxValue: boolean = false) {
  const baseCp = 40
  const cpFromCLevel = getCpFromProperty(character, crystalLevelProperty, when, useMaxValue, region)
  const cpFromCBoards = getCpFromProperty(character, characterBoardProperty, when, useMaxValue, region)
  const cpFromLdBoards = getCpFromProperty(character, ldBoardProperty, when, useMaxValue, region)
  const cpFromFrBoards = getCpFromProperty(character, frBoardProperty, when, useMaxValue, region)
  const cpFromFe = getCpFromProperty(character, forceEnhancementProperty, when, useMaxValue, region)
  const cpFromWeapons = getGroupCp(character, weaponGroup, when, useMaxValue, region)
  const cpFromArmor = getGroupCp(character, armorGroup, when, useMaxValue, region)
  return baseCp + cpFromCLevel + cpFromCBoards + cpFromLdBoards + cpFromWeapons + cpFromArmor + cpFromFe + cpFromFrBoards
}

export const cpProperty = new EnumerableCharacterProperty<number>(
  "1cb12bdd-2c72-4da9-b2e8-8e95a8ed1162",
  "CP",
  "CP",
  defaultCharacterDataNumberConverter,
  calculateCp,
  (character, when, region) => {
    const maxCp = calculateCp(region, character, when, true)
    return Array.from(new Array(maxCp + 1), (x,i) => i)
  }
)
