import React, { FunctionComponent, useCallback, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { shouldGetAsset } from 'src/image-cache/image-effects'
import Skeleton from '@material-ui/lab/Skeleton';
import { CrystalColor } from 'src/forecast/model/universal-data-model';
import { selectImage } from 'src/image-cache/image-selectors';

interface CrystalColorProps {
  crystalColor: CrystalColor
}

function getColorIdentifier(crystalColor: CrystalColor) {
  return `${CrystalColor[crystalColor]}-COLOR`
}

const colorMapToInternalImage = (color: CrystalColor) => {
    switch (color) {
      case CrystalColor.RED:
        return 1
      case CrystalColor.BLUE:
        return 4
      case CrystalColor.GREEN:
        return 7
      case CrystalColor.YELLOW:
        return 10
      case CrystalColor.BLACK:
        return 13
      case CrystalColor.WHITE:
        return 16
    }
  }

const CrystalColorComponent: FunctionComponent<CrystalColorProps> = ({
  crystalColor
}) => {
  const crystalImage = useSelector(selectImage(getColorIdentifier(crystalColor)))

  const dispatch = useDispatch()
  const fetchImageCallback = useCallback((assetLocation: string, assetIdentifier: string) => {
    dispatch(shouldGetAsset(assetLocation, assetIdentifier))
  }, [dispatch])

  useEffect(() => {
    if (!crystalImage) {
      fetchImageCallback(`stl_item_cry_${colorMapToInternalImage(crystalColor)}.png`, getColorIdentifier(crystalColor))
    }
  })

  const loaded = () => {
    return !!crystalImage
  }

  const imgScalingStyling: React.CSSProperties = {
    maxWidth: "100%",
    height: "auto",
    width: "32px"
  }

  const imgCommonStyling: React.CSSProperties = Object.assign({}, imgScalingStyling, {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })

  const skeleton = <Skeleton variant='rect' style={Object.assign({}, imgCommonStyling)} />
  const crystalImageElement = <img src={crystalImage || undefined} alt={CrystalColor[crystalColor] + " crystal"} style={Object.assign({ 'zIndex': 1 }, imgCommonStyling)} />
  return (
    <div style={Object.assign({}, { position: "relative", margin: "4px" }, imgScalingStyling)} >
      {!loaded() && skeleton}
      {loaded() && crystalImageElement}
      <div style={{ display: 'block', paddingTop: '100%', float: 'left' }} />
    </div>
  )
}

export default CrystalColorComponent
