import Typography from '@material-ui/core/Typography'
import React, { FunctionComponent } from "react"

interface ResourceValueDisplayProps {
  value: number
  refinedResourceMode?: boolean
}

const ResourceValueDisplay: FunctionComponent<ResourceValueDisplayProps> = ({
  value,
  refinedResourceMode = false
}) => {
  const displayRed = value < 0
  const displayValue = refinedResourceMode ? value / 20 : value
  return <Typography color={displayRed ? 'secondary' : undefined}>{displayValue}</Typography>
}

export default ResourceValueDisplay

