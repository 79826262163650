import { AppState } from 'src/store/root-reducer';
import { getActivePanelCharacterName } from 'src/router/router-selectors';
import { getCharacterIdsByName } from 'src/forecast/forecast-selectors';

export const getCharacterFromName = (state: AppState, name: string) => {
  const characterId = getCharacterIdsByName(state)[name] || ""
  return state.forecast.universalData.characters[characterId]
}

export const getActivePanelCharacter = (state: AppState) => {
  const characterName = getActivePanelCharacterName(state) || ""
  return getCharacterFromName(state, characterName)
}
