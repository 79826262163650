import { armorNuggetProperty } from "../../property/resource/armor-nugget-property";
import { ResourceColumn } from "../planner-column";

export const armorNuggetsColumn = new ResourceColumn(
  'Armor Nuggets',
  'stl_limit_break_item_6_cropped.png',
  'armorNuggets',
  armorNuggetProperty,
  undefined,
  'stl_limit_break_item_10_cropped.png',
  'armorIngots',
)
