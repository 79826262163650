import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getDisplayDetailedEstimates} from 'src/preferences/preferences-selectors'
import {writeDisplayDetailedEstimates} from 'src/preferences/preferences-effects'
import {PreferencesWidget} from 'src/preferences/widgets/preferences-widget'
import generateToggle from '../generate-toggle'

function DisplayDetailedEstimatesComponent() {
  const displayDetailedEstimates = useSelector(getDisplayDetailedEstimates)

  const dispatch = useDispatch()

  const displayDetailedEstimatesCallback = useCallback((displayDetailedEstimatesCur: boolean) => {
    dispatch(writeDisplayDetailedEstimates(displayDetailedEstimatesCur))
  }, [dispatch])
  const handleDisplayDetailedEstimatesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    displayDetailedEstimatesCallback(event.target.checked)
  }

  return (
    <>
      {generateToggle("Display detailed estimates. In the off state (the default), estimates for events that do not have announced dates are displayed by the half month (i.e., early december, late february). When toggled on, more fine grained dates are provided. It is important to note these are still estimates which can and will change and be wrong.",
        displayDetailedEstimates, handleDisplayDetailedEstimatesChanged)}
    </>
  )
}

export const DisplayDetailedEstimatesWidget = new PreferencesWidget(
  <DisplayDetailedEstimatesComponent />,
  "Display detailed estimates",
)
