import { Uuid } from "src/forecast/model/universal-data-model";
import { createDefaultTierUtilization } from "./components/tier-utilization";
import { createDefaultTranscendenceTierUtilization, getGateCharacterIds, TranscendenceTierUtilization } from "./components/transcendence-tier-utilization";
import { validateTierUtilization } from "./tier-utilization-validator";

export function validateTranscendenceTier(next: TranscendenceTierUtilization, previous: TranscendenceTierUtilization, forbiddenCharacters: Uuid[]) {
  var safeNext = Object.assign({}, createDefaultTranscendenceTierUtilization(), next)

  const previousTierIds = new Set(getGateCharacterIds(previous))
  const possibleCenterIds = new Set(getGateCharacterIds(safeNext))
  const forbiddenIds = forbiddenCharacters.filter(id => !previousTierIds.has(id))

  if (!!safeNext.Center.character1 && !possibleCenterIds.has(safeNext.Center.character1)) {
    const newTierUtilization = Object.assign({}, createDefaultTierUtilization(), safeNext.Center, {"character1": null, "call1": null})
    safeNext = Object.assign({}, safeNext, {"Center": newTierUtilization})
  }

  if (!!safeNext.Center.character2 && !possibleCenterIds.has(safeNext.Center.character2)) {
    const newTierUtilization = Object.assign({}, createDefaultTierUtilization(), safeNext.Center, {"character2": null, "call2": null})
    safeNext = Object.assign({}, safeNext, {"Center": newTierUtilization})
  }

  if (!!safeNext.Center.character3 && !possibleCenterIds.has(safeNext.Center.character3)) {
    const newTierUtilization = Object.assign({}, createDefaultTierUtilization(), safeNext.Center, {"character3": null, "call3": null})
    safeNext = Object.assign({}, safeNext, {"Center": newTierUtilization})
  }

  return Object.assign({}, new TranscendenceTierUtilization(validateTierUtilization(safeNext.Left, previous.Left, forbiddenIds),
    validateTierUtilization(safeNext.Right, previous.Right, forbiddenIds), validateTierUtilization(safeNext.Center, previous.Center, forbiddenIds)))
}
