import { Resource } from 'src/tracker/model/tracker-model'
import { DailyResourceStats, ResourceProperty } from '../resource-meta/resource-property'

export const exWeaponTokenProperty = new ResourceProperty(
  'ab8fd1cf-71f8-45c0-bac1-49f450a80143',
  'EX Weapon Tokens',
  'exWeaponTokens',
  Resource.EX_WEAPON_TOKENS,
  new DailyResourceStats(30, 1),
)
