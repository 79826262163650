import { gemProperty } from "../../property/resource/gem-property";
import { ResourceColumn } from "../planner-column";

export const gemColumn = new ResourceColumn(
  'Gems',
  '00_86_00_24th_8.png',
  'gems',
  gemProperty,
  "100px",
)
