import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { FunctionComponent } from 'react'
import {Element} from 'src/forecast/model/universal-data-model'
import {Summon, SummonType, Uuid} from 'src/forecast/model/universal-data-model'
import SummonImage from 'src/character-details/summon-passives/summon-image'
import {useSelector} from 'react-redux'
import { AppState } from 'src/store/root-reducer'

interface SummonPassivesProps {
  elements: Element[],
  characterSummonOverride: Record<Uuid, number[]>
}

const SummonPassives: FunctionComponent<SummonPassivesProps> = ({
  elements,
  characterSummonOverride,
}) => {
  const summonPassiveData = useSelector((state: AppState) => Object.values(state.forecast.universalData.summons))

  const renderSummonData = (summonPassiveData: Summon[], characterSummonOverride: Record<Uuid, number[]>,
    jsxReturnedFunction: (passivesDiffered: boolean, summonName: SummonType, summonData: Summon, passiveNumbers: number[], passiveNames: string[]) => JSX.Element | null): JSX.Element[] => {
    return summonPassiveData.map(summonData => {
      const elementsPassivesDiffered = summonData.elementType != undefined && elements.includes(summonData.elementType)
      var passiveNumbers

      const characterSpecificPassives = characterSummonOverride[summonData.id]
      if (!!characterSpecificPassives) {
        passiveNumbers = characterSpecificPassives
      } else if (elementsPassivesDiffered) {
        passiveNumbers = summonData.elementOverride
      } else {
        passiveNumbers = summonData.defaults
      }

      const passiveIndexes = passiveNumbers.map(passiveNumber => passiveNumber - 1)
      const passiveNames = passiveIndexes.map(passiveIndex => summonData.passives[passiveIndex])
      return jsxReturnedFunction(elementsPassivesDiffered || !!characterSpecificPassives, summonData.name, summonData, passiveNumbers, passiveNames)
    })
    .filter(element => !!element)
    .map(element => element as JSX.Element)
  }

  const renderTableForm = (passivesDiffered: boolean, summonName: SummonType, summonData: Summon, passiveNumbers: number[], passiveNames: string[]): JSX.Element | null => {
    const elements = passiveNumbers.map(passiveNumber => {
      return (<TableCell key={passiveNumber}><Typography color={passivesDiffered ? 'secondary' : 'initial' }>{passiveNumber}</Typography></TableCell>);
    })
    if (passiveNumbers.length !== 0) {
      return (
        <TableRow key={summonName}>
          <TableCell key='image' style={{ maxWidth: '42px' }}>
            <SummonImage summon={summonData} dimension='48px'/>
          </TableCell>
          {elements}
        </TableRow>
      )
    } else {
      return null
    }
  }

  if (!!summonPassiveData) {
    const tableForm =
      <Table>
        <TableBody>
          {renderSummonData(summonPassiveData, characterSummonOverride, renderTableForm)}
        </TableBody>
      </Table>
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h6'>Summon Passives:</Typography>
        {tableForm}
      </div>)
  }
  return null
}

export default SummonPassives
