import { Uuid } from "src/forecast/model/universal-data-model";
import { FilterSpecification, serializeFilterSpecifications, serializeSortSpecifications, SortSpecification } from "src/tracker/model/selector-model";
import { v4 as uuidgen } from 'uuid'

export interface SavedView {
  id: Uuid,
  name: string,
  filterSpecifications: string,
  sortSpecifications: string,
}

export const serializeSavedView = (name: string, filterSpecifications: FilterSpecification<any>[], sortSpecifications: SortSpecification[]) => {
  return {
    id: uuidgen(),
    name: name,
    sortSpecifications: serializeSortSpecifications(sortSpecifications),
    filterSpecifications: serializeFilterSpecifications(filterSpecifications),
  } as SavedView
}
