import { Resource } from 'src/tracker/model/tracker-model'
import { MogPassMode } from '../../mog-pass/mog-pass-mode'
import { ResourceProperty } from '../resource-meta/resource-property'

export const providenceCoreProperty = new ResourceProperty(
  '785ab73b-669f-4143-b4f2-358153f90bd9',
  'Providence Cores',
  'providenceCores',
  Resource.PROVIDENCE_CORES,
  null,
  (allocation, negate) => {
    const eventResources = allocation.event.eventResources
    return (allocation.mogPassMode === MogPassMode.PREMIUM ? eventResources.providenceCoresPremium : eventResources.providenceCores) * (!!negate ? -1 : 1)
  }
)

// TODO: add expenditure
