import { Character, Uuid } from "src/forecast/model/universal-data-model"
import { Region } from "src/tracker/model/components/region"
import { EditableCharacterProperty } from "src/tracker/model/property/editable-character-property"
import { CharacterData, generateDefaultCharacterData, Resource } from "src/tracker/model/tracker-model"
import { TotalAllocation } from "../../planner-model"
import { DailyResourceStats, ResourceProperty } from "./resource-property"

export class CharacterResourceProperty extends ResourceProperty {
  constructor(
    id: Uuid,
    name: string,
    propertyName: string,
    resource: Resource,
    dailyResourceStats: DailyResourceStats | null = null,
    eventAccessor?: (totalAllocation: TotalAllocation, negate?: boolean) => number,
  ) {
    super(id, name, propertyName, resource, dailyResourceStats, eventAccessor)
    this.getResourceFromCharacterDataAccessors = []
    this.getResourceFromCharacterData = (characterData: CharacterData, negate?: boolean) => {
      return this.getResourceFromCharacterDataAccessors.map(accessor => {
        return accessor(characterData, negate)
      }).reduce((a, b) => a + b, 0)
    }
    this.getResourceFromCharacterDataDifference = (currentCharacterData: CharacterData, previousCharacterData: CharacterData, negate?: boolean) => {
      const previousAllocationValue = this.getResourceFromCharacterData(previousCharacterData, negate)
      const currentAllocationValue = this.getResourceFromCharacterData(currentCharacterData, negate)
      return (currentAllocationValue - previousAllocationValue)
    }
    this.expenditureAccessors.push((totalAllocation: TotalAllocation, negate: boolean) => {
      return Object.entries(totalAllocation.eventAllocation.characterAllocations)
        .map(([characterId, characterAllocation]) => {
          const previousCharacterAllocation = totalAllocation.characterIdToPreviousCharacterAllocation[characterId]?.characterData || generateDefaultCharacterData()
          return this.getResourceFromCharacterDataDifference(characterAllocation, previousCharacterAllocation, negate)
        }).reduce((a, b) => a + b, 0)
    })
    this.getNeededResources = (totalAllocation: TotalAllocation) => {
      return this.resourcesListAccessor(totalAllocation.resourcesNeededInCharacters)
    }
  }
  getResourceFromCharacterData: (characterData: CharacterData, negate?: boolean) => number
  getResourceFromCharacterDataDifference: (currentCharacterData: CharacterData, previousCharacterData: CharacterData, negate?: boolean) => number
  getResourceFromCharacterDataAccessors: ((characterData: CharacterData, negate?: boolean) => number)[]
  getNeededResources: (totalAllocation: TotalAllocation, universalCharacterData: Record<Uuid, Character>, region: Region) => number
}

export const createStandardCharacterDataAccessor = (trackerProperty: EditableCharacterProperty<number>, trackerResource: Resource) => {
  return (characterData: CharacterData, negate?: boolean) => {
    const value = trackerProperty.getFromCharacterData(characterData)
    return trackerProperty.valueToResource(value, trackerResource) * (!!negate ? -1 : 1)
  }
}

export const createStandardCharacterResourceProperty = (
  id: Uuid,
  name: string,
  propertyName: string,
  trackerProperty: EditableCharacterProperty<number>,
  trackerResource: Resource,
  dailyResourceStats: DailyResourceStats | null = null
) => {
  const output = new CharacterResourceProperty(id, name, propertyName, trackerResource, dailyResourceStats)
  output.getResourceFromCharacterDataAccessors.push(createStandardCharacterDataAccessor(trackerProperty, trackerResource))
  return output
}
