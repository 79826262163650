import { powerStoneProperty } from "../../property/resource/power-stone-property";
import { ResourceColumn } from "../planner-column";

export const powerStoneColumn = new ResourceColumn(
  'Power Stones',
  'stl_limit_break_item_1_cropped.png',
  'powerStones',
  powerStoneProperty,
  undefined,
  'stl_limit_break_item_20_cropped.png',
  'highPowerStones',
)
