import { DatePropertyMode, DateTransienceMode, MiscDateProperty } from "./date-property"
import { Region } from "../components/region";
import { AllCharacterData } from "../components/all-character-data";
import { getEventAppearanceValue } from "src/utils/event-time-utilities";
import { resolveDatePropertyMode } from "./date-property-debut-scheme";
import { datePropertyModeToPrefix } from "./date-property-weapon-appearance-scheme";
import { MiscDefinitionValues } from "./misc-definition-values";
import { getDefinitionFromValue } from "./event-date/misc-definition-lookup";

const v1Schema = "v1"
const eventDateSchema = "eventdatemiscdefinition"

export const generateDateProperty = (definitionValue: MiscDefinitionValues, datePropertyMode: DatePropertyMode) => {
  const definition = getDefinitionFromValue(definitionValue)
  const resolvedPropertyMode = definition.dateTransienceMode === DateTransienceMode.INSTANT ? resolveDatePropertyMode(datePropertyMode) : datePropertyMode
  const id = v1Schema + ":" + eventDateSchema + ":" + MiscDefinitionValues[definition.id] + ":" + DatePropertyMode[resolvedPropertyMode]
  const prefix = datePropertyModeToPrefix(resolvedPropertyMode)
  const name = prefix + " " + definition.name + " date"
  const allCharacterDataToProperty = (region: Region, character: AllCharacterData | undefined) => getEventAppearanceValue(character, definition.eventAppearanceValuePredicate, resolvedPropertyMode, region)
  return new MiscDateProperty(id, name, name, definition.dateTransienceMode, datePropertyMode, definition.id, allCharacterDataToProperty)
}

export const createFromIdDefinition = (id: string) => {
  const splitString = id.split(":")
  if (splitString[0] !== undefined && splitString[0] === v1Schema && splitString[1] !== undefined && splitString[1] === eventDateSchema && splitString[2] !== undefined && !!splitString[3] !== undefined) {
    const valueCandidate = MiscDefinitionValues[splitString[2] as keyof typeof MiscDefinitionValues]
    const modeCandidate = DatePropertyMode[splitString[3] as keyof typeof DatePropertyMode]
    if (valueCandidate !== undefined && modeCandidate !== undefined) {
      return generateDateProperty(valueCandidate, modeCandidate)
    }
  }
  return undefined
}
