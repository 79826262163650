import { fifteenCpProperty } from "./fifteen-cp-property";
import { thirtyFiveCpProperty } from "./thirty-five-cp-property";
import { exProperty } from "./ex-property";
import { ldProperty } from "./ld-property";
import { btProperty } from "./bt-property";
import { woiProperty } from "./woi-property";
import { silverWeaponProperty } from "./silver-weapon-property";
import { manikinProperty } from "./manikin-property";
import { ntProperty } from "./nt-property";
import { PropertyGroup } from "../../property-group";
import { frProperty } from "./fr-property";

export const pullableGroup = [fifteenCpProperty, thirtyFiveCpProperty, exProperty, ldProperty, btProperty, frProperty]

export const weaponGroup = new PropertyGroup("Weapon", [...pullableGroup, silverWeaponProperty, woiProperty, manikinProperty, ntProperty])

